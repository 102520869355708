import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordForm: any = FormGroup;
  userType:any;
  errors = {'description':[]};
  success = {'description':[]};
  constructor(public apiService: ApiService,public spinnerService :NgxSpinnerService) { }

  ngOnInit() {
    this.forgotpasswordForm = new FormGroup({
      'email': new FormControl('', [Validators.required,this.emailValidator])
    });
  }


  public emailValidator(control: FormControl) {
    //console.log(control.value);
    if (control.value && control.value.length > 0 && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(control.value)) {
      //valid
      return null;
    } else if(control.value != null && control.value.length > 0 && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(control.value))) {
      //invalid
      return {'isEmail': true};

    }else {
      return null;
    }
  }


  onSubmit(myForm: NgForm) {
    //console.log('successful.');
    this.success.description = [];
    this.errors.description = [];
    //console.log(myForm);
    this.spinnerService.show();
    if(myForm.status == "VALID") {
      this.apiService.forgotpassword({'email':myForm.value.email})
      .subscribe((res) => {
        //console.log(res);
        this.success.description.push('Thank you. If your email is registered with us then you’ll get a reset link on your registered Email ID');
        this.forgotpasswordForm.reset('');
        let el = document.getElementById('forgotPassword');
        el.scrollIntoView();
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
        this.spinnerService.hide();
      },
      error=> {
        //console.log(error); 
        this.spinnerService.hide();
        this.forgotpasswordForm.reset('');
        //console.log(error);
        //console.log(error.error.errors);
        this.errors.description = [];
        if (error.error.errors && error.error.errors.length > 0) {
          this.errors.description.push(error.error.errors[0]['description']);
        } else {
          this.errors.description.push('Network Issue, Please Try Again Later.');
        }

      });
    }
  }

  closeAlert() {
    this.errors.description = [];
  }

}
