import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
declare var google;
declare var $:any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  changeSettingsForm:FormGroup;
  error = {'description' :[]};
  success = {'description' :[]};
  userDetails = {'usertype':'','sub':''};
  city = '';
  state = '';
  country ='';
  postal_code= '';
  currentLng;
  currentLat;
  autocompleteItems =[];
  service;
  isCityhidden:boolean = true;
  isStatehidden:boolean = true;
  addressErr = false;
  constructor(private formBuilder: FormBuilder,public apiService:ApiService,private spinner: NgxSpinnerService,private router : Router) { 
    this.changeSettingsForm = new FormGroup({
      id: new FormControl('', Validators.required),
      Name: new FormControl('', [Validators.required,this.namealphabetsValidator]),
      Designation: new FormControl('', Validators.required),
      Contact: new FormControl('', Validators.required),
      Address: new FormControl('', Validators.required),
      city: new FormControl('', [Validators.required,this.alphabetsValidator]),
      state: new FormControl('', [Validators.required,this.alphabetsValidator]),
      country: new FormControl('', Validators.required),
      postal_code: new FormControl('')
    });
  }

  ngOnInit() {
    this.service = new google.maps.places.AutocompleteService();
    $('.dropdown').css('display','none');
    this.addressErr = false;
    this.changeSettingsForm = new FormGroup({
      id: new FormControl('', Validators.required),
      Name: new FormControl('', [Validators.required,this.namealphabetsValidator]),
      Designation: new FormControl('', Validators.required),
      Contact: new FormControl('', Validators.required),
      Address: new FormControl('', Validators.required),
      city: new FormControl('', [Validators.required,this.alphabetsValidator]),
      state: new FormControl('', [Validators.required,this.alphabetsValidator]),
      country: new FormControl('', Validators.required),
      postal_code: new FormControl('')
    });
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.getUser(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone']);
    }
  }

  public namealphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }
  
  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    // var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 2 && control.value.trim().length <= 50) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  getUser(mobileNo) {
    //console.log(mobileNo);    
    const data = {
      "filters" : [{
        "member": "mobileNo",
        "value": mobileNo,
        "operation": "EqualTo"
      }]
    }
    this.apiService.getUser(data).subscribe((response:any) => {
      //console.log(response);
      var userDetails = {};
      userDetails = response.content.payLoad[0];
      //console.log(userDetails['address']);
      this.currentLat = userDetails['address'] != null && userDetails['address'].location ? userDetails['address'].location.coordinates[1] : '';
      this.currentLng = userDetails['address'] != null && userDetails['address'].location ? userDetails['address'].location.coordinates[0] : '';
      this.changeSettingsForm.patchValue({
        id:userDetails['id'],
        Name: userDetails['name'],
        Designation: userDetails['userType'],
        Contact:userDetails['mobileNo'],
        Address:userDetails['address'] && userDetails['address'].addressLine ? userDetails['address'].addressLine : "",
        city:userDetails['address'] && userDetails['address'].city ? userDetails['address'].city : "",
        state:userDetails['address'] && userDetails['address'].state ? userDetails['address'].state : "",
        country:userDetails['address'] && userDetails['address'].country ? userDetails['address'].country : "",
        postal_code:userDetails['address'] && userDetails['address'].zipCode ? userDetails['address'].zipCode : ""
      });
      this.getCountryByName(userDetails['address'].country);
      //console.log(this.changeSettingsForm);

    }, error => {
      this.error = {'description' :[]};
      //console.log(error);
    });
  }

  editSettings() {
    //console.log(this.changeSettingsForm);
    this.success.description = [];
    let data = {
      "userId":this.changeSettingsForm.get('id').value,
      "name": this.changeSettingsForm.get('Name').value,
      "mobileNo": this.changeSettingsForm.get('Contact').value,
      "addressLine": this.changeSettingsForm.get('Address').value,
      "location": {
        "type": "Point",
        "coordinates": [this.currentLng,this.currentLat]
      },
      "city":this.changeSettingsForm.get('city').value,
      "state": this.changeSettingsForm.get('state').value,
      "country":this.changeSettingsForm.get('country').value,
      "postal_code":this.changeSettingsForm.get('postal_code').value,
    }
    //console.log(data);
    this.apiService.changeSettings(data).subscribe(response => {
      //console.log(response);
      //Updated Successfully
      this.success.description.push('Profile Updated Successfully.');
      $('.alert').alert();
      function FetchData() {
        // do something
        $('.alert').alert('close');
      }
      setInterval(FetchData, 10000);
    }, error => {
      //Error while updating
      //console.log(error.error);
      //console.log(error.error.errors[0]);
      //console.log(error.error.errors[0].description);
      this.error = {'description' :[]};
      this.error.description.push(error.error.errors[0].description);
    })
  }

  getCountryByName(countryName) {
    this.spinner.show();
    let data = {
      "filters": [{
        "member": "country", "value": countryName, "operation": "EqualTo"
      }]
    }
    this.apiService.getCountryList(data).subscribe((response: any) => {
      this.spinner.hide();
      //console.log(response, "this.getCountryList");
      this.userDetails['countryCode'] = response['content'].payLoad[0].countryCode;
      //console.log(this.countryList, "this.getCountryList");
    }, error => {
      this.spinner.hide();
    });
  }


  getAddress() {
    this.autocompleteItems = [];
    let me = this;
    this.addressErr = false;
    this.changeSettingsForm.get('city').reset('');
    this.changeSettingsForm.get('state').reset('');
    var regex = /[^a-zA-Z0-9(),.-/\ ]/;
    // this.spinner.show()    
    if (this.changeSettingsForm.get('Address').value.length >= 10 && !regex.test(this.changeSettingsForm.get('Address').value)) {
      this.apiService.getPlacePredictions(this.changeSettingsForm.get('Address').value, this.userDetails['countryCode']).then(result => {
        this.spinner.hide();
        //console.log(result);
        //console.log(typeof result);
        this.autocompleteItems = <Array<any>>result;
        //console.log(this.autocompleteItems);
        $('.dropdown').css('display', 'inline');
      }, error => {
        console.error(error);
        this.spinner.hide();
      });
    } else {
      this.addressErr = true;
      this.spinner.hide();
    }
  }

  chooseItem(item: any) {
    //console.log(item);
    $('.dropdown').css('display','none');
    this.changeSettingsForm.patchValue({
      Address : item.description
    })
    this.autocompleteItems = [];
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'placeId': item.place_id}, (results, status) => {
      //console.log(results);
      //console.log(results[0].address_components);
      if(results.length > 0) {
        results[0].address_components.forEach(element => {
        //console.log(element.types[0]);
        if(element.types[0] == 'administrative_area_level_2') {
          //console.log(element.long_name);
          this.city = element.long_name;
          this.changeSettingsForm.patchValue({
            city:element.long_name ? element.long_name : ""
          })
          //get City
        }
        if(element.types[0] == 'administrative_area_level_1') {
          //console.log(element.long_name);
          this.state = element.long_name;
          this.changeSettingsForm.patchValue({
            state:element.long_name ? element.long_name : ""
          })
          //get State
        }
        if(element.types[0] == 'country') {
          //console.log(element.long_name);
          this.country = element.long_name;
          this.changeSettingsForm.patchValue({
            country:element.long_name ? element.long_name : ""
          })
          //get Country
        }
        if(element.types[0] == 'postal_code') {
          //console.log(element.long_name);
          this.changeSettingsForm.patchValue({
            postal_code:element.long_name ? element.long_name : ""
          })
          this.postal_code = element.long_name;
          //get Postal
        }
        });
      } else {
        this.changeSettingsForm.get('city').reset('');
        this.changeSettingsForm.get('state').reset('');
      }
      //console.log(this.changeSettingsForm.get('city').value);
      //console.log(this.changeSettingsForm.get('state').value);
      if(this.changeSettingsForm.get('city').value == undefined || this.changeSettingsForm.get('city').value == "") {
        this.isCityhidden = false;
      }
      if(this.changeSettingsForm.get('state').value == undefined || this.changeSettingsForm.get('state').value == "") {
        this.isStatehidden = false;
      }
      if (status == 'OK') {
        var lat = results[0].geometry.location.lat();
        var lng = results[0].geometry.location.lng();
        //console.log(lat, lng);
        this.currentLat = lat;
        this.currentLng = lng;
      }
    });
  }

  cancelChanges() {
    this.router.navigate(['/serviceStations']);
  }
}
