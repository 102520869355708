import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-adminmain',
  templateUrl: './adminmain.component.html',
  styleUrls: ['./adminmain.component.css']
})
export class AdminmainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  sidebarClicked() {
    //console.log('+++++++++show+++++++++++++++++++++++++')
    $(".page-wrapper").removeClass("toggled");
  }
  
  sidebarClickedadd() {
    //console.log('+++++++++show+++++++++++++++++++++++++')
    $(".page-wrapper").addClass("toggled");
  }

}
