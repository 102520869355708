import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpResponse } from '@angular/common/http';
import { ResponseContentType } from '@angular/http';
import { MessagingService } from "./shared/messaging.service";
import { environment } from "../environments/environment";
import { RouterModule, Routes, Router } from '@angular/router';
import { map, catchError } from "rxjs/operators";
import { Observable } from 'rxjs';
declare var google;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  jwtHelper: any;
  service;
  baseUrl:string = environment.baseUrl;
  refreshTokenHeader = 'set-authorization';

  

  checkTokenExpiry(exp) {
    //console.log(exp);
    const date = new Date(0);
    date.setUTCSeconds(exp);
    if (date === null) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  headers() {
    //Api for authentication
    if (localStorage['access_token'] != null) {
      var token = localStorage['access_token'];
      //console.log(token);
      if(JSON.parse(localStorage.getItem('userDetails')) != null) {
        if (this.checkTokenExpiry(JSON.parse(localStorage.getItem('userDetails'))['exp'])) {
          this.messagingService.requestPermission();
          setTimeout(() => {
            //CALL LOGOUT FUNCTION
            this.logout({ userId: localStorage['userId'] });
            var userId = localStorage['userId'];
            localStorage.clear();
            localStorage.setItem('userId', userId);
            this.router.navigate(['/login']);
          }, 1000)
        } else {

          let acceptLang = 'en-US'
          if (JSON.parse(localStorage.getItem('userDetails')) != null) {
            let userDetail = JSON.parse(localStorage.getItem('userDetails'))
            if (userDetail) {
              acceptLang = userDetail['languagecode'] + '-' + userDetail['countryCode'];
            }
          }
          const httpOptions = new HttpHeaders({
            'Authorization': "Bearer " + localStorage['access_token'],
            'Accept-Language': acceptLang,
          })
          return httpOptions;
        }
      }
      else {
        setTimeout(() => {
          //CALL LOGOUT FUNCTION
          this.logout({ userId: localStorage['userId'] });
          var userId = localStorage['userId'];
          localStorage.clear();
          localStorage.setItem('userId', userId);
          this.router.navigate(['/login']);
        }, 1000);
      }
    }
  }

  constructor(private messagingService: MessagingService, public http: HttpClient, private helper: JwtHelperService, private router: Router) {
    this.jwtHelper = new JwtHelperService();
    //console.log('Hello ApiProvider Provider');
    
  }

  login(data) {
    //console.log(data);
    //console.log(environment.production);
    // if(!environment.production) {
    //   const httpOptions =  new HttpHeaders({
    //     'bypass-captcha-validation' : 'hjk'
    //   })
    //   return this.http.post(this.baseUrl + '/user/login',data,{headers:httpOptions})
    // } else {
    //   return this.http.post(this.baseUrl + '/user/login',data)
    // }
    return this.http.post(this.baseUrl + '/user/login', data)
  }


  getuserdetails() {
    let acceptLang = 'en-US'
    const httpOptions = new HttpHeaders({
      'Authorization': "Bearer " + localStorage['access_token'],
      'Accept-Language': acceptLang,
    })
    return this.http.get(this.baseUrl + '/user/userdetails', { headers: httpOptions})
  }

  forgotpassword(data) {
    //console.log(data);
    return this.http.post(this.baseUrl + '/user/forgotpassword', data)
  }

  initiatelogout() {
    return this.http.get(this.baseUrl + '/user/initiatelogout', { headers: this.headers()})
  }

  cancellogout() {
    return this.http.get(this.baseUrl + '/user/cancellogout', { headers: this.headers()} )
  }

  getCaptcha() {
    const httpOptions = new HttpHeaders({});
    // const options = {headers: httpOptions, responseType: ResponseContentType.Blob};
    return this.http.get(this.baseUrl + '/user/captcha', { headers: httpOptions, observe: 'response' })
  }

  logout(data) {
    return this.http.post(this.baseUrl + '/user/logout', data).pipe(
      map((res: any) => {
        // //console.log(res);
        return res;
      })
    );
  }

  refreshToken(response) {
    //console.log(response);
    // //console.log(response.header);
    if (response.headers && response.headers.get(this.refreshTokenHeader)) {
      // //console.log('refreshed');
      localStorage.setItem('access_token', response.headers.get(this.refreshTokenHeader));
    }
    return response && response.body ? response.body : response;
  }

  getMechanic(data) {
    return this.http.post(this.baseUrl + '/mechanic/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        // //console.log(res.headers.get());
        //console.log(this.refreshToken(res));
        return this.refreshToken(res);
      })
    )
  }

  addMechanic(data) {
    return this.http.post(this.baseUrl + '/user/registermechanic', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  editMechanic(data) {
    return this.http.put(this.baseUrl + '/mechanic/update', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getServicestations(data) {
    return this.http.post(this.baseUrl + '/servicestation/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  addServicestations(data) {
    return this.http.post(this.baseUrl + '/servicestation/create', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  editServicestations(data) {
    return this.http.put(this.baseUrl + '/servicestation/update', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }


  addFla(data) {
    return this.http.post(this.baseUrl + '/admin/createfla', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  editFla(data) {
    return this.http.put(this.baseUrl + '/admin/updatefla', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  deleteFla() {

  }

  getUser(data) {
    return this.http.post(this.baseUrl + '/user/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getServiceType(data) {
    return this.http.post(this.baseUrl + '/servicetype/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getApplicationType(data) {
    return this.http.post(this.baseUrl + '/applicationType/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  createServiceRequest(data) {
    //console.log(data);

    const httpOptions = new HttpHeaders({
      'Authorization': "Bearer " + localStorage['access_token'],
      "Content-Type": 'application/json'
    })
    return this.http.post(this.baseUrl + '/servicerequest/create', data, { headers: httpOptions }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  getServiceStationByVicinity(data) {
    return this.http.post(this.baseUrl + '/servicestation/byvicinity', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getServiceRequest(data) {
    return this.http.post(this.baseUrl + '/servicerequest/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  assignRequesttoMech(data) {
    return this.http.post(this.baseUrl + '/servicerequest/assign', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getHistoryByAdmin(data) {
    return this.http.post(this.baseUrl + '/servicerequest/historybyadmin', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getserviceHistoryByAdmin(data) {
    return this.http.post(this.baseUrl + '/reports/getservicehistory', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getServiceHistoryByCountryhead(countryHeadId, data) {
    return this.http.post(this.baseUrl + '/reports/getservicehistorybycountryhead?countryHeadId=' + countryHeadId, data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getServiceHistoryByFla(flaId, data) {
    return this.http.post(this.baseUrl + '/reports/getservicehistoryhistorybyfla?flaId=' + flaId, data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getServiceHistoryByDistributor(distributorId, data) {
    return this.http.post(this.baseUrl + '/reports/getservicehistorybydistributor?distributorId=' + distributorId, data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getHistoryByCountryhead(countryHeadId, data) {
    return this.http.post(this.baseUrl + '/servicerequest/historybycountryhead?countryHeadId=' + countryHeadId, data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getHistoryByDistributor(distributorId, data) {
    return this.http.post(this.baseUrl + '/servicerequest/historybydistributor?distributorId=' + distributorId, data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getHistoryByFla(flaId, data) {
    return this.http.post(this.baseUrl + '/servicerequest/historybyfla?flaId=' + flaId, data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getHistoryByExecutive(executiveId, data) {
    return this.http.post(this.baseUrl + '/servicerequest/historybyexecutive?executiveId=' + executiveId, data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  reschedule(data) {
    return this.http.post(this.baseUrl + '/servicerequest/reschedule', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  serviceRequestCancel(data) {
    return this.http.put(this.baseUrl + '/servicerequest/cancel', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }
  acceptServiceRequest(data) {
    return this.http.post(this.baseUrl + '/servicerequest/accept', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  updateServiceRequest (data) {
    return this.http.post(this.baseUrl + '/servicerequest/updatestatus', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  createInvoice (data) {
    return this.http.post(this.baseUrl + '/servicerequest/createinvoice', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  getCallcenterList(data) {
    return this.http.post(this.baseUrl + '/callcenter/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        this.refreshToken(res);
        // //console.log(res);
        return res;
      })
    );
  }

  addCallcenter(data) {
    return this.http.post(this.baseUrl + '/callcenter/create', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getcallcenterexecutiveList(data) {
    return this.http.post(this.baseUrl + '/admin/getcallcenterexecutives', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  addcallcenterexecutive(data) {
    return this.http.post(this.baseUrl + '/admin/createcallcenterexecutive', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  editcallcenterexecutive(data) {
    return this.http.put(this.baseUrl + '/admin/updatecallcenterexecutive', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getTractorModels(data) {
    return this.http.post(this.baseUrl + '/tractor/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getTractorbyModel(data) {
    return this.http.post(this.baseUrl + '/user/getTractorbyModel', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }
  /*adds tractor models under roles*/
  addTractorModels(data) {
    let query = {
      model: data
    }
    return this.http.post(this.baseUrl + '/tractor/create', query, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  /*adds tractor under users*/
  addTractors(data) {
    return this.http.post(this.baseUrl + '/user/addtractor', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  getTractors(userId) {
    return this.http.get(this.baseUrl + '/user/gettractors?userId=' + userId, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }
  editTractorModels(data) {
    let query = {
      model: data
    }
    return this.http.put(this.baseUrl + '/tractor/update', query, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  changePassword(data) {
    return this.http.post(this.baseUrl + '/user/changepassword', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getCountryList(data) {
    return this.http.post(this.baseUrl + '/country/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        // //console.log(res.body);
        // //console.log(res.header);
        // //console.log(res.headers);
        // //console.log(res.headers.get(this.refreshTokenHeader));
        // //console.log(this.refreshToken(res));
        return this.refreshToken(res);
      })
    );
  }

  getCountryhdList(data) {
    return this.http.post(this.baseUrl + '/admin/getallcountryhead', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  addCountryhd(data) {
    return this.http.post(this.baseUrl + '/admin/createcountryhead', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  editCountryhd(data) {
    return this.http.put(this.baseUrl + '/admin/updatecountryhead', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getAllDistributors() {
    //get all distributors
    return this.http.post(this.baseUrl + '/admin/getalldistributorbycountryheadid', { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }
  getDistributorByCountryhead(data) {
    //get distributor by countryheadid
    return this.http.post(this.baseUrl + '/admin/getalldistributorbycountryheadid', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getDistributorbyAdmin(data) {
    //get distributor by Admin
    return this.http.post(this.baseUrl + '/admin/getalldistributorforadmin', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  addDistributor(data) {
    return this.http.post(this.baseUrl + '/admin/createdistributor', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  editDistributor(data) {
    return this.http.put(this.baseUrl + '/admin/updatedistributor', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  changeStatus(body) {
    return this.http.get(this.baseUrl + '/user/changeStatus?userId=' + body, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  changeServicestationStatus(body) {
    return this.http.get(this.baseUrl + '/servicestation/changestatus?serviceStationId=' + body, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  changeTractorStatus(body) {
    return this.http.get(this.baseUrl + '/tractor/changestatus?tractorId=' + body, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  changeCustomerStatus(body) {
    return this.http.get(this.baseUrl + '/customer/changestatus/' + body, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  getFlabyAdmin(data) {
    return this.http.post(this.baseUrl + '/admin/getallflaforadmin', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getFlaByDistributor(data) {
    return this.http.post(this.baseUrl + '/admin/getallflabydistributorid', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getFlaByCountryhead(data) {
    return this.http.post(this.baseUrl + '/admin/getallflabycountryheadid', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  uploadVerbatim(data) {
    return this.http.post(this.baseUrl + '/servicerequest/uploadverbatim', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  /*API FOR REPORTS*/
  getCustomerregReport(data) {
    return this.http.post(this.baseUrl + '/reports/customerregistration', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  dumpExcelReports(data) {
    const options: {
      headers?: HttpHeaders | {
          [header: string]: string | string[];
      };
      observe: 'events';
      params?: HttpParams | {
          [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
  } = { responseType: 'blob', headers: this.headers() , observe: 'events'}

    return this.http.post(this.baseUrl + '/reports/downloadexcel', data, options)
      .pipe(
        map((res: HttpResponse<any>) => {
          console.log(res.headers);
          if(res.headers.has("content-disposition")) {
            let obj = {
              'content-disposition' : res.headers.get('content-disposition'),
              'blob': res.body
            }
            return obj;
          }
          else {
            let obj = {
              'content-disposition' : '',
              'blob': res.body
            }
            return obj;
          }
         
        }),
        catchError((error: any) => {
          throw error.error;
        })
      );
  }
  
  getCountrywisereport(data) {
    return this.http.post(this.baseUrl + '/reports/countrywiseperformance', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getFeedbackReport(data) {
    return this.http.post(this.baseUrl + '/reports/feedback', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }
  getCustomerserviceReport(data) {
    return this.http.post(this.baseUrl + '/reports/customerservicerequest', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }
  getserviceHitoryReport(data) {
    return this.http.post(this.baseUrl + '/reports/getservicehistory', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }
  getVideoverbatimReport(data) {
    return this.http.post(this.baseUrl + '/servicerequest/uploadverbatim', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getDistributorFlaReport(data) {
    return this.http.post(this.baseUrl + '/reports/distributorwisefla', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getDistributorTechnicianReport(data) {
    return this.http.post(this.baseUrl + '/reports/distributorwisetechnician', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  resetPassword(data) {
    return this.http.put(this.baseUrl + '/admin/updateuserpassword', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getallFeedback(data) {
    return this.http.post(this.baseUrl + '/feedbackquestions/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  createFeedback(data) {
    return this.http.post(this.baseUrl + '/feedbackquestions/create', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  editFeedback(data) {
    return this.http.put(this.baseUrl + '/feedbackquestions/update', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getallServicetypes(data) {
    return this.http.post(this.baseUrl + '/servicetype/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  createServicetype(data) {
    return this.http.post(this.baseUrl + '/servicetype/create', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  editServicetype(data) {
    return this.http.put(this.baseUrl + '/servicetype/update', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getallApplicationtypes(data) {
    return this.http.post(this.baseUrl + '/applicationType/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  createApplicationtype(data) {
    return this.http.post(this.baseUrl + '/applicationType/create', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  editApplicationtype(data) {
    return this.http.put(this.baseUrl + '/applicationType/update', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  gettechnicianforassignment(data) {
    return this.http.post(this.baseUrl + '/servicerequest/gettechnicianforassignment', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  changeSettings(data) {
    return this.http.post(this.baseUrl + '/user/update', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getUploadverbatim(data) {
    return this.http.post(this.baseUrl + '/servicerequest/getforverbatim', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  registerCustomer(data) {
    return this.http.post(this.baseUrl + '/user/registerbyfla', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  getmechanicstatus(data) {
    return this.http.get(this.baseUrl + '/user/getmechanicstatus?' + data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  uploadCustomer (data) {
    //console.log(data);
    return this.http.post(this.baseUrl + '/customer/upload', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  downloadTemplate(){
    //return this.http.get(this.baseUrl + '/customer/downloadtemplate', { })
    // .pipe(
    //   map((res: any) => {        
    //     return this.refreshToken(res);
    //   })
    // )

    // return this.http.get(this.baseUrl + '/customer/download?Id=1', { headers: this.headers(), observe: 'response' }).pipe(
    //   map((res: any) => {
    //     // //console.log(res);
    //     return this.refreshToken(res);
    //   });
      // reportProgress: true,
      // observe: 'events',
      // responseType: 'blob'
      return this.http.get(this.baseUrl + '/customer/download/1' , { headers: this.headers(), observe: 'response' }).pipe(
        map((res: any) => {
          // //console.log(res);
          return this.refreshToken(res);
        })
      )
  }

  

  exportContent(blobName) {
    // const options: {
    //   responseType: 'arraybuffer',
    // } = { responseType: 'arraybuffer'}



    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'arraybuffer',
      withCredentials?: boolean
    } = { responseType: 'arraybuffer', headers: this.headers() }

    return this.http.get(this.baseUrl +'/blob/download/'+blobName , options).pipe(
      map((res: any) => {
        console.log(res);
        return res;
        // return this.refreshToken(res);
      })
    )
  }

  getCustomers(data) {
    return this.http.post(this.baseUrl + '/customer/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  changeLanguage(data) {
    return this.http.post(this.baseUrl + '/user/changelanguage', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }


  getgeoAddress(placeId) {
    var geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ 'placeId': placeId }, (result, status) => {
        //console.log(result);
        //console.log(status);
        if (status == 'OK') {
          resolve(result);
        } else {
          resolve({});
        }
        // if(result.Response.View.length > 0) {
        //     if(result.Response.View[0].Result.length > 0) {
        //         resolve(result.Response.View[0].Result);
        //     } else {
        //         reject({ message: "no results found" });
        //     }
        // } else {
        //     reject({ message: "no results found" });
        // }
        return result;
      }, error => {
        reject(error);
      });
    });
  }

  getPlacePredictions(Address, countrycode) {
    var service = new google.maps.places.AutocompleteService();
    return new Promise((resolve, reject) => {
      service.getPlacePredictions({ input: Address, componentRestrictions: { country: countrycode } }, (result, status) => {
        //console.log(result);
        //console.log(status);
        if (status == 'OK') {
          resolve(result);
        } else {
          resolve({});
        }
        // if(result.Response.View.length > 0) {
        //     if(result.Response.View[0].Result.length > 0) {
        //         resolve(result.Response.View[0].Result);
        //     } else {
        //         reject({ message: "no results found" });
        //     }
        // } else {
        //     reject({ message: "no results found" });
        // }
        return result;
      }, error => {
        reject(error);
      });
    });
  }

  downloadCustomerregGraph(data,params) {
    var method = 'customerregistrationgraphbyall';
    if(params == 'all') {
      method = 'customerregistrationgraphbyall';
    } else {
      method = 'customerregistrationgraphbycountry';
    }
    return this.http.post(this.baseUrl + '/reports/'+method, data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }


  downloadtechReportGraph(data,params) {
    var method = 'alltechniciangraphreport';
    if(params == 'all') {
      method = 'alltechniciangraphreport';
    } else {
      method = 'countrywisetechniciangraphreport';
    }
    return this.http.post(this.baseUrl + '/reports/'+method, data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    )
  }

  forgetResetPassword(data) {
    return this.http.post(this.baseUrl + '/user/forgotresetpassword', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getallFeedbackQuestion(data) {
    return this.http.post(this.baseUrl + '/feedbackquestions/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  addFeedback(data) {
    return this.http.post(this.baseUrl + '/user/feedback', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getCustomerByModel(data) {
    return this.http.post(this.baseUrl + '/notification/all', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getCustomerByFLA(data) {
    return this.http.post(this.baseUrl + '/notification/getAllCustomerByFLA', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  sendNotification(data) {
    return this.http.post(this.baseUrl + '/notification/offernotification', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }

  getCustomerQueries(data) {
    return this.http.post(this.baseUrl + '/user/getAllCustomerQuery', data, { headers: this.headers(), observe: 'response' }).pipe(
      map((res: any) => {
        // //console.log(res);
        return this.refreshToken(res);
      })
    );
  }
}
