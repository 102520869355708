import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes,Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $ :any;

@Component({
  selector: 'app-tractor-list',
  templateUrl: './tractor-list.component.html',
  styleUrls: ['./tractor-list.component.css']
})
export class TractorListComponent implements OnInit {

  constructor(public apiService:ApiService,public router : Router,private spinner: NgxSpinnerService,private route: ActivatedRoute,private location: Location) { 
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if( params['id'] == 1) {
        this.location.replaceState("tractors");
        this.success.description = [];
        this.success.description.push('Tractor Model Added Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      } else if( params['id'] == 2) {
        this.location.replaceState("tractors");
        this.success.description = [];
        this.success.description.push('Tractor Model Updated Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      }
    });
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
  }
  selectedTractor = '';
  selectedStatus = '';
  tractorModelList = [];
  countryList = [];
  selectedCountry = "";
  success = {'description':[]};
  userDetails = {'usertype':'','sub':'','countryDetails':''};
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  ngOnInit() {
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.calllist();
    this.tractorModelList = [];
  }

  calllist()
  {
    if(this.userDetails.usertype == 'Admin') {
      // let query = {"filters":[{
      //   "member": "distributorId",
      //   "value": this.selectedDistributor,
      //   "operation": "EqualTo"
      // }]}
      // // let query = {}
      // this.getServiceStations(query);
      this.getCountryList();
      
    } else if(this.userDetails.usertype == 'CountryHead') {
      //when userType is CountryHead.
      // let data = {
      // "filters": [
      //   {
      //     "member": "country",
      //     "value": this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
      //     "operation": "EqualTo"
      //   }]
      // }
      // this.getServiceStations(data);
      //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      var counrtyDetails=JSON.parse(this.userDetails.countryDetails)
      this.countryList=counrtyDetails;
      this.selectedCountry=this.countryList[0].countryName;
      this.getTractorList();
    }
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response['content'].payLoad;
      // by default
      this.selectedCountry = this.countryList[0].countryName;
      this.getTractorList();
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
      this.spinner.hide();
    });
  }

  getTractorList() {
    this.tractorModelList =[];
    this.spinner.show();
    const filters = [];
    filters.push({
      "member": "country",
      "value": this.selectedCountry,
      "operation": "EqualTo"
    })
    if(this.selectedStatus  != "")
    {
      filters.push({
        "member": "isActive",
        "value": this.selectedStatus,
        "operation": "EqualTo"
      })
    }
      this.query = {"filters":filters,
      "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    };
   
    this.apiService.getTractorModels(this.query).subscribe(response => {
      //console.log(response);
      this.tractorModelList = []
      this.tractorModelList = response['content'].payLoad;
      
      this.spinner.hide();
      //console.log(this.tractorModelList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  addTractors() {
    this.router.navigate(['/tractors/add']);
  }

  resetFilters() {
    this.selectedCountry = "";
    this.tractorModelList = [];
  }

  editTractors(tractorDetails){
    //console.log(tractorDetails);
    this.router.navigate(['/tractors/edit'],{queryParams:{id:tractorDetails.id}});
  }
  
  changeStatus(modelId) {
    //console.log(modelId,"tractor");
    this.apiService.changeTractorStatus(modelId).subscribe(response => {
      //console.log(response);
      this.getTractorList();
    }, error => {
      //console.log(error);
    })
  }

  // showCountryModal(localizationDetails) {
  //   this.selectedCountry =[];
  //   //console.log(localizationDetails);
  //   $('#countrylistModal').modal('show');
  //   this.selectedCountry = localizationDetails;
  // }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    // this.getTractorList('next');
  }

  pageNavigated(state) {
    this.query = {};
    // this.getTractorList(state);
  }

  closeAlert() {
    this.success.description = [];
  }
}
