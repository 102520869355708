import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { RouterModule, Routes,Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-tractor-list',
  templateUrl: './add-tractor-list.component.html',
  styleUrls: ['./add-tractor-list.component.css']
})
export class AddTractorListComponent implements OnInit {
  addTractorModelForm:any;
  countryList = [];
  selectedItems = [];
  dropdownSettings = {};
  localizationDetails = [];
  language;
  error = {'description':[]};
  userDetails = {'usertype':'','sub':'','countryDetails':''};
  selectedCountry = '';
  selectedCountryhead = '';
  selectedCountryheadName = '';
  selectedDistributor = '';
  selectedDistributorName = '';
  constructor(private fb: FormBuilder,private location: Location, public apiService: ApiService,private router : Router,private spinner: NgxSpinnerService) {
    this.addTractorModelForm = this.fb.group({
      country: new FormControl('', [Validators.required]),
      modelname: new FormControl ('',[Validators.required,this.alphabetsValidator]),
    });
  }

  ngOnInit() {
    this.countryList = [];
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'countryName',
      textField: 'countryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      enableCheckAll:false,
      // allowSearchFilter: true
    };
    

    this.addTractorModelForm = this.fb.group({
      country: new FormControl('', [Validators.required]),
      modelname: new FormControl ('',[Validators.required,this.alphabetsValidator]),
    });

    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
    

    // this.callList();
    this.getCountryList({});
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z0-9 ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }
  callList() {
    if(this.userDetails.usertype == 'Admin') 
    {
      // this.getCountryList({});
    } else if(this.userDetails.usertype == 'CountryHead')
    {
      //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.countryList=JSON.parse(this.userDetails.countryDetails);
      this.selectedCountry =this.countryList[0].countryName
      this.addTractorModelForm.patchValue({
        country: this.selectedCountry
      })
      this.language =  this.userDetails['language']
    } else if(this.userDetails.usertype == 'Distributor'){
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.addTractorModelForm.patchValue({
        country: this.selectedCountry
      })
      this.language =  this.userDetails['language']
    } else if(this.userDetails.usertype == 'FrontLineAdvisor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.addTractorModelForm.patchValue({
        country: this.selectedCountry
      })
      this.language =  this.userDetails['language']
    } else if(this.userDetails.usertype ==  'CallCenterExecutive') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.addTractorModelForm.patchValue({
        country: this.selectedCountry
      })
      this.language =  this.userDetails['language']
    }
  }


  public alphanumericValidator(control: FormControl) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = false;
    var regex = /[^a-zA-Z0-9&\-+, ]/;
    if(control.value && control.value.trim().length >= 3 && control.value.trim().length <= 50 && (!isNaN(control.value) || !regex.test(control.value))) {
      isValid = true;
      return isValid ? null : { 'alphanumeric': true }
    }else if(!control.value) {
      return null;
    } else {
      return isValid ? null : { 'alphanumeric': false }
    }
  }

  onItemSelect(item: any) {
    this.localizationDetails = [];
    //console.log(item);
    //console.log(this.selectedItems);
    this.countryList.forEach(element => {
      if(element.countryName == item) {
        element.selected = !element.selected;
      }
    });
    //console.log(this.countryList);
  }
  onSelectAll(items: any) {
    //console.log(items);
    //console.log(this.selectedItems);
    //console.log(this.addTractorModelForm.value.country);
    if(this.selectedItems) {
      this.countryList.forEach(element => {
        element.selected = !element.selected;
      });
    }
  }

  getCountryList(data) {
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      this.callList();
      this.countryList.forEach(element => {
        element.selected = false;
      });
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
      
    });
  }

  onSelectcountry(countryName) {
    //console.log(countryName)
    this.language = '';
    this.countryList.forEach(element => {
      if(element.countryName == countryName) {
        this.language = element.languages[0].languageName
      }
    });
    //console.log(this.language)

  }

  addTractorModel() {
    //console.log(this.addTractorModelForm.value.country);
    // var localizationDetails = [];
    // //console.log(localizationDetails);
    // let data = {
    //   "tractorName": this.addTractorModelForm.value.modelname,
    //   "localizationDetails": localizationDetails,
    //   "active": true,
    // }

    let data = {
      "tractorName": this.addTractorModelForm.value.modelname,
      "country": this.addTractorModelForm.value.country,
      "language": this.language
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.addTractorModels(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/tractors'],{queryParams:{id:1}});
      this.addTractorModelForm.reset();
    },error => {
      //console.log(error);
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }
}
