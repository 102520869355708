import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes,Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $:any;

@Component({
  selector: 'app-fla-list',
  templateUrl: './fla-list.component.html',
  styleUrls: ['./fla-list.component.css']
})
export class FlaListComponent implements OnInit {

  constructor(public apiService:ApiService,private router : Router,private route:ActivatedRoute,private spinner: NgxSpinnerService,private location: Location) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if( params['id'] == 1) {
        this.location.replaceState("fla");
        this.success.description = [];
        this.success.description.push('FLA Added Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      } else if( params['id'] == 2) {
        this.location.replaceState("fla");
        this.success.description = [];
        this.success.description.push('FLA Updated Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      }
    });
   }
   
  countryList = [];
  countryheadList = [];
  distributorList = [];
  selectedCountry = '';
  selectedCountryhead = '';
  selectedDistributor = '';
  selectedStatus = '';
  selectedDistributorName = '';

  flaList = [];
  success = {'description':[]};
  userDetails = {'usertype':'','sub':'','countryDetails':''};
  selectedUser = {};
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  ngOnInit() {
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
      this.callList('next');
    }
  }

  callList(state) {
    if(this.userDetails.usertype == 'Admin') {
      this.getCountryList();
    } else 
    if(this.userDetails.usertype == 'CountryHead') 
    {
      //console.log("country head");
      this.countryList=JSON.parse(this.userDetails.countryDetails)
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['given_name'];
      this.getDistributorByCountryhead(this.userDetails['userid']);
    } else 
    if(this.userDetails.usertype == 'Distributor')
    {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['userid'];
      this.selectedDistributorName = this.userDetails['given_name']; 
      this.filterChanged('next');
      //when userType is Distributor.
      //patch country head name , language & country by logged in user details.
    }
  }  

  resetFilter() {
    this.flaList = [];
    if(this.userDetails.usertype == 'Admin') {
      this.selectedCountry = '';
      this.selectedCountryhead = '';
      this.selectedDistributor = '';
      this.selectedDistributorName = '';
      this.countryheadList =[];
      this.distributorList = [];
    } else if(this.userDetails.usertype == 'CountryHead') {
      this.selectedDistributor = '';
      this.selectedDistributorName = '';
    }
    this.selectedStatus = '';
    this.currentPage = 1;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.selectedRecord = "20";
    this.query = {};
  }

  getCountryList() {
    this.spinner.show();
    this.countryList = [];
    this.selectedCountry = "";
    this.countryheadList = [];
    this.selectedCountryhead = "";
    this.distributorList = [];
    this.selectedDistributor = "";
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      // By default
      if(this.countryList.length > 0) {
        this.selectedCountry = this.countryList[0].countryName;
        this.getCountryheadList();
        //console.log(this.countryList, "this.getCountryList");
      }
    },error=>{
      this.spinner.hide();
    });
  }

  getCountryheadList() {
    //console.log(this.countryheadList);
    let query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ]
    }
    this.countryheadList = [];
    this.flaList = [];
    this.selectedCountryhead = "";
    this.distributorList = [];
    this.selectedDistributor = '';
    this.spinner.show();
    this.apiService.getCountryhdList(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.countryheadList = response['content'];
      this.countryheadList.length != 0 ? this.selectedCountryhead = this.countryheadList[0].id : this.countryheadList;
      this.onselectCountryhead(this.selectedCountryhead);
      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }
  onselectCountryhead(countryHeadId) {
    //console.log(countryHeadId);
    /*if user id admin*/
    if(this.userDetails['usertype'] == 'Admin') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId);
    }
  }

  getDistributorByCountryhead(countryHeadId) {
    //console.log(this.distributorList);
    let data = {
      "options" :{},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      this.distributorList.length != 0 ? this.selectedDistributor = this.distributorList[0].id : this.distributorList;
      this.filterChanged('next');
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  clickOnFilter()
  {
    this.currentPage = 1;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state)
  {
    this.flaList = [];
    const filters = [];
    filters.push({
      "member":"distributorId",
      "value":this.selectedDistributor,
    })
    if(this.selectedStatus) {
      filters.push({
        "member":"status",
        "value":this.selectedStatus
      })
    }
    this.query = {
      "filters" :filters,
      "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    this.getFlalist(state)
  }

  getFlalist(state)
  {
    this.spinner.show();
    this.apiService.getFlabyAdmin(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.flaList = response['content'].payLoad;
      //console.log(this.flaList);
      this.saveTokens(state,response);
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  // getFlabyAdmin(state) {
  //   //console.log(this.flaList);
  //   const filters = [];
  //   if(this.selectedCountry) {
  //     filters.push({
  //       "member":"distributorId",
  //       "value":this.selectedDistributor,
  //     })
  //   }
  //   if(this.selectedStatus) {
  //     filters.push({
  //       "member":"status",
  //       "value":this.selectedStatus
  //     })
  //   }
  //   this.spinner.show();
  //   this.query = {
  //     "filters" :filters,
  //     "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
  //     "itemsPerPage": this.selectedRecord,
  //     "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
  //     "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null}
  //   this.apiService.getFlabyAdmin(this.query).subscribe(response => {
  //     //console.log(response);
  //     this.spinner.hide();
  //     this.flaList = response['content'].payLoad;
  //     //console.log(this.flaList);
  //     this.saveTokens(state,response);
  //   }, error => {
  //     //console.log(error);
  //     this.spinner.hide();
  //   })
  // }

  // getFlaByCountryhead(state) {
  //   //console.log(this.flaList);
  //   this.spinner.show();
  //   this.query ={"countryHeadId": localStorage.userId,
  //   "options":{
  //     "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
  //     "itemsPerPage": this.selectedRecord,
  //     "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
  //     "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null}}
  //   this.apiService.getFlaByCountryhead(this.query).subscribe(response => {
  //     //console.log(response);
  //     this.flaList = response['content'].payLoad;
  //     this.spinner.hide();
  //     //console.log(this.flaList);
  //     this.saveTokens(state,response);
  //   }, error => {
  //     this.spinner.hide();
  //     //console.log(error);
  //   })
  // }

  // getFlaByDistributor(state) {
  //   //console.log(this.flaList);
  //   this.spinner.show();
  //   this.query ={"distributorId": localStorage.userId,
  //   "options":{
  //     "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
  //     "itemsPerPage": this.selectedRecord,
  //     "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
  //     "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null}}
  //   this.apiService.getFlaByDistributor(this.query).subscribe(response => {
  //     //console.log(response);
  //     this.flaList = response['content'].payLoad;
  //     //console.log(this.flaList);
  //     this.saveTokens(state,response);      
  //     this.spinner.hide();
  //     //console.log(this.flaList);
  //   }, error => {
  //     this.spinner.hide();
  //     //console.log(error);
  //   })
  // }

  saveTokens(state,response) {
    if(response['content'].queryOptions) {
      if(state == 'next') {     
        //console.log(state); 
        if(response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "ifffffffffff"); 
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
          this.continuationToken = continuationToken;
        } else if(!response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff"); 
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      } else if(this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults){
        //console.log(state + '-->'+ "else ifffffffffff 1111111111"); 
        this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        let continuationToken = [];
        continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
        this.continuationToken = continuationToken;
      } else if(!response['content'].queryOptions.hasMoreResults) {
        //console.log(state + '-->'+ "else ifffffffffff no resultssssssssss"); 
        this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
      }
    }
  }

  addFla() {
    this.router.navigate(['/fla/add']);
  }

  editFla(fladetails) {
    //console.log(fladetails);
    this.router.navigate(['/fla/edit'],{queryParams:{id:fladetails.id}});
  }

  changeStatus(flaId) {
    //console.log(flaId,"distributor");
    this.spinner.show();
    this.apiService.changeStatus(flaId).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.currentPage = 1;
      this.query = {};
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      // this.callList('next');
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  openModal(data) {
    //console.log(data);
    $('#resetPassword').modal('show');
  }

  closeModal(){
    $('#resetPassword').modal('hide');
    this.success.description = [];
    this.success.description.push(' Password Reset Successfully.');
    var scope = this;
    setInterval(function(){
      scope.success.description = [];
    }, 5000);
    this.filterChanged('next');
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    this.query = {};
    //console.log(obj.state)
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }

  closeAlert() {
    this.success.description = [];
  }
}
