import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes,Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $:any;
@Component({
  selector: 'app-add-feedback',
  templateUrl: './add-feedback.component.html',
  styleUrls: ['./add-feedback.component.css']
})
export class AddFeedbackComponent implements OnInit {

  constructor(public apiService:ApiService,private router : Router,private spinner: NgxSpinnerService,private location: Location) { }
  countryList = [];
  defaultQuestion = '';
  defaultStatus = false;
  isValid : boolean = true;
  nativeQuestion : boolean = false;
  error = {'description':[]};
  ngOnInit() {
    this.getCountryList();
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      if(this.countryList.length > 0) {
        this.countryList.forEach(element => {
          element.nativeQuestion = "";
          element.active = true;
        });
      }
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
      this.spinner.hide();
    });
  }

  changeStatus(id) {
    this.countryList.forEach(element => {
     if(element.id == id) {
      element.active = !element.active;
     }
    });
    //console.log(this.countryList);
  }

  addFeedback() {
    const questions = [];
    //console.log(this.countryList);
    questions.push({
      "question": this.defaultQuestion ? this.defaultQuestion.trim() : '',
      "country": "Default",
      "language": "English",
      "active": true
    })
    
    this.countryList.forEach(element => {
      if(element.nativeQuestion.length > 0) {
        questions.push({
          "question": element.nativeQuestion ? element.nativeQuestion.trim() : '',
          "country": element.countryName,
          "language": element.languages[0].languageName,
          "active": element.active
        })
      }
    });

    // if(model.length > 0) {

    // }

    
    //console.log(questions);
    let data = {model:questions};
    this.spinner.show();
    this.apiService.createFeedback(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/feedbackQB'],{queryParams:{id:1}});
    },error => {
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  public alphanumericValidator(control) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    this.isValid = false;
    var regex = /[^0-9a-zA-Z.,!?& ]/;
    //console.log(regex.test(control));
    //console.log(control);
    if(control && control.trim().match(/^[0-9a-zA-Z.,!?& ]+$/) && control.trim().length >= 4 ) {
      this.isValid = true;  
      this.defaultStatus = true;
      return true;
    } else if(control && control.trim() == "") {
      this.isValid = false;
      this.defaultStatus = false;     
      return false;
    } else {
      this.isValid = false;   
      this.defaultStatus = false;  
      return false;
    } 
  }

  changedefaultStatus() {
    if(this.defaultQuestion && this.defaultQuestion.length > 0) {
      this.alphanumericValidator(this.defaultQuestion);
    } else {
      this.defaultStatus = false;
    }
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  addNativeQuestion() {
    //console.log(this.countryList);
    this.nativeQuestion = false;
    this.countryList.forEach(element => {
      element.serviceTypeError = false;
      if(element.nativeQuestion && element.nativeQuestion.trim().length >=4) {
        //console.log('valid');
        element.serviceTypeError = false;
        element.active = true;
        this.nativeQuestion = true;
      } else if(element.nativeQuestion && element.nativeQuestion.trim().length >= 0) {
        //console.log('in valid');
        element.serviceTypeError = true;
        this.nativeQuestion = false;
        element.active = false;
      } else if(element.nativeQuestion && element.nativeQuestion.trim() == "") {
        //console.log('in valid');
        element.serviceTypeError = true;
        this.nativeQuestion = false;
        element.active = false;
      }
    });
  }

  closeAlert() {
    this.error.description = [];
  }
}
