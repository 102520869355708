import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.css']
})
export class ServiceListComponent implements OnInit {
  servicecenterList = [];
  countryList = [];
  countryheadList = [];
  distributorList = [];
  myData = "here im.....";
  success = { 'description': [] };
  userDetails = { 'usertype': '', 'sub': '','countryDetails':'' };
  selectedCountry = '';
  selectedCountryhead = '';
  selectedStatus = "";
  selectedDistributor = '';
  selectedDistributorName = '';
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  constructor(public apiService: ApiService, private router: Router, private spinner: NgxSpinnerService, private route: ActivatedRoute, private location: Location) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if (params['id'] == 1) {
        this.location.replaceState("serviceStations");
        this.success.description = [];
        this.success.description.push('Service Station Added Successfully.');
        var scope = this;
        setInterval(function () {
          scope.success.description = [];
        }, 5000);
      } else if (params['id'] == 2) {
        this.location.replaceState("serviceStations");
        this.success.description = [];
        this.success.description.push('Service Station Updated Successfully.');
        var scope = this;
        setInterval(function () {
          scope.success.description = [];
        }, 5000);
      }
    });
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
  }

  ngOnInit() {
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }]
    this.callList();
  }

  callList() {
    if (this.userDetails.usertype == 'Admin')
    {
      this.getCountryList(true);
    } else if (this.userDetails.usertype == 'CountryHead') 
    {
      //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      var counrtyDetails=JSON.parse(this.userDetails.countryDetails)
      this.countryList=counrtyDetails;
      this.selectedCountry=this.countryList[0].countryName;
      this.selectedCountryhead = this.userDetails['given_name'];
      this.getCountryheadList(true);
      this.getDistributorByCountryhead(this.userDetails['userid'],true);
    } else if (this.userDetails.usertype == 'Distributor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['userid'];
      this.selectedDistributorName = this.userDetails['given_name'];
      this.filterChanged('next');
    } else if (this.userDetails.usertype == 'FrontLineAdvisor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['distributorId'];
      this.selectedDistributorName = this.userDetails['distributorName'];
      this.filterChanged('next');
    } else if (this.userDetails.usertype == 'CallCenterExecutive') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['distributorId'];
      this.selectedDistributorName = this.userDetails['distributorName'];
      this.filterChanged('next');
    }
  }

  getCountryList(isinitial) {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.servicecenterList = [];
      this.countryList = response.content.payLoad;
      // by default
      if (response.content.payLoad.length > 0) {
        this.selectedCountry = this.countryList[0].countryName;
        this.getCountryheadList(isinitial);
        //console.log(this.countryList, "this.getCountryList");
      }
    }, error => {
      this.spinner.hide();
    });
  }

  getCountryheadList(isinitial) {
    //console.log(this.countryheadList);
    this.selectedCountryhead = "";
    this.selectedDistributor = "";
    this.distributorList = [];
    let query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ]
    }
    this.spinner.show();
    this.apiService.getCountryhdList(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.servicecenterList = [];
      this.countryheadList = response['content'];
      // by defaulat
      if(this.userDetails.usertype==="CountryHead")
      {
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.countryheadList[0].id,isinitial)

      }else
      if (this.countryheadList.length > 0) {
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.countryheadList[0].id,isinitial)
      }

      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getDistributorByCountryhead(countryHeadId,isinitial) {
    //console.log(this.distributorList);
    this.servicecenterList = [];
    let data = {
      "options": {},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      // by defaulat
      if (this.distributorList.length > 0) {
        this.selectedDistributor = this.distributorList[0].id
        if(isinitial) {
          this.filterChanged('next');
        }
      }
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getServiceStationList(state) {
    this.servicecenterList = [];
    this.spinner.show();
    this.apiService.getServicestations(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.servicecenterList = response['content'].payLoad;
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      //console.log(this.servicecenterList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }
  addservice_station() {
    this.router.navigate(['/serviceStations/add']);
  }

  editservice_station(servicestation) {
    //console.log(servicestation);
    this.router.navigate(['/serviceStations/edit'], { queryParams: { id: servicestation.id } });
  }

  changeServicestationStatus(scenterId) {
    //console.log(scenterId,"Station Id");
    this.spinner.show();
    this.apiService.changeServicestationStatus(scenterId).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.currentPage = 1;
      this.query = {};
      this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
      this.filterChanged('next');
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  refresh() {
    //console.log('refresh');
  }


  onselectCountryhead(countryHeadId,isinitial) {
    //console.log(countryHeadId);
    //console.log(this.userDetails['usertype']);
    /*if user id admin*/
    if (this.userDetails['usertype'] == 'Admin') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId,isinitial);
    }
    if (this.userDetails['usertype'] == 'CountryHead') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId,isinitial);
    }
  }

  clickOnFilter() {
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state) {
    //console.log(this.servicecenterList);
    const filters = [];
    if(this.userDetails.usertype == 'FrontLineAdvisor') {
      filters.push({
        "member": "flaId",
        "value": this.userDetails['userid'],
        "operation": "EqualTo"
      })
    } else{
      filters.push({
        "member": "distributorId",
        "value": this.selectedDistributor,
        "operation": "EqualTo"
      })
    }
    if(this.selectedStatus  != "") {
      filters.push({
        "member": "Status",
        "value": this.selectedStatus,
        "operation": "EqualTo"
      })
    }
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    this.getServiceStationList(state);
  }

  resetFilter() {
    if (this.userDetails.usertype == 'Admin') {
      this.selectedCountry = "";
      this.selectedCountryhead = ""
      this.countryheadList = [];
      this.distributorList = [];
      this.selectedDistributor = "";
    } else if (this.userDetails.usertype == 'CountryHead') {
      this.selectedDistributor = "";
    }
    this.query = {};
    this.servicecenterList = [];
    this.currentPage = 1;
    this.hasMoreResults = false;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.selectedRecord = "20";
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    //console.log(obj, " state");
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }

  closeAlert() {
    this.success.description = [];
  }

}
