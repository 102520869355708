import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  sidebarClicked() {
    //console.log('+++++++++show+++++++++++++++++++++++++')
    $(".page-wrapper").removeClass("toggled");
  }
  
  sidebarClickedadd() {
    //console.log('+++++++++show+++++++++++++++++++++++++')
    $(".page-wrapper").addClass("toggled");
  }

}
