import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMyDpOptions } from 'mydatepicker';
import { DateService } from '../date.service';
declare var $: any;
@Component({
  selector: 'app-upload-video-verbatim',
  templateUrl: './upload-video-verbatim.component.html',
  styleUrls: ['./upload-video-verbatim.component.css']
})
export class UploadVideoVerbatimComponent implements OnInit {
  userDetails = { 'usertype': '', 'sub': '' };
  constructor(public apiService: ApiService, private router: Router, private spinner: NgxSpinnerService, public dateService: DateService, ) {
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
  }
  serviceRequests = [];
  requestImage = [];
  requestVideo: any = {};
  requestVerbatim;
  verbatimText = '';
  imageAttachment = [];
  verbatimStatus = '';
  startDateFilter: any;
  endDateFilter: any;
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public endDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    componentDisabled: true,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  ngOnInit() {

  }

  filterChanged() {
    this.getServiceRequest();
  }

  getServiceRequest() {
    const filters = [];
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }

    filters.push({
      "member": 'verbatimStatus',
      "value": this.verbatimStatus
    },
      {
        "member": 'flaId',
        "value": this.userDetails['userid']
      })
    this.spinner.show();
    this.apiService.getUploadverbatim({ filters }).subscribe(response => {
      this.spinner.hide();
      //console.log(response);
      this.serviceRequests = response['content'].payLoad;
      console.log(this.serviceRequests);
      if (this.serviceRequests.length > 0) {
        this.serviceRequests.forEach(element => {
          element.imageAttachment = [];
          if (element.attachments && element.attachments.length > 0) {
            element.attachments.forEach(attachmentElement => {
              //console.log(attachmentElement);
              attachmentElement.serviceRequestId = element.id;
              if (attachmentElement.contentType.startsWith('image')) {
                element.hasImage = true;
                element.imageAttachment.push(attachmentElement.url);
                // element.imageAttachment.push({url:'http://219.91.243.80/ecataloguestaging/api/images/image/ZGtTbkkxUG9jYTBhSXVKeUFEZ1Zwdz09'});
              } else if (attachmentElement.contentType.startsWith('video')) {
                //Check for video
                if (attachmentElement.nativeVerbatimText) {
                  //View Verbatim
                  element.hasVerbatim = true;
                } else {
                  //Upload Verbatim
                  element.hasVideo = true;
                }
              }
            });
          }
        });
      }
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  viewImage(serviceRequest) {
    this.imageAttachment = [];
    //console.log(serviceRequest);
    this.imageAttachment = [];
    if (serviceRequest.imageAttachment && serviceRequest.imageAttachment.length > 0) {
      serviceRequest.imageAttachment.forEach(element => {
        if (this.imageAttachment.length == 0) {
          this.imageAttachment.push({ url: element, active: true })
        } else {
          this.imageAttachment.push({ url: element, active: false })
        }
      });
    }
  }

  viewVideo(requestAttachment, type, serviceRequests) {
    this.requestVideo = {};
    this.requestVideo['url'] = null;
    //console.log(serviceRequests);
    //console.log(requestAttachment);
    if (type == 'view') {
      requestAttachment.forEach(element => {
        if (element.contentType.startsWith('video')) {
          this.requestVideo = element;
          $('#viewvideo_ID').remove();
          this.requestVideo = element;
          var video = $('<video />', {
            id: 'viewvideo_ID',
            src: this.requestVideo.url,
            type: 'video/mp4',
            width:"100%",
            height:"280px",
            controls: true
          });
          video.appendTo($('#view_ID'));
          $('#videoModal').modal('show');
        }
      });
      //console.log(this.requestVideo);

    } else {
      //console.log('fdwereew')
      requestAttachment.forEach(element => {
        //console.log(element.contentType.startsWith('video'));
        if (element.contentType.startsWith('video')) {
          $('#verbatimvideo_ID').remove();
          this.requestVerbatim = element;
          var video = $('<video />', {
            id: 'verbatimvideo_ID',
            src: this.requestVerbatim.url,
            type: 'video/mp4',
            width:"100%",
            height:"280px",
            controls: true
          });
          video.appendTo($('#verbatim_ID'));
          $('#verbatimModal').modal('show');
        }
      });
    }
  }

  uploadVerbatim(requestVerbatim) {
    //console.log(requestVerbatim);
    //console.log(this.verbatimText);
    let data = {
      "serviceRequestId": requestVerbatim.serviceRequestId,
      "attachmentId": requestVerbatim.id,
      "englishVerbatimText": "",
      "nativeVerbatimText": this.verbatimText.trim()
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.uploadVerbatim(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      $('#verbatimModal').modal('hide');
      this.verbatimText = '';
      this.filterChanged();
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  previousClicked(index) {
    //console.log('previous');
    //console.log(index);
    //console.log(this.imageAttachment);
    this.imageAttachment.forEach((element) => {
      element.active = false;
      if (index - 1 >= 0) {
        if (this.imageAttachment[index - 1]) {
          this.imageAttachment[index - 1].active = true;
        }
      } else {
        this.imageAttachment[this.imageAttachment.length - 1].active = true;
      }
    });
  }

  nextClicked(index) {
    //console.log('next');
    //console.log(index);
    //console.log(this.imageAttachment);
    this.imageAttachment.forEach((element) => {
      element.active = false;
      if (index + 1 < this.imageAttachment.length) {
        if (this.imageAttachment[index + 1]) {
          this.imageAttachment[index + 1].active = true;
        }
      } else {
        this.imageAttachment[0].active = true;
      }
    });
  }
  getUpdatedUrl() {
    return this.requestVideo.url;
  }

  closeModal() {
    //console.log('closssssssseeeee')
    this.verbatimText = "";
    $( "div" ).remove( "#verbatimvideo_ID" );
    $( "div" ).remove( "#viewvideo_ID" );
    $('#videoModal').modal('hide');
    $('#verbatimModal').modal('hide');
    this.requestVideo['url'] = null;
  }

  onstartDatechange(event) {
    //console.log(event);
    if (event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate;
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
    }
  }

  onendDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
    } else {
      this.endDateFilter = '';
    }
  }

  resetFilters() {
    this.verbatimStatus = "";
    this.serviceRequests = [];
  }

}
