import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../app/api.service';
import {Router} from '@angular/router';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  userDetails = {'usertype':''};
  constructor(private auth: ApiService,
    private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // //console.log(window.location.href);
      const allowedRoles = next.data.allowedRoles;
      // //console.log(this.userDetails.usertype);
      // //console.log(allowedRoles + "allowedRoles.....................");
      if(localStorage.getItem('userDetails') != null ) {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if(this.auth.headers() && allowedRoles.includes(this.userDetails.usertype)){
          //console.log('Inside AuthGuardGuard');
          return true;
        } else{
          //console.log('Show Error');
          localStorage.clear();
          this.router.navigate(['/login']);          
          // this.router.navigate(['/error']);
          // this.sidebarClicked();
          return false;
        }
      }
      // return false;
      // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
      //return allowedRoles.includes(decodeToken['role']);
    // return true;
  }

  sidebarClicked() {
    //console.log('+++++++++hide+++++++++++++++++++++++++');
    //Also take care of content component
    $(".page-wrapper").removeClass("toggled");
    $('.page-content').css('margin-left', '2%');
  }
}
