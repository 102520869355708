import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { TransfereService } from '../../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $:any;

@Component({
  selector: 'app-editcountryhead',
  templateUrl: './editcountryhead.component.html',
  styleUrls: ['./editcountryhead.component.css']
})
export class EditcountryheadComponent implements OnInit {
  editCountryheadForm:any;
  countryList = [];
  countryheadList = [];
  countryheadId;
  countryheadData = {};
  error = {'description':[]};
  maxLength = 10;
  minLength = 10;

  public loadContent: boolean = false; 
  public countryData = [];
  public multiCountryDropDownsettings = {};
  public selectedCountries = [];
  @ViewChild('multiSelect')  multiSelect;

  constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService,private router : Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private location: Location) {
    this.editCountryheadForm = this.fb.group({
      id:new FormControl('', [Validators.required]),
      // country: new FormControl('', [Validators.required]),
      // language: new FormControl('', [Validators.required]),
      countryheadname: new FormControl('', [Validators.required,this.alphabetsValidator]),
      email: new FormControl('',[Validators.required,this.emailValidator]),
      Contact:new FormControl('',[Validators.required]),
      // status: new FormControl(true,[Validators.required]),
      multicountry: new FormControl(this.countryList,Validators.required)
    });
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.countryheadId = params['id'];
      //console.log(this.countryheadId);
    })

    this.editCountryheadForm = this.fb.group({
      id:new FormControl('', [Validators.required]),
      // country: new FormControl('', [Validators.required]),
      // language: new FormControl('', [Validators.required]),
      countryheadname: new FormControl('', [Validators.required,this.alphabetsValidator]),
      email: new FormControl('',[Validators.required,this.emailValidator]),
      Contact:new FormControl('',[Validators.required]),
      // status: new FormControl(true,[Validators.required]),
      multicountry: new FormControl(this.countryList,Validators.required)
    });
    // this.editCountryheadForm.get('Contact').disable();
    this.getCountryList({});
    this.getCountryheadList();

    this.multiCountryDropDownsettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'countryName',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }
  
  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
    //console.log('valid');

      return null;
    } else if(control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');

      return {'inValid': true};
    }else {
      return null;
    }
  }

  public emailValidator(control: FormControl) {
    ////console.log(control.value);
    if (control.value && control.value.length > 0 &&  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value)) {
      //valid
      return null;
    } else if(control.value != null && control.value.length > 0 && !( /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value))) {
      //invalid
      return {'isEmail': true};
    }else {
      return null;
    }
  }

  getCountryList(data) {
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
    });
  }

  getCountryheadList() {
    //console.log(this.countryheadList);
    this.spinner.show();
    this.apiService.getCountryhdList({}).subscribe(response => {
      //console.log(response);
      this.countryheadList = response['content'];
      if(response['content'].length > 0) {
        this.countryheadList.forEach(element => {
          //console.log(element.id);
          //console.log(this.countryheadId);
          this.spinner.hide();
          if(element.id == this.countryheadId) {
            this.countryheadData = element;
            this.patchValue();
          }
          console.log(this.countryheadData);
          
        });
      }
      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  patchValue() {
    console.log(this.countryList);
    console.log(this.countryheadData);
    this.editCountryheadForm.patchValue({
      id:this.countryheadData['id'],
      // country:this.countryheadData['address'].country,
      // language: this.countryheadData['language'],
      countryheadname:this.countryheadData['name'] ,
      email:this.countryheadData['email'],
      Contact:this.countryheadData['mobileNo'],
      // status:this.countryheadData['active'],
      multicountry: new FormControl(this.countryList,Validators.required)
    })

    console.log(this.editCountryheadForm);
    this.selectedCountries=this.countryheadData['countryDetails']


   // this.onselectCountry(this.countryheadData['address'].country)
  }
  
  onselectCountry(countryName) {
    //console.log(countryName);
    if(countryName) {
      this.editCountryheadForm.get('Contact').enable();
      this.countryList.forEach(element => {
        if(element.countryName == countryName) {
          this.minLength = element.mobileNumberLength['min'];
          this.maxLength = element.mobileNumberLength['max'];
          this.editCountryheadForm.patchValue({
            language : element.languages[0].languageName
          })
        }
      });
    } else {
      // this.editCountryheadForm.get('Contact').disable();
      this.editCountryheadForm.get('Contact').reset('');
    }
  }

  editCountryhead() {
    //console.log(this.editCountryheadForm);
    // //console.log(this.editCountryheadForm.value.status);
    this.error.description = [];
    let data = {
      "id":this.editCountryheadForm.value.id,
      "name": this.editCountryheadForm.value.countryheadname,
      "mobileNo": this.editCountryheadForm.value.Contact,
      // "country":this.editCountryheadForm.value.country,
      // "language":this.editCountryheadForm.value.language,
      "email": this.editCountryheadForm.value.email,
      // "active":this.editCountryheadForm.value.status
      "CountryListDetails":this.editCountryheadForm.value.multicountry
    }

    console.log(data);

    this.spinner.show();
    this.apiService.editCountryhd(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      // this.router.navigate(['/countryheads']);
      this.router.navigate(['/countryheads'],{queryParams:{id:2}});
      this.editCountryheadForm.reset();
    },error => {
      this.spinner.hide();
      //console.log(error.error)
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }

  public onFilterChange(item: any) 
  {
    console.log(item);
  }
  public onDropDownClose(item: any) 
  {    
    if(item==="undefined")
    {
      this.editCountryheadForm.get('Contact').disable();
     
    }
  }

  public onItemSelect(item: any) 
  {    
    this.editCountryheadForm.get('Contact').enable();
  }
  public onDeSelect(item: any) 
  {  
    if(this.editCountryheadForm.value.multicountry.length <=0)
    {
      this.editCountryheadForm.get('Contact').disable();
      
    }
  }

  public onSelectAll(items: any) 
  {   
    this.editCountryheadForm.get('Contact').enable();
  }
  public onDeSelectAll(items: any) 
  {    
    this.editCountryheadForm.get('Contact').disable();    
  }
}
