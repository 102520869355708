import { Component, HostListener } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MessagingService } from "./shared/messaging.service";
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'my-app';
  message;
  isLoggedin: boolean = false;
  refreshKeyPressed = false;
  modifierPressed = false;

  f5key = 116;
  //f5 key
  rkey = 82;
  //
  modkey = [17, 224, 91, 93];
  location: Location;
  constructor(
    private messagingService: MessagingService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
    , private translate: TranslateService,
    location: Location
  ) {

    var self = this;
    window.addEventListener('focus', function () {
      // console.log('1 PAGE IS FOCUSED');
      if (localStorage.getItem('isRefreshed') == 'true') {
        self.router.navigate(['/serviceStations']);
        //window.location.reload();
        localStorage.setItem('isRefreshed', 'false');
      }
    });

    if (localStorage.getItem('userDetails') == null) {
      if(location.path().includes('/forgotResetPassword') || location.path().includes('/forgotPassword')){
        this.router.navigate([location.path()], { replaceUrl: true });
     }else{
          this.router.navigate(['/login'], { replaceUrl: true });
     };
    }
    if (localStorage.getItem('userDetails') != null) {
      this.translate.setDefaultLang(
        localStorage['languageCode']);
        this.translate.use(localStorage['languageCode']).subscribe((res) => {
         // console.log(res);
        }, error => {
          console.log(error);
          this.translate.setDefaultLang('en');
          this.translate.use('en');
        });
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }

    $(document).click(function (event) {
      $('.dropdownlist').css('display', 'none');
    });


    /*this is important 16-1-2020*/

    // window.addEventListener('beforeunload', function (event) {
    //   //console.log("Page is beforeunload" + new Date());
    //   //console.log(router.url);
    //   //console.log(window.location.href);
    //   if (localStorage['userId']) {
    //     apiService.initiatelogout()
    //       .subscribe((res) => {
    //         //console.log(res);
    //       }, error => {
    //         //console.log(error);
    //       });
    //   }
    // })


    // window.addEventListener('load', function (event) {
    //   //console.log("Page after load" + new Date());
    //   //Here we need to cancel logout (When user loads window call cancellogout);
    //   if (localStorage['userId']) {
    //     apiService.cancellogout()
    //       .subscribe((res) => {
    //         //console.log(res);
    //         localStorage.setItem('count', '0');
    //       }, error => {
    //         //console.log(error);
    //       });
    //   }
    // })
    /*this is important 16-1-2020*/

  }
  ngOnInit() {
    // const userId = 'user00123';
    const userId = localStorage['userId'];
    //console.log("called")
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage
  }

  cancellogout() {
    this.apiService.cancellogout()
      .subscribe((res) => {
        //console.log(res);
      }, error => {
        //console.log(error);
      });
  }
}
