import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ActivatedRoute } from '@angular/router';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-editfeeback',
  templateUrl: './editfeeback.component.html',
  styleUrls: ['./editfeeback.component.css']
})
export class EditfeebackComponent implements OnInit {

  constructor(public apiService: ApiService, private router: Router, private spinner: NgxSpinnerService, private location: Location, private route: ActivatedRoute) { }
  countryList = [];
  feedbackList = [];
  questionsList = [];
  defaultQuestion = '';
  defaultquestionDetailId = '';
  defaultStatus = true;
  feedbackId;
  query = {};
  feedbackData;
  selectedCountry;
  questionId;
  isValid: boolean = true;
  nativeQuestion: boolean = true;
  error = { 'description': [] };
  ngOnInit() {
    this.getCountryList();
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.feedbackId = params['id'];
      this.selectedCountry = params['country'];
      //console.log(this.feedbackId);
      //console.log(this.selectedCountry);
    })
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      if (this.countryList.length > 0) {
        this.countryList.forEach(element => {
          element.nativeQuestion = "";
        });
      }
      //console.log(this.countryList, "this.getCountryList");
      this.getallFeedback();
    }, error => {
      this.spinner.hide();
    });
  }

  changeStatus(id) {
    this.countryList.forEach(element => {
      if (element.id == id) {
        element.active = !element.active;
      }
    });
  }

  public alphanumericValidator(control) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    this.isValid = false;
    //console.log(control);
    if (control && control.trim().length >= 4 && control.trim().match(/^[0-9a-zA-Z.,!?& ]+$/)) {
      this.isValid = true;
      this.defaultStatus = true;
      return true;
    } else if (control && control.trim() == "") {
      this.isValid = false;
      this.defaultStatus = false;
      return false;
    } else {
      this.isValid = false;
      this.defaultStatus = false;
      return false;
    }
  }

  changedefaultStatus() {
    if (this.defaultQuestion && this.defaultQuestion.length > 0) {
      this.alphanumericValidator(this.defaultQuestion);
    } else {
      this.defaultStatus = false;
    }
  }

  getallFeedback() {
    this.spinner.show();
    this.feedbackList = [];
    const filters = [];
    this.questionsList = [];
    filters.push({
      "member": "country",
      "value": this.selectedCountry,
      "operation": "EqualTo"
    })
    this.query = { "filters": filters }
    this.apiService.getallFeedback(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.feedbackList = response['content'].payLoad;
      if (this.feedbackList.length > 0) {
        this.feedbackList.forEach(element => {
          if (element.id == this.feedbackId) {
            this.feedbackData = element;
            this.patchValue();
          }
        });
      }
    }, error => {
      this.spinner.hide();
      //console.log(error);
      this.cancel();
    })
  }

  patchValue() {
    //console.log(this.feedbackData);
    this.questionId = this.feedbackData['id'];
    this.feedbackData.questionDetails.forEach(typeElement => {
      this.countryList.forEach(countryElement => {
        if (typeElement.country == countryElement.countryName) {
          console.log(typeElement);
          countryElement.nativeQuestion = typeElement.question;
          countryElement.active = typeElement.active;
          countryElement.questionDetailId = typeElement.questionDetailId;
        } else if (typeElement.country == 'Default') {
          console.log(typeElement);
          this.defaultQuestion = typeElement.question;
          this.defaultStatus = typeElement.active;
          this.defaultquestionDetailId = typeElement.questionDetailId;
        }
      });
    });

    //console.log(this.countryList);
  }

  editFeedback() {
    const questions = [];
    //console.log(this.countryList);
    questions.push({
      "questionDetailId": this.defaultquestionDetailId,
      "question": this.defaultQuestion ? this.defaultQuestion.trim() : '',
      "country": "Default",
      "language": "English",
      "active": true
    })
    console.log(this.countryList)
    this.countryList.forEach(element => {
      if (element.nativeQuestion.length > 0) {
        questions.push({
          "questionDetailId": element.questionDetailId,
          "question": element.nativeQuestion ? element.nativeQuestion.trim() : '',
          "country": element.countryName,
          "language": element.languages[0].languageName,
          "active": element.active
        })
      }
    });

    console.log(questions);

    let data = {"id":this.questionId,
    "questionDetails":questions};
    //console.log(data);
    this.spinner.show();
    this.apiService.editFeedback(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/feedbackQB'],{queryParams:{id:2}});
    },error => {
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  addNativeQuestion(selectedId) {
    //console.log(this.countryList);
    this.nativeQuestion = false;
    this.countryList.forEach(element => {
      if (element.id == selectedId) {
        element.serviceTypeError = false;
        if (element.nativeQuestion && element.nativeQuestion.trim().length >= 4) {
          //console.log('valid');
          element.serviceTypeError = false;
          element.active = true;
          this.nativeQuestion = true;
        } else if (element.nativeQuestion && element.nativeQuestion.trim().length > 0) {
          //console.log('in valid');
          element.serviceTypeError = true;
          this.nativeQuestion = false;
          element.active = false;
        } else if (element.nativeQuestion && element.nativeQuestion.trim() == "") {
          //console.log('in valid');
          element.serviceTypeError = true;
          this.nativeQuestion = false;
          element.active = false;
        }
      }
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }

}
