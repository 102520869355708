import { Injectable } from '@angular/core';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ApiService } from './api.service';
declare var $ :any;
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
const VIDEO_EXTENSION = ".mp4";
@Injectable({
  providedIn: 'root'
})
export class ExcelServiceService {

  constructor(public apiService:ApiService) { }

  public exportDocument(jsonData: any[], fileName: string) {
    this.exportAsExcelFile(jsonData,fileName);
  }

  private exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"]
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array"
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + '_' +new Date().getTime() + EXCEL_EXTENSION
    );
  }
  public saveAsVideoFile(url: any, fileName: string): void {
    //console.log(url, fileName);
    // this.apiService.exportVideo(url).subscribe(response => {
    //   //console.log(response);
      // const data: Blob = new Blob([url], { type: 'video/mp4' });
      // let fileURL = URL.createObjectURL(data);
      // FileSaver.saveAs(
      //   data,
      //   fileName + VIDEO_EXTENSION
      // );
    // })
    // const data = new Blob([url], { type: 'video/mp4' });
    // //console.log(data);
    // let fileURL = URL.createObjectURL(data);
    // window.open(fileURL);
    FileSaver.saveAs(
      url,
      fileName + VIDEO_EXTENSION
    );
  }

  public saveAsImageFile(blobName: string,extension:string): void {
    //console.log(url, fileName);

    this.apiService.exportContent(blobName).subscribe(response => {
      console.log(response);
      const data: Blob = new Blob([response], { type: extension });
      FileSaver.saveAs(
        data,
        blobName
      );
    })
  }
}
