import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { TransfereService } from '../../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-distributor',
  templateUrl: './edit-distributor.component.html',
  styleUrls: ['./edit-distributor.component.css']
})
export class EditDistributorComponent implements OnInit {
  countryheadList = [];
  distributorId;
  editDistributorForm:any;
  distributorList = [];
  distributorData = {};
  error = {'description':[]}
  userDetails = {'usertype':'','sub':''};
  maxLength = 10;
  minLength = 9;

  constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService,private router : Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private location: Location) {
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
    this.editDistributorForm = this.fb.group({
      id:['', Validators.required],
      country: new FormControl('', [Validators.required]),
      language: new FormControl('', Validators.required),
      countryHeadId: new FormControl('', [Validators.required]),
      countryHeadName: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      code: new FormControl('', [Validators.required]),
      email:new FormControl('', [Validators.required,this.emailValidator]),
      Contact:new FormControl('', [Validators.required,this.numberValidator]),
      // status:[true, Validators.required]
    });
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.distributorId = params['id'];
      //console.log(this.distributorId);
    })
    this.editDistributorForm = this.fb.group({
      id:['', Validators.required],
      country: new FormControl('', [Validators.required]),
      language: new FormControl('', Validators.required),
      countryHeadId: new FormControl('', [Validators.required]),
      countryHeadName: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      code: new FormControl('', [Validators.required]),
      email:new FormControl('', [Validators.required,this.emailValidator]),
      Contact:new FormControl('', [Validators.required]),
      // status:[true, Validators.required]
    });
    this.callList();
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public alphanumericValidator(control: FormControl) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = false;
    // var regex = /^[a-z0-9]+$/i; 
    var regex = /[^a-zA-Z0-9]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphanumeric': true }
    } else {
      return isValid ? null : { 'alphanumeric': false }
    }
  }

  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
    //console.log('valid');
      return null;
    } else if(control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');
      return {'inValid': true};
    }else {
      return null;
    }
  }

  public emailValidator(control: FormControl) {
    ////console.log(control.value);
    // var regex = '^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$'
    if (control.value && control.value.length > 0 && /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value)) {
      //valid
      return null;
    } else if(control.value != null && control.value.length > 0 && !(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value))) {
      //invalid
      return {'isEmail': true};

    }else {
      return null;
    }
  }

  callList() {
    if(this.userDetails.usertype == 'Admin') {
      this.getDistributorbyAdmin();
    } else {
      //when userType is Country head.
      //patch country head name & country by logged in user details.
      this.getDistributorByCountryhead();
    }
  }

  getDistributorbyAdmin() {
    //console.log(this.distributorList);
    this.spinner.show();
    this.apiService.getDistributorbyAdmin({}).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      if(response['content'].payLoad.length > 0) {
        this.distributorList.forEach(element => {
          //console.log(element.id);
          //console.log(this.distributorId);
          this.spinner.hide();
          if(element.id == this.distributorId) {
            this.distributorData = element;
            this.patchValue();
          }
          //console.log(this.distributorData);
          
        });
      }
      //console.log(this.distributorList);
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  getDistributorByCountryhead() {
    //console.log(this.distributorList);
    let data = {
      "options" :{},
      "countryHeadId": localStorage.userId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.distributorList = response['content'].payLoad;
      if(response['content'].payLoad.length > 0) {
        this.distributorList.forEach(element => {
          //console.log(element.id);
          //console.log(this.distributorId);
          this.spinner.hide();
          if(element.id == this.distributorId) {
            this.distributorData = element;
            this.patchValue();
          }
          //console.log(this.distributorData);
        });
      }
      this.spinner.hide();
      //console.log(this.distributorList);
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  // getCountryheadList() {
  //   //console.log(this.countryheadList);
  //   this.apiService.getCountryhdList({}).subscribe(response => {
  //     //console.log(response);
  //     this.countryheadList = response['content'];
  //     //console.log(this.countryheadList);
  //   }, error => {
  //     //console.log(error);
  //   })
  // }

  onselectCountryhead(countryheadId) {
    //console.log(countryheadId);
    this.countryheadList.forEach(element => {
      if(element.id == countryheadId) {
        this.editDistributorForm.patchValue({
          country: element.address.country
        })
      }
    });
  }

  patchValue() {
    //console.log(this.userDetails);
    //console.log(this.distributorData['address'].country);

    this.getMinAndMaxLenghBuCountry(this.distributorData['address'].country)

    this.editDistributorForm.patchValue({
      id:this.distributorData['id'],
      country:this.distributorData['address'].country,
      language: this.distributorData['language'],
      countryHeadId:this.distributorData['countryHeadId'],
      countryHeadName:this.distributorData['countryHeadName'],
      code:this.distributorData['code'],
      name:this.distributorData['name'] ,
      email:this.distributorData['email'],
      Contact:this.distributorData['mobileNo'],
    })
    //console.log(this.editDistributorForm);
  }
  
  editDistributor() {
    //console.log(this.editDistributorForm);
    //console.log(this.editDistributorForm.value);
    this.error.description = [];
    let data = {
      "id":this.editDistributorForm.value.id,
      "name": this.editDistributorForm.value.name,
      "distributorCode":this.editDistributorForm.value.code,
      "email": this.editDistributorForm.value.email,
      "active": this.editDistributorForm.value.status,
      "mobileNo":this.editDistributorForm.value.Contact
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.editDistributor(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/distributors'],{queryParams:{id:2}});
      this.editDistributorForm.reset();
    },error => {
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }
  closeAlert() {
    this.error.description = [];
  }

  getMinAndMaxLenghBuCountry(countryName)
  {
    let countrydetails={};
    let data={
      "filters":[{
        "member":"country",
        "value":countryName
      }]
    }
    this.spinner.show();
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      countrydetails = response.content.payLoad[0];     
      this.minLength = countrydetails['mobileNumberLength'].min;
      this.maxLength = countrydetails['mobileNumberLength'].max;
      this.spinner.hide();
      
    },error=>{
      this.spinner.hide();
    });

  }
}
