import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes,Router,ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-editapplication-type',
  templateUrl: './editapplication-type.component.html',
  styleUrls: ['./editapplication-type.component.css']
})
export class EditapplicationTypeComponent implements OnInit {
  applicationtypeList = [];
  countryList = [];
  applicationtypeId;
  selectedCountry;
  applicationtypeData = {};
  editapplicationtypeForm: any;
  typeId;
  defaultStatus;
  defaulttype = "";
  defaulttypelId;
  query = {};
  isValid : boolean = true;
  nativeQuestion : boolean = true;
  error = {'description':[]};
  constructor(public apiService:ApiService,private spinner: NgxSpinnerService,private fb: FormBuilder,private router:Router,private route: ActivatedRoute,private location: Location) { 
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.applicationtypeId = params['id'];
      this.selectedCountry = params['country'];      
      // console.log(this.applicationtypeId);
      // console.log(this.selectedCountry);
    })

    this.editapplicationtypeForm = this.fb.group({
      id:['',Validators.required],
      country:['',Validators.required],
      language:['',Validators.required],
      applicationType:['',Validators.required]
    })
  }

  ngOnInit() {
    this.editapplicationtypeForm = this.fb.group({
      id:['',Validators.required],
      country:['',Validators.required],
      language:['',Validators.required],
      applicationType:['',Validators.required]
    })
    this.getCountryList();
  }

  getCountryList() {
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      if(this.countryList.length > 0) {
        this.countryList.forEach(element => {
          element.applicationType = "";
        });
      }
      //console.log(this.countryList, "this.getCountryList");
      this.getApplicationtypeList();
    },error=>{
      
    });
  }

  

  getApplicationtypeList() {
    //console.log(this.applicationtypeList);
    const filters = [];
    if(this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    this.query = {"filters":filters};
    this.spinner.show();
    this.apiService.getallApplicationtypes(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.applicationtypeList = response['content'].payLoad;
      if(this.applicationtypeList.length > 0) {
        this.applicationtypeList.forEach(element => {
          if(element.id == this.applicationtypeId) {
            this.applicationtypeData = element;
            this.patchValue();
          }
        });
      }
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  patchValue() {
    //console.log(this.applicationtypeData);
    this.typeId = this.applicationtypeData['id'];
    this.applicationtypeData['applicationTypeDetails'].forEach(typeElement => {
      this.countryList.forEach(countryElement => {
        //console.log(typeElement);
        //console.log(countryElement);

        //console.log(typeElement.country +"-->"+ countryElement.countryName +"--->");
        if(typeElement.country == countryElement.countryName) {
          countryElement.applicationType = typeElement.applicationTypeName;
          countryElement.active = typeElement.active;
          countryElement.applicationTypeDetailId = typeElement.applicationTypeDetailId;
        } else if(typeElement.country == 'Default') {
          this.defaulttype = typeElement.applicationTypeName;
          this.defaultStatus = typeElement.active;
          this.defaulttypelId = typeElement.applicationTypeDetailId;
        }
      });
    });

    //console.log(this.countryList);
  }

  editserviceType() {
    const applicationTypes = [];
    //console.log(this.countryList);
    applicationTypes.push({
      "ApplicationTypeDetailId": this.defaulttypelId,
      "ApplicationTypeName": this.defaulttype ? this.defaulttype.trim() : '',
      "country": "Default",
      "language": "English",
      "active": true
    })
    
    this.countryList.forEach(element => {
      if(element.applicationType.length > 0) {
        applicationTypes.push({
          "ApplicationTypeDetailId": element.applicationTypeDetailId,
          "ApplicationTypeName": element.applicationType ? element.applicationType.trim() : '',
          "country": element.countryName,
          "language": element.languages[0].languageName,
          "active": element.active
        })
      }
    });

    //console.log(applicationTypes);
    
    let data = {"id":this.typeId,
    "ApplicationTypeDetails":applicationTypes};
    //console.log(data);
    this.apiService.editApplicationtype(data).subscribe(response => {
      //console.log(response);
      this.editapplicationtypeForm.reset();
      this.router.navigate(['/applicationTypes'],{queryParams:{id:2}});
    }, error => {
      //console.log(error);
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }
  
  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  changeStatus(id) {
    this.countryList.forEach(element => {
      if(element.id == id) {
        element.active = !element.active;
      }
    });
  }
  
  public alphanumericValidator(control) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    // this.isValid = false;
    // var regex = /[^a-zA-Z0-9&]/;
    // //console.log(regex.test(control));
    //console.log(control);
    if(control && control.trim().match(/^[0-9a-zA-Z ]+$/) && control.trim().length >= 4 ) {
      this.isValid = true;  
      this.defaultStatus = true;
      return true;
    } else if(control && control.trim() == "") {
      this.isValid = false;
      this.defaultStatus = false;     
      return false;
    } else {
      this.isValid = false;   
      this.defaultStatus = false;  
      return false;
    } 
  }

  changedefaultStatus() {
    if(this.defaulttype && this.defaulttype.length > 0) {
      this.alphanumericValidator(this.defaulttype);
    } else {
      this.defaultStatus = false;
    }
  }
  
  addNativeQuestion(selectedId) {
    //console.log(this.countryList);
    //console.log(selectedId);
    this.nativeQuestion = false;
    this.countryList.forEach(element => {
      element.applicationTypeError = false;
      if(element.id == selectedId) {
        if(element.applicationType && element.applicationType.trim().length >= 4) {
          //console.log('valid');
          element.applicationTypeError = false;
          element.active = true;
          this.nativeQuestion = true;
        } else if(element.applicationType && element.applicationType.trim().length > 0) {
          //console.log('in valid');
          element.applicationTypeError = true;
          this.nativeQuestion = false;
          element.active = false;
        } else if(element.applicationType && element.applicationType.trim() == "") {
          //console.log('in valid');
          element.applicationTypeError = true;
          this.nativeQuestion = false;
          element.active = false;
        }
      }
    });
  }

  closeAlert() {
    this.error.description = [];
  }
}
