import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelServiceService } from '../../excel-service.service';
import { DateService } from '../../date.service';
const FILE_NAME = "Distributorwise Technicians Export";
import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, rotateIn, fadeInDown } from 'ng-animate';
import * as jsPDF from 'jspdf';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

declare var $: any;
@Component({
  selector: 'app-distributor-wise-technicians',
  templateUrl: './distributor-wise-technicians.component.html',
  styleUrls: ['./distributor-wise-technicians.component.css'],
  animations: [
    trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown))])
  ]
})
export class DistributorWiseTechniciansComponent implements OnInit {

  constructor(public apiService: ApiService, public router: Router, private spinner: NgxSpinnerService, public dateService: DateService, public excelService: ExcelServiceService) {
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
  }
  disTechReport = [];
  countryList = [];
  countryheadList = [];
  distributorList = [];
  servicestationList = [];
  selectedCountry = '';
  selectedCountryhead = '';
  selectedDistributor = '';
  selectedDistributorName = '';
  selectedserviceStations = '';
  userDetails = { 'usertype': '', 'sub': '' ,'countryDetails':''};
  startDateFilter: any;
  endDateFilter: any;
  selectedStatus = '';
  query = {};
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  hasMorePrevious = false;
  continuationToken = [];
  IsfilterDisabled=false;
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public endDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    componentDisabled: true,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  bounce: any;

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    animations: true,
    animationSteps: 60,
    animationEasing: "easeOutBounce",
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    useAnimation: true,
    animation: {
      duration: 1000,
      easing: 'easeOutQuart'
    },
    legend: {
      display: true,
      position: 'bottom'
    }
  };
  public barChartLabels: string[] = [];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: any[] = [
    {
      data: [], label: '',
      backgroundColor: []
    }
  ];
  noGraph = false;
  colorPalette = ['#F97F51', '#B33771', '#6D214F', '#182C61',
    '#3B3B98',
    '#FC427B',
    '#FD7272',
    '#EAB543',
    '#58B19F',
    '#2C3A47',
    '#D6A2E8',
    '#82589F',
    '#eb2f06',
    '#1e3799',
    '#0a3d62',
    '#3c6382',
    '#6a89cc',
    '#84817a',
    '#227093']
  ngOnInit() {
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.callList();
  }

  callList() {
    if (this.userDetails.usertype == 'Admin') {
      this.getCountryList();
    } else if (this.userDetails.usertype == 'CountryHead') 
    {
      this.countryList=JSON.parse(this.userDetails.countryDetails)
      this.selectedCountry =this.countryList[0].countryName;// this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  
      //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['given_name'];
      this.getCountryheadList();
      this.getDistributorByCountryhead(this.userDetails['userid']);
    } else if (this.userDetails.usertype == 'Distributor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['userid'];
      this.selectedDistributorName = this.userDetails['given_name'];
      this.getServicestationByDistributor(this.selectedDistributor);
    } else if (this.userDetails.usertype == 'FrontLineAdvisor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['distributorId'];
      this.selectedDistributorName = this.userDetails['distributorName'];
      this.getServicestationByDistributor(this.selectedDistributor);
    }
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      this.filterChanged('next');
      // By default
      // if (this.countryList.length > 0) {
      //   this.selectedCountry = this.countryList[0].countryName;
      //   // this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      //   this.getCountryheadList();
      //   //console.log(this.countryList, "this.getCountryList");
      // }
    }, error => {
      this.spinner.hide();
    });
  }

  getCountryheadList() {
    if (this.userDetails.usertype == 'CountryHead' && this.selectedCountry=="")
    {  
      this.IsfilterDisabled=true;  
      this.selectedCountryhead="";  
      this.countryheadList=[];      
   }
   else
   {
    this.IsfilterDisabled=false; 
    let query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ]
    }
    this.spinner.show();
    this.selectedCountryhead = "";
    this.distributorList = [];
    this.selectedDistributor = "";
    this.selectedserviceStations = "";
    this.servicestationList = [];
    //console.log(this.countryheadList);
    this.apiService.getCountryhdList(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      if(this.userDetails.usertype==="CountryHead")
      {
        this.countryheadList = response['content'];
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.countryheadList[0].id)

      }else{
      this.countryheadList = response['content'];
      this.countryheadList.length != 0 ? this.selectedCountryhead = this.countryheadList[0].id : '';
      this.onselectCountryhead(this.selectedCountryhead);
    }
      
      // By default
      // if(this.countryheadList.length > 0) {
      //   this.selectedCountryhead = this.countryheadList[0].id;
      //   this.onselectCountryhead(this.selectedCountryhead);
      // }
      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }
  }

  onselectCountryhead(countryHeadId) {
    //console.log(countryHeadId);
    /*if user id admin*/
    if (this.userDetails['usertype'] == 'Admin') {
      //console.log(countryHeadId);
      this.selectedDistributor = "";
      this.distributorList = [];
      this.selectedserviceStations = "";
      this.servicestationList = [];
      this.getDistributorByCountryhead(countryHeadId);
    }
    if (this.userDetails['usertype'] == 'CountryHead') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId);
    }
  }

  onselectDistributor(distributorId) {
    //console.log(distributorId);
    /*if user id admin*/
    if (this.userDetails['usertype'] == 'Admin') {
      //console.log(distributorId);
      this.servicestationList = [];
      this.selectedserviceStations = "";
      this.getServicestationByDistributor(distributorId);
    }
  }

  getDistributorByCountryhead(countryHeadId) {
    //console.log(this.distributorList);
    let data = {
      "options": {},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.selectedDistributor = "";
      this.distributorList = response['content'].payLoad;
      if (this.distributorList.length > 0) {
        // this.selectedDistributor = this.distributorList[0].id;
        // this.getServicestationByDistributor(this.selectedDistributor);
        // this.filterChanged('next');
        this.servicestationList = [];
        this.selectedserviceStations = "";
      }
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getServicestationByDistributor(distributorId) {
    //console.log(this.servicestationList);
    this.servicestationList = [];
    this.selectedserviceStations = "";
    let query = {
      "filters": [
        {
          "member": "distributorId",
          "value": distributorId,
          "operation": "EqualTo"
        }
      ]
    }

    this.spinner.show();
    this.apiService.getServicestations(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.servicestationList = response['content'].payLoad;
      //console.log(this.servicestationList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  clickOnFilter() {
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }


  filterChanged(state) {
    const filters = [];
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }

    if (this.selectedserviceStations) {
      filters.push({
        "member": "servicestationid",
        "value": this.selectedserviceStations
      })
    } else if (this.selectedDistributor) {
      filters.push({
        "member": "distributorId",
        "value": this.selectedDistributor
      })
    } else if (this.selectedCountryhead) {
      filters.push({
        "member": "countryheadId",
        "value": this.selectedCountryhead
      })
    } else if (this.selectedCountry || this.selectedCountry == '') {
      filters.push({
        "member": "country",
        "value": this.selectedCountry ? this.selectedCountry : 'All'
      })
    }


    //console.log(this.selectedStatus);
    if (this.selectedStatus != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus
      })
    }
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    if (!this.toogleView) {
      this.getTechnicianByDistributor(state);
    } else {
      this.gettechReportGraph();
    }
  }

  getTechnicianByDistributor(state) {
    this.spinner.show();
    this.disTechReport = [];
    this.apiService.getDistributorTechnicianReport(this.query).subscribe(response => {
      //console.log(response);
      this.disTechReport = response['content'].payLoad;
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      this.spinner.hide();
      //console.log(this.disTechReport);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  onstartDatechange(event) {
    //console.log(event);
    if (event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate;
      // this.onChangeFilter();
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onendDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
      // this.onChangeFilter();
    } else {
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onChangeFilter() {
    const filters = [];
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }
    //console.log(filters);
  }

  private getExportableItems() {
    if (this.disTechReport && this.disTechReport.length > 0) {
      return this.disTechReport.map((item, index) => {
        return {
          "Sr No.": index + 1,
          "Country": item.countryName,
          "Technician Name": item.technicianName,
          "Mobile No.": item.mobileNo,
          "Distributor Name": item.distributorName,
          "Distributor Code": item.distributorCode,
          "Service Station Name": item.serviceStationName,
          // "Tractor Model Name": item.regionName,
          "Created Date": item.createdDate != null ? moment(item.createdDate).format('DD/MM/YYYY') : '',
          "Status": item.active ? "Active" : "Inactive"
        };
      });
    } else {
      return [];
    }
  }

  exporttoexcel() {
    var json = this.getExportableItems();
    this.excelService.exportDocument(json, FILE_NAME)
  }

  dumpExcelReports() {

    this.spinner.show();

    const filters = [];
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }

    if (this.selectedserviceStations) {
      filters.push({
        "member": "servicestationid",
        "value": this.selectedserviceStations
      })
    } else if (this.selectedDistributor) {
      filters.push({
        "member": "distributorId",
        "value": this.selectedDistributor
      })
    } else if (this.selectedCountryhead) {
      filters.push({
        "member": "countryheadId",
        "value": this.selectedCountryhead
      })
    } else if (this.selectedCountry || this.selectedCountry == '') {
      filters.push({
        "member": "country",
        "value": this.selectedCountry ? this.selectedCountry : 'All'
      })
    }


    //console.log(this.selectedStatus);
    if (this.selectedStatus != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus
      })
    }


    let payload = {
      "reportType": "DistributorwiseTechnician",
      "filters": filters
    }
    this.apiService.dumpExcelReports(payload)
      .subscribe((obj: any) => {
        console.log(obj);
        let content_disposition = obj['content-disposition'];
        let file_name = this.getFilename(content_disposition);
        const blob: any = new Blob([obj.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let link = document.createElement("a");

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        }

        else if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", file_name != '' ? file_name : 'DistributorwiseTechnicianExcelReport');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });

  }

  getFilename(content_disposition: any): string {
    let file_name = '';
    if (content_disposition != '') {
      let arr = content_disposition.split(';');
      if (arr.length > 0) {
        if (arr[1].includes('filename')) {
          file_name = arr[1].split('=')[1];
        }
      }
    }
    return file_name;
  }

  resetFilter() {
    this.disTechReport = [];
    this.toogleView = false;
    if (this.userDetails.usertype == 'Admin') {
      this.selectedCountry = '';
      this.countryheadList = [];
      this.distributorList = [];
      this.servicestationList = [];
      this.selectedCountryhead = '';
      this.selectedDistributor = '';
      this.selectedDistributorName = '';
      this.selectedserviceStations = '';
    } else if (this.userDetails.usertype == 'CountryHead') {
      this.servicestationList = [];
      this.selectedDistributor = '';
      this.selectedDistributorName = '';
      this.selectedserviceStations = '';
    } else if (this.userDetails.usertype == 'Distributor') {
      this.selectedserviceStations = "";
    }
    this.selectedStatus = '';
    this.startDateFilter = '';
    this.endDateFilter = '';
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.selectedRecord = "20";
    this.query = {};
    this.noGraph = false;
    // this.getTechnicianByDistributor('next');
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    //console.log(obj, " state");
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }

  gettechReportGraph() {
    this.spinner.show();
    const filters = [];
    var params = '';
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }

    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry ? this.selectedCountry : 'All'
      })
      params = 'distributor';
    }
    if (this.selectedStatus != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus
      })
    }
    if (this.selectedCountry == '') {
      params = 'all';
    }

    const query = { "filters": filters };
    this.apiService.downloadtechReportGraph(query, params).subscribe(response => {
      console.log(response);
      this.barChartLabels = [];
      this.barChartData[0]['data'] = [];
      this.barChartData[0]['backgroundColor'] = [];
      this.barChartData[0]['label'] = 'Customer Count';
      if (response['content'] && response['content'].length > 0) {
        this.drawGraph(response['content']);
      } else {
        this.noGraph = true;
      }
      this.spinner.hide();
      //console.log(this.customerregReport);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  drawGraph(data) {
    console.log(this.barChartData);
    this.barChartLabels = [];
    this.barChartData[0]['data'] = [];
    this.noGraph = false;
    this.barChartData[0]['backgroundColor'] = [];
    if (data.length > 0) {
      if (this.selectedCountry == '') {
        this.barChartData[0]['label'] = 'Countries vs Technicians';
        data.forEach(element => {
          console.log(element.countryName);
          var min = 1;
          var max = 19;
          var random = Math.floor(Math.random() * (+max - +min)) + +min;
          if (element.countryName.length > 0) {
            this.barChartLabels.push(element.countryName + ' (' + element.technicianCount + ')');
            this.barChartData[0]['data'].push(element.technicianCount);
            console.log(this.colorPalette);
            console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
            this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
            // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
          }
        });
      } else {
        this.barChartData[0]['label'] = 'Distributors vs Technicians';
        data.forEach(element => {
          var min = 1;
          var max = 19;
          var random = Math.floor(Math.random() * (+max - +min)) + +min;
          console.log("Random Number Generated : " + random);
          console.log(element.distributorName);
          if (element.distributorName.length > 0) {
            this.barChartLabels.push(element.distributorName + ' (' + element.technicianCount + ')');
            this.barChartData[0]['data'].push(element.technicianCount);
            console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
            this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
            // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
          }
        });
      }
      if (this.barChartLabels.length == 0) {
        this.noGraph = true;
      }
      console.log(this.barChartData);
      console.log(this.barChartLabels);
    } else {
      this.noGraph = true;
    }
  }

  printgraph() {
    // get size of report page
    var reportPageHeight = $('#bar-chart').innerHeight();
    var reportPageWidth = $('#bar-chart').innerWidth();

    // create a new canvas object that we will populate with all other canvas objects
    var pdfCanvas = $('<canvas />').attr({
      id: "canvaspdf",
      width: reportPageWidth,
      height: reportPageHeight
    });

    // keep track canvas position
    var pdfctx = $(pdfCanvas)[0].getContext('2d');
    var pdfctxX = 0;
    var pdfctxY = 0;
    var buffer = 100;
    pdfctx.fillStyle = "white";
    pdfctx.fillRect(0, 0, reportPageWidth, reportPageHeight);
    pdfctx.restore();
    // for each chart.js chart
    $("canvas").each(function (index) {
      // get the chart height/width
      var canvasHeight = $(this).innerHeight();
      var canvasWidth = $(this).innerWidth();

      // draw the chart into the new canvas
      pdfctx.drawImage($(this)[0], pdfctxX, pdfctxY, canvasWidth, canvasHeight);
      pdfctxX += canvasWidth + buffer;

      // our report page is in a grid pattern so replicate that in the new canvas
      if (index % 2 === 1) {
        pdfctxX = 0;
        pdfctxY += canvasHeight + buffer;
      }
    });

    // create new pdf and add our new canvas as an image
    var pdf = new jsPDF('l', 'pt', [reportPageWidth, reportPageHeight]);
    pdf.addImage($(pdfCanvas)[0], 'PNG', 50, 50, 0, 0);

    // download the pdf
    pdf.save('Distributorwisetechnician.pdf');
  }

  toogleView = false;

  toogleViewFn() {
    console.log(this.toogleView);
    if (!this.toogleView) {
      this.toogleView = true;
    } else {
      this.toogleView = false;
    }
    this.clickOnFilter();
  }
}
