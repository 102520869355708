import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-feedback-qb',
  templateUrl: './feedback-qb.component.html',
  styleUrls: ['./feedback-qb.component.css']
})
export class FeedbackQBComponent implements OnInit {
  constructor(public apiService: ApiService, private router: Router, private spinner: NgxSpinnerService, private route: ActivatedRoute, private location: Location) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if (params['id'] == 1) {
        this.location.replaceState("feedbackQB");
        this.success.description = [];
        this.success.description.push('Feedback Question Added Successfully.');
        var scope = this;
        setInterval(function () {
          scope.success.description = [];
        }, 5000);
      } else if (params['id'] == 2) {
        this.location.replaceState("feedbackQB");
        this.success.description = [];
        this.success.description.push('Feedback Question Updated Successfully.');
        var scope = this;
        setInterval(function () {
          scope.success.description = [];
        }, 5000);
      }
    });
  }
  success = { 'description': [] };
  userDetails = { 'usertype': '', 'sub': '' };
  feedbackList = [];
  questionsList = [];
  countryList = [];
  selectedCountry = '';
  selectedStatus = '';
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  ngOnInit() {
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.getCountryList();

    this.getallFeedback('next');
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      this.selectedCountry = this.countryList[0].countryName;
      //console.log(this.countryList, "this.getCountryList");
    }, error => {
      this.spinner.hide();
    });
  }

  getallFeedback(state) {
    this.spinner.show();
    this.feedbackList = [];
    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    if (this.selectedStatus != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus
      })
    }
    this.questionsList = [];
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    this.getData(state);
  }

  getData(state) {
    this.apiService.getallFeedback(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.feedbackList = response['content'].payLoad;
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state); 
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff"); 
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
          } else if (!response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "else ifffffffffff"); 
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        } else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff 1111111111"); 
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
        } else if (!response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff no resultssssssssss"); 
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      if (this.feedbackList.length > 0) {
        this.feedbackList.forEach(element => {
          //console.log(element.questionDetails);
          element.questionDetails.forEach(questionElement => {
            if (questionElement.country == this.selectedCountry) {
              questionElement['id'] = element.id;
              this.questionsList.push(questionElement);
            }
          });
        });
      }
      //console.log(this.questionsList);
      //console.log(this.feedbackList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }


  addFeedback() {
    this.router.navigate(['/feedbackQB/add']);
  }

  editFeedback(feedbackDetails) {
    //console.log(feedbackDetails);
    this.router.navigate(['/feedbackQB/edit'], { queryParams: { id: feedbackDetails.id, country: this.selectedCountry } });
  }

  resetPages() {
    this.feedbackList = [];
    this.questionsList = [];
    this.currentPage = 1;
    this.hasMoreResults = false;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.query = {};
  }

  resetFilters() {
    this.selectedCountry = "";
    this.selectedStatus = '';
    this.feedbackList = [];
    this.questionsList = [];
    this.currentPage = 1;
    this.hasMoreResults = false;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.selectedRecord = "20";
    this.query = {};
  }

  changeStatus(feedback) {
    //console.log(feedback,"feedbackId");
    var data = {};
    this.feedbackList.forEach(element => {
      //console.log(element);
      if (element.id == feedback.id) {
        element.questionDetails.forEach(questionElement => {
          //console.log(questionElement);
          if (questionElement.country == feedback.country) {
            questionElement.active = !questionElement.active;
          }
          data = element;
        });
      }
    });
    this.spinner.show();
    //console.log(data);
    this.apiService.editFeedback(data).subscribe(response => {
      //console.log(response);
      // this.spinner.hide();
      this.currentPage = 1;
      this.query = {};
      this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
      this.getallFeedback('next');
    }, error => {
      //console.log(error);
      // this.spinner.hide();
    })
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.getallFeedback('next');
  }

  pageNavigated(obj) {
    //console.log(obj.state)
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.getallFeedback(obj.state);
  }

}
