import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  public simpleDate(dateInfo) {
    //console.log(dateInfo);
    //Used in components where dd/MM/yyyy --> yyyy-mm-dd
    //console.log((dateInfo.date.year + '-' + dateInfo.date.month + '-' + dateInfo.date.day + ' ').concat(moment().format("HH:mm:ss A")));
    return (dateInfo.date.year + '-' + dateInfo.date.month + '-' + dateInfo.date.day + ' ').concat(moment().format("HH:mm:ss A"));
}

public simpleDate_withoutTime(dateInfo) {
  //console.log(dateInfo);
  //Used in components where dd/MM/yyyy --> yyyy-mm-dd
  //console.log((dateInfo.date.year + '-' + dateInfo.date.month + '-' + dateInfo.date.day ));
  return (dateInfo.date.year + '-' + dateInfo.date.month + '-' + dateInfo.date.day );
}
}
