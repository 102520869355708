import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes,Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelServiceService } from '../../excel-service.service';
import { DateService } from '../../date.service';
const FILE_NAME = "Distributorwise Fla Export";
import * as moment from 'moment';
import {IMyDpOptions} from 'mydatepicker';
declare var $ :any;@Component({
  selector: 'app-distributor-wise-fla',
  templateUrl: './distributor-wise-fla.component.html',
  styleUrls: ['./distributor-wise-fla.component.css']
})
export class DistributorWiseFLAComponent implements OnInit {

  constructor(public apiService:ApiService,public router : Router,private spinner: NgxSpinnerService,public dateService:DateService,public excelService:ExcelServiceService) {
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
   }
  flaReport = [];
  countryList = [];
  countryheadList = [];
  distributorList = [];
  selectedCountry = '';
  selectedCountryhead = '';
  selectedStatus = "";
  selectedDistributor = '';
  selectedDistributorName = '';
  query = {};
  userDetails = {'usertype':'','sub':''};
  startDateFilter:any;
  endDateFilter:any;
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  hasMorePrevious = false;
  continuationToken = [];
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField:true,
    editableDateField:false,
    height:'28px',
    showClearDateBtn:false
  };
  public endDateOptions: IMyDpOptions = {
     // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField:true,
    editableDateField:false,
    componentDisabled:true,
    height:'28px',
    showClearDateBtn:false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  ngOnInit() {
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.callList();
  }

  callList() {
    if(this.userDetails.usertype == 'Admin') {
      this.getCountryList();
    } else if(this.userDetails.usertype == 'CountryHead'){
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['given_name'];
      this.getDistributorByCountryhead(this.userDetails['userid']);
    } else if(this.userDetails.usertype == 'Distributor'){
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['userid'];
      this.selectedDistributorName = this.userDetails['given_name'];
    } else if(this.userDetails.usertype == 'FrontLineAdvisor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['distributorId'];
      this.selectedDistributorName = this.userDetails['distributorName'];
    }
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      if(this.countryList.length > 0) {
        this.selectedCountry = this.countryList[0].countryName;
        this.getCountryheadList();
        //console.log(this.countryList, "this.getCountryList");
      }
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
      this.spinner.hide();
    });
  }

  getCountryheadList() {
    //console.log(this.countryheadList);
    let query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ]
    }
    this.spinner.show();
    this.apiService.getCountryhdList(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.countryheadList = response['content'];
      if(response['content'].length > 0) {
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.selectedCountryhead);
      }
      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }
  onselectCountryhead(countryHeadId) {
    //console.log(countryHeadId);
    /*if user id admin*/
    if(this.userDetails['usertype'] == 'Admin') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.getDistributorByCountryhead(countryHeadId);
    }
  }

  getDistributorByCountryhead(countryHeadId) {
    //console.log(this.distributorList);
    let data = {
      "options" :{},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getDistributorFlaReport(state) {
    //console.log(this.flaReport);
    this.spinner.show();
    this.apiService.getDistributorFlaReport(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.flaReport = response['content'].payLoad;
      //console.log(this.flaReport);
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  onstartDatechange(event) {
    //console.log(event);
    if(event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField:true,
        editableDateField:false,
        componentDisabled:false,
        disableUntil:{year: event.date.year, 
          month: event.date.month, 
          day: event.date.day}
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate; 
      // this.onChangeFilter();
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField:true,
        editableDateField:false,
        componentDisabled:true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onendDatechange(event) {
    if(event.jsdate != null) {
      this.endDateFilter = event;
      // this.onChangeFilter();
    } else {
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onChangeFilter() {
    const filters = [];
    if(this.startDateFilter !=null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member":"fromDate",
        "value":this.dateService.simpleDate(this.startDateFilter),
        "operation":"GreaterThanOrEqualTo"
      })
    }
    if(this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member":"toDate",
        "value":this.dateService.simpleDate(this.endDateFilter),
        "operation":"LessThanOrEqualTo"
      })
    }
    //console.log(filters);
  }

  private getExportableItems() {
    if (this.flaReport && this.flaReport.length > 0) {
      return this.flaReport.map((item,index) => {
        return {
          "Sr No.":index+1,
          "Country Head": item.countryHeadName,
          "Distributor Name": item.distributorName,
          "Distributor Code": item.distributorCode,
          "FLA Name": item.flaName,
          "Mobile No.": item.mobileNo,
          "Service Stations": item.serviceStations,
          "Created Date":item.createdOn != null ? moment(item.createdOn).format('DD/MM/YYYY') : '',
          "Status":item.active ? "Active" : "Inactive"
        };
      });
    } else {
      return [];
    }
  }

  exporttoexcel() {
    var json = this.getExportableItems();
    this.excelService.exportDocument(json,FILE_NAME)
  }

  resetFilter() {
    this.selectedCountry = '';
    this.selectedCountryhead = '';
    this.selectedDistributor = '';
    this.selectedDistributorName = '';
    this.selectedStatus = "";
    this.startDateFilter = '';
    this.endDateFilter = '';
    this.currentPage = 1;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.selectedRecord = "20";
    this.query = {};
    this.flaReport = [];
    // this.getDistributorFlaReport();
  }

  clickOnFilter()
  {
    this.currentPage = 1;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state){
    const filters = [];
    if(this.selectedDistributor) {
      filters.push({
        "member": "distributorId",
        "value": this.selectedDistributor
      })
    }
    if(this.selectedStatus  != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus
      })
    }
    this.query = {
      "filters" :filters,
      "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    if(filters.length > 0) {
      this.getDistributorFlaReport(state);
    }
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    //console.log(obj, " state");
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }
}
