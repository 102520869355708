import { Component, OnInit, Input, Output,EventEmitter,SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() hasMoreResults: boolean = false;
  @Input() hasMorePrevious: string;
  @Input() customTitle: string;
  @Input() currentPage: number;
  @Input() selectedRecord:'20';  
  @Output() callEvent = new EventEmitter<any>();
  @Output() changeRecordsEvent = new EventEmitter<any>();
  @Input() continuationToken;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // //console.log(changes);
    // //console.log(this.currentPage, " pagination");
    // //console.log(this.hasMoreResults, " pagination");
    // if(this.continuationToken.length == 1) {
    //   this.currentPage = 1;
    //   this.hasMoreResults = false;
    // }
    // //console.log(this.selectedRecord, " pagination");
    // //console.log(this.continuationToken, " pagination");
    // //console.log(this.hasMoreResults, " pagination");
  }

  ngOnInit() {
    //console.log(this.selectedRecord);
    //console.log(this.currentPage);
    //console.log('kdjhfcskdjhfkjdshfkjhsdjghfsjgfdjkhgjfhsgjfdhg' + this.continuationToken);
  }

  changeRecords() {
    this.currentPage = 1;
    this.changeRecordsEvent.emit(this.selectedRecord);
  }

  pageNavigated(state:any) {
    //console.log(state);
    if(state == 'next') {
      this.currentPage = this.currentPage + 1;
      // this.continuationToken.push({'pageNo':this.continuationToken[this.continuationToken.length - 1]['pageNo'] + 1});
      this.callEvent.emit({state:state, currentPage:this.currentPage, hasMoreResults: this.hasMoreResults, continuationToken:this.continuationToken, selectedRecord: this.selectedRecord});
    } else {
      //console.log(state);
      this.currentPage = this.currentPage - 1;
      this.continuationToken.pop();
      this.callEvent.emit({state:state, currentPage:this.currentPage, hasMoreResults: this.hasMoreResults, continuationToken:this.continuationToken, selectedRecord: this.selectedRecord});
    }
  }

  clearFilter()
  {
    this.currentPage = 1;
    // this.continuationToken = [];
    // this.hasMoreResults = false;
  }

}
