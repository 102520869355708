import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { TransfereService } from '../../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $:any;

@Component({
  selector: 'app-addfla',
  templateUrl: './addfla.component.html',
  styleUrls: ['./addfla.component.css']
})
export class AddflaComponent implements OnInit {
  countryList = [];
  countryheadList = [];
  distributorList = [];
  error = {'description':[]};
  addflaForm:any;
  userDetails = {'usertype':'','sub':'','countryDetails':''};
  maxLength = 10;
  minLength = 10;
  constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService,private router : Router,private spinner: NgxSpinnerService,private location: Location) {
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }

    this.addflaForm = this.fb.group({
      country: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      countryHeadName:new FormControl(''),
      countryHeadId: new FormControl('', Validators.required),
      distributorId: new FormControl('', Validators.required),
      distributorName: new FormControl(''),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      email:new FormControl('', [Validators.required,this.emailValidator]),
      Contact:new FormControl('', [Validators.required])
    });
    this.addflaForm.get('Contact').disable();
  }
  ngOnInit() {
    this.addflaForm = this.fb.group({
      country: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      countryHeadName:new FormControl(''),
      countryHeadId: new FormControl('', Validators.required),
      distributorId: new FormControl('', Validators.required),
      distributorName: new FormControl(''),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      email:new FormControl('', [Validators.required,this.emailValidator]),
      Contact:new FormControl('', [Validators.required])
    });
    this.addflaForm.get('Contact').disable();
    this.callList();
  }
  
  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  callList() {
    if(this.userDetails.usertype == 'Admin') {
      this.getCountryhdList();
      this.getCountryList({});
    } 
    else 
    if(this.userDetails.usertype == 'CountryHead')
     {
      //if CountryHead is logged in patch his/her own details to form.
      this.countryList=JSON.parse(this.userDetails.countryDetails);
      this.addflaForm.patchValue({
        countryHeadId:this.userDetails['userid'],
        countryHeadName:this.userDetails['given_name'],
        //country:this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
        //language:this.userDetails['language']
      })
      if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) 
      {
        this.countryList.forEach(countryElement => 
          {
          //console.log(countryElement)
          //console.log(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'])
          if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
            this.minLength = countryElement.mobileNumberLength['min'];
            this.maxLength = countryElement.mobileNumberLength['max'];
          }
        });
        this.addflaForm.get('Contact').enable();
      } 
      else 
      {
        this.addflaForm.get('Contact').disable();
        this.addflaForm.get('Contact').reset('');
      }
      this.getDistributorByCountryhead(this.userDetails['userid']);
    } 
    else if(this.userDetails.usertype == 'Distributor') {
      //if Distributor is logged in patch his/her own details to form,
      //country,langug,countryhead,distributor details
      this.addflaForm.patchValue({
        countryHeadId:this.userDetails['countryHeadId'],
        countryHeadName:this.userDetails['countryHeadName'],
        distributorId:this.userDetails['userid'],
        distributorName:this.userDetails['given_name'],
        country:this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
        language:this.userDetails['language']
      })
      if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
        this.countryList.forEach(countryElement => {
          //console.log(countryElement)
          //console.log(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'])
          if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
            this.minLength = countryElement.mobileNumberLength['min'];
            this.maxLength = countryElement.mobileNumberLength['max'];
          }
        });
        this.addflaForm.get('Contact').enable();
      } else {
        this.addflaForm.get('Contact').disable();
        this.addflaForm.get('Contact').reset('');
      }
    }
  }

  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
    //console.log('valid');
      return null;
    } else if(control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');
      return {'inValid': true};
    }else {
      return null;
    }
  }

  public emailValidator(control: FormControl) {
    ////console.log(control.value);
    if (control.value && control.value.length > 0 &&  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value)) {
      //valid
      return null;
    } else if(control.value != null && control.value.length > 0 && !( /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value))) {
      //invalid
      return {'isEmail': true};

    }else {
      return null;
    }
  }

  getCountryhdList() {
    //console.log(this.countryheadList);
    this.apiService.getCountryhdList({}).subscribe(response => {
      //console.log(response);
      this.countryheadList = response['content'];
      //console.log(this.countryheadList);
    }, error => {
      //console.log(error);
    })
  };

  getFlabyAdmin() {
    //console.log(this.countryheadList);
    this.apiService.getFlabyAdmin({}).subscribe(response => {
      //console.log(response);
      this.countryheadList = response['content'];
      //console.log(this.countryheadList);
    }, error => {
      //console.log(error);
    })
  }

  onselectCountryhead(countryHeadId) {
    //console.log(countryHeadId);
    //console.log(this.userDetails['usertype']);
    if(countryHeadId) {
      this.countryheadList.forEach(element => {
        if(element.id == countryHeadId)
         {
           this.countryList=element.countryDetails;
          // this.countryList.forEach(countryElement => 
          //   {
          //   //console.log(countryElement);
          //   if(countryElement.countryName == element.address.country)
          //    {
          //     this.addDistributorForm.get('Contact').enable();
          //     this.minLength = countryElement.mobileNumberLength['min'];
          //     this.maxLength = countryElement.mobileNumberLength['max'];
          //   }
          // });
          this.addflaForm.patchValue({
            //country: element.address.country,
            //language:element.language,
            countryHeadName : element.name
          })
        }
      });

      /*if user id admin*/
      if(this.userDetails['usertype'] == 'Admin') {
        //console.log(countryHeadId);
        this.distributorList = [];
        this.addflaForm.patchValue({
          distributorId : ''
        })
        this.getDistributorByCountryhead(countryHeadId);
      }
    } else {
      this.countryList=[]
      this.addflaForm.get('Contact').disable();
      this.addflaForm.get('Contact').reset('');
      this.addflaForm.get('language').reset('');
    }
  }

  getDistributorByCountryhead(countryHeadId) {
    //console.log(this.distributorList);
    let data = {
      "options" :{},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getCountryList(data) {
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
    });
  }

  // onselectCountry(countryName) {
  //   //console.log(countryName);
  //   this.countryList.forEach(element => {
  //     if(element.countryName == countryName) {
  //       this.addflaForm.patchValue({
  //         language : element.languages[0].languageName
  //       })
  //     }
  //   });
  // }

  addFla() {
    //console.log(this.addflaForm);
    //console.log(this.addflaForm.value);
    let data = {
      "name": this.addflaForm.value.name,
      "countryHeadId": this.addflaForm.value.countryHeadId,
      "distributorId": this.addflaForm.value.distributorId,
      "mobileNo": this.addflaForm.value.Contact,
      "address": {
        "country":this.addflaForm.value.country
      },
      "language":this.addflaForm.value.language,
      "email": this.addflaForm.value.email
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.addFla(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/fla'],{queryParams:{id:1}});
      this.addflaForm.reset();
    },error => {
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  onselectCountryChange(countryName)
  {
    console.log(countryName)
    if(countryName)
    {    
    this.countryList.forEach(countryElement => 
      {      
      if(countryElement.countryName == countryName)
       {     
        console.log(countryElement)  
        this.addflaForm.get('Contact').enable();
        this.minLength = countryElement.mobileNumberLength['min'];
        this.maxLength = countryElement.mobileNumberLength['max'];
        this.addflaForm.patchValue({          
          language:countryElement.languages[0].languageName         
        })        
      }
    }); 
  }
  else{
    this.addflaForm.get('Contact').disable();
    this.addflaForm.get('language').reset('');
  }
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }
}
