import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes,Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $:any;

@Component({
  selector: 'app-executive-list',
  templateUrl: './executive-list.component.html',
  styleUrls: ['./executive-list.component.css']
})
export class ExecutiveListComponent implements OnInit {
  success = {'description':[]};
  countryList = [];
  selectedStatus = '';
  selectedCountry = '';
  countryheadList = [];
  selectedCountryhead = '';
  selectedCountryheadName = '';
  distributorList = [];
  selectedDistributor = '';
  customerExecutiveList = [];
  selectedDistributorName = '';
  constructor(public apiService:ApiService,private router : Router,private spinner: NgxSpinnerService,private route: ActivatedRoute,private location: Location) { 
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if( params['id'] == 1) {
        this.location.replaceState("callcenterExecutive");
        this.success.description = [];
        this.success.description.push('Service Center Executive Added Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      } else if( params['id'] == 2) {
        this.location.replaceState("callcenterExecutive");
        this.success.description = [];
        this.success.description.push('Service Center Executive Updated Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      }
    });
  }
  userDetails = {'usertype':'','sub':'','countryDetails':''};
  selectedUser = {};
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  ngOnInit() {
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
      this.callList('next');
    }
  }

  callList(state) {
    this.query = {};
    if(this.userDetails.usertype == 'Admin') {
      this.getCountryList(true);
      
      // this.getcallcenterexecutiveList(state);
    } else if(this.userDetails.usertype == 'CountryHead') {
      
    this.countryList=JSON.parse(this.userDetails.countryDetails)
    this.selectedCountry =this.countryList[0].countryName;// this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];

    //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
    this.selectedCountryhead = this.userDetails['userid'];
    this.selectedCountryheadName = this.userDetails['given_name'];
    this.getDistributorByCountryhead(this.userDetails['userid'],true);
    this.getCountryheadList(true);
      // this.getCountryList();
      // this.getcallcenterexecutiveList(state);
    } else if(this.userDetails.usertype == 'Distributor')  {
      
    this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
    this.selectedCountryhead = this.userDetails['countryHeadId'];
    this.selectedCountryheadName = this.userDetails['countryHeadName'];
    this.selectedDistributor = this.userDetails['userid'];
    this.selectedDistributorName = this.userDetails['given_name'];
      // this.getCountryList();
      //when userType is Distributor.
      this.filterChanged(state);
    }
  }

  clickOnFilter()
  {
    this.currentPage = 1;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state) {
    //console.log(this.customerExecutiveList);
    this.customerExecutiveList = [];
    const filters = [];
    filters.push({
      "member": "countryheadId",
      "value":this.selectedCountryhead,
      "operation": "EqualTo"
    })
    filters.push({
      "member": "distributorId",
      "value":this.selectedDistributor,
      "operation": "EqualTo"
    })
    // if(this.selectedDistributor)
    // {
    //   filters.push({
    //     "member": "distributorId",
    //     "value":this.selectedDistributor,
    //     "operation": "EqualTo"
    //   })
    // }
    if(this.selectedStatus)
    {
      filters.push({
        "member": "Status",
        "value":this.selectedStatus,
        "operation": "EqualTo"
      })
    }
    this.query = { 
      "filters" : filters, 
    "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
    "itemsPerPage": this.selectedRecord,
    "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
    "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null}
    //console.log(this.query);
    this.getcallcenterexecutiveList(state);
  }

  getCountryList(isinitial) {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      // by default
      if(this.countryList.length > 0){
        this.selectedCountry = this.countryList[0].countryName;
        this.getCountryheadList(isinitial);
        //console.log(this.countryList, "this.getCountryList");
      }
    },error=>{
      this.spinner.hide();
    });
  }

  getCountryheadList(isinitial) {
    //console.log(this.countryheadList);
    this.selectedCountryhead = "";
    this.selectedDistributor = "";
    this.distributorList = [];
    let query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ]
    }
    this.spinner.show();
    this.apiService.getCountryhdList(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.countryheadList = response['content'];
      // by defaulat
      if(this.userDetails.usertype==="CountryHead")
      {
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.countryheadList[0].id,isinitial)

      }else
      if(this.countryheadList.length > 0)
      {
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.countryheadList[0].id,isinitial)
      }
      
      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  
  onselectCountryhead(countryHeadId,isinitial) {
    //console.log(countryHeadId);
    //console.log(this.userDetails['usertype']);
    /*if user id admin*/
    if(this.userDetails['usertype'] == 'Admin') {
    //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId,isinitial);
    }
    if (this.userDetails['usertype'] == 'CountryHead') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId,isinitial);
    }
  }

  getDistributorByCountryhead(countryHeadId,isinitial) {
    //console.log(this.distributorList);
    let data = {
      "options" :{},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      // by defaulat
      if(this.distributorList.length > 0)
      {
        this.selectedDistributor = this.distributorList[0].id
        if(isinitial) {
          this.filterChanged('next');
        }
      }
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }
  
  getcallcenterexecutiveList(state) {
    this.spinner.show();
    this.apiService.getcallcenterexecutiveList(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.customerExecutiveList = response['content'].payLoad;
      if(response['content'].queryOptions)
      {
        if(state == 'next')
        {
          //console.log(state);
          if(response['content'].queryOptions.hasMoreResults)
          {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else
          {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if(this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults)
        {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else
        {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      //console.log(this.customerExecutiveList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  addExecutive() {
    this.router.navigate(['/callcenterExecutive/add']);
  }

  editexecutivedetails(executivedetails) {
    //console.log(executivedetails);
    this.router.navigate(['/callcenterExecutive/edit'],{queryParams:{id:executivedetails.id,cname:this.selectedCountry}});
  }

  changeStatus(executiveId) {
    //console.log(executiveId,"executiveId");
    // this.spinner.show();
    this.apiService.changeStatus(executiveId).subscribe(response => {
      //console.log(response);
      // this.spinner.hide();
      this.currentPage = 1;
      this.query = {};
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.callList('next');
    }, error => {
      //console.log(error);
      // this.spinner.hide();
    })
  }

  resetFilter()
  {
    if(this.userDetails.usertype == 'Admin') {
      this.selectedCountry = '';
      this.selectedCountryhead = '';
      this.selectedDistributor = '';
      this.selectedDistributorName = '';
      this.countryheadList =[];
      this.distributorList = [];
      this.customerExecutiveList = [];
    } else if(this.userDetails.usertype == 'CountryHead') {
      this.selectedDistributor = '';
      this.selectedDistributorName = '';
    }
    this.selectedStatus = '';
    this.currentPage = 1;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.selectedRecord = "20";
    this.query = {};
  }

  openModal(data) {
    //console.log(data);
    $('#resetPassword').modal('show');
  }

  closeModal(){
    $('#resetPassword').modal('hide');
    this.success.description = [];
    this.success.description.push(' Password Reset Successfully.');
    var scope = this;
    setInterval(function(){
      scope.success.description = [];
    }, 5000);
    this.callList('next');
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.callList('next');
  }

  pageNavigated(obj) {
    //console.log(obj.state)
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.callList(obj.state);
  }
}
