import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes,Router,ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransfereService } from '../transfere.service';
import { Location } from '@angular/common';

declare var $ : any;
@Component({
  selector: 'app-countryhead-list',
  templateUrl: './countryhead-list.component.html',
  styleUrls: ['./countryhead-list.component.css']
})
export class CountryheadListComponent implements OnInit {
  success = {'description':[]};
  constructor(private fb: FormBuilder,public transfereservice: TransfereService,private route: ActivatedRoute,public apiService:ApiService,private router : Router,private spinner: NgxSpinnerService,private location: Location) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if( params['id'] == 1) {
        this.location.replaceState("countryheads");
        this.success.description = [];
        this.success.description.push('Country Head Added Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      } else if( params['id'] == 2) {
        this.location.replaceState("countryheads");
        this.success.description = [];
        this.success.description.push('Country Head Updated Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      }
    });
  }
  countryList = [];
  selectedCountry = '';
  selectedStatus = '';
  countryheadList = [];
  userDetails = {'usertype':'','sub':''};
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  subscription;
  ngOnInit() {
    this.getCountryList();
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    }
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      if(this.countryList.length > 0) {
        // this.selectedCountry = this.countryList[0].countryName;
        this.getCountryheadList('next');
        //console.log(this.countryList, "this.getCountryList");
      }
    },error=>{
      this.spinner.hide();
    });
  }

  resetFilters() {
    this.selectedCountry = "";
    this.selectedStatus = '';
    this.countryheadList = [];
    this.currentPage = 1;
    this.hasMoreResults = false;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.selectedRecord = "20";
    this.query = {};
  }

  getCountryheadList(state) {
    this.spinner.show();
    let filters = [];
    if(this.selectedCountry)
    {
      filters.push(
        {
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }

    if(this.selectedStatus  != "")
    {
      filters.push({
        "member": "isactive",
        "value": this.selectedStatus,
        "operation": "EqualTo"
      })
    }
    
    this.query = {
      "filters" :filters,
      "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    //console.log(this.countryheadList);
    this.apiService.getCountryhdList(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.countryheadList = response['content'];
      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  

  addCountryhead() {
    this.router.navigate(['/countryheads/add']);
  }

  editCountryhead(countryheaddetails) {
    //console.log(countryheaddetails);
    this.router.navigate(['/countryheads/edit'],{queryParams:{id:countryheaddetails.id}});
  }

  changeStatus(distributorId) {
    //console.log(distributorId,"distributor");
    this.spinner.show();
    this.apiService.changeStatus(distributorId).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.currentPage = 1;
      this.query = {};
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.getCountryheadList('next');
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  openModal(data) {
    //console.log(data);
    $('#resetPassword').modal('show');
  }

  closeModal(){
    $('#resetPassword').modal('hide');
    this.success.description = [];
    this.success.description.push('Password Reset Successfully.');
    var scope = this;
    setInterval(function(){
      scope.success.description = [];
    }, 5000);
    this.getCountryheadList('next');
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.getCountryheadList('next');
  }

  pageNavigated(obj) {
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.getCountryheadList(obj.state);
  }

  closeAlert() {
    this.success.description = [];
  }

}
