import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelServiceService } from '../../excel-service.service';
import { DateService } from '../../date.service';
const FILE_NAME = "Country Wise Performance Export";
import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, rotateIn, fadeInDown } from 'ng-animate'; declare var $: any;
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-country-wise-performance',
  templateUrl: './country-wise-performance.component.html',
  styleUrls: ['./country-wise-performance.component.css'],
  animations: [
    trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown))])
  ]
})
export class CountryWisePerformanceComponent implements OnInit {
  countrywiseReport = [];
  countryList = [];
  query = {};
  startDateFilter: any;
  endDateFilter: any;
  selectedCountry = '';
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  hasMorePrevious = false;
  continuationToken = [];
  noGraph = false;
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public endDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    componentDisabled: true,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    animations: true,
    animationSteps: 60,
    animationEasing: "easeOutBounce",
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    useAnimation: true,
    animation: {
      duration: 1000,
      easing: 'easeOutQuart'
    },
    legend: {
      display: true,
      position: 'bottom'
    }
  };
  bounce: any;
  // public barChartLabels: string[] = ["Africa", "Asia", "Europe", "Latin America", "North America", "India", "Srilanka", "India", "Srilanka"];
  // public barChartType: string = 'bar';
  // public barChartLegend: boolean = true;

  // public barChartData: any[] = [
  //   {
  //     data: [2478, 5267, 734, 784, 433, 784, 433, 784, 433], label: 'Series A',
  //     backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850", "#8e5ea2", "#3cba9f", "#8e5ea2", "#3cba9f"]
  //   }
  // ];
  public barChartLabels: string[] = [];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;

  public barChartData: any[] = [
    {
      data: [], label: '',
      backgroundColor: []
    }
  ];
  colorPalette = ['#F97F51', '#B33771', '#6D214F', '#182C61',
    '#3B3B98',
    '#FC427B',
    '#FD7272',
    '#EAB543',
    '#58B19F',
    '#2C3A47',
    '#D6A2E8',
    '#82589F',
    '#eb2f06',
    '#1e3799',
    '#0a3d62',
    '#3c6382',
    '#6a89cc',
    '#84817a',
    '#227093']
  constructor(public apiService: ApiService, public router: Router, private spinner: NgxSpinnerService, public dateService: DateService, public excelService: ExcelServiceService) { }

  ngOnInit() {
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.getCountryList();
    // this.getCountrywisereport();
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      // By default
      if (this.countryList.length > 0) {
        this.selectedCountry = '';
        this.filterChanged('next');
        //console.log(this.countryList, "this.getCountryList");
      }
    }, error => {
      this.spinner.hide();
    });
  }

  getCountrywisereport(state) {
    this.spinner.show();
    this.countrywiseReport = [];

    var stackedData = { 'requested': [], 'assigned': [], 'accepted': [], 'jobdone': [], 'closed': [], 'cancelled': [] };

    this.apiService.getCountrywisereport(this.query).subscribe(response => {
      console.log(response);
      this.countrywiseReport = response['content'].payLoad;
      this.barChartLabels = [];
      this.barChartData[0]['data'] = [];
      this.barChartData[0]['backgroundColor'] = [];
      this.barChartData[0]['label'] = 'Total Request Count';
      if (response['content'].payLoad && response['content'].payLoad.length > 0) {
        response['content'].payLoad.forEach(element => {
          stackedData['requested'].push(element.requested)
          stackedData['assigned'].push(element.assigned)
          stackedData['accepted'].push(element.inProcess)
          stackedData['jobdone'].push(element.jobDone)
          stackedData['closed'].push(element.closed)
          stackedData['cancelled'].push(element.cancelled)
        });
        this.drawGraph(response['content'].payLoad, stackedData);
      } else {
        this.noGraph = true;
      }
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      this.spinner.hide();
      //console.log(this.countrywiseReport);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  clickOnFilter() {
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state) {
    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry
      })
    }
    if (this.startDateFilter && this.startDateFilter != null) {
      filters.push({
        "member": "fromDate",
        "value": this.simpleDate(this.startDateFilter)
      })
    }
    if (this.endDateFilter && this.endDateFilter != null) {
      filters.push({
        "member": "toDate",
        "value": this.simpleDate(this.endDateFilter)
      })
    }
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    this.getCountrywisereport(state);
  }

  resetFilter() {
    this.selectedCountry = '';
    this.startDateFilter = '';
    this.endDateFilter = '';
    this.query = {};
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.selectedRecord = "20";
    this.countrywiseReport = [];
    this.toogleView = false;
    this.noGraph = false;
  }

  simpleDate(dateInfo) {
    return (dateInfo.date.year + '-' + dateInfo.date.month + '-' + dateInfo.date.day + ' ').concat(moment().format("HH:mm:ss A"));
  }

  onstartDatechange(event) {
    //console.log(event);
    if (event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate;
      // this.onChangeFilter();
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onendDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
      // this.onChangeFilter();
    } else {
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  private getExportableItems() {
    if (this.countrywiseReport && this.countrywiseReport.length > 0) {
      return this.countrywiseReport.map((item, index) => {
        return {
          "Sr No.": index + 1,
          "Country": item.countryName,
          "Distributor": item.distributors,
          "FLA": item.fla,
          "Technicians": item.mechanics,
          "Customer Registrations": item.customerRegistrations,
          "Total Request": item.totalRequests,
          "Requested": item.requested,
          "Assigned": item.assigned,
          "Inprocess": item.inProcess,
          "Job Done": item.jobDone,
          "Closed": item.closed,
          "Cancelled": item.cancelled,
          "Avg Feedback Rating": item.avgFeedbackRating ? item.avgFeedbackRating : 0
        };
      });
    } else {
      return [];
    }
  }

  exporttoexcel() {
    var json = this.getExportableItems();
    this.excelService.exportDocument(json, FILE_NAME)
  }

  dumpExcelReports() {
  
    this.spinner.show();

    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry
      })
    }
    if (this.startDateFilter && this.startDateFilter != null) {
      filters.push({
        "member": "fromDate",
        "value": this.simpleDate(this.startDateFilter)
      })
    }
    if (this.endDateFilter && this.endDateFilter != null) {
      filters.push({
        "member": "toDate",
        "value": this.simpleDate(this.endDateFilter)
      })
    }

    let payload = {
      "reportType": "CountrywisePerformance",
      "filters": filters
  }
    this.apiService.dumpExcelReports(payload)
      .subscribe((obj: any) => {
        console.log(obj);
        let content_disposition = obj['content-disposition'];
        let file_name = this.getFilename(content_disposition);
        const blob: any = new Blob([obj.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let link = document.createElement("a");

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        }

        else if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", file_name != '' ? file_name : 'CountrywisePerformanceExcelReport');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });

  }

  getFilename(content_disposition: any): string {
    let file_name = '';
    if(content_disposition != '') {
      let arr = content_disposition.split(';');
      if(arr.length >0) {
        if(arr[1].includes('filename')){
          file_name = arr[1].split('=')[1];
        }
      }
    }
    return file_name;
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    //console.log(obj.state)
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }

  drawGraph(data, stackedData) {
    console.log(this.barChartData);
    console.log(stackedData);
    this.barChartLabels = [];
    this.barChartData[0]['data'] = [];
    this.barChartData[0]['backgroundColor'] = [];
    this.noGraph = false;
    if (data.length > 0) {
      if (this.selectedCountry == '') {
        data.forEach(element => {
          console.log(element.countryName);
          var min = 1;
          var max = 19;
          var random = Math.floor(Math.random() * (+max - +min)) + +min;
          console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
          this.barChartData[0]['label'] = 'Countries vs Total Requests';
          if (element.countryName.length > 0) {
            this.barChartLabels.push(element.countryName + ' (' + element.totalRequests + ')');
            this.barChartData[0]['data'].push(element.totalRequests);
            // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
            this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
          }
        });
        // this.barChartData[0]['data'] = [
        //   {
        //     label: 'Requested',
        //     data: stackedData['requested'],
        //     backgroundColor: '#22aa99'
        //   }, {
        //     label: 'Assigned',
        //     data: stackedData['assigned'],
        //     backgroundColor: '#994499'
        //   }, {
        //     label: 'Accepted',
        //     data: stackedData['accepted'],
        //     backgroundColor: '#316395'
        //   }, {
        //     label: 'Jobdone',
        //     data: stackedData['jobdone'],
        //     backgroundColor: '#66aa00'
        //   }, {
        //     label: 'Closed',
        //     data: stackedData['closed'],
        //     backgroundColor: '#dd4477'
        //   }, {
        //     label: 'Cancelled',
        //     data: stackedData['cancelled'],
        //     backgroundColor: '#0099c6'
        //   }
        // ];
      } else {
        this.barChartData[0]['label'] = 'Requests vs Count';
        data.forEach(element => {
          console.log(element.countryName);
          if (element.countryName.length > 0) {
            element['totalRequests']
            for (var key in element) {
              if (element['totalRequests'] > 0) {
                if (key == 'requested') {
                  this.barChartLabels.push(key.toUpperCase() + ' (' + element[key] + ')');
                  var min = 1;
                  var max = 19;
                  var random = Math.floor(Math.random() * (+max - +min)) + +min;
                  this.barChartData[0]['data'].push(element[key]);
                  console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
                  // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
                  this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
                }
                else if (key == 'assigned') {
                  this.barChartLabels.push(key.toUpperCase() + ' (' + element[key] + ')');
                  var min = 1;
                  var max = 19;
                  var random = Math.floor(Math.random() * (+max - +min)) + +min;
                  this.barChartData[0]['data'].push(element[key]);
                  console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
                  // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
                  this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
                }
                else if (key == 'inProcess') {
                  this.barChartLabels.push(key.toUpperCase() + ' (' + element[key] + ')');
                  var min = 1;
                  var max = 19;
                  var random = Math.floor(Math.random() * (+max - +min)) + +min;
                  this.barChartData[0]['data'].push(element[key]);
                  console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
                  // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
                  this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
                }
                else if (key == 'jobDone') {
                  this.barChartLabels.push(key.toUpperCase() + ' (' + element[key] + ')');
                  var min = 1;
                  var max = 19;
                  var random = Math.floor(Math.random() * (+max - +min)) + +min;
                  this.barChartData[0]['data'].push(element[key]);
                  console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
                  // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
                  this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
                }
                else if (key == 'closed') {
                  this.barChartLabels.push(key.toUpperCase() + ' (' + element[key] + ')');
                  var min = 1;
                  var max = 19;
                  var random = Math.floor(Math.random() * (+max - +min)) + +min;
                  this.barChartData[0]['data'].push(element[key]);
                  console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
                  // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
                  this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
                }
                else if (key == 'cancelled') {
                  this.barChartLabels.push(key.toUpperCase() + ' (' + element[key] + ')');
                  var min = 1;
                  var max = 19;
                  var random = Math.floor(Math.random() * (+max - +min)) + +min;
                  this.barChartData[0]['data'].push(element[key]);
                  console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
                  // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
                  this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
                }
              }
            }
          }
        });
      }

      if (this.barChartLabels.length == 0) {
        this.noGraph = true;
      }
    } else {
      this.noGraph = true;
    }

    console.log(this.barChartLabels);
    console.log(this.barChartData);
  }

  printgraph() {
    // get size of report page
    var reportPageHeight = $('#bar-chart').innerHeight();
    var reportPageWidth = $('#bar-chart').innerWidth();

    // create a new canvas object that we will populate with all other canvas objects
    var pdfCanvas = $('<canvas />').attr({
      id: "canvaspdf",
      width: reportPageWidth,
      height: reportPageHeight
    });

    // keep track canvas position
    var pdfctx = $(pdfCanvas)[0].getContext('2d');
    var pdfctxX = 0;
    var pdfctxY = 0;
    var buffer = 100;
    pdfctx.fillStyle = "white";
    pdfctx.fillRect(0, 0, reportPageWidth, reportPageHeight);
    pdfctx.restore();
    // for each chart.js chart
    $("canvas").each(function (index) {
      // get the chart height/width
      var canvasHeight = $(this).innerHeight();
      var canvasWidth = $(this).innerWidth();

      // draw the chart into the new canvas
      pdfctx.drawImage($(this)[0], pdfctxX, pdfctxY, canvasWidth, canvasHeight);
      pdfctxX += canvasWidth + buffer;

      // our report page is in a grid pattern so replicate that in the new canvas
      if (index % 2 === 1) {
        pdfctxX = 0;
        pdfctxY += canvasHeight + buffer;
      }
    });

    // create new pdf and add our new canvas as an image
    var pdf = new jsPDF('l', 'pt', [reportPageWidth, reportPageHeight]);
    pdf.addImage($(pdfCanvas)[0], 'PNG', 50, 50, 0, 0);

    // download the pdf
    pdf.save('Countrywiseperformance.pdf');
  }
  toogleView = false;

  toogleViewFn() {
    console.log(this.toogleView);
    if (!this.toogleView) {
      this.toogleView = true;
    } else {
      this.toogleView = false;
    }
    this.clickOnFilter();
  }
}
