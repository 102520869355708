import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes,Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-service-type',
  templateUrl: './service-type.component.html',
  styleUrls: ['./service-type.component.css']
})
export class ServiceTypeComponent implements OnInit {

  constructor(public apiService:ApiService,private router : Router,private spinner: NgxSpinnerService,private route: ActivatedRoute,private location: Location) { }
  servicetypeList = [];
  typeList = [];
  success = {'description':[]};
  countryList = [];
  selectedCountry = "";
  selectedStatus = "";
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  error = {'description':[]};
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if( params['id'] == 1) {
        this.location.replaceState("serviceTypes");
        this.success.description = [];
        this.success.description.push('Service Type Added Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      } else if( params['id'] == 2) {
        this.location.replaceState("serviceTypes");
        this.success.description = [];
        this.success.description.push('Service Type Updated Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      }
    });
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.getCountryList();
    // this.getServicetypeList();
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      this.selectedCountry = this.countryList[0].countryName;
      
      this.getServicetypeList('next');
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
      this.spinner.hide();
    });
  }

  getServicetypeList(state) {
    //console.log(this.servicetypeList);
    this.servicetypeList = [];
    this.typeList = [];
    const filters = [];
    if(this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    if(this.selectedStatus  != "") {
      filters.push({
        "member": "isactive",
        "value": this.selectedStatus
      })
    }
    this.query = {"filters":filters,
    "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
    "itemsPerPage": this.selectedRecord,
    "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
    "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null};
    this.getData(state);
  }

  getData(state)
  {
    this.spinner.show();
    this.apiService.getallServicetypes(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.servicetypeList = response['content'].payLoad;
      if(response['content'].queryOptions) {

        if(state == 'next') {     
          //console.log(state); 
          if(response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff"); 
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
            this.continuationToken = continuationToken;
          } else if(!response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "else ifffffffffff"); 
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        } else if(this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults){
          //console.log(state + '-->'+ "else ifffffffffff 1111111111"); 
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
          this.continuationToken = continuationToken;
        } else if(!response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff no resultssssssssss"); 
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      if(this.servicetypeList.length > 0) {
        this.servicetypeList.forEach(element => {
          //console.log(element.serviceTypeDetails);
          element.serviceTypeDetails.forEach(typeElement => {
            //console.log(typeElement);
            if(typeElement.country == this.selectedCountry) {
              typeElement['id'] = element.id;
              this.typeList.push(typeElement);
            }
          });
        });
      }
      //console.log(this.typeList);
      //console.log(this.servicetypeList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }
  addservicetype(){
    this.router.navigate(['/serviceTypes/add']);
  }

  editservicetype(servicetype){
    //console.log(servicetype);
    this.router.navigate(['/serviceTypes/edit'],{queryParams:{id:servicetype.id,country:this.selectedCountry}});
  }

  resetFilters() {
    this.selectedCountry = "";
    this.selectedStatus = "";
    this.servicetypeList = [];
    this.typeList = [];
    this.query = {};
    this.currentPage = 1;
    this.hasMoreResults = false;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.selectedRecord = "20";
  }

  changeServiceTypeStatus(servicetype) {
    //console.log(servicetype,"stypeId");
    var data = {};
    this.servicetypeList.forEach(element => {
      //console.log(element);
      if(element.id == servicetype.id) {
        element.serviceTypeDetails.forEach(typeElement => {
          if(typeElement.country == servicetype.country) {
            typeElement.active = !typeElement.active;
          }
        })
        data = element;
      }
      //console.log(element);
    });
    //console.log(data);
    this.spinner.show();
    this.apiService.editServicetype(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.currentPage = 1;
      this.query = {};
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.getServicetypeList('next');
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.getServicetypeList('next');
  }

  pageNavigated(obj) {
    //console.log(obj, " state");
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.getServicetypeList(obj.state);
  }

  closeAlert() {
    this.error.description = [];
  }
}
