import { Injectable,EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransfereService {
  navchange: EventEmitter<string> = new EventEmitter();
  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();
  constructor() { }

  private data;
  emitNavChangeEvent(successMsg) {
    this.messageSource.next(successMsg)
    // this.navchange.emit(successMsg);
  }
  getNavChangeEmitter() {
    return this.navchange;
  }

  clearMessage() {
    //console.log('clear Messssssssssssssssssage');
    this.messageSource.next('');
    this.messageSource.unsubscribe;
  }

  setData(data){
    this.data = data;
  }

  getData(){
    let temp = this.data;
    this.clearData();
    return temp;
  }

  clearData(){
    this.data = undefined;
  }
}
