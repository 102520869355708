import { Component, OnInit,ViewChild,ElementRef, ɵConsole } from '@angular/core';
import { ApiService } from '../../api.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
declare var google;
import { RouterModule, Routes,Router } from '@angular/router';
declare var $:any;
import { Location } from '@angular/common';

@Component({
  selector: 'app-service-station',
  templateUrl: './service-station.component.html',
  styleUrls: ['./service-station.component.css']
})
export class ServiceStationComponent implements OnInit {
  addServiceCenterForm : any;
  @ViewChild('stationmap')stationmapElement: ElementRef;
  stationmap:any;
  map: any;
  marker:any;
  servicestation : any;
  geocoder = new google.maps.Geocoder;
  currentLng;
  currentLat;
  city = '';
  state = '';
  country ='';
  postal_code= '';
  autocompleteItems = [];
  countryheadList = [];
  distributorList = [];
  flaList = [];
  service;
  userDetails = {'usertype':'','sub':''};
  error = {'description':[]};
  countryCode = '';
  countryList = [];
  maxLength = 10;
  minLength = 10;
  addressErr = false;
  noCity = false;
  noState = false;
  isCityhidden:boolean = true;
  isStatehidden:boolean = true;
  constructor(public apiService:ApiService,private spinner: NgxSpinnerService,private fb: FormBuilder,private router:Router,private location: Location) { 
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      console.log(this.userDetails);
    }
    $(document).click(function(event) {
      $('.dropdownlist').css('display','none');
    });

    this.addServiceCenterForm = this.fb.group({
      Name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      Contact: new FormControl('', [Validators.required]),
      Address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required,this.addValidator]),
      state: new FormControl('', [Validators.required,this.addValidator]),
      countryHeadId: new FormControl('', [Validators.required]),
      countryHeadName:new FormControl(''),
      distributorId: new FormControl('', [Validators.required]),
      distributorName:new FormControl(''),
      flaId: new FormControl('', [Validators.required]),
      flaName:['']
    })
    //console.log(this.addServiceCenterForm)
    this.addServiceCenterForm.get('Address').disable();
  }

  ngOnInit() {
    this.service = new google.maps.places.AutocompleteService();
    $('.dropdown').css('display','none');
    // this.spinner.show();
    this.addressErr = false;
    this.addServiceCenterForm = this.fb.group({
      Name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      Contact: new FormControl('', [Validators.required]),
      Address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required,this.addValidator]),
      state: new FormControl('', [Validators.required,this.addValidator]),
      countryHeadId: new FormControl('', [Validators.required]),
      countryHeadName:new FormControl(''),
      distributorId: new FormControl('', [Validators.required]),
      distributorName:new FormControl(''),
      flaId: new FormControl('', [Validators.required]),
      flaName:['']
    })
    //console.log(this.addServiceCenterForm)
    this.addServiceCenterForm.get('Address').disable();
    // if(navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position)=>{
    //     this.spinner.hide();
    //     this.currentLat = position.coords.latitude;
    //     this.currentLng = position.coords.longitude;
    //     //console.log(this.currentLat);
    //     //console.log(this.currentLng);
    //   }, (error) => {
    //     this.spinner.hide();
    //     alert("Geolocation is not supported by this browser.");
    //   });
    // } else {
    //   this.spinner.hide();
    //   alert("Geolocation is not supported by this browser.");
    // }
    this.callList();
  }

  public addValidator(control: FormControl) {
    let isValid = false;
    // var regex = /[^a-zA-Z ]/;
    // if(control.value && control.value.trim().length >= 3 && control.value.trim().length <= 50 && !regex.test(control.value)) {
    if(control.value && control.value.trim().length >= 2 && control.value.trim().length <= 50 ) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 3 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
    //console.log('valid');
      return null;
    } else if(control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');
      return {'inValid': true};
    }else {
      return null;
    }
  }

  callList() {
    if(this.userDetails.usertype == 'Admin') {
      this.getCountryheadList();
      this.getCountryList({});
    } else if(this.userDetails.usertype == 'CountryHead') {
      //if CountryHead is logged in patch his/her own details to form.
      console.log(this.userDetails);
      this.addServiceCenterForm.patchValue({
        countryHeadId:this.userDetails['userid'],
        countryHeadName:this.userDetails['given_name'],
        country:this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
        language:this.userDetails['language']
      })
      if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
        this.countryList.forEach(countryElement => {
          if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
            this.minLength = countryElement.mobileNumberLength['min'];
            this.maxLength = countryElement.mobileNumberLength['max'];
          }
        });
        this.addServiceCenterForm.get('Contact').enable();
      } else {
        this.addServiceCenterForm.get('Contact').disable();
        this.addServiceCenterForm.get('Contact').reset('');
      }
      this.countryCode = this.userDetails['usercountryCode'];
      this.addServiceCenterForm.get('Address').enable();
      this.getDistributorByCountryhead(this.userDetails['userid']);
    } else if(this.userDetails.usertype == 'Distributor') {
      //if Distributor is logged in patch his/her own details to form,
      //country,langug,countryhead,distributor details
      this.addServiceCenterForm.patchValue({
        countryHeadId:this.userDetails['countryHeadId'],
        countryHeadName:this.userDetails['countryHeadName'],
        distributorId:this.userDetails['userid'],
        distributorName:this.userDetails['given_name'],
        country:this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
        language:this.userDetails['language']
      })
      if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
        this.countryList.forEach(countryElement => {
          if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
            this.minLength = countryElement.mobileNumberLength['min'];
            this.maxLength = countryElement.mobileNumberLength['max'];
          }
        });
        this.addServiceCenterForm.get('Contact').enable();
      } else {
        this.addServiceCenterForm.get('Contact').disable();
        this.addServiceCenterForm.get('Contact').reset('');
      }
      this.countryCode = this.userDetails['usercountryCode'];
      this.addServiceCenterForm.get('Address').enable();
      this.getFlaByDistributor(this.userDetails['userid']);
    } else if(this.userDetails.usertype == 'FrontLineAdvisor') {
      this.addServiceCenterForm.patchValue({
        countryHeadId:this.userDetails['countryHeadId'],
        countryHeadName:this.userDetails['countryHeadName'],
        distributorId:this.userDetails['distributorId'],
        distributorName:this.userDetails['distributorName'],
        flaId:this.userDetails['userid'],
        flaName:this.userDetails['given_name'],
        country:this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
        language:this.userDetails['language']
      })
      if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
        this.countryList.forEach(countryElement => {
          if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
            this.minLength = countryElement.mobileNumberLength['min'];
            this.maxLength = countryElement.mobileNumberLength['max'];
          }
        });
        this.addServiceCenterForm.get('Contact').enable();
      } else {
        this.addServiceCenterForm.get('Contact').disable();
        this.addServiceCenterForm.get('Contact').reset('');
      }
      this.countryCode = this.userDetails['usercountryCode'];
      this.addServiceCenterForm.get('Address').enable();
    }
  }

  getCountryList(data) {
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
    });
  }

  loadMap(latitude,longitude) {
    //console.log('Load');
    //console.log('Load');
    this.spinner.show();
    this.currentLat = latitude;
    this.currentLng = longitude;
    this.noCity = false;
    this.noState = false;
    let latLng = new google.maps.LatLng(latitude,longitude);
    this.geocoder.geocode({'location': latLng}, (results) =>{
      if (results[0]) {
        //console.log(results[0]);
        var isSameCountry = false;
        results[0].address_components.forEach(element => {
          if(element.types[0] == 'country') {
            if(element.long_name == this.country) {
              isSameCountry = true;
            } else {
              //console.log('COUNTRY DOES NOT MATCH.....');
            }
          }
        });
        if(isSameCountry) {
          this.addServiceCenterForm.get('city').reset('');
          this.addServiceCenterForm.get('state').reset('');
          results[0].address_components.forEach(element => {
            //console.log(element.types[0]); 
            // if(element.types[0] == 'administrative_area_level_2') {
            if(element.types[0] == 'administrative_area_level_2') {
            //console.log(element.long_name);
            this.addServiceCenterForm.patchValue({
              city : element.long_name
            })
            this.isCityhidden = true;
            // this.city = element.long_name;
            //get City
            }
            if(element.types[0] == 'administrative_area_level_1') {
            //console.log(element.long_name);
            this.addServiceCenterForm.patchValue({
              state : element.long_name
            })
            this.isStatehidden = true;
            // this.state = element.long_name;
            //get State
            }
            if(element.types[0] == 'country') {
              //console.log(element.long_name);
              this.country = element.long_name;
              //get Country
            }
            if(element.types[0] == 'postal_code') {
              //console.log(element.long_name);
              this.postal_code = element.long_name;
              //get Postal
            }
          });
          this.addServiceCenterForm.patchValue({
            Address:results[0].formatted_address
          })
          //console.log(this.addServiceCenterForm);
          if(this.addServiceCenterForm.get('city').value == undefined || this.addServiceCenterForm.get('city').value == "") {
            this.isCityhidden = false;
          }
          if(this.addServiceCenterForm.get('state').value == undefined || this.addServiceCenterForm.get('state').value == "") {
            this.isStatehidden = false;
          }
        } else {
          //console.log('Country Does not match');
          this.addServiceCenterForm.get('Address').reset('');
          this.addServiceCenterForm.get('city').reset('');
          this.addServiceCenterForm.get('state').reset('');
        }
      } else {
        //console.log('No results found');
      }
      this.spinner.hide();
    },error => {
      this.spinner.hide();
    });
    let mapOptions = {
      center: latLng,
      zoom: 12,
      disableDefaultUI: true, // a way to quickly hide all controls
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      overviewMapControl: false,
      fullscreenControl: false,
    }
   
    this.map = new google.maps.Map(this.stationmapElement.nativeElement, mapOptions);
      

    this.marker = new google.maps.Marker({
      map: this.map,
    });

    this.marker.bindTo('position', this.map, 'center');


    this.map.addListener('dragend', () => {
      //console.log(this.map);
      //console.log(this.marker.getPosition());
      this.map.setCenter(this.marker.getPosition());
      var lat = this.marker.getPosition().lat();
      var lng = this.marker.getPosition().lng();
      //console.log(lat);
      //console.log(lng);
      this.loadMap(lat, lng)
    });
  }
  getCountryheadList() {
    //console.log(this.countryheadList);
    this.apiService.getCountryhdList({}).subscribe(response => {
      //console.log(response);
      this.countryheadList = response['content'];
    }, error => {
      //console.log(error);
    })
  }

  onselectCountryhead(id) {
    //console.log(id);
    if(id) {
    this.addServiceCenterForm.get('Address').reset('');
    this.addServiceCenterForm.get('Address').disable();
    this.currentLat = '';
    this.currentLng = '';
    this.countryheadList.forEach(element => {
      //console.log(element);
      //console.log(id);
        if(element.id == id) {
          this.countryList.forEach(countryElement => {
            //console.log(countryElement);
            if(countryElement.countryName == element.address.country) {
              this.addServiceCenterForm.get('Contact').enable();
              this.minLength = countryElement.mobileNumberLength['min'];
              this.maxLength = countryElement.mobileNumberLength['max'];
            }
          });
        this.countryCode = element.countryCode;
        this.addServiceCenterForm.get('Address').enable();
        //console.log(this.userDetails['usertype']);
        /*if user id admin*/
        this.addServiceCenterForm.get('distributorId').reset('');
        this.addServiceCenterForm.get('flaId').reset('');
        this.distributorList = [];
        this.flaList = [];
        if(this.userDetails['usertype'] == 'Admin') {
          //console.log(element.id);
          this.getDistributorByCountryhead(element.id);
        }
        }
      });
    } else {
      this.addServiceCenterForm.get('Contact').disable();
      this.addServiceCenterForm.get('Contact').reset('');
      this.addServiceCenterForm.get('distributorId').reset('');
      this.addServiceCenterForm.get('flaId').reset('');
      this.distributorList = [];
      this.flaList = [];
      this.addServiceCenterForm.get('Address').reset('');
      this.addServiceCenterForm.get('Address').disable();
    }
  }

  getDistributorByCountryhead(countryHeadId) {
    //console.log(this.distributorList);
    let data = {
      "options" :{},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  onselectDistributor(distributorId) {
    //console.log(distributorId);
    this.flaList = [];
    //console.log(this.userDetails['usertype']);
    /*If user is admin or country head*/
    this.getFlaByDistributor(this.addServiceCenterForm.get('distributorId').value);
  }

  getFlabyAdmin() {
    //console.log(this.flaList);
    this.spinner.show();
    this.apiService.getFlabyAdmin({}).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.flaList = response['content'];
      //console.log(this.flaList);
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  getFlaByCountryhead() {
    //console.log(this.flaList);
    this.spinner.show();
    let data = {
      "options" :{},
      "countryHeadId": localStorage.userId
    }
    this.apiService.getFlaByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.flaList = response['content'].payLoad;
      this.spinner.hide();
      //console.log(this.flaList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getFlaByDistributor(distributorId) {
    //console.log(this.flaList);
    this.spinner.show();
    let data ={"distributorId": distributorId,
    "options":{}}
    this.apiService.getFlaByDistributor(data).subscribe(response => {
      //console.log(response);
      this.flaList = response['content'].payLoad;
      //console.log(this.flaList);
      this.spinner.hide();
      //console.log(this.flaList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  addServiceCenter(){
    //console.log(this.addServiceCenterForm.status);
    if(this.addServiceCenterForm.get('city').value && this.addServiceCenterForm.get('state').value && this.addServiceCenterForm.status == "VALID") {
      const data = {
        "serviceStationName": this.addServiceCenterForm.get('Name').value,
        // "countryHeadId": this.userDetails['countryHeadId'],
        // "distributorId":this.userDetails['distributorId'],
        // "flaId":this.userDetails['userid'],
        "countryHeadId": this.addServiceCenterForm.get('countryHeadId').value,
        "distributorId":this.addServiceCenterForm.get('distributorId').value,
        "flaId":this.addServiceCenterForm.get('flaId').value,
        "address": {
          "addressLine": this.addServiceCenterForm.get('Address').value,
          "location":{
            "type": "Point",
            "coordinates": [this.currentLng,this.currentLat],
          },
          "city":this.addServiceCenterForm.get('city').value,
          "state":this.addServiceCenterForm.get('state').value,
          "country":this.country,
          "pincode": this.postal_code,
        },
        "contact": this.addServiceCenterForm.get('Contact').value
      }
      if(this.country) {
        this.apiService.addServicestations(data).subscribe(response => {
          //console.log(response);
          this.addServiceCenterForm.reset();
          this.router.navigate(['/serviceStations'],{queryParams:{id:1}});
        }, error => {
          //console.log(error);
          this.error.description = [];
          if(error.error.errors.length > 0) {
            this.error.description.push(error.error.errors[0]['description']);
          }
        })
      } else {
        this.error.description.push('Country is required.');
      }
    }
  }

  getAddress() {
    this.autocompleteItems = [];
    let me = this;
    this.addressErr = false;
    this.addServiceCenterForm.get('city').reset('');
    this.addServiceCenterForm.get('state').reset('');
    // this.spinner.show()    
    var regex = /[^a-zA-Z0-9(),./\-_ ]/;
    if (this.addServiceCenterForm.get('Address').value.length >= 10 
    //&& !regex.test(this.addServiceCenterForm.get('Address').value)
    ) 
    {
      this.apiService.getPlacePredictions(this.addServiceCenterForm.get('Address').value, this.countryCode).then(result => {
        this.spinner.hide();
        //console.log(result);
        //console.log(typeof result);
        this.autocompleteItems = <Array<any>>result;
        //console.log(this.autocompleteItems);
        $('.dropdown').css('display', 'inline');
      }, error => {
        console.error(error);
        this.spinner.hide();
      });
    } else {
      this.addressErr = true;
      this.spinner.hide();
    }
  }

  chooseItem(item: any) {
    //console.log(item);
    $('.dropdown').css('display','none');
    this.addServiceCenterForm.patchValue({
      Address : item.description
    })
    this.autocompleteItems = [];
    var geocoder = new google.maps.Geocoder();
    //console.log(this.marker);    
    // if (this.marker && this.marker.setMap) {
    //   this.marker.setMap(null);
    // }
    geocoder.geocode({'placeId': item.place_id}, (results, status) => {
      //console.log(results);
      //console.log(results[0].address_components);
      results[0].address_components.forEach(element => {
       //console.log(element.types[0]);
       if(element.types[0] == 'administrative_area_level_2') {
        //console.log(element.long_name);
        // this.city = element.long_name;
        this.addServiceCenterForm.patchValue({
          city : element.long_name
        })
        //get City
       }
       if(element.types[0] == 'administrative_area_level_1') {
        //console.log(element.long_name);
        // this.state = element.long_name;
        this.addServiceCenterForm.patchValue({
          state : element.long_name
        })
        //get State
       }
       if(element.types[0] == 'country') {
        //console.log(element.long_name);
        this.country = element.long_name;
        //get Country
       }
       if(element.types[0] == 'postal_code') {
        //console.log(element.long_name);
        this.postal_code = element.long_name;
        //get Postal
      }
      });
      if(this.addServiceCenterForm.get('city').value == undefined || this.addServiceCenterForm.get('city').value == "") {
        this.isCityhidden = false;
      }
      if(this.addServiceCenterForm.get('state').value == undefined || this.addServiceCenterForm.get('state').value == "") {
        this.isStatehidden = false;
      }
      //console.log(this.addServiceCenterForm);
      if (status == 'OK') {
        var lat = results[0].geometry.location.lat();
        var lng = results[0].geometry.location.lng();
        //console.log(lat, lng);
        this.currentLat = lat;
        this.currentLng = lng;
        this.loadMap(this.currentLat,this.currentLng);
        // var latlng = new google.maps.LatLng(lat,lng);
        // this.marker.setPosition(latlng);
      }
      //console.log(status)
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }
}
