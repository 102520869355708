import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelServiceService } from '../../excel-service.service';
import { DateService } from '../../date.service';
const FILE_NAME = "Customer Registration Export";
import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, rotateIn, fadeInDown } from 'ng-animate';
import * as jsPDF from 'jspdf';
declare var $: any;
@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.css'],
  animations: [
    trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown))])
  ]
})
export class CustomerRegistrationComponent implements OnInit {
  userDetails = { 'usertype': '', 'sub': '','countryDetails':'' };
  query = {};
  constructor(public excelService: ExcelServiceService, public apiService: ApiService, public router: Router, private spinner: NgxSpinnerService, public dateService: DateService) {
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
  }
  customerregReport = [];
  countryList = [];
  currentDate = new Date();
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  currentDay = new Date().getDate();
  fy = [];
  selectedCountry = '';
  selectedFY = '2019';
  startDateFilter: any;
  endDateFilter: any;
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  hasMorePrevious = false;
  continuationToken = [];
  noGraph = false;
  IsfilterDisabled=false;
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public endDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    componentDisabled: true,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  myData = "hello";
  bounce: any;

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    animations: true,
    animationSteps: 60,
    animationEasing: "easeOutBounce",
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    useAnimation: true,
    animation: {
      duration: 1000,
      easing: 'easeOutQuart'
    },
    legend: {
      display: true,
      position: 'bottom'
    }
  };
  public barChartLabels: string[] = [];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;

  public barChartData: any[] = [
    {
      data: [], label: '',
      backgroundColor: []
    }
  ];
  colorPalette = ['#F97F51', '#B33771', '#6D214F', '#182C61',
    '#3B3B98',
    '#FC427B',
    '#FD7272',
    '#EAB543',
    '#58B19F',
    '#2C3A47',
    '#D6A2E8',
    '#82589F',
    '#eb2f06',
    '#1e3799',
    '#0a3d62',
    '#3c6382',
    '#6a89cc',
    '#84817a',
    '#227093']
  //Current is 1st April 2019 to 31st March 2020 also change from & to date

  ngOnInit() {
    //console.log(this.currentYear)
    //console.log(this.currentMonth)
    //console.log(this.currentDay);
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    if (this.userDetails.usertype == 'Admin') {
      this.getCountryList();
    } else
    if (this.userDetails.usertype == 'CountryHead') 
    {
    var counrtyDetails=JSON.parse(this.userDetails.countryDetails)
    this.countryList=counrtyDetails;
    this.selectedCountry=this.countryList[0].countryName;
    
    }
    else {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.filterChanged('next');
    }
    this.fy.push({ 'year': this.selectedFY });
    var difference = this.currentYear - 2019;
    console.log(difference);
    for (let index = 1; index <= difference; index++) {
      console.log(2019 + index)
      this.fy.push({ 'year': (2019 + index).toString() })
    }
    console.log(this.fy);
  }



  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      // By default
      //console.log(response.content.payLoad);
      //console.log(this.countryList);

      if (response.content.payLoad.length > 0) {
        //console.log(response.content.payLoad.length);
        // this.selectedCountry = this.countryList[0].countryName;
        this.filterChanged('next');
        //console.log(this.countryList, "this.getCountryList");
      }
    }, error => {
      this.spinner.hide();
    });
  }

  clickOnFilter() {
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state) {
    const filters = [];
    if (this.selectedCountry)
     {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    if (this.startDateFilter && this.startDateFilter != null) {
      filters.push({
        "member": "fromDate",
        "value": this.simpleDate(this.startDateFilter)
      })
    }
    if (this.endDateFilter && this.endDateFilter != null) {
      filters.push({
        "member": "toDate",
        "value": this.simpleDate(this.endDateFilter)
      })
    }
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    //console.log(this.query);
    if (!this.toogleView) {
      this.getCustomerregReport(state);
    } else {
      this.getCustomerregReportGraph();
    }
  }

  simpleDate(dateInfo) {
    return (dateInfo.date.year + '-' + dateInfo.date.month + '-' + dateInfo.date.day + ' ').concat(moment().format("HH:mm:ss A"));
  }
  getCustomerregReport(state) {
    this.spinner.show();
    this.customerregReport = [];
    this.apiService.getCustomerregReport(this.query).subscribe(response => {
      //console.log(response);
      this.customerregReport = response['content'].payLoad;
      //console.log(this.customerregReport);
      // this.drawGraph();
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      this.spinner.hide();
      //console.log(this.customerregReport);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  dumpExcelReports() {

    this.spinner.show();
    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    if (this.startDateFilter && this.startDateFilter != null) {
      filters.push({
        "member": "fromDate",
        "value": this.simpleDate(this.startDateFilter)
      })
    }
    if (this.endDateFilter && this.endDateFilter != null) {
      filters.push({
        "member": "toDate",
        "value": this.simpleDate(this.endDateFilter)
      })
    }
    let payload = {
      "reportType": "CustomerRegistration",
      "filters": filters
    }
    this.apiService.dumpExcelReports(payload)
      .subscribe((obj: any) => {
        console.log(obj);
        let content_disposition = obj['content-disposition'];
        let file_name = this.getFilename(content_disposition);

        const blob: any = new Blob([obj.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let link = document.createElement("a");

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        }

        else if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", file_name != '' ? file_name : 'CustomerRegistrationExcelReport');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });

  }

  getFilename(content_disposition: any): string {
    let file_name = '';
    if (content_disposition != '') {
      let arr = content_disposition.split(';');
      if (arr.length > 0) {
        if (arr[1].includes('filename')) {
          file_name = arr[1].split('=')[1];
        }
      }
    }
    return file_name;
  }

  getCustomerregReportGraph() {
    this.spinner.show();
    const filters = [];
    var params = 'all'
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
      filters.push({
        "member": "year",
        "value": this.selectedFY,
        "operation": "EqualTo"
      })
      params = 'country';
    } else {
      if (this.startDateFilter && this.startDateFilter != null) {
        filters.push({
          "member": "fromDate",
          "value": this.simpleDate(this.startDateFilter)
        })
      }
      if (this.endDateFilter && this.endDateFilter != null) {
        filters.push({
          "member": "toDate",
          "value": this.simpleDate(this.endDateFilter)
        })
      }
    }
    const query = { "filters": filters };
    this.apiService.downloadCustomerregGraph(query, params).subscribe(response => {
      console.log(response);
      this.barChartLabels = [];
      this.barChartData[0]['data'] = [];
      this.barChartData[0]['backgroundColor'] = [];
      this.barChartData[0]['label'] = 'Customer Count';
      if (response['content'] && response['content'].length > 0) {
        this.drawGraph(response['content']);
      } else {
        this.noGraph = true;
      }
      this.spinner.hide();
      //console.log(this.customerregReport);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  private getExportableItems() {
    if (this.customerregReport && this.customerregReport.length > 0) {
      return this.customerregReport.map((item, index) => {
        //console.log(item , index);
        if (item.tractors && item.tractors.length > 1) {
          var tractorString = '';
          item.tractors.forEach(element => {
            tractorString = tractorString.concat(element.tractorModelName);
            tractorString = tractorString.concat(',');
          });
          item.tractorString = tractorString.slice(0, tractorString.length - 1);
        }
        else if (item.tractors && item.tractors.length == 1) {
          var tractorString = '';
          tractorString = item.tractors[0].tractorModelName;
          item.tractorString = tractorString;
        }
        return {
          "Sr No.": index + 1,
          "Country": item.country,
          "Customer Name": item.customerName,
          "Mobile No.": item.mobileNo,
          "Tractor Model Name": item.tractorString,
          "Registration Date": item.registrationDate != null ? moment(item.registrationDate).format('DD/MM/YYYY HH:mm') : ''
        };
      });
    } else {
      return [];
    }
  }

  onstartDatechange(event) {
    //console.log(event);
    if (event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate;
      // this.onChangeFilter();
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onendDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
      // this.onChangeFilter();
    } else {
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onChangeFilter() {
    const filters = [];
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
      })
    }
    //console.log(filters);
  }

  exporttoexcel() {
    // var json = [{
    //   "Sr No.":3,
    //   "First Name": "43434",
    //   "Last Name": "4343",
    //   "User Type": "434343" 
    // }]

    // return this.customerregReport.map((item,index) => {
    //   return {
    //     "Sr No.":index+1,
    //     "Country Name": item.countryName,
    //     "State Name": item.stateName,
    //     "City Name": item.cityName,
    //     "Region Name": item.regionName
    //   };
    // });
    var json = this.getExportableItems();
    this.excelService.exportDocument(json, FILE_NAME)
  }

  resetFilter() {
    if (this.userDetails.usertype == 'Admin') 
    {
      this.selectedCountry = '';
    }
    this.selectedRecord = "20";
    this.selectedFY = '';
    this.startDateFilter = '';
    this.endDateFilter = '';
    this.query = {};
    this.hasMorePrevious = false;
    this.hasMoreResults = false;
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.customerregReport = [];
    this.toogleView = false;
    this.noGraph = false;
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    //console.log(obj.state)
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    // console.log(e);
  }

  drawGraph(data) {
    console.log(this.barChartData);
    this.barChartLabels = [];
    this.barChartData[0]['data'] = [];
    this.barChartData[0]['backgroundColor'] = [];
    this.noGraph = false;
    if (data.length > 0) {
      if (this.selectedCountry == '') {
        data.forEach(element => {
          var min = 1;
          var max = 19;
          var random = Math.floor(Math.random() * (+max - +min)) + +min;
          if (element.countryName && element.countryName.length > 0) {
            this.barChartLabels.push(element.countryName + ' (' + element.registrationCount + ')');
            this.barChartData[0]['data'].push(element.registrationCount);
            this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
            console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
            // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
          }
        });
        this.barChartData[0]['label'] = 'Countries vs Customers';
      } else {
        this.barChartData[0]['label'] = 'Month vs Customers';
        var month = ["January", "February", "March", "April", "May", "June", "July",
          "August", "September", "October", "November", "December"];;
        month.forEach(element => {
          var monthElement = data.find(o => o.month === element);
          var min = 1;
          var max = 19;
          var random = Math.floor(Math.random() * (+max - +min)) + +min;
          if (monthElement) {
            this.barChartData[0]['data'].push(monthElement['registationCount']);
            this.barChartLabels.push(element + ' (' + monthElement['registationCount'] + ')');
            this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
            console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
            // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
          } else {
            this.barChartData[0]['data'].push(0);
            this.barChartLabels.push(element + ' (0)');
            this.barChartData[0]['backgroundColor'].push(this.colorPalette[random]);
            console.log("Random Number Generated : " + random + 'color is:' + this.colorPalette[random]);
            // this.barChartData[0]['backgroundColor'].push('#' + (Math.random() * 0xFFFFFF << 0).toString(16));
          }
        });
        console.log(this.barChartData);
        console.log(this.barChartLabels);
      }
      if (this.barChartLabels.length == 0) {
        this.noGraph = true;
      }
    } else {
      this.noGraph = true;
    }
  }

  printgraph() {
    // get size of report page
    var reportPageHeight = $('#bar-chart').innerHeight();
    var reportPageWidth = $('#bar-chart').innerWidth();

    // create a new canvas object that we will populate with all other canvas objects
    var pdfCanvas = $('<canvas />').attr({
      id: "canvaspdf",
      width: reportPageWidth,
      height: reportPageHeight
    });

    // keep track canvas position
    var pdfctx = $(pdfCanvas)[0].getContext('2d');
    var pdfctxX = 0;
    var pdfctxY = 0;
    var buffer = 100;
    pdfctx.fillStyle = "white";
    pdfctx.fillRect(0, 0, reportPageWidth, reportPageHeight);
    pdfctx.restore();
    // for each chart.js chart
    $("canvas").each(function (index) {
      // get the chart height/width
      var canvasHeight = $(this).innerHeight();
      var canvasWidth = $(this).innerWidth();

      // draw the chart into the new canvas
      pdfctx.drawImage($(this)[0], pdfctxX, pdfctxY, canvasWidth, canvasHeight);
      pdfctxX += canvasWidth + buffer;

      // our report page is in a grid pattern so replicate that in the new canvas
      if (index % 2 === 1) {
        pdfctxX = 0;
        pdfctxY += canvasHeight + buffer;
      }
    });

    // create new pdf and add our new canvas as an image
    var pdf = new jsPDF('l', 'pt', [reportPageWidth, reportPageHeight]);
    pdf.addImage($(pdfCanvas)[0], 'PNG', 50, 50, 0, 0);
    // download the pdf
    pdf.save('Customerregistration.pdf');
  }

  toogleView = false;

  toogleViewFn() {
    console.log(this.toogleView);
    if (!this.toogleView) {
      this.toogleView = true;
    } else {
      this.toogleView = false;
    }
    this.clickOnFilter();
  }

  onChangeCountry(){
    if (this.userDetails.usertype == 'CountryHead')
     {      
      if(this.selectedCountry=="")
      {
        this.IsfilterDisabled=true;

      }else{
        this.IsfilterDisabled=false;
      }
    }
    
  }
}
