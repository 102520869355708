import { Component, OnInit,Output,EventEmitter,ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { TransfereService } from '../../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators, MinLengthValidator } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $:any;

@Component({
  selector: 'app-addcountryhead',
  templateUrl: './addcountryhead.component.html',
  styleUrls: ['./addcountryhead.component.css']
})
export class AddcountryheadComponent implements OnInit {
  addCountryheadForm:any;
  countryList = [];
  error = {'description':[]};
  maxLength = 10;
  minLength = 10;
  public loadContent: boolean = false;
  
  public countryData = [];
  public multiCountryDropDownsettings = {};
  public selectedItems = [];
  @ViewChild('multiSelect')  multiSelect;

  constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService,private router : Router,private spinner: NgxSpinnerService,private location: Location) {
    this.addCountryheadForm = this.fb.group({
      // country: new FormControl('', [Validators.required]),
     // language: new FormControl('', [Validators.required]),
      countryheadname: new FormControl('', [Validators.required,this.alphabetsValidator]),
      email: new FormControl('',[Validators.required,this.emailValidator]),
      Contact:new FormControl('',[Validators.required]),
      // status: new FormControl(true,[Validators.required]),
      multicountry: new FormControl(this.countryData,Validators.required)
    });
  }
  ngOnInit() {
    
    this.addCountryheadForm = this.fb.group({
     // country: new FormControl('', [Validators.required]),
      //language: new FormControl('', [Validators.required]),
      countryheadname: new FormControl('', [Validators.required,this.alphabetsValidator]),
      email: new FormControl('',[Validators.required,this.emailValidator]),
      Contact:new FormControl('',[Validators.required]),
      // status: new FormControl(true,[Validators.required]),
      multicountry: new FormControl(this.countryData,Validators.required)
    });
    this.addCountryheadForm.get('Contact').disable();
    this.getCountryList({});
   

    this.multiCountryDropDownsettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'countryName',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
  }

  
  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  // public numberValidator(control: FormControl) {
  //   if (control.value && control.value.length >= 10) {
  //     //valid
  //   //console.log('valid');

  //     return null;
  //   } else if(control.value != null && control.value.length < 10) {
  //     //invalid
  //     //console.log('invalid');

  //     return {'inValid': true};
  //   }else {
  //     return null;
  //   }
  // }

  public emailValidator(control: FormControl) {
    ////console.log(control.value);
    if (control.value && control.value.length > 0 &&  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value)) {
      //valid
      return null;
    } else if(control.value != null && control.value.length > 0 && !( /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value))) {
      //invalid
      return {'isEmail': true};

    }else {
      return null;
    }
  }

  getCountryList(data) {
    this.spinner.show();
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      this.countryData=response.content.payLoad;
      this.spinner.hide();
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
      this.spinner.hide();
    });
  }

  onselectCountry(countryName) {
    //console.log(countryName);
   
    if(countryName) {
      this.addCountryheadForm.get('Contact').enable();
      this.countryList.forEach(element => {
        if(element.countryName == countryName) {
          //console.log(element);
          this.minLength = element.mobileNumberLength['min'];
          this.maxLength = element.mobileNumberLength['max'];
          this.addCountryheadForm.patchValue({
            language : element.languages[0].languageName
          })
        }
      });
    } else {
      this.addCountryheadForm.get('Contact').disable();
      this.addCountryheadForm.get('Contact').reset('');
    }
  }
  
  addCountryhead() {
    //console.log(this.addCountryheadForm);
    //console.log(this.addCountryheadForm.value);
    this.error.description = [];
    let data = {
      "name": this.addCountryheadForm.value.countryheadname,
      "mobileNo": this.addCountryheadForm.value.Contact,
      //"country":this.addCountryheadForm.value.country,
      //"language":this.addCountryheadForm.value.language,
      "email": this.addCountryheadForm.value.email,
      "CountryListDetails":this.addCountryheadForm.value.multicountry
    }
    this.spinner.show();
    //console.log(data);
    this.apiService.addCountryhd(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      // this.router.navigate(['/countryheads']);
      this.router.navigate(['/countryheads'],{queryParams:{id:1}});
      // this.transfereservice..emit();
      this.addCountryheadForm.reset();
    },error => {
      this.spinner.hide();
      //console.log(error);
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    // this.transfereservice.emitNavChangeEvent('Country Head Added Successfully.');
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }

  public onFilterChange(item: any) 
  {
    console.log(item);
  }
  public onDropDownClose(item: any) 
  {    
    if(item==="undefined")
    {
      this.addCountryheadForm.get('Contact').disable();
      this.addCountryheadForm.get('Contact').reset('');
    }
  }

  public onItemSelect(item: any) 
  {    
    this.addCountryheadForm.get('Contact').enable();
  }
  public onDeSelect(item: any) 
  {  
    if(this.addCountryheadForm.value.multicountry.length <=0)
    {
      this.addCountryheadForm.get('Contact').disable();
      this.addCountryheadForm.get('Contact').reset('');
    }
  }

  public onSelectAll(items: any) 
  {   
    this.addCountryheadForm.get('Contact').enable();
  }
  public onDeSelectAll(items: any) 
  {    
    this.addCountryheadForm.get('Contact').disable();    
  }

}
