import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { TransfereService } from 'src/app/transfere.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { } from 'googlemaps';
import { IMyDpOptions } from 'mydatepicker';
declare var $: any;
@Component({
  selector: 'app-add-customers',
  templateUrl: './add-customers.component.html',
  styleUrls: ['./add-customers.component.css']
})
export class AddCustomersComponent implements OnInit {
  customerRegisterForm: any;
  error = { 'description': [] };
  userDetails = { 'usertype': '', 'sub': '' };
  autocompleteItems = [];
  addressErr = false;
  city = '';
  state = '';
  country = '';
  postal_code = '';
  currentLng;
  currentLat;
  isCityhidden: boolean = true;
  isStatehidden: boolean = true;
  maxLength = 10;
  minLength = 10;
  maxLengthNumber: any = 10;
  countryList = [];
  countryCode = '';
  language = '';
  tractors = [];
  serialNoErr = false;
  tractorModelList = [];
  isInvalid = false;
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService, private location: Location) {
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
    this.customerRegisterForm = this.fb.group({
      Name: new FormControl('', [Validators.required, this.namealphabetsValidator]),
      Address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required, this.alphabetsValidator]),
      state: new FormControl('', [Validators.required, this.alphabetsValidator]),
      country: new FormControl('', Validators.required),
      mobileNo: new FormControl('', Validators.required)
    });
    this.customerRegisterForm.get('mobileNo').disable();
  }

  public namealphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if (control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    // var regex = /[^a-zA-Z ]/;
    if (control.value && control.value.trim().length >= 2 && control.value.trim().length <= 50) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  ngOnInit() {
    this.getCountryList();
    this.disableSince();
  }

  disableSince() {
    let d: Date = new Date();
    d.setDate(d.getDate() + 1);
    let copy: IMyDpOptions = this.getCopyOfOptions();
    copy.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.startDateOptions = copy;
  }
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.startDateOptions));
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      this.spinner.hide();
      if (this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
        this.countryList.forEach(element => {
          if (element.countryName == this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
            if (this.userDetails.usertype != 'Admin') {
              this.countryCode = element.countryCode;
            }
            this.customerRegisterForm.get('mobileNo').enable();
            this.minLength = element.mobileNumberLength['min'];
            this.maxLength = element.mobileNumberLength['max'];
          }
        });
      }
      //console.log(this.countryList, "this.getCountryList");
    }, error => {
      this.spinner.hide();
    });
  }

  getAddress() {
    this.error = { 'description': [] };
    this.tractorModelList = [];
    this.cancelTractors();
    this.autocompleteItems = [];
    this.country = '';
    this.addressErr = false;
    this.customerRegisterForm.get('city').reset('');
    this.customerRegisterForm.get('state').reset('');
    var regex = /[^a-zA-Z0-9(),.-/\ ]/;
    this.spinner.show()
    if (this.customerRegisterForm.get('Address').value.length >= 10 && !regex.test(this.customerRegisterForm.get('Address').value)) {
      this.apiService.getPlacePredictions(this.customerRegisterForm.get('Address').value, this.countryCode).then(result => {
        this.spinner.hide();
        //console.log(result);
        //console.log(typeof result);
        this.autocompleteItems = <Array<any>>result;
        //console.log(this.autocompleteItems);
        $('.dropdown').css('display', 'inline');
      }, error => {
        console.error(error);
        this.spinner.hide();
      });
    } else {
      this.addressErr = true;
      this.spinner.hide();
    }
  }

  chooseItem(item: any) {
    //console.log(item);
    // $('.dropdown').css('display','none');
    this.tractorModelList = [];
    this.cancelTractors();
    this.error = { 'description': [] };
    this.customerRegisterForm.patchValue({
      Address: item.description
    })
    this.autocompleteItems = [];
    var geocoder = new google.maps.Geocoder();
    var self = this;


    this.apiService.getgeoAddress(item.place_id).then(result => {
      this.spinner.hide();
      //console.log(result);
      var results = <Array<any>>result;
      results[0].address_components.forEach(element => {
        console.log(element.types[0]);
        if (element.types[0] == 'administrative_area_level_2') {
          //console.log(element.long_name);
          self.city = element.long_name;
          self.customerRegisterForm.patchValue({
            city: element.long_name
          })
          //get City
        }
        if (element.types[0] == 'administrative_area_level_1') {
          //console.log(element.long_name);
          self.state = element.long_name;
          self.customerRegisterForm.patchValue({
            state: element.long_name
          })
          //get State
        }
        if (element.types[0] == 'country') {
          console.log(element.long_name);
          //get Country
          if (element.long_name == 'Myanmar (Burma)') {
            element.long_name = 'Myanmar';
          }
          const index = this.countryList.map(e => e.countryName).indexOf(element.long_name);
          if (index > -1) {
            self.country = element.long_name;
            self.customerRegisterForm.patchValue({
              country: element.long_name
            })
            this.getTractorModels(element.long_name);
            self.language = this.countryList[index].languages[0].languageName
            this.customerRegisterForm.get('mobileNo').enable();
            this.customerRegisterForm.get('mobileNo').reset('');
            this.minLength = this.countryList[index].mobileNumberLength['min'];
            this.maxLength = this.countryList[index].mobileNumberLength['max'];

          } else {
            this.error = { 'description': [] };
            this.error.description.push('Cannot create customer for selected country.');
            this.customerRegisterForm.get('mobileNo').disable();
            this.customerRegisterForm.get('mobileNo').reset('');
            this.minLength = 10;
            this.maxLength = 10;
            self.country = '';
          }
        }
        if (element.types[0] == 'postal_code') {
          //console.log(element.long_name);
          self.postal_code = element.long_name;
          //get Postal
        }
      });
      //console.log(self.customerRegisterForm.get('city').value);
      //console.log(self.customerRegisterForm.get('state').value);
      if (self.customerRegisterForm.get('city').value == undefined || self.customerRegisterForm.get('city').value == "") {
        self.isCityhidden = false;
      }
      if (self.customerRegisterForm.get('state').value == undefined || self.customerRegisterForm.get('state').value == "") {
        self.isStatehidden = false;
      }
      //console.log(self.customerRegisterForm);
      //console.log(self.customerRegisterForm['status']);
      this.customerRegisterForm['status'] = self.customerRegisterForm['status'];
      $('#updateName').trigger('click');
      var lat = results[0].geometry.location.lat();
      var lng = results[0].geometry.location.lng();
      //console.log(lat, lng);
      self.currentLat = lat;
      self.currentLng = lng;
    }, error => {
      console.error(error);
      this.spinner.hide();
    });
  }

  addCustomers() {
    //console.log(this.updateForm);
    this.isInvalid = false;
    if (this.tractors.length > 0) {
      this.tractors.forEach(element => {
        if (element.purchasedOn && element.purchasedOn.date) {
          element.purchasedOn = element.purchasedOn.date.year + '-' + element.purchasedOn.date.month + '-' + element.purchasedOn.date.day;
          return;
        } else {
          this.isInvalid = true;
        }
      });
    }
    let data = {
      "mobileNo": this.customerRegisterForm.value.mobileNo,
      "name": this.customerRegisterForm.value.Name,
      "country": this.customerRegisterForm.value.country,
      "location": {
        "type": "Point",
        "coordinates": [this.currentLng, this.currentLat]
      },
      "tractors": this.tractors,
      "addressLine": this.customerRegisterForm.value.Address,
      "city": this.customerRegisterForm.value.city,
      "state": this.customerRegisterForm.value.state,
      "language": this.language
    }
    console.log(data);
    console.log(this.tractors);
    this.apiService.registerCustomer(data).subscribe(response => {
      //console.log(response);
      //Added Successfully
      this.router.navigate(['/customers'], { queryParams: { id: 2 } });
    }, error => {
      //Error while updating
      //console.log(error.error);
      //console.log(error.error.errors[0]);
      //console.log(error.error.errors[0].description);
      this.error = { 'description': [] };
      this.error.description.push(error.error.errors[0].description);
      //console.log(this.error);
    })
  }

  cancel() {
    this.router.navigate(['/customers']);
  }

  trashTractor(i) {
    //console.log(i, "iiii...")
    if (this.tractors.length > 0) {
      this.tractors.splice(i, 1);
    }
  }

  addTractor() {
    this.tractors.push({
      tractorName: "",
      serialNo: "",
      purchasedOn: "",
      active: true,
      index: 0
    });
    $("#collapseadd").addClass('show');
  }

  checkSerialNo(selectedNo) {
    var regex = /[^a-zA-Z0-9- ]/;
    this.serialNoErr = false;
    //console.log(regex.test(selectedNo.serialNo));
    //console.log(!regex.test(selectedNo.serialNo));

    if (selectedNo.serialNo.trim().length >= 5 && selectedNo.serialNo.trim().length < 18 && !regex.test(selectedNo.serialNo.trim())) {
      this.tractors.forEach(element => {
        if (element.serialNo == selectedNo.serialNo) {
          element.serialNoErr = false;
        }
      });
    } else {
      this.tractors.forEach(element => {
        if (element.serialNo == selectedNo.serialNo) {
          element.serialNoErr = true;
          this.serialNoErr = true;
        }
      });
    }
  }

  getTractorModels(countryName) {
    const query = {
      "itemsPerPage": -1,
      "filters": [{
        "member": "country", "value": countryName, "operation": "EqualTo"
      }, {
        "member": "isactive", "value": true
      }]
    }
    this.apiService.getTractorModels(query).subscribe(response => {
      //console.log(response);
      this.tractorModelList = []
      this.tractorModelList = response['content'].payLoad;
      this.spinner.hide();
      //console.log(this.tractorModelList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  modelChanged(data, item) {
    console.log(data);
    console.log(item);
    var id;
    this.tractorModelList.forEach(element => {
      if (element.tractorName == data) {
        id = element.id;
        return;
      }
    });

    this.tractors.forEach((element, index) => {
      if (element.tractorName == data) {
        element.id = id;
        element.index = index;
        element.active = true;
      }
    });
    console.log(this.tractors);

  }

  changeStatus(selectedData) {
    console.log(this.tractors);

    this.tractors.forEach((element, index) => {
      if (index == selectedData.index) {
        element.active = !element.active;
      }
    });
  }

  validateTractorsData() {
    console.log(this.tractors);
    this.isInvalid = false;
    if (this.tractors.length > 0) {
      this.tractors.forEach(element => {
        if (!element.serialNo || !element.tractorName || !element.purchasedOn) {
          //console.log('1')
          this.isInvalid = true;
          return;
        } else if (element.serialNoErr) {
          this.serialNoErr = true;
          return;
        }
      });
      console.log(this.tractors);
      if (!this.isInvalid) {
        this.addCustomers();
      } else {
        return;
      }
    } else {
      this.addCustomers();
    }

  }

  closeAlert() {
    this.error.description = [];
  }

  cancelTractors() {
    this.tractors = [];
    $("#collapseadd").removeClass('show');
    $("#collapseview").addClass('collapse show');
    this.isInvalid = false;
    this.serialNoErr = false;
  }
}
