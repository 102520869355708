import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransfereService } from '../../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { } from 'googlemaps';
import { IMyDpOptions } from 'mydatepicker';
declare var $: any;

@Component({
  selector: 'app-update-customer',
  templateUrl: './update-customer.component.html',
  styleUrls: ['./update-customer.component.css']
})
export class UpdateCustomerComponent implements OnInit {
  updateForm: any;
  customerList = [];
  customerData;
  customerId;
  service;
  error = { 'description': [] };
  city = '';
  state = '';
  country = '';
  postal_code = '';
  currentLng;
  currentLat;
  autocompleteItems = [];
  isCityhidden: boolean = true;
  isStatehidden: boolean = true;
  addressErr = false;
  userDetails = { 'usertype': '', 'sub': '' };
  maxLength = 10;
  minLength = 10;
  predictions = [];
  tractorList = [];
  tractors = [];
  serialNoErr = false;
  tractorModelList = [];
  countryCode = '';
  countryList = [];
  isInvalid = false;
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService, private location: Location) {


    this.updateForm = this.fb.group({
      id: new FormControl('', Validators.required),
      Name: new FormControl('', [Validators.required, this.namealphabetsValidator]),
      Address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required, this.alphabetsValidator]),
      state: new FormControl('', [Validators.required, this.alphabetsValidator]),
      country: new FormControl('', Validators.required),
      Contact: new FormControl('', Validators.required)
    });
  }



  ngOnInit() {
    this.disableSince();
    this.tractorList = [];
    this.service = new google.maps.places.AutocompleteService();
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.customerId = params['id'];
      //console.log(this.countryheadId);
    })

    this.updateForm = this.fb.group({
      id: new FormControl('', Validators.required),
      Name: new FormControl('', [Validators.required, this.namealphabetsValidator]),
      Address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required, this.alphabetsValidator]),
      state: new FormControl('', [Validators.required, this.alphabetsValidator]),
      country: new FormControl('', Validators.required),
      Contact: new FormControl('', Validators.required)
    });

    var query = {
      "filters": [
        {
          "member": "id",
          "value": this.customerId,
          "operation": "EqualTo"
        }]
    }

    this.getCustomers(query);
  }

  disableSince() {
    let d: Date = new Date();
    d.setDate(d.getDate() + 1);
    let copy: IMyDpOptions = this.getCopyOfOptions();
    copy.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.startDateOptions = copy;
  }
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.startDateOptions));
  }

  public namealphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if (control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    // var regex = /[^a-zA-Z ]/;
    if (control.value && control.value.trim().length >= 2 && control.value.trim().length <= 50) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  getCustomers(query) {
    //console.log(this.countryheadList);
    this.spinner.show();
    this.apiService.getCustomers(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      if (response['content'].payLoad.length > 0) {
        this.customerList = response['content'].payLoad;
        this.customerList.forEach(element => {
          // console.log(element.id);
          // console.log(this.customerId);
          if (element.id == this.customerId) {
            this.customerData = element;
            this.patchValue();
          }
          //console.log(this.countryheadData);

        });
      }
      //console.log(this.customerList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  patchValue() {
    console.log(this.customerData);
    if (this.customerData['country'] == 'Myanmar (Burma)') {
      this.customerData['country'] = 'Myanmar';
    }
    this.updateForm.patchValue({
      id: this.customerData['id'],
      Name: this.customerData['name'],
      Address: this.customerData['address'].addressLine,
      city: this.customerData['address'].city,
      state: this.customerData['address'].state,
      country: this.customerData['country'],
      Contact: this.customerData['mobileNo']
    })

    if (!this.customerData['address'].city || this.customerData['address'].city == "") {
      this.isCityhidden = false;
    }
    if (!this.customerData['address'].state || this.customerData['address'].state == "") {
      this.isStatehidden = false;
    }
    this.country = this.customerData['country'];
    this.tractorList = [];
    this.tractorList = this.customerData['tractors'] && this.customerData['tractors'].length > 0 ? this.customerData['tractors'] : [];
    this.getTractorModels(this.customerData['country']);
    this.currentLat = this.customerData['address'].location.coordinates[1];
    this.currentLng = this.customerData['address'].location.coordinates[0];
    this.getCountryByName(this.customerData['country']);
  }

  getCountryByName(countryName) {
    this.spinner.show();
    let data = {
      "filters": [{
        "member": "country", "value": countryName, "operation": "EqualTo"
      }]
    }
    this.apiService.getCountryList(data).subscribe((response: any) => {
      this.spinner.hide();
      console.log(response, "this.getCountryList");
      this.countryList = response;
      this.countryCode = response['content'].payLoad[0].countryCode;
      this.minLength = response['content'].payLoad[0].mobileNumberLength['min'];
      this.maxLength = response['content'].payLoad[0].mobileNumberLength['max'];
      //console.log(this.countryList, "this.getCountryList");
    }, error => {
      this.spinner.hide();
    });
  }

  editSettings() {
    this.isInvalid = false;
    if (this.tractors.length > 0) {
      this.tractors.forEach(element => {
        if (element.purchasedOn && element.purchasedOn.date) {
          element.purchasedOn = element.purchasedOn.date.year + '-' + element.purchasedOn.date.month + '-' + element.purchasedOn.date.day;
          return;
        } else {
          this.isInvalid = true;
        }
      });
    }
    let data = {
      "userId": this.updateForm.get('id').value,
      "name": this.updateForm.get('Name').value,
      "mobileNo": this.updateForm.get('Contact').value,
      "addressLine": this.updateForm.get('Address').value,
      "location": {
        "type": "Point",
        "coordinates": [this.currentLng, this.currentLat]
      },
      "city": this.updateForm.get('city').value,
      "state": this.updateForm.get('state').value,
      "country": this.updateForm.get('country').value
    }

    if (this.tractorList.length > 0) {
      data["tractors"] = this.tractorList.concat(this.tractors);
    } else {
      data["tractors"] = this.tractors;
    }
    console.log(data);
    console.log(this.tractorList);
    this.apiService.changeSettings(data).subscribe(response => {
      //console.log(response);
      //Updated Successfully
      this.router.navigate(['/customers'], { queryParams: { id: 1 } });
    }, error => {
      //Error while updating
      //console.log(error.error);
      //console.log(error.error.errors[0]);
      //console.log(error.error.errors[0].description);
      this.error = { 'description': [] };
      this.error.description.push(error.error.errors[0].description);
      //console.log(this.error);
    })
  }


  getAddress() {
    this.tractorModelList = [];
    this.tractors = [];
    this.country = '';
    this.autocompleteItems = [];
    let me = this;
    this.addressErr = false;
    this.updateForm.get('city').reset('');
    this.updateForm.get('state').reset('');
    var regex = /[^a-zA-Z0-9(),.-/\ ]/;
    this.spinner.show()
    if (this.updateForm.get('Address').value.length >= 10 && !regex.test(this.updateForm.get('Address').value)) {
      this.apiService.getPlacePredictions(this.updateForm.get('Address').value, this.countryCode).then(result => {
        this.spinner.hide();
        //console.log(result);
        //console.log(typeof result);
        this.autocompleteItems = <Array<any>>result;
        //console.log(this.autocompleteItems);
        $('.dropdown').css('display', 'inline');
      }, error => {
        console.error(error);
        this.spinner.hide();
      });
    } else {
      this.addressErr = true;
      this.spinner.hide();
    }
  }

  chooseItem(item: any) {
    //console.log(item);
    // $('.dropdown').css('display','none');
    this.tractorModelList = [];
    this.tractors = [];
    this.updateForm.patchValue({
      Address: item.description
    })
    this.autocompleteItems = [];
    var geocoder = new google.maps.Geocoder();
    var self = this;


    this.apiService.getgeoAddress(item.place_id).then(result => {
      this.spinner.hide();
      //console.log(result);
      var results = <Array<any>>result;
      results[0].address_components.forEach(element => {
        //console.log(element.types[0]);
        if (element.types[0] == 'administrative_area_level_2') {
          //console.log(element.long_name);
          self.city = element.long_name;
          self.updateForm.patchValue({
            city: element.long_name
          })
          //get City
        }
        if (element.types[0] == 'administrative_area_level_1') {
          //console.log(element.long_name);
          self.state = element.long_name;
          self.updateForm.patchValue({
            state: element.long_name
          })
          //get State
        }
        if (element.types[0] == 'country') {
          //console.log(element.long_name);
          //get Country
          if (element.long_name == 'Myanmar (Burma)') {
            element.long_name = 'Myanmar';
          }
          self.country = element.long_name;
          self.updateForm.patchValue({
            country: element.long_name
          })
        }
        if (element.types[0] == 'postal_code') {
          //console.log(element.long_name);
          self.postal_code = element.long_name;
          //get Postal
        }
      });
      //console.log(self.updateForm.get('city').value);
      //console.log(self.updateForm.get('state').value);
      if (self.updateForm.get('city').value == undefined || self.updateForm.get('city').value == "") {
        self.isCityhidden = false;
      }
      if (self.updateForm.get('state').value == undefined || self.updateForm.get('state').value == "") {
        self.isStatehidden = false;
      }
      //console.log(self.updateForm);
      //console.log(self.updateForm['status']);
      this.updateForm['status'] = self.updateForm['status'];
      $('#updateName').trigger('click');
      var lat = results[0].geometry.location.lat();
      var lng = results[0].geometry.location.lng();
      //console.log(lat, lng);
      self.currentLat = lat;
      self.currentLng = lng;
    }, error => {
      console.error(error);
      this.spinner.hide();
    });
  }

  cancel() {
    this.router.navigate(['/customers']);
  }

  trashTractor(i) {
    //console.log(i, "iiii...")
    if (this.tractors.length > 0) {
      this.tractors.splice(i, 1);
    }
  }

  addTractor() {
    this.tractors.push({
      tractorName: "",
      serialNo: "",
      purchasedOn: "",
      active: true,
      index: 0
    });
    $("#collapseadd").addClass('show');
  }

  checkSerialNo(selectedNo) {
    var regex = /[^a-zA-Z0-9- ]/;
    this.serialNoErr = false;
    //console.log(regex.test(selectedNo.serialNo));
    //console.log(!regex.test(selectedNo.serialNo));

    if (selectedNo.serialNo.trim().length >= 5 && selectedNo.serialNo.trim().length < 18 && !regex.test(selectedNo.serialNo.trim())) {
      this.tractors.forEach(element => {
        if (element.serialNo == selectedNo.serialNo) {
          element.serialNoErr = false;
        }
      });
    } else {
      this.tractors.forEach(element => {
        if (element.serialNo == selectedNo.serialNo) {
          element.serialNoErr = true;
          this.serialNoErr = true;
        }
      });
    }
  }

  getTractorModels(countryName) {
    const query = {
      "itemsPerPage": -1,
      "filters": [{
        "member": "country", "value": countryName, "operation": "EqualTo"
      }, {
        "member": "isactive", "value": true
      }]
    }
    this.apiService.getTractorModels(query).subscribe(response => {
      //console.log(response);
      this.tractorModelList = []
      this.tractorModelList = response['content'].payLoad;
      this.spinner.hide();
      //console.log(this.tractorModelList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  modelChanged(data, item) {
    console.log(data);
    console.log(item);
    var id;
    this.tractorModelList.forEach(element => {
      if (element.tractorName == data) {
        id = element.id;
        return;
      }
    });

    this.tractors.forEach((element, index) => {
      if (element.tractorName == data) {
        element.id = id;
        element.index = index;
        element.active = true;
      }
    });
    console.log(this.tractors);

  }


  validateTractorsData() {
    console.log(this.tractors);
    this.isInvalid = false;
    if (this.tractors.length > 0) {
      this.tractors.forEach(element => {
        if (!element.serialNo || !element.tractorName || !element.purchasedOn) {
          //console.log('1')
          this.isInvalid = true;
          return;
        } else if (element.serialNoErr) {
          this.serialNoErr = true;
          return;
        }
      });
      console.log(this.tractors);
      if (!this.isInvalid) {
        this.editSettings();
      } else {
        return;
      }
    } else {
      this.editSettings();
    }

  }

  cancelTractors() {
    this.tractors = [];
    $("#collapseadd").removeClass('show');
    $("#collapseview").addClass('collapse show');
    this.isInvalid = false;
    this.serialNoErr = false;
  }

  closeAlert() {
    this.error.description = [];
  }

  changeExistingStatus(selectedDataIndex) {
    this.tractorList.forEach((element, index) => {
      if (index == selectedDataIndex) {
        element.active = !element.active;
        console.log(element);
      }
    });

    console.log(this.tractorList)
  }

  changeStatus(selectedData) {
    console.log(this.tractors);
    this.tractors.forEach((element, index) => {
      if (index == selectedData.index) {
        element.active = !element.active;
      }
    });
  }
}
