import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { TransfereService } from '../../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-distributor',
  templateUrl: './add-distributor.component.html',
  styleUrls: ['./add-distributor.component.css']
})
export class AddDistributorComponent implements OnInit {
  countryheadList = [];
  distributorList = [];
  addDistributorForm : any;
  error = {'description':[]}
  userDetails = {'usertype':'','sub':'','countryDetails':''};
  countryList = [];
  maxLength = 10;
  minLength = 10;
  constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService,private router : Router,private spinner: NgxSpinnerService,private location: Location) {
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
    this.addDistributorForm = this.fb.group({
      country: new FormControl('', [Validators.required]),
      countryHeadId: new FormControl('', [Validators.required]),
      language: new FormControl('', Validators.required),
      countryHeadName: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      code: new FormControl('', [Validators.required]),
      email:new FormControl('', [Validators.required,this.emailValidator]),
      Contact:new FormControl('', [Validators.required]),
      // status:[true, Validators.required]
    });
  }

  ngOnInit() {
    this.addDistributorForm = this.fb.group({
      country: new FormControl('', [Validators.required]),
      countryHeadId: new FormControl('', [Validators.required]),
      language: new FormControl('', Validators.required),
      countryHeadName: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      code: new FormControl('', [Validators.required]),
      email:new FormControl('', [Validators.required,this.emailValidator]),
      Contact:new FormControl('', [Validators.required]),
      // status:[true, Validators.required]
    });
    
    this.addDistributorForm.get('Contact').disable();
    if(this.userDetails.usertype == 'Admin')
     {
      //this.getCountryList({});
      this.getCountryheadList();
    } 
    else 
    {
      //when userType is Country head.
      //patch country head name  & country by logged in user details.
      this.addDistributorForm.patchValue({
        countryHeadId : this.userDetails['userid'],
        //language : this.userDetails['language'],
        //country: this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
        countryHeadName:this.userDetails['given_name'],
      })

      this.countryList=JSON.parse(this.userDetails.countryDetails);

      this.addDistributorForm.get('Contact').disable();
        this.addDistributorForm.get('Contact').reset('');
      // if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) 
      // {
      //   this.countryList.forEach(countryElement =>
      //      {
      //     if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
      //       this.minLength = countryElement.mobileNumberLength['min'];
      //       this.maxLength = countryElement.mobileNumberLength['max'];
      //     }
      //   });
      //   this.addDistributorForm.get('Contact').enable();
      // }
      //  else 
      //  {
      //   this.addDistributorForm.get('Contact').disable();
      //   this.addDistributorForm.get('Contact').reset('');
      // }
      
    }
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public alphanumericValidator(control: FormControl) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = false;
    // var regex = /^[a-z0-9]+$/i; 
    var regex = /[^a-zA-Z0-9]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphanumeric': true }
    } else {
      return isValid ? null : { 'alphanumeric': false }
    }
  }
  
  getCountryList(data) {
    this.spinner.show();
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      this.spinner.hide();
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
      this.spinner.hide();
    });
  }
  
  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
    //console.log('valid');
      return null;
    } else if(control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');
      return {'inValid': true};
    }else {
      return null;
    }
  }

  public emailValidator(control: FormControl) {
    ////console.log(control.value);
    if (control.value && control.value.length > 0 &&  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value)) {
      //valid
      return null;
    } else if(control.value != null && control.value.length > 0 && !( /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value))) {
      //invalid
      return {'isEmail': true};

    }else {
      return null;
    }
  }

  getCountryheadList() {
    //console.log(this.countryheadList);
    this.apiService.getCountryhdList({}).subscribe(response => {
      //console.log(response);
      this.countryheadList = response['content'];
      //console.log(this.countryheadList);
    }, error => {
      //console.log(error);
    })
  }

  onselectCountryhead(countryHeadId) {
    //console.log(countryHeadId);
    //console.log(this.addDistributorForm.get('countryHeadId').value);
    if(countryHeadId) {
      this.countryheadList.forEach(element => {
        if(element.id == countryHeadId)
         {
           this.countryList=element.countryDetails;
          // this.countryList.forEach(countryElement => 
          //   {
          //   //console.log(countryElement);
          //   if(countryElement.countryName == element.address.country)
          //    {
          //     this.addDistributorForm.get('Contact').enable();
          //     this.minLength = countryElement.mobileNumberLength['min'];
          //     this.maxLength = countryElement.mobileNumberLength['max'];
          //   }
          // });
          this.addDistributorForm.patchValue({
            //country: element.address.country,
            //language:element.language,
            countryHeadName : element.name
          })
        }
      });
    } else {
      this.countryList=[]
      this.addDistributorForm.get('Contact').disable();
      this.addDistributorForm.get('Contact').reset('');
      this.addDistributorForm.get('language').reset('');
    }
  }

  onselectCountryChange(countryName)
  {
    if(countryName)
    {    
    this.countryList.forEach(countryElement => 
      {      
      if(countryElement.countryName == countryName)
       {       
        this.addDistributorForm.get('Contact').enable();
        this.minLength = countryElement.mobileNumberLength['min'];
        this.maxLength = countryElement.mobileNumberLength['max'];
        this.addDistributorForm.patchValue({          
          language:countryElement.languages[0].languageName         
        })        
      }
    }); 
  }
  else{
    this.addDistributorForm.get('Contact').disable();
    this.addDistributorForm.get('language').reset('');
  }
  }

  addDistributor() {
    //console.log(this.addDistributorForm);
    //console.log(this.addDistributorForm.value);
    this.error.description = [];
    let data = {
      "countryHeadId": this.addDistributorForm.value.countryHeadId,
      "name": this.addDistributorForm.value.name,
      "mobileNo": this.addDistributorForm.value.Contact,
      "distributorCode": this.addDistributorForm.value.code,
      "country":this.addDistributorForm.value.country,
      "email": this.addDistributorForm.value.email,
      "language":this.addDistributorForm.value.language,
      // "active": this.addDistributorForm.value.status
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.addDistributor(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/distributors'],{queryParams:{id:1}});
      this.addDistributorForm.reset();
    },error => {
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }
  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }
  closeAlert() {
    this.error.description = [];
  }
  
}
