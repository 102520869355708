import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { FormControl, Validators, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { Jsonp } from '@angular/http';
declare var $: any;
@Component({
  selector: 'app-offernotification',
  templateUrl: './offernotification.component.html',
  styleUrls: ['./offernotification.component.css']
})
export class OffernotificationComponent implements OnInit {
  tractorModelList = [];
  errors = {description:[]};
  userDetails = { 'usertype': '', 'sub': '' };
  query = {};
  selectedCountry = "";
  selectedRecord = "";
  selectedStatus = '';
  customers = [];
  flaList = [];
  sendNotificationForm: FormGroup;
  continuationToken = [];
  selectedDistributor = '';
  dropdownList = [];
  selectedTractor = [];
  selectedCustomers = [];
  selectedFla = [];
  tractorModelSettings = {};
  customersSettings = {};
  FLASettings = {};
  fdata = {
    notificationType: '',
  }
  selectedFiles: any = [];
  selectedVideoFiles: any = [];
  selectedPDFFiles: any = [];
  uploadErr = false;
  uploadErrVideo = false;
  uploadErrPdf = false;
  fla: any = [];
  FLAdetails: any = [];
  custdetails: any = [];
  tractordetails: any = [];
  errorMsg: string;
  error = { 'description': [] };
  success = { 'description': [] };
  constructor(public apiService: ApiService, public router: Router, private spinner: NgxSpinnerService, private route: ActivatedRoute, private location: Location,
    private formBuilder: FormBuilder) {
    this.sendNotificationForm = this.formBuilder.group({
      // id: new FormControl(''),
      fla: new FormControl([], [Validators.required]),
      tractormodel: new FormControl([], [Validators.required]),
      customer: new FormControl([], [Validators.required]),
      notificationType: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      AttachmentImages: new FormControl(),
      AttachmentVideos: new FormControl(),
      AttachmentPdf: new FormControl()
    });
  }

  ngOnInit() {
    this.uploadErr = false;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
      if (this.userDetails.usertype == 'Admin') {
        // this.getCountryList();
      } else {
        this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
        this.getTractorList();
        //this.getCustomers();
        this.selectedDistributor = this.userDetails['userid'];
        this.getFlaByDistributor();
      }
    }
    this.tractorModelSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'tractorName',
      //selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.customersSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.FLASettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  fileUpload(event: any) {
    //let files = event.target.files;
    console.log(event.target.files)

  }

  onTracorModelSelect(item: any) {
    this.query = {
      "FLA": this.sendNotificationForm.value.fla,
      "TractorsModel": this.sendNotificationForm.value.tractormodel
    }
    this.customers = [];

    // console.log(this.query);
    // return
    this.apiService.getCustomerByFLA(this.query).subscribe(response => {
      this.customers = response['content'].payLoad;
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  onFlaSelect(item: any) {
    this.spinner.show();
    this.query = {
      "FLA": this.sendNotificationForm.value.fla,
      "TractorsModel": this.sendNotificationForm.value.tractormodel
    }
    this.customers = [];

    // console.log(this.query);
    // return
    this.apiService.getCustomerByFLA(this.query).subscribe(response => {
      this.customers = response['content'].payLoad;
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      //console.log(error);
    })

  }

  onSelectAllModel(items: any) {
    //this.spinner.show();
    this.query = {
      "FLA": this.sendNotificationForm.value.fla,
      "TractorsModel": items
    }
    this.customers = [];

    //  console.log(items);
    //  return
    this.apiService.getCustomerByFLA(this.query).subscribe(response => {
      this.customers = response['content'].payLoad;
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }
  onSelectAllFLA(items: any) {

    this.query = {
      "FLA": items,
      "TractorsModel": this.sendNotificationForm.value.tractormodel
    }
    this.customers = [];

    //  console.log(items);
    //  return
    this.apiService.getCustomerByFLA(this.query).subscribe(response => {
      this.customers = response['content'].payLoad;
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  onDeSelectAll(items: any) {
    // alert();
    this.customers = [];
  }

  onFLADeSelectAll(items: any) {
    // alert();
    this.customers = [];
  }

  onModelDeSelect(item: any) {
    this.spinner.show();
    this.query = {
      "FLA": this.sendNotificationForm.value.fla,
      "TractorsModel": this.sendNotificationForm.value.tractormodel
    }
    this.customers = [];

    // console.log(this.query);
    // return
    this.apiService.getCustomerByFLA(this.query).subscribe(response => {
      this.customers = response['content'].payLoad;
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }


  onFLADeSelect(item: any) {
    this.spinner.show();
    this.query = {
      "FLA": this.sendNotificationForm.value.fla,
      "TractorsModel": this.sendNotificationForm.value.tractormodel
    }
    this.customers = [];

    // console.log(this.query);
    // return
    this.apiService.getCustomerByFLA(this.query).subscribe(response => {
      this.customers = response['content'].payLoad;
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  onOptionsSelected(event) {
    this.fdata.notificationType = event;
  }
  getTractorList() {
    this.tractorModelList = [];
    this.spinner.show();
    this.apiService.getTractorModels(this.query).subscribe(response => {
      //console.log(response);
      this.tractorModelList = []
      this.tractorModelList = response['content'].payLoad;
      this.spinner.hide();
      //console.log(this.tractorModelList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }


  getCustomers() {
    this.spinner.show();
    this.customers = [];
    this.apiService.getCustomers(this.query).subscribe(response => {
      //console.log(response);
      this.customers = response['content'].payLoad;
      this.spinner.hide();
      //console.log(this.customers);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }


  getFlaByDistributor() {
    this.spinner.show();
    this.query = {
      "distributorId": localStorage.userId,
      "options": {
        "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
        "itemsPerPage": this.selectedRecord,
        "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
        "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
      }
    }
    this.apiService.getFlaByDistributor(this.query).subscribe(response => {
      //console.log(response);
      this.flaList = response['content'].payLoad;
      //console.log(this.flaList);
      this.spinner.hide();
      //console.log(this.flaList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }


  handleImageInput($files) {
    this.errorMsg = "";
    this.selectedVideoFiles = [];
    let filecount = this.selectedFiles.length + $files.target.files.length;

    if (filecount > 3) {
      this.uploadErr = true;
      this.sendNotificationForm.get('AttachmentImages').reset('');
      return;
    }

    for (let i = 0; i < $files.target.files.length; i++) {
      let file = $files.target.files[i];
      if (file.type && file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var self = this;
        reader.onload = function (e) {
          //Initiate the JavaScript Image object.
          var image: any = new Image();
          //Set the Base64 string return from FileReader as source.
          image.src = e.target['result'];
          //self.selectedFiles.push(file);
          //Validate the File Height and Width.
          image.onload = function () {
            if (image.height >= 150 && image.width >= 150 && Math.round(file.size / 1024 / 1024) <= 5) {
              self.uploadErr = false;

              if (file.name.length > 35) {
                file.fileName = file.name.substring(0, 32).concat('...');
              } else {
                file.fileName = file.name;
              }
              self.selectedFiles.push(file);
              this.errorMsg = "";
            } else {
              self.uploadErr = true;
              self.sendNotificationForm.get('AttachmentImages').reset('');
              self.selectedFiles = [];
            }
          };
        };
      }
      else {
        this.uploadErr = true;
        this.sendNotificationForm.get('AttachmentImages').reset('');
        this.selectedFiles = [];
      }
    }
  }

  handleVideoFileInput($files) {
    this.sendNotificationForm.get('AttachmentImages').reset('');
    this.selectedVideoFiles = [];
    let file: File = null;
    file = $files.target.files[0];

    //file has to be image with max 2 mb size & dimension 150*150
    if (file.type && file.type == 'video/mp4' || file.type == 'video/3gpp' || file.type == 'video/mpeg') {

      if (Math.round(file.size / 1024 / 1024) <= 50) {
        this.uploadErrVideo = false;
        this.selectedVideoFiles.push(file);
        this.errorMsg = "";
      } else {
        this.uploadErrVideo = true;
        this.sendNotificationForm.get('AttachmentVideos').reset('');
        this.selectedVideoFiles = [];
      }
    } else {
      this.uploadErrVideo = true;
      this.sendNotificationForm.get('AttachmentVideos').reset('');
      this.selectedVideoFiles = [];
    }
  }

  handlePdfFileInput(event: any) {

    let fileData: File = null;
    fileData = event.target.files[0];
    this.sendNotificationForm.get('AttachmentPdf').reset('');
    this.selectedPDFFiles = [];

    //file has to be image with max 2 mb size & dimension 150*150
    if (fileData.type == "application/pdf") {
      const file = Math.round((fileData.size / 1024));
      if (file < 5000) {
        this.uploadErrPdf = false;
        this.selectedPDFFiles.push(fileData);
        this.errorMsg = "";
      } else {
        this.uploadErrPdf = true;
        this.sendNotificationForm.get('AttachmentPdf').reset('');
        this.selectedPDFFiles = [];
      }
    } else {
      this.uploadErrPdf = true;
      this.sendNotificationForm.get('AttachmentPdf').reset('');
      this.selectedPDFFiles = [];
    }
  }

  sendNotification() {
    // var data = {
    //   "FLA": this.sendNotificationForm.value.fla,
    //   "TractorModel": this.sendNotificationForm.value.tractormodel,
    //   "Customers": this.sendNotificationForm.value.customer,
    //   "Title": this.sendNotificationForm.value.title,
    //   "Description": this.sendNotificationForm.value.description,
    //   "TypesOfNotification": this.sendNotificationForm.value.notificationType
    // }
    this.errorMsg = "";
    if (this.sendNotificationForm.value.notificationType == "Image" && this.selectedFiles.length <= 0) {
      this.errorMsg = "Please select Image"
      return false
    }
    if (this.sendNotificationForm.value.notificationType == "PDF" && this.selectedPDFFiles.length <= 0) {
      this.errorMsg = "Please select PDF File"
      return false
    }
    if (this.sendNotificationForm.value.notificationType == "Video" && this.selectedVideoFiles.length <= 0) {
      this.errorMsg = "Please select Video"
      return false
    }

    let payload = new FormData();
    for (var i = 0; i < this.sendNotificationForm.value.fla.length; i++) {
      var fla = {
        "id": this.sendNotificationForm.value.fla[i].id,
        "name": this.sendNotificationForm.value.fla[i].name
      }
      this.FLAdetails = fla
      payload.append("FLA", JSON.stringify(this.FLAdetails));
    }
    for (var i = 0; i < this.sendNotificationForm.value.tractormodel.length; i++) {
      var tractorModelDetails = {
        "id": this.sendNotificationForm.value.tractormodel[i].id,
        "tractorName": this.sendNotificationForm.value.tractormodel[i].tractorName
      }
      this.tractordetails = tractorModelDetails
      payload.append("TractorModel", JSON.stringify(this.tractordetails));
    }
    for (var i = 0; i < this.sendNotificationForm.value.customer.length; i++) {
      var CustomerDetails = {
        "id": this.sendNotificationForm.value.customer[i].id,
        "name": this.sendNotificationForm.value.customer[i].name
      }
      this.custdetails = CustomerDetails
      payload.append("Customers", JSON.stringify(this.custdetails));
    }
    payload.append('Title', this.sendNotificationForm.value.title);
    payload.append('Description', this.sendNotificationForm.value.description);
    payload.append('TypesOfNotification', this.sendNotificationForm.value.notificationType);

    for (var i = 0; i < this.selectedFiles.length; i++) {
      payload.append("Files", this.selectedFiles[i], this.selectedFiles[i].name);
    }

    for (var i = 0; i < this.selectedVideoFiles.length; i++) {
      payload.append("Files", this.selectedVideoFiles[i], this.selectedVideoFiles[i].name);
    }

    for (var i = 0; i < this.selectedPDFFiles.length; i++) {
      payload.append("Files", this.selectedPDFFiles[i], this.selectedPDFFiles[i].name);
    }

    //return
    this.spinner.show();
    this.apiService.sendNotification(payload).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      // this.getHistoryByFla()
      this.success.description = [];

      this.success.description.push(' Notification sent Successfully.');
      //alert("Notification sent Successfully");
      this.spinner.hide();
      location.reload();
    }, error => {
      this.spinner.hide()
      this.errors.description = [];
      if(error.error.errors.length > 0) {
        this.errors.description.push(error.error.errors[0]['description']);
      } else {
        this.errors.description.push('Cannot Send Notification,Please Try Again Later.')
      }  
    });

    //alert(JSON.stringify(this.sendNotificationForm.value))  
  }

  closeAlert() {
    this.error.description = [];
  }

  closeAlerterorr(){
    this.error.description = [];
  }

}
