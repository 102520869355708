import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from '@angular/forms';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MessagingService } from './shared/messaging.service';
import { InterceptorService } from './interceptor.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { ServiceListComponent } from './service-list/service-list.component';
import { MechanicListComponent } from './mechanic-list/mechanic-list.component';
import { LoginComponent } from './login/login.component';
import { ServiceStationComponent } from './service-list/service-station/service-station.component';
import { AddmechanicComponent } from './mechanic-list/addmechanic/addmechanic.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';
import { ServiceHistoryComponent } from './service-history/service-history.component';
import { AllServicesComponent } from './all-services/all-services.component';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule,HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CreateRequestComponent } from './create-request/create-request.component';
import {ApiService} from './api.service';
import {TransfereService} from './transfere.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ExportAsModule } from 'ngx-export-as';
import { MyDatePickerModule } from 'mydatepicker';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function tokenGetter() {
  return localStorage['access_token'];
}
import { MasterComponent } from './master/master.component';
import { InputNumberDirective } from './input-number.directive';
import { CallcenterExecutivesComponent } from './executive-list/callcenter-executives/callcenter-executives.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { AdminmainComponent} from './adminmain/adminmain.component';
import { ExecutiveListComponent } from './executive-list/executive-list.component';
import { EditServiceStationComponent } from './service-list/edit-service-station/edit-service-station.component';
import { EditMechanicComponent } from './mechanic-list/edit-mechanic/edit-mechanic.component';
import { EditCallcenterExecutivesComponent } from './executive-list/edit-callcenter-executives/edit-callcenter-executives.component';
import { TractorListComponent } from './tractor-list/tractor-list.component';
import { AddTractorListComponent } from './tractor-list/add-tractor-list/add-tractor-list.component';
import { EditTractorListComponent } from './tractor-list/edit-tractor-list/edit-tractor-list.component';
import { AddcountryheadComponent } from './countryhead-list/addcountryhead/addcountryhead.component';
import { EditcountryheadComponent } from './countryhead-list/editcountryhead/editcountryhead.component';
import { CountryheadListComponent } from './countryhead-list/countryhead-list.component';
import { DistributorListComponent } from './distributor-list/distributor-list.component';
import { AddDistributorComponent } from './distributor-list/add-distributor/add-distributor.component';
import { EditDistributorComponent } from './distributor-list/edit-distributor/edit-distributor.component';
import { CustomerRegistrationComponent } from './reports/customer-registration/customer-registration.component';
import { DistributorWiseFLAComponent } from './reports/distributor-wise-fla/distributor-wise-fla.component';
import { DistributorWiseTechniciansComponent } from './reports/distributor-wise-technicians/distributor-wise-technicians.component';
import { FeedbackReportComponent } from './reports/feedback-report/feedback-report.component';
import { CustomerServiceRequestComponent } from './reports/customer-service-request/customer-service-request.component';
import { VideoVerbatimReportComponent } from './reports/video-verbatim-report/video-verbatim-report.component';
import { CountryWisePerformanceComponent } from './reports/country-wise-performance/country-wise-performance.component';
import { CustomerRequestComponent } from './customer-request/customer-request.component';
import { FlaListComponent } from './fla-list/fla-list.component';
import { AddflaComponent } from './fla-list/addfla/addfla.component';
import { EditflaComponent } from './fla-list/editfla/editfla.component';
import { FeedbackQBComponent } from './feedback-qb/feedback-qb.component';
import { UploadVideoVerbatimComponent } from './upload-video-verbatim/upload-video-verbatim.component';
import { AddFeedbackComponent } from './feedback-qb/add-feedback/add-feedback.component';
import {CustomerRegisterationComponent} from './customer-registeration/customer-registeration.component';
import { ServiceTypeComponent } from './service-type/service-type.component';
import { AddserviceTypeComponent } from './service-type/addservice-type/addservice-type.component';
import { EditserviceTypeComponent } from './service-type/editservice-type/editservice-type.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EditfeebackComponent } from './feedback-qb/editfeeback/editfeeback.component';
import { PaginationComponent } from './pagination/pagination.component';
import { AuthGuardGuard } from './auth-guard.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { UpdateCustomerComponent } from './customer-list/update-customer/update-customer.component';
import { AddCustomersComponent } from './customer-list/add-customers/add-customers.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotResetPasswordComponent } from './forgot-reset-password/forgot-reset-password.component';
import { OffernotificationComponent } from './offernotification/offernotification.component';
import { QueriesFeedbackComponent } from './queries-feedback/queries-feedback.component';
import { TractorsrlnoWiseSeviceHistoryComponent } from './reports/tractorsrlno-wise-sevice-history/tractorsrlno-wise-sevice-history.component';
import { ApplicationTypeComponent } from './application-type/application-type.component';
import { AddapplicationTypeComponent } from './application-type/addapplication-type/addapplication-type.component';
import { EditapplicationTypeComponent } from './application-type/editapplication-type/editapplication-type.component';
const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'forgotResetPassword/:ui/:token',
    component: ForgotResetPasswordComponent
  },
  {
    path: '',
    component: MasterComponent,
    children: [
      { path: 'technician', component: MechanicListComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      { path: 'technician/add', component: AddmechanicComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      { path: 'technician/edit', component: EditMechanicComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      { path: 'serviceStations/add', component: ServiceStationComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      { path:'serviceStations/edit',component:EditServiceStationComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      { path: 'serviceStations', component: ServiceListComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      { path:'createRequest',component:CreateRequestComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      { path:'registerCustomer',component:AddCustomerComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      { path: 'all-services', component: AllServicesComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      { path: 'changepassword', component: ChangepasswordComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor','CallCenterExecutive']
        }
      },
      { path: 'serviceHistory', component: ServiceHistoryComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor','CallCenterExecutive']
        }
      },
      {path: 'callcenterExecutive', component:ExecutiveListComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor']
        }
      },
      {path: 'callcenterExecutive/add', component:CallcenterExecutivesComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor']
        }
      },
      {path: 'callcenterExecutive/edit', component:EditCallcenterExecutivesComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor']
        }
      },
      {path: 'tractors', component:TractorListComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      {path: 'tractors/add', component:AddTractorListComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      {path: 'tractors/edit', component:EditTractorListComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      {path:'settings',component:SettingsComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor','CallCenterExecutive']
        }
      },
      {path: 'countryheads', component:CountryheadListComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path: 'countryheads/add', component:AddcountryheadComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path: 'countryheads/edit', component:EditcountryheadComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path: 'distributors', component:DistributorListComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead']
        }
      },
      {path: 'distributors/add', component:AddDistributorComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead']
        }
      },
      {path: 'distributors/edit', component:EditDistributorComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead']
        }
      },
      {path: 'fla', component:FlaListComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor']
        }
      },
      {path: 'fla/add', component:AddflaComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor']
        }
      },
      {path: 'fla/edit', component:EditflaComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor']
        }
      },
      {path: 'uploadVideoVerbatim', component:UploadVideoVerbatimComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['FrontLineAdvisor','Distributor']
        }
      },
      {path: 'offerNotification', component:OffernotificationComponent,canActivate: [AuthGuardGuard], 
      data: { 
        allowedRoles: ['Distributor']
      }
      },
      {path: 'feedbackQB', component:FeedbackQBComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path: 'feedbackQB/add', component:AddFeedbackComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path: 'feedbackQB/edit',component:EditfeebackComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path: 'serviceTypes', component:ServiceTypeComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path: 'serviceTypes/add', component:AddserviceTypeComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path: 'serviceTypes/edit', component:EditserviceTypeComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      { path: 'notifications', component: NotificationsComponent ,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['CountryHead','Distributor','FrontLineAdvisor','CallCenterExecutive']
        }
      },
      {path: 'applicationTypes', component:ApplicationTypeComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path: 'applicationTypes/add', component:AddapplicationTypeComponent,canActivate: [AuthGuardGuard], 
      data: { 
        allowedRoles: ['Admin']
      }
    },
    {path: 'applicationTypes/edit', component:EditapplicationTypeComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      /**REPORTING*/
      {path: 'customer-registration', component:CustomerRegistrationComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor','CallCenterExecutive']
        }
      },
      {path: 'distributor-wise-fla', component:DistributorWiseFLAComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor']
        }
      },
      {path: 'distributor-wise-technicians', component:DistributorWiseTechniciansComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor']
        }
      },
      {path: 'feedback-report', component:FeedbackReportComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      {path: 'customer-service-request', component:CustomerServiceRequestComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor']
        }
      },
      {path: 'video-verbatim-report', component:VideoVerbatimReportComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','CountryHead','Distributor','CallCenterExecutive']
        }
      },
      {path: 'country-wise-performance', component:CountryWisePerformanceComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin']
        }
      },
      {path:'customers',component:CustomerListComponent,canActivate: [AuthGuardGuard] , 
        data: { 
          allowedRoles: ['Admin','Distributor','FrontLineAdvisor']
        }
      },
      {path: 'customers/add', component:AddCustomersComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','Distributor','FrontLineAdvisor']
        }
      },
      {path: 'customers/edit', component:UpdateCustomerComponent,canActivate: [AuthGuardGuard], 
        data: { 
          allowedRoles: ['Admin','Distributor','FrontLineAdvisor']
        }
      },
      { path: '', redirectTo: 'serviceStations', pathMatch: 'full', data: { 
        allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor','CallCenterExecutive']
      }},
      {path: 'queries-feedback', component:QueriesFeedbackComponent,canActivate: [AuthGuardGuard], 
      data: { 
        allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor','CallCenterExecutive']
      }},

      {path: 'service-history', component:TractorsrlnoWiseSeviceHistoryComponent,canActivate: [AuthGuardGuard], 
      data: { 
        allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor','CallCenterExecutive']
      }},

      { path: 'error',component:PageNotFoundComponent},
      { path: '**', redirectTo: 'serviceStations', pathMatch: 'full',
      data: { 
        allowedRoles: ['Admin','CountryHead','Distributor','FrontLineAdvisor','CallCenterExecutive']
      }}
    ]
  },
  // {
  //   path:'admin',
  //   component: NotificationsComponent,
  //   children : [
  //     { path: '', redirectTo: 'child-one', pathMatch: 'full' },
  //     { path: 'child-one', component: NotificationsComponent },
  //   ]
  // },
  // { path: 'notification', component: NotificationsComponent },
  // { path: 'mechanic', component: MechanicListComponent },
  // { path: 'servicestations', component: ServiceListComponent },
]

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidemenuComponent,
    NavbarComponent,
    ServiceListComponent,
    MechanicListComponent,
    LoginComponent,
    ServiceStationComponent,
    AddmechanicComponent,
    NotificationsComponent,
    SettingsComponent,
    ServiceHistoryComponent,
    AllServicesComponent,
    CreateRequestComponent,
    MasterComponent,
    InputNumberDirective,
    CallcenterExecutivesComponent,
    ChangepasswordComponent,
    AdminmainComponent,
    ExecutiveListComponent,
    EditServiceStationComponent,
    EditMechanicComponent,
    EditCallcenterExecutivesComponent,
    TractorListComponent,
    AddTractorListComponent,
    EditTractorListComponent,
    AddcountryheadComponent,
    EditcountryheadComponent,
    DistributorListComponent,
    AddDistributorComponent,
    EditDistributorComponent, 
    CustomerRegistrationComponent,
    DistributorWiseFLAComponent,
    DistributorWiseTechniciansComponent,
    FeedbackReportComponent,
    CustomerServiceRequestComponent,
    VideoVerbatimReportComponent,
    CountryWisePerformanceComponent,
    CountryheadListComponent,
    FlaListComponent,
    AddflaComponent,
    EditflaComponent,
    FeedbackQBComponent,
    UploadVideoVerbatimComponent,
    AddFeedbackComponent,
    CustomerRequestComponent,
    CustomerRegisterationComponent,
    ServiceTypeComponent,
    AddserviceTypeComponent,
    EditserviceTypeComponent,
    ResetPasswordComponent,
    EditfeebackComponent,
    PaginationComponent,
    PageNotFoundComponent,
    AddCustomerComponent,
    CustomerListComponent,
    UpdateCustomerComponent,
    ForgotPasswordComponent,
    AddCustomersComponent,
    ForgotResetPasswordComponent,
    OffernotificationComponent,
    QueriesFeedbackComponent,
    TractorsrlnoWiseSeviceHistoryComponent,
    ApplicationTypeComponent,
    AddapplicationTypeComponent,
    EditapplicationTypeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    RouterModule.forRoot(appRoutes, { useHash: false }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // whitelistedDomains: ['example.com'],
        // blacklistedRoutes: ['example.com/examplebadroute/']
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ChartsModule,
    MyDatePickerModule,
    ExportAsModule,
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [MessagingService, AsyncPipe,ApiService,TransfereService,AuthGuardGuard, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
