import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes,Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-addapplication-type',
  templateUrl: './addapplication-type.component.html',
  styleUrls: ['./addapplication-type.component.css']
})
export class AddapplicationTypeComponent implements OnInit {
  countryList = [];
  defaulttype = "";
  defaultStatus:boolean = false;
  statusCount = 0;
  isValid : boolean = true;
  nativeQuestion : boolean = false;
  error = {'description':[]};
  constructor(public apiService:ApiService,private fb: FormBuilder,private router : Router,private spinner: NgxSpinnerService,private location: Location) { 
  }

  ngOnInit() {
    this.getCountryList();
  }

  getCountryList() {
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      if(this.countryList.length > 0) {
        this.countryList.forEach(element => {
          element.serviceType = "";
          element.active = true;
        });
      }
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
      
    });
  }

  changeStatus(id) {
    this.countryList.forEach(element => {
      if(element.id == id) {
        element.active = !element.active;
      }
    });
  }
  
  public alphanumericValidator(control) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    this.isValid = false;
    if(control && control.trim().match(/^[0-9a-zA-Z ]+$/) && control.trim().length >= 4 ) {
      this.isValid = true;  
      this.defaultStatus = true;
      return true;
    } else if(control && control.trim() == "") {
      this.isValid = false;
      this.defaultStatus = false;     
      return false;
    } else {
      this.isValid = false;   
      this.defaultStatus = false;  
      return false;
    } 
  }

  changedefaultStatus() {
    if(this.defaulttype && this.defaulttype.length > 0) {
      this.alphanumericValidator(this.defaulttype);
    } else {
      this.defaultStatus = false;
    }
  }

  addapllicationType() {
    //console.log(this.countryList);
    const serviceTypes = [];
    //console.log(this.countryList);
    serviceTypes.push({
      "applicationTypeName": this.defaulttype ? this.defaulttype.trim() : '',
      "country": "Default",
      "language": "English",
      "active": true
    })

    this.countryList.forEach(element => {
      if(element.serviceType.length > 0) {
        serviceTypes.push({
          "applicationTypeName": element.serviceType ? element.serviceType.trim() : '',
          "country": element.countryName,
          "language": element.languages[0].languageName,
          "active": element.active
        })
      }
    });
    //console.log(serviceTypes);
    let data = {model:serviceTypes};
    this.apiService.createApplicationtype(data).subscribe(response => {
      //console.log(response);
      this.router.navigate(['/applicationTypes'],{queryParams:{id:1}});
    }, error => {
      //console.log(error);
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  addNativeQuestion() {
    //console.log(this.countryList);
    this.nativeQuestion = false;
    this.countryList.forEach(element => {
      console.log(element.serviceTypeError)
      element.serviceTypeError = false;
     
      if(element.serviceType && element.serviceType.trim().length >=4) {
        //console.log('valid');
        element.serviceTypeError = false;
        element.active = true;
        this.nativeQuestion = true;
      } else if(element.serviceType && element.serviceType.trim().length > 0) {
        //console.log('in valid');
        element.serviceTypeError = true;
        this.nativeQuestion = false;
        element.active = false;
      } else if(element.serviceType && element.serviceType.trim() == "") {
        //console.log('in valid');
        element.serviceTypeError = true;
        this.nativeQuestion = false;
        element.active = false;
      }
    });
  }

  closeAlert() {
    this.error.description = [];
  }
}
