import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransfereService } from '../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators, MinLengthValidator } from '@angular/forms';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
import { element } from '@angular/core/src/render3/instructions';
declare var $: any;
declare var google;

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
  customerRegisterForm: any;
  countryList = [];
  error = { 'description': [] };
  success = { 'description': [] };
  autocompleteItems = [];
  geocoder = new google.maps.Geocoder;
  currentLng;
  currentLat;
  city = '';
  state = '';
  country = '';
  postal_code = '';
  service;
  countryCode = '';
  marker: any;
  selectedCountry = '';
  maxLengthNumber: any = 10;
  tractorModelList = [];
  registerdUser;
  tractors = []; //Tractors which are added by FLA for particular customer.
  tractorList = [];
  isInvalid = false;
  maxLength = 10;
  minLength = 10;
  addressErr = false;
  isCityhidden:boolean = true;
  isStatehidden:boolean = true;
  serialNoErr = false;
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  userDetails = {'usertype':'','sub':''};
  constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService, private router: Router, private spinner: NgxSpinnerService, private location: Location) {
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }

    this.customerRegisterForm = new FormGroup({
      country: new FormControl('', [Validators.required]),
      language: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      mobileNo: new FormControl('', [Validators.required]),
      Address: new FormControl('', [Validators.required]),
      City: new FormControl('', [Validators.required,this.addValidator]),
      State: new FormControl('', [Validators.required,this.addValidator]),
    });
    this.customerRegisterForm.get('Address').disable();
    this.customerRegisterForm.get('mobileNo').disable();
  }

  ngOnInit() {
    this.addressErr = false;
    this.tractors.push({
      tractorName: "",
      serialNo: "",
      purchasedOn: "",
    });
    this.service = new google.maps.places.AutocompleteService();
    this.customerRegisterForm = new FormGroup({
      country: new FormControl('', [Validators.required]),
      language: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      mobileNo: new FormControl('', [Validators.required]),
      Address: new FormControl('', [Validators.required]),
      City: new FormControl('', [Validators.required,this.addValidator]),
      State: new FormControl('', [Validators.required,this.addValidator]),
    });
    this.customerRegisterForm.get('Address').disable();
    this.customerRegisterForm.get('mobileNo').disable();
    this.customerRegisterForm.patchValue({
      countryHeadId : this.userDetails['userid'],
      language : this.userDetails['language'],
      country: this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
      countryHeadName:this.userDetails['given_name'],
    })
    this.getCountryList({});
  }

  
  public addValidator(control: FormControl) {
    let isValid = false;
    if(control.value && control.value.trim().length >= 2 && control.value.trim().length <= 50) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public numberValidator(control: FormControl) {
    if (control.value && control.value.length == 10) {
      //valid
      //console.log('valid');
      return null;
    } else if (control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');
      return { 'inValid': true };
    } else {
      return null;
    }
  }

  public emailValidator(control: FormControl) {
    ////console.log(control.value);
    if (control.value && control.value.length > 0 &&  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value)) {
      //valid
      return null;
    } else if (control.value != null && control.value.length > 0 && !( /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value))) {
      //invalid
      return { 'isEmail': true };

    } else {
      return null;
    }
  }

  getCountryList(data) {
    this.spinner.show();
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      this.spinner.hide();
      if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
      this.countryList.forEach(element => {
          if (element.countryName == this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
            this.countryCode = element.countryCode;
            this.customerRegisterForm.get('mobileNo').enable();
            this.customerRegisterForm.get('Address').enable();
            this.minLength = element.mobileNumberLength['min'];
            this.maxLength = element.mobileNumberLength['max'];
          }
        });
      }
      //console.log(this.countryList, "this.getCountryList");
    }, error => {
      this.spinner.hide();
    });
  }

  onselectCountry(countryName) {
    //console.log(countryName);
    if(countryName) {
      this.autocompleteItems = [];
      this.countryList.forEach(element => {
        if (element.countryName == countryName) {
          this.countryCode = element.countryCode;
          this.customerRegisterForm.get('mobileNo').enable();
          this.minLength = element.mobileNumberLength['min'];
          this.maxLength = element.mobileNumberLength['max'];
        }
      });
      this.customerRegisterForm.patchValue({
        language: '',
        // name: '',
        email: '',
        mobileNo: '',
        Address: '',
        City: '',
        State: ''
      })

      if (countryName == '') {
        this.customerRegisterForm.get('Address').reset('');
        this.customerRegisterForm.get('Address').disable();
      }
      else {
        this.customerRegisterForm.get('Address').enable();
      }

      countryName == 'Sri Lanka' ? this.maxLengthNumber = 9 : this.maxLengthNumber = 10;

      this.countryList.forEach(element => {
        if (element.countryName == countryName) {
          this.customerRegisterForm.patchValue({
            language: element.languages[0].languageName
          })
        }
      });
    } else {
      this.customerRegisterForm.get('Address').reset('');
      this.customerRegisterForm.get('Address').disable();
      this.customerRegisterForm.get('mobileNo').reset('');
      this.customerRegisterForm.get('mobileNo').disable();
    }
  }

  getAddress() {
    this.addressErr = false;
    this.autocompleteItems = [];
    this.customerRegisterForm.patchValue({
      City: '',
      State: ''
    })
    let me = this;
    this.spinner.show();
    var regex = /[^a-zA-Z0-9(),.-/\ ]/;
    //console.log(!regex.test(this.customerRegisterForm.get('Address').value))
    if (this.customerRegisterForm.get('Address').value.trim() && this.customerRegisterForm.get('Address').value.trim().length >= 10 && !regex.test(this.customerRegisterForm.get('Address').value.trim())) {
      //console.log(this.countryCode);
      this.apiService.getPlacePredictions(this.customerRegisterForm.get('Address').value, this.countryCode).then(result => {
        this.spinner.hide();
        //console.log(result);
        //console.log(typeof result);
        this.autocompleteItems = <Array<any>>result;
        //console.log(this.autocompleteItems);
        $('.dropdown').css('display', 'inline');
      }, error => {
        console.error(error);
        this.spinner.hide();
      });
    } else {
      this.spinner.hide();
      this.addressErr = true;      
    }
  }

  chooseItem(item: any) {
    //console.log(item);
    $('.dropdown').css('display', 'none');

    var geocoder = new google.maps.Geocoder();

    geocoder.geocode({ 'placeId': item.place_id }, (results, status) => {
      //console.log(results);
      results[0].address_components.forEach(element => {
        //console.log(element.types[0]);
        this.customerRegisterForm.patchValue({
          Address: results[0].formatted_address
        })
        if (element.types[0] == 'administrative_area_level_2') {
          this.customerRegisterForm.patchValue({
            City: element.long_name
          })
          // this.city = element.long_name;
        }
        if (element.types[0] == 'administrative_area_level_1') {
          this.customerRegisterForm.patchValue({
            State: element.long_name
          })
          // this.state = element.long_name;
        }
        // if(element.types[0] == 'country')
        // {
        //   this.country = element.long_name;
        // }
        // if(element.types[0] == 'postal_code')
        // {
        //   this.postal_code = element.long_name;
        // }
      });
      if(this.customerRegisterForm.get('City').value == undefined || this.customerRegisterForm.get('City').value == "") {
        this.isCityhidden = false;
      }
      if(this.customerRegisterForm.get('State').value == undefined || this.customerRegisterForm.get('State').value == "") {
        this.isStatehidden = false;
      }
      if (status == 'OK') {
        var lat = results[0].geometry.location.lat();
        var lng = results[0].geometry.location.lng();
        //console.log(lat, lng);
        this.currentLat = lat;
        this.currentLng = lng;
        //console.log(this.customerRegisterForm)
        //console.log(this.customerRegisterForm.valid)
        // this.loadMap(this.currentLat,this.currentLng);
        // var latlng = new google.maps.LatLng(lat,lng);
        // this.marker.setPosition(latlng);
      }
    })
  }

  registerCustomer() {
    this.error.description = [];
    this.success.description = [];
    const data = {
      "mobileNo": this.customerRegisterForm.value.mobileNo,
      "name": this.customerRegisterForm.value.name,
      "country": this.customerRegisterForm.value.country,
      "location": {
        "type": "Point",
        "coordinates": [this.currentLng, this.currentLat]
      },
      "addressLine": this.customerRegisterForm.value.Address,
      "city": this.customerRegisterForm.value.City,
      "state": this.customerRegisterForm.value.State,
      "language": this.customerRegisterForm.value.language,
    }
    //console.log(data);
    this.apiService.registerCustomer(data).subscribe((res: any) => {
      //console.log(res);
      this.selectedCountry = res.content['country'];
      if (res.succeeded) {
        this.registerdUser = res.content['id'];
        this.success.description = ["Customer Registration added successfully!"];
        this.getTractorModels(this.customerRegisterForm.value.country);
        // this.customerRegisterForm.get('country').reset('');
        // this.customerRegisterForm.get('language').reset('');
        // this.customerRegisterForm.get('name').reset('');
        // this.customerRegisterForm.get('mobileNo').reset('');
        // this.customerRegisterForm.get('Address').reset('');
        // this.customerRegisterForm.get('City').reset('');
        // this.customerRegisterForm.get('State').reset('');
        // this.customerRegisterForm.get('Address').disable();
        setTimeout(function () {
          $(".myAlert-top").hide();
        }, 2000);
      }
    }, error => {
      this.spinner.hide();
      this.error.description = [];
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  closeAlert() {
    this.error.description = [];
    // this.success.description = [];
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getTractorModels(countryName) {
    const query = {
      "itemsPerPage": -1,
      "filters": [{
        "member": "country", "value": countryName, "operation": "EqualTo"
      }, {
        "member": "isactive", "value": true
      }]
    }
    this.apiService.getTractorModels(query).subscribe(response => {
      //console.log(response);
      this.tractorModelList = []
      this.tractorModelList = response['content'].payLoad;
      this.spinner.hide();
      //console.log(this.tractorModelList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }


  addTractor() {
    this.tractors.push({
      tractorName: "",
      serialNo: "",
      purchasedOn: "",
    });
  }

  checkSerialNo(selectedNo) {
    var regex = /[^a-zA-Z0-9- ]/;
    this.serialNoErr = false;
    //console.log(regex.test(selectedNo.serialNo));
    //console.log(!regex.test(selectedNo.serialNo));

    if(selectedNo.serialNo.trim().length >= 5 && selectedNo.serialNo.trim().length < 16 && !regex.test(selectedNo.serialNo.trim())) {
      this.tractors.forEach(element => {
        if(element.serialNo == selectedNo.serialNo) {
          element.serialNoErr = false;
        }
      });
    } else {
      this.tractors.forEach(element => {
        if(element.serialNo == selectedNo.serialNo) {
          element.serialNoErr = true;
          this.serialNoErr = true;
        }
      });
    }
  }
  
  trashTractor(i) {
    this.isInvalid = false;
    //console.log(i, "iiii...")
    if (this.tractors.length > 0) {
      this.tractors.splice(i, 1);
    }
  }

  modelChanged() {
    this.isInvalid = false;
  }

  checkDuplicates() {
    this.error.description = [];
    this.isInvalid = false;
    this.serialNoErr = false;
    if (this.tractors.length >= 1) {
      var uniqueTractors = [];
      this.isInvalid = false;
      this.tractors.forEach(element => {
        if(!element.serialNo || !element.tractorName) {
          //console.log('1')
          return;
        } else if(element.serialNoErr) {
          this.serialNoErr = true;
          return;
        } else {
          //console.log('2')
          if (uniqueTractors.length == 0) {
            uniqueTractors.push(element.tractorName);
          } else if (uniqueTractors.indexOf(element.tractorName) == -1) {
            uniqueTractors.push(element.tractorName);
          } else if (uniqueTractors.indexOf(element.tractorName) > -1) {
            this.isInvalid = true;
            return;
          }
        }
      })
      if (!this.isInvalid || !this.serialNoErr) {
        this.saveTractor();
        //console.log("SUCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCESSSSSSSSSSSSSSSSSSSSSSSS");
      } else {
        //console.log('Enter Valid Details.')
      }
    } else {
      // this.saveTractor();
    }
  }

  saveTractor() {
    this.isInvalid = false;
    this.error.description = [];
    //console.log(this.tractors);
    if (this.tractors.length > 0) {
      this.tractors.forEach(element => {
        var purchasedOn = element.purchasedOn ? element.purchasedOn : '';
        //console.log(purchasedOn, "purchasedOn");
        delete element.purchasedOn;
        element.purchasedOn = purchasedOn.date ? purchasedOn.date.year + '-' + purchasedOn.date.month + '-' + purchasedOn.date.day : "";

        // if(element.tractorName && element.serialNo) {
        //   this.isInvalid = false;          

        // }
      });
      //console.log(this.tractors);
      var data = {
        "userId": this.registerdUser,
        "tractors": this.tractors
      }
      //console.log(data);

      this.apiService.addTractors(data).subscribe(response => {
        this.tractors = [];
        this.addTractor();
        $("#collapseadd").removeClass('show');
        $("#collapseview").addClass('collapse show');
        this.getTractorList();
      }, (error: any) => {
        //console.log(error);
        this.isInvalid = false;
        this.tractors = [];
        this.addTractor();
        this.spinner.hide();
        this.error.description = [];
        if (error.error.errors.length > 0) {
          this.error.description.push(error.error.errors[0]['description']);
          let el = document.getElementById('addCustomerTop');
          el.scrollIntoView();
        }
      });
    }
  }

  getTractorList() {
    this.apiService.getTractors(this.registerdUser).subscribe(response => {
      //console.log(response['content'].payLoad);
      this.tractorList = response['content'].payLoad;
    }, (error: any) => {
      //console.log(error);
    });
  }

  gotoraiseRequest(tractorModel, serialno) {
    //console.log(this.customerRegisterForm.value.mobileNo);
    this.router.navigate(['/createRequest'], {
      queryParams: {
        mobileNo: this.customerRegisterForm.value.mobileNo,
        tractorModel: tractorModel,
        serialNO: serialno
      }
    });
  }
}
