import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { isNgTemplate } from '@angular/compiler';
import { ApiService } from '../api.service';
declare var $: any;
import { MessagingService } from "../shared/messaging.service";
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})

export class SidemenuComponent implements OnInit {
  userDetails = { 'usertype': '', 'given_name': '', 'distributorId': '' };
  notificationCount = 0;
  selectedLanguage = "";
  servicestationList: any;
  /*show menu as per roles*/
  /*for fla & call center landing page should be requested tab from all services*/
  /*for rest all show reports by default with customer reg tab open*/
  adminmenu = {
    'masters': [{ name: 'COUNTRY HEAD', url: '/countryheads', icon: 'fas fa-user-tie' }, { name: 'DISTRIBUTOR', url: '/distributors', icon: 'far fa-address-card' }, { name: 'FLA', url: '/fla', icon: 'far fa-id-badge' }, { name: 'TECHNICIANS', url: '/technician', icon: 'fas fa-wrench' }, { name: 'TRACTOR MODELS', url: '/tractors', icon: 'fas fa-tractor' }, { name: 'SERVICE STATIONS', url: '/serviceStations', icon: 'fas fa-warehouse' }, { name: 'SERVICE CENTER EXECUTIVES', url: '/callcenterExecutive', icon: 'fas fa-phone-square' }, { name: 'SERVICE TYPE', url: '/serviceTypes', icon: 'far fa-list-alt' }, { name: 'FEEDBACK QUESTION BANK', url: '/feedbackQB', icon: 'far fa-list-alt' }, { name: 'CUSTOMERS', url: '/customers', icon: 'fas fa-users' },{ name: 'CUSTOMER QUERIES', url: '/queries-feedback', icon: 'fas fa-question' },{ name: 'APPLICATION TYPE', url: '/applicationTypes', icon: 'far fa-list-alt' }],
    'services': [{ name: 'RAISE REQUEST', url: '/createRequest', icon: 'far fa-hand-paper' }, { name: 'ALL SERVICES', url: '/all-services', icon: 'fas fa-user-cog' }, { name: 'SERVICE HISTORY', url: '/serviceHistory', icon: 'fas fa-book' }],
    'reports': [{ name: 'CUSTOMER REGISTRATIONS', url: '/customer-registration', icon: 'fas fa-user-check' }, { name: 'COUNTRY WISE PERFORMANCE', url: '/country-wise-performance', icon: 'fas fa-chart-line' }, { name: 'FEEDBACK REPORT', url: '/feedback-report', icon: 'fas fa-address-book' }, { name: 'CUSTOMER SERVICE REQUEST REPORT', url: '/customer-service-request', icon: 'fas fa-users' }, { name: 'DISTRIBUTOR WISE TECHNICIANS', url: '/distributor-wise-technicians', icon: 'fas fa-id-card-alt' }
      // {name:'DISTRIBUTOR WISE FLA',url:'/distributor-wise-fla',icon:'fas fa-users-cog'}
      , { name: 'VIDEO VERBATIM REPORT', url: '/video-verbatim-report', icon: 'far fa-file-video' },{ name: 'TRACTOR SERIAL NO WISE SERVICE HISTORY', url: '/service-history', icon: 'far fa-file-video' }]
  }

  countryheadmenu = {
    'masters': [{ name: 'DISTRIBUTOR', url: '/distributors', icon: 'far fa-address-card' }, { name: 'FLA', url: '/fla', icon: 'far fa-id-badge' }, { name: 'SERVICE STATIONS', url: '/serviceStations', icon: 'fas fa-warehouse' }, { name: 'SERVICE CENTER EXECUTIVES', url: '/callcenterExecutive', icon: 'fas fa-phone-square' }, { name: 'TECHNICIANS', url: '/technician', icon: 'fas fa-wrench' }, { name: 'TRACTOR MODELS', url: '/tractors', icon: 'fas fa-tractor' },{ name: 'CUSTOMER QUERIES', url: '/queries-feedback', icon: 'fas fa-question' }],
    'services': [{ name: 'RAISE REQUEST', url: '/createRequest', icon: 'far fa-hand-paper' }, { name: 'ALL SERVICES', url: '/all-services', icon: 'fas fa-user-cog' }, { name: 'SERVICE HISTORY', url: '/serviceHistory', icon: 'fas fa-book' }],
    'reports': [{ name: 'CUSTOMER REGISTRATIONS', url: '/customer-registration', icon: 'fas fa-user-check' }, { name: 'FEEDBACK REPORT', url: '/feedback-report', icon: 'fas fa-address-book' }, { name: 'CUSTOMER SERVICE REQUEST REPORT', url: '/customer-service-request', icon: 'fas fa-users' },
    // {name:'DISTRIBUTOR WISE FLA',url:'/distributor-wise-fla',icon:'fas fa-users-cog'},
    { name: 'DISTRIBUTOR WISE TECHNICIANS', url: '/distributor-wise-technicians', icon: 'fas fa-id-card-alt' }, { name: 'VIDEO VERBATIM REPORT', url: '/video-verbatim-report', icon: 'far fa-file-video' },{ name: 'TRACTOR SERIAL NO WISE SERVICE HISTORY', url: '/service-history', icon: 'far fa-file-video' }]
  }

  distributormenu = {
    'masters': [{ name: 'FLA', url: '/fla', icon: 'far fa-id-badge' }, { name: 'SERVICE STATIONS', url: '/serviceStations', icon: 'fas fa-warehouse' }, { name: 'SERVICE CENTER EXECUTIVES', url: '/callcenterExecutive', icon: 'fas fa-phone-square' }, { name: 'TECHNICIANS', url: '/technician', icon: 'fas fa-wrench' }, { name: 'CUSTOMERS', url: '/customers', icon: 'fas fa-users' },{ name: 'CUSTOMER QUERIES', url: '/queries-feedback', icon: 'fas fa-question' }], /*hides tractor crud here*/
    'services': [{ name: 'RAISE REQUEST', url: '/createRequest', icon: 'far fa-hand-paper' }, { name: 'ALL SERVICES', url: '/all-services', icon: 'fas fa-user-cog' }, { name: 'SERVICE HISTORY', url: '/serviceHistory', icon: 'fas fa-book' }, { name: 'UPLOAD VIDEO VERBATIM', url: '/uploadVideoVerbatim', icon: 'fas fa-video' }],
    'reports': [{ name: 'CUSTOMER REGISTRATIONS', url: '/customer-registration', icon: 'fas fa-user-check' },
    { name: 'DISTRIBUTOR WISE TECHNICIANS', url: '/distributor-wise-technicians', icon: 'fas fa-id-card-alt' }, { name: 'FEEDBACK REPORT', url: '/feedback-report', icon: 'fas fa-address-book' }, { name: 'CUSTOMER SERVICE REQUEST REPORT', url: '/customer-service-request', icon: 'fas fa-users' }, { name: 'VIDEO VERBATIM REPORT', url: '/video-verbatim-report', icon: 'far fa-file-video' },{ name: 'TRACTOR SERIAL NO WISE SERVICE HISTORY', url: '/service-history', icon: 'far fa-file-video' }],
    'notifications': [{ name: 'OFFER NOTIFICATION', url: '/offerNotification', icon: 'fas fa-bell' }]
  } /*hides tractor crud here*/

  flamenu = {
    'masters': [{ name: 'SERVICE STATIONS', url: '/serviceStations', icon: 'fas fa-warehouse' }, { name: 'TECHNICIANS', url: '/technician', icon: 'fas fa-wrench' }, { name: 'CUSTOMERS', url: '/customers', icon: 'fas fa-users' },{ name: 'CUSTOMER QUERIES', url: '/queries-feedback', icon: 'fas fa-question' }],
    'services': [{ name: 'RAISE REQUEST', url: '/createRequest', icon: 'far fa-hand-paper' }, { name: 'ALL SERVICES', url: '/all-services', icon: 'fas fa-user-cog' }, { name: 'SERVICE HISTORY', url: '/serviceHistory', icon: 'fas fa-book' }, { name: 'UPLOAD VIDEO VERBATIM', url: '/uploadVideoVerbatim', icon: 'fas fa-video' }],
    'reports': [{ name: 'CUSTOMER REGISTRATIONS', url: '/customer-registration', icon: 'fas fa-user-check' }, { name: 'FEEDBACK REPORT', url: '/feedback-report', icon: 'fas fa-address-book' }, { name: 'CUSTOMER SERVICE REQUEST REPORT', url: '/customer-service-request', icon: 'fas fa-users' },{ name: 'VIDEO VERBATIM REPORT', url: '/video-verbatim-report', icon: 'far fa-file-video' },{ name: 'TRACTOR SERIAL NO WISE SERVICE HISTORY', url: '/service-history', icon: 'far fa-file-video' }]
  }

  servicecentermenu = {
     'masters': [{ name: 'SERVICE STATIONS', url: '/serviceStations', icon: 'fas fa-warehouse' }, { name: 'TECHNICIANS', url: '/technician', icon: 'fas fa-wrench' }],
    // 'services': [{ name: 'RAISE REQUEST', url: '/createRequest', icon: 'far fa-hand-paper' }, { name: 'ALL SERVICES', url: '/all-services', icon: 'fas fa-user-cog' }, { name: 'SERVICE HISTORY', url: '/serviceHistory', icon: 'fas fa-book' }],
    'services': [{ name: 'SERVICE HISTORY', url: '/serviceHistory', icon: 'fas fa-book' }],
    'reports': [{ name: 'CUSTOMER REGISTRATIONS', url: '/customer-registration', icon: 'fas fa-user-check' }, { name: 'VIDEO VERBATIM REPORT', url: '/video-verbatim-report', icon: 'far fa-file-video' }]
     
  }
  languages = [];

  constructor(private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router, private messagingService: MessagingService, public apiService: ApiService, private translate: TranslateService) {
    // //console.log(this.adminmenu);
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      // //console.log(this.userDetails);
    }

    // this.messagingService.currentMessage.subscribe(value => {
    //   //console.log(value, 'msg');
    //   this.notificationCount = value.length;
    // })
    this.messagingService.getUpdatedCount.subscribe((data) => {
      //console.log("update count" + data);
      //console.log(this.router.url);
      if (this.router.url != "/notifications") {
        this.updateCount(data);
      }
    })
  }

  updateCount(notificationCount) {
    // //console.log(notificationCount);
    this.notificationCount = notificationCount;
  }

  ngOnInit() {
    this.notificationCount = parseInt(localStorage.getItem('notificationCount'));
    this.languages = [];
    this.getCountryList({});
    // if(this.userDetails['userType'] == 'FrontLineAdvisor' || this.userDetails['userType'] =='CallCenterExecutive'){
    //   //console.log("__++++++++1");
    //   $( document ).ready(function() {
    //     $('#servicesGroup').addClass('show');
    //   })
    // } else {
    //   $( document ).ready(function() {
    //     //console.log("__++++++++2++++++++");
    //     $('#reportsGroup').addClass('show');
    //   });
    // }
    // $( document ).ready(function() {
    //   $('#reportsGroup').removeClass('show');
    // });
    // //console.log(this.flamenu);
  }

  getCountryList(data) {
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      if (response.content.payLoad.length > 0) {
        response.content.payLoad.forEach(element => {
          if (element.languages && element.languages.length > 0) {
            if (this.languages.length > 0 && !(this.languages.some(item => item.languageName == element.languages[0].languageName))) {
              this.languages.push(element.languages[0])
            } else if (this.languages.length == 0) {
              this.languages.push(element.languages[0])
            }

          }
        });
        this.selectedLanguage =
          localStorage['languageCode'];
      } else {
        this.languages = [];
      }
      //console.log(this.countryList, "this.getCountryList");
    }, error => {
    });
  }

  activeMenu(item, obj, role) {
    if (role == 'Admin') {
      this.adminmenu[obj];
      // //console.log(this.adminmenu[obj]);
      this.adminmenu['masters'].forEach(element => {
        element['active'] = false;
      });
      this.adminmenu['services'].forEach(element => {
        element['active'] = false;
      });
      this.adminmenu['reports'].forEach(element => {
        element['active'] = false;
      });
      this.adminmenu[obj].forEach(element => {
        if (element.name == item.name) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    } else if (role == 'CountryHead') {
      // //console.log(this.countryheadmenu[obj])
      this.countryheadmenu['masters'].forEach(element => {
        element['active'] = false;
      });
      this.countryheadmenu['services'].forEach(element => {
        element['active'] = false;
      });
      this.countryheadmenu['reports'].forEach(element => {
        element['active'] = false;
      });
      this.countryheadmenu[obj].forEach(element => {
        if (element.name == item.name) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    } else if (role == 'Distributor') {
      // //console.log(this.distributormenu)
      // //console.log(obj);
      // //console.log(this.distributormenu[obj])
      this.distributormenu['masters'].forEach(element => {
        element['active'] = false;
      });
      this.distributormenu['services'].forEach(element => {
        element['active'] = false;
      });
      this.distributormenu['reports'].forEach(element => {
        element['active'] = false;
      });
      this.distributormenu['notifications'].forEach(element => {
        element['active'] = false;
      });
      this.distributormenu[obj].forEach(element => {
        if (element.name == item.name) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    } else if (role == 'FrontLineAdvisor') {
      // //console.log(this.flamenu[obj])
      this.flamenu[obj].forEach(element => {
        if (element.name == item.name) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    } else if (role == 'CallCenterExecutive') {
      // //console.log(this.servicecentermenu[obj])
      this.servicecentermenu[obj].forEach(element => {
        if (element.name == item.name) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    }
  }

  sidebarClicked() {
    // //console.log('+++++++++hide+++++++++++++++++++++++++');
    //Also take care of content component
    $(".page-wrapper").removeClass("toggled");
    $('.page-content').css('margin-left', '2%');
  }

  sidebarClickedadd() {
    // //console.log('+++++++++show+++++++++++++++++++++++++');
    //Also take care of content component
    $(".page-wrapper").addClass("toggled");
    $('.page-content').css('margin-left', '20%');
  }

  notifications() {
    // //console.log('notifications');
    this.notificationCount = 0;
    this.router.navigate(['/notifications']);
  }

  settings() {
    this.router.navigate(['/settings']);
  }


  getService_stations() {
    console.log('getService_stationsgetService_stationsgetService_stations');
    if (this.userDetails.usertype == "CountryHead") {
      this.getServicestationByID(this.userDetails["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country"], 'CountryHead');
    } else if (this.userDetails.usertype == "FrontLineAdvisor") {
      this.getServicestationByID(this.userDetails.distributorId, 'FrontLineAdvisor');
    }
    else if (this.userDetails.usertype == 'Distributor') {
      this.getServicestationByID(localStorage.getItem("userId"), 'Distributor');
    }
    else if (this.userDetails.usertype == 'CallCenterExecutive') {
      this.getServicestationByID(this.userDetails.distributorId, 'CallCenterExecutive');
    } else {
      this.logout();
    }
  }

  getServicestationByID(filter, role) {
    //console.log(filter);
    this.servicestationList = [];
    let query = {};
    if (role == 'CountryHead') {
      query = {
        "filters": [
          {
            "member": "country",
            "value": filter,
            "operation": "EqualTo"
          }
        ]
      }
    } else {
      query = {
        "filters": [
          {
            "member": "distributorId",
            "value": filter,
            "operation": "EqualTo"
          }
        ]
      }
    }
    this.spinner.show();
    this.apiService.getServicestations(query).subscribe(response => {
      //console.log(response,".......servicestationList");
      this.spinner.hide();
      this.servicestationList = response['content'].payLoad;
      //console.log(this.servicestationList,"this.servicestationList");
      if (this.servicestationList.length > 0) {
        this.servicestationList.forEach((serviceStation, index) => {
          //console.log(serviceStation,"serviceStation.id");
          //console.log(serviceStation['id'],"serviceStation.id");
          if (serviceStation['id'] && localStorage.getItem("fcmToken") != null) {
            this.messagingService.subscribeTokenToTopic(serviceStation['id']);
          }
          if (index == this.servicestationList.length - 1) {
            this.logout();
          }
          // else { 
          //THis is creating issue for newly created users, as new user do not have any service staions,
          //they are not able to login they directly gets out to login page.sssss
          //   this.router.navigate(['/login', { replaceUrl: true }]);
          // }
        });
      } else {
        this.logout();
      }
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }


  logout() {
    this.spinner.show();
    console.log('++++++++++Inside Logout+++++++++++')
    this.apiService.logout({ userId: localStorage['userId'] }).subscribe((response: any) => {
      this.spinner.hide();
      var userId = localStorage['userId'];
      localStorage.clear();
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      localStorage.setItem('userId', userId);
      this.messagingService.requestPermission();
      this.router.navigate(['/login'], { replaceUrl: true });
    }, error => {
      // //console.log(error);
      this.spinner.hide();
    });
  }

  onClick(selectedMenu) {
    // //console.log(selectedMenu);
    var allGroup = $(".panel-collapse").find("show");
    for (var i = 0; i < allGroup['prevObject'].length; i++) {
      if (allGroup['prevObject'][i].id == selectedMenu) {
        // //console.log('Iffff');
        // //console.log(allGroup['prevObject'][i].id);
        $(allGroup['prevObject'][i].id).addClass('show');
      } else {
        // //console.log(allGroup['prevObject'][i].id + "-->" + "else");
        $('.panel-collapse').removeClass('show');
      }
    }
  }

  modelChanged(value) {
    // //console.log('fdrglkdfgl');
    // //console.log(value);
    // //console.log(this.selectedLanguage);
    if (this.selectedLanguage != localStorage.selectedLanguage) {

      var languageName = '';
      this.languages.forEach(element => {
        if (element.languageCode == this.selectedLanguage) {
          languageName = element.languageName;
        }
      });
      //also called api to refresh token
      var query = {
        "language": languageName,
        "loginType": "Web"
      }
      // //console.log(languageName);
      this.apiService.changeLanguage(query).subscribe((response: any) => {
        localStorage.setItem('access_token', response['content'].jwtToken);
        this.apiService.getuserdetails().subscribe((decryptedResponse: any) => {
          // get verify token and user details
          // const helper = new JwtHelperService();
          // this.userDetails = helper.decodeToken(response.content.jwtToken);
          this.userDetails = decryptedResponse.content;
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
          //console.log(JSON.stringify(this.userDetails));
          localStorage['languageCode'] = this.userDetails['languagecode'] ? this.userDetails['languagecode'] : 'en';
          if (localStorage.getItem("languageCode")) {
            var check = new File([], 'assets/i18n/' + localStorage.getItem("languageCode") + '.json');
            console.log(check);
            console.log(check['exists']);
            this.translate.setDefaultLang(localStorage['languageCode']);
            this.translate.use(localStorage['languageCode']).subscribe((res) => {
              console.log(res);
            }, error => {
              console.log(error);
              this.translate.setDefaultLang('en');
              this.translate.use('en');
            });
          } else {
            this.translate.setDefaultLang('en');
            this.translate.use(localStorage['languageCode']).subscribe((res) => {
              console.log(res);
            }, error => {
              console.log(error);
              this.translate.setDefaultLang('en');
              this.translate.use('en');
            });
          }
        }, error => {
          // //console.log(error);
        });
      }, error => {
        // //console.log(error);
      });
      //also called api to refresh token
    }
  }

}
