import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { DateService } from '../date.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var google;
import { ActivatedRoute, RouterModule, Routes, Router } from '@angular/router';
import { Location } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
declare var $: any;

@Component({
  selector: 'app-create-request',
  templateUrl: './create-request.component.html',
  styleUrls: ['./create-request.component.css']
})
export class CreateRequestComponent implements OnInit {
  @ViewChild('usermap') usermapElement: ElementRef;
  @ViewChild('stationmap') stationmapElement: ElementRef;
  usermap: any;
  stationmap: any;
  usermarker: any;
  stationmarker: any;
  serviceCenters = [];
  mechanics = [];
  createRequestForm: FormGroup;
  tractors = [];
  tractorsSerialNo = [];
  customerId: any;
  serviceNames = [];
  applicationList = [];
  userLatitude;
  userLongitude;
  userAddress = {};
  autocompleteItems = [];
  applicationtypeData = {};
  errors = {description:[]};
  currentLng;
  currentLat;
  city = '';
  state = '';
  country = '';
  postal_code = '';
  selectedTechnician;
  startDateFilter: any;
  endDateFilter: any;
  geocoder = new google.maps.Geocoder;
  requestId;
  date = new Date();
  last = new Date(this.date.getTime() - (15 * 24 * 60 * 60 * 1000));
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false,
    disableUntil: { year: this.last.getFullYear(), month: this.last.getMonth() + 1, day: this.last.getDate() - 1 }

  };
  service;
  userDetails = {};
  loggedinUser = { 'usertype': '', 'sub': '' };
  isformValid = false;
  selectedtractorModel;
  selectedtractorSerialno;
  maxLength = 11;
  minLength = 7;
  countryList = [];
  selectedCountry = '';
  selectedCountryhead = '';
  selectedCountryheadName = '';
  selectedDistributor = '';
  selectedDistributorName = '';
  selectedserviceStations = '';
  isCityhidden: boolean = true;
  isStatehidden: boolean = true;
  addressErr = false;
  constructor(private location: Location, private route: ActivatedRoute,
    private formBuilder: FormBuilder, public apiService: ApiService,
    private router: Router, private spinner: NgxSpinnerService, private dateService: DateService) {
    this.createRequestForm = this.formBuilder.group({
      mobileNo: new FormControl('', [Validators.required]),
      customerName: new FormControl('', [Validators.required]),
      serialNo: new FormControl('', [Validators.required]),
      serviceType: new FormControl('', [Validators.required]),
      userAddress: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      serviceStation: new FormControl('', [Validators.required]),
      technicianId: [''],
      stationAddress: [''],
      tractorModel:new FormControl('', [Validators.required]),
      purchasedOn:[''],
      date:new FormControl('', [Validators.required]),
      time:new FormControl('', [Validators.required]),
      description:new FormControl('', [Validators.required]),
      startDateFilter:new FormControl(''),
      endDateFilter:new FormControl(''),
      country:new FormControl(''),
      hours:new FormControl('', [Validators.required]),
      applicationType: new FormControl('', [Validators.required]),
    });
    this.isformValid = false;
    this.createRequestForm.disable();
    this.createRequestForm.get('mobileNo').enable();
    this.route.queryParams.subscribe(params => {
      //console.log(params,"params");
      //console.log(params['mobileNo']);
      //console.log(params['tractorModel']);
      this.createRequestForm.patchValue({
        mobileNo: params['mobileNo']
      })
      this.selectedtractorModel = params['tractorModel'];
      this.selectedtractorSerialno = params['serialNO'];
      if (params['mobileNo']) {
        this.getUser(params['mobileNo']);
        this.location.replaceState('createRequest');
      }
    });
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    // var regex = /[^a-zA-Z ]/;
    if (control.value && control.value.trim().length >= 2 && control.value.trim().length <= 50) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public descValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z0-9(),?. ]/;
    if (control.value.trim().length <= 100 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'description': true }
    } else {
      return isValid ? null : { 'description': false }
    }
  }

  ngOnInit() {
    // this.callList();
    this.addressErr = false;
    this.service = new google.maps.places.AutocompleteService();
    if (localStorage.getItem('userDetails') != null) {
      this.loggedinUser = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.loggedinUser);
    }
    $('.dropdown').css('display', 'none');
    this.errors.description = [];
    //this.getApplicationList();
  }

  getCountryList(data) {
    this.spinner.show();
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      if (this.loggedinUser['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
        // this.countryList.forEach(countryElement => {
        //   //console.log(countryElement);
        //   if(countryElement.countryName == this.loggedinUser['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
        //     this.minLength = countryElement.mobileNumberLength && countryElement.mobileNumberLength['min'] ? countryElement.mobileNumberLength['min'] : 10;
        //     this.maxLength = countryElement.mobileNumberLength && countryElement.mobileNumberLength['max'] ? countryElement.mobileNumberLength['max'] : 10;
        //   } else {
        //     this.minLength = 10;
        //     this.maxLength = 10;
        //   }
        //   //console.log(this.minLength);
        //   //console.log(this.maxLength);

        // });
        this.createRequestForm.get('mobileNo').enable();
      } else {
        this.createRequestForm.get('mobileNo').disable();
        this.createRequestForm.get('mobileNo').reset('');
      }
      this.spinner.hide();
      // //console.log(this.countryList, "this.getCountryList");
    }, error => {
      this.spinner.hide();
    });
  }

  customerRegister() {
    //console.log("customer register");
    this.router.navigate(['/createRequest/add']);
  }

  // public timeValidator(control: FormControl) {
  //   //console.log(control);
  //   var controlhh = control.value.split(':')[0];
  //   var controlmm = control.value.split(':')[1];
  //   var hh = new Date().getHours();
  //   var mm = new Date().getMinutes(); 
  //   //console.log(hh);
  //   //console.log(new Date());
  //   //console.log(mm);
  //   if(controlhh < hh) {
  //     //error
  //     //console.log(mm);
  //     return {'inValid': true};
  //   }  else if(controlhh == hh && controlmm < mm || controlmm == mm){
  //     //error
  //     return {'inValid': true};
  //   } else {
  //     return null;
  //   }
  // }

  detectNumber() {
    //console.log(this.createRequestForm.get('mobileNo').value);
    //console.log(this.maxLength); 
    //console.log(this.minLength);
    if (this.minLength <= this.createRequestForm.get('mobileNo').value.length && this.createRequestForm.get('mobileNo').value.length <= this.maxLength) {
      this.createRequestForm.get('customerName').reset('');
      this.createRequestForm.get('serialNo').reset('');
      this.createRequestForm.get('serviceType').reset('');
      this.createRequestForm.get('userAddress').reset('');
      this.createRequestForm.get('serviceStation').reset('');
      this.createRequestForm.get('technicianId').reset('');
      this.createRequestForm.get('stationAddress').reset('');
      this.createRequestForm.get('tractorModel').reset('');
      this.createRequestForm.get('purchasedOn').reset('');
      this.createRequestForm.get('date').reset('');
      this.createRequestForm.get('time').reset('');
      this.createRequestForm.get('description').reset('');
      this.createRequestForm.get('country').reset('');
      this.createRequestForm.get('hours').reset('');
      this.getUser(this.createRequestForm.get('mobileNo').value);
    }
  }

  getUser(mobileNo) {
    //console.log(mobileNo);
    this.isformValid = false;
    this.errors.description = [];
    this.userAddress = {};
    this.userLongitude = '';
    this.userLatitude = '';
    const data = {
      "filters": [{
        "member": "mobileNo",
        "value": mobileNo,
        "operation": "EqualTo"
      }]
    }
    this.spinner.show();
    this.apiService.getUser(data).subscribe((response: any) => {
      //console.log(response);
      this.userDetails = { 'userAddress': {} };
      if (response.content['payLoad'].length > 0 && response.content['payLoad'][0].userType == "Customer") {
        this.spinner.hide();
        this.createRequestForm.enable();
        this.isformValid = true;
        //console.log(response.content['payLoad']);
        //console.log(response.content['payLoad'][0].address);
        //console.log(response.content['payLoad'][0].address.city);
        //console.log(response.content['payLoad'][0].address.state);
        //console.log(response.content['payLoad'][0].address.country);
        //console.log(response.content['payLoad'][0].address.zipCode);
        //console.log(response.content['payLoad'][0].tractors);
        //console.log(this.userDetails['userAddress']);
        if (this.selectedtractorModel && this.selectedtractorSerialno) {
          this.createRequestForm.patchValue({
            tractorModel: this.selectedtractorModel,
            serialNo: this.selectedtractorSerialno
          })
        }
        this.userLongitude = response.content['payLoad'][0].address.location['coordinates'][0]
        this.userLatitude = response.content['payLoad'][0].address.location['coordinates'][1];
        this.userDetails['userId'] = response.content['payLoad'][0].id;
        this.customerId = response.content['payLoad'][0].id;
        this.userDetails['userAddress'].city = response.content['payLoad'][0].address.city;
        this.userDetails['userAddress'].state = response.content['payLoad'][0].address.state;
        this.userDetails['userAddress'].country = response.content['payLoad'][0].address.country;
        this.country = response.content['payLoad'][0].address.country;
        this.userDetails['userAddress'].postal_code = response.content['payLoad'][0].address.zipCode;
        this.userDetails['userAddress'].addressLine = response.content['payLoad'][0].address.addressLine;
        this.getCountryByName(this.userDetails['userAddress'].country);
        this.createRequestForm.patchValue({
          userAddress: response.content['payLoad'][0].address.addressLine,
          city: response.content['payLoad'][0].address.city,
          state: response.content['payLoad'][0].address.state,
        })
        this.loadMap(this.userLatitude, this.userLongitude, this.usermap, this.usermarker, 'user');
        if (response.content['payLoad'][0].tractors && response.content['payLoad'][0].tractors.length > 0) {
          //this.tractors = response.content['payLoad'][0].tractors;
          this.tractors = this.removeDuplicateModels(response.content['payLoad'][0].tractors, "tractorName");
          //console.log("unique Models" + JSON.stringify(this.tractors));
          //this.createRequestForm.get('serialNo').disable();
          this.createRequestForm.get('serviceType').enable();
        }
        this.createRequestForm.patchValue({
          customerName: response.content['payLoad'][0].name,
          userAddress: response.content['payLoad'][0].address.addressLine,
          country: response.content['payLoad'][0].address.country
        })


        //get service types
        this.serviceNames = [];
        this.applicationList = [];
        const filters = [];
        if (this.createRequestForm.get('country').value) {
          filters.push({
            "member": "country",
            "value": this.createRequestForm.get('country').value,
            "operation": "EqualTo"
          })
        }

        filters.push({
          "member": "isactive", "value": true
        })
        let query = {};
        query = {
          "filters": filters,
          "itemsPerPage": -1
        }
        this.apiService.getServiceType(query).subscribe(response => {
          //console.log(response);
          if (response['content'].payLoad.length > 0) {
            response['content'].payLoad.forEach(element => {
              if (element.serviceTypeDetails && element.serviceTypeDetails.length > 0) {
                element.serviceTypeDetails.forEach(typeElement => {
                  if (typeElement.active == true && typeElement.country == this.createRequestForm.get('country').value) {
                    this.serviceNames.push({ "country": typeElement.country, "text": typeElement.serviceTypeName })
                  }
                });
              }
            });
            //console.log(this.serviceNames);
          }
        }, error => {
          //console.log(error);
        })
        this.apiService.getApplicationType(query).subscribe(response => {
          if(response['content'].payLoad.length > 0) {
            response['content'].payLoad.forEach(element => {
              if(element.applicationTypeDetails && element.applicationTypeDetails.length > 0) {
                element.applicationTypeDetails.forEach(typeElement => {
                  if(typeElement.active == true && typeElement.country == this.createRequestForm.get('country').value) {
                    this.applicationList.push({"country":typeElement.country,"text":typeElement.applicationTypeName})
                    //console.log(this.applicationList,"this.applicationList")
                  }
                });
              }
            });
            //console.log(this.serviceNames);
          }
        }, error => {
          //console.log(error);
        })
      } else {
        this.spinner.hide();
        this.isformValid = false;
        //console.log(this.loggedinUser['usertype'])
        if (this.loggedinUser['usertype'] == 'FrontLineAdvisor') {
          $('#messageModal').modal('show');
        } else {
          this.errors.description.push('Mobile No. is not valid.');
        }
        this.createRequestForm.disable();
        this.createRequestForm.get('mobileNo').enable();
      }
      this.spinner.hide();
    }, error => {
      //console.log(error);
      this.spinner.hide();
      this.isformValid = false;
      //console.log(this.loggedinUser['usertype'])

      if (this.loggedinUser['usertype'] == 'FrontLineAdvisor') {
        $('#messageModal').modal('show');
      }
      else {
        this.errors.description.push('Mobile No. is not valid.');
      }
      this.createRequestForm.disable();
      this.createRequestForm.get('mobileNo').enable();
    })
  }

  removeDuplicateModels(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};
    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}


  getCountryByName(countryName) {
    this.spinner.show();
    let data = {
      "filters": [{
        "member": "country", "value": countryName, "operation": "EqualTo"
      }]
    }
    this.apiService.getCountryList(data).subscribe((response: any) => {
      this.spinner.hide();
      //console.log(response, "this.getCountryList");
      this.userDetails['countryCode'] = response['content'].payLoad[0].countryCode;
      // this.minLength = response['content'].payLoad[0].mobileNumberLength['min'];
      // this.maxLength = response['content'].payLoad[0].mobileNumberLength['max'];
      //console.log(this.countryList, "this.getCountryList");
    }, error => {
      this.spinner.hide();
    });
  }

  getApplicationList() {
    this.spinner.show();
    this.applicationList = [];
    const filters = [];
    //alert(this.createRequestForm.get('country').value)
        //if( this.createRequestForm.get('country').value) {
          filters.push({
            "member": "country",
            "value":  "India",//this.createRequestForm.get('country').value,
            "operation": "EqualTo"
          })
        //}

        filters.push({
          "member": "isActive", "value": true
        })
        let query = {};
        query = {
          "filters":filters,
          "itemsPerPage": -1
        }
        this.apiService.getApplicationType(query).subscribe(response => {
          if(response['content'].payLoad.length > 0) {
            response['content'].payLoad.forEach(element => {
              if(element.applicationTypeDetails && element.applicationTypeDetails.length > 0) {
                element.applicationTypeDetails.forEach(typeElement => {
                  if(typeElement.active == true && typeElement.country == this.createRequestForm.get('country').value) {
                    this.applicationList.push({"country":typeElement.country,"text":typeElement.serviceTypeName})
                    console.log(this.applicationList,"this.applicationList")
                  }
                });
              }
            });
            //console.log(this.serviceNames);
          }
        }, error => {
      this.spinner.hide();
    });
  }

  loadMap(latitude,longitude,map,marker,type) {
    //console.log('Load');
    //console.log('Load');
    //console.log(type);

    let latLng = new google.maps.LatLng(latitude, longitude);
    let mapOptions = {
      center: latLng,
      zoom: 12,
      disableDefaultUI: true, // a way to quickly hide all controls
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      overviewMapControl: false,
      fullscreenControl: false,
      scrollWheel: false,
    }
    if (type == "user" || type == "drag") {
      map = new google.maps.Map(this.usermapElement.nativeElement, mapOptions);
      //console.log(map);
    } else {
      map = new google.maps.Map(this.stationmapElement.nativeElement, mapOptions);
    }

    this.usermarker = new google.maps.Marker({
      map: map,
    });

    //console.log(this.userDetails);
    this.userLatitude = latitude;
    this.userLongitude = longitude;
    // this.userDetails['userAddress']['location']['coordinates'][1] = longitude;
    // this.userDetails['userAddress']['location']['coordinates'][0] = latitude;
    this.geocoder.geocode({ 'location': latLng }, (results) => {
      if (results[0] && type == "drag") {
        //console.log(results[0]);
        this.createRequestForm.get('city').reset('');
        this.createRequestForm.get('state').reset('');
        var isSameCountry = false;
        results[0].address_components.forEach(element => {
          if (element.types[0] == 'country') {
            if (element.long_name == this.country) {
              isSameCountry = true;
            } else {
              //console.log('COUNTRY DOES NOT MATCH.....');
            }
          }
        });
        if (isSameCountry) {

          results[0].address_components.forEach(element => {
            //console.log(element.types[0]);
            if (element.types[0] == 'administrative_area_level_2') {
              //console.log(element.long_name);
              this.createRequestForm.patchValue({
                city: element.long_name
              })
              this.isCityhidden = true;
              this.userDetails['userAddress'].city = element.long_name;
              //get City
            }
            if (element.types[0] == 'administrative_area_level_1') {
              //console.log(element.long_name);
              this.createRequestForm.patchValue({
                state: element.long_name
              })
              this.isStatehidden = true;
              this.userDetails['userAddress'].state = element.long_name;
              //get State
            }
            if (element.types[0] == 'country') {
              //console.log(element.long_name);
              this.userDetails['userAddress'].country = element.long_name;
              //get Country
            }
            if (element.types[0] == 'postal_code') {
              //console.log(element.long_name);
              this.userDetails['userAddress'].postal_code = element.long_name;
              //get Postal
            }
          });
          //console.log(this.createRequestForm.get('city').value);
          this.createRequestForm.patchValue({
            userAddress: results[0].formatted_address
          })
          if (this.createRequestForm.get('city').value == undefined || this.createRequestForm.get('city').value == "") {
            this.isCityhidden = false;
          }
          if (this.createRequestForm.get('state').value == undefined || this.createRequestForm.get('state').value == "") {
            this.isStatehidden = false;
          }
          this.userDetails['userAddress'].addressLine = results[0].formatted_address;
        } else {
          //console.log('Country Does not match');
        }
      } else {
        //console.log('No results found');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    map.addListener('dragend', () => {
      //console.log(this.usermarker.getPosition());
      map.setCenter(this.usermarker.getPosition());
      var lat = this.usermarker.getPosition().lat();
      var lng = this.usermarker.getPosition().lng();
      //console.log(lat);
      //console.log(lng);
      this.loadMap(lat, lng, map, marker, "drag")
    });
    this.usermarker.bindTo('position', map, 'center');

    if (this.loggedinUser.usertype == 'Distributor') {
      this.selectedDistributor = this.loggedinUser['userid'];
      this.selectedDistributorName = this.loggedinUser['sub'];
      this.getServiceStation(this.selectedDistributor);
    } else if (this.loggedinUser.usertype == 'FrontLineAdvisor') {
      this.selectedDistributor = this.loggedinUser['distributorId'];
      this.selectedDistributorName = this.loggedinUser['distributorName'];
      this.getServiceStation(this.selectedDistributor);
    } else if (this.loggedinUser.usertype == 'CallCenterExecutive') {
      this.selectedDistributor = this.loggedinUser['distributorId'];
      this.selectedDistributorName = this.loggedinUser['distributorName'];
      this.getServiceStation(this.selectedDistributor);
    }
    else {
      this.getServiceStationByVicinity(latitude, longitude)
    }

  }

  selectTechnician(technicianDetails) {
    //console.log(technicianDetails);
    this.selectedTechnician = '';
    this.mechanics.forEach(element => {
      if (element.mechanicId == technicianDetails.mechanicId) {
        element.selected = true;
        this.selectedTechnician = element.mechanicId;
      } else {
        element.selected = false;
      }
    });
  }

  tractorSelected(tractorName) {
    // this.tractors.forEach(element => {
    //   if(element.tractorName == tractorName) {
    //     this.createRequestForm.patchValue({
    //       serialNo:element.serialNo,
    //       purchasedOn:element.purchasedOn
    //     });
    //   }
    // });
    const filters = [];
    filters.push({
      "member": "userId",
      "value": this.customerId,
      "operation": "EqualTo"
    })

    filters.push({
      "member": "tractorName", "value": tractorName
    })
    let query = {};
    query = {
      "filters": filters,
      "itemsPerPage": -1
    }
    this.apiService.getTractorbyModel(query).subscribe(response => {
      //console.log(response);
      if (response['content'].payLoad.length > 0) {
        this.tractorsSerialNo = response['content'].payLoad;
        this.tractorsSerialNo.forEach(element => {
          if (element.tractorName == tractorName) {
            this.createRequestForm.patchValue({
              serialNo: element.serialNo,
              purchasedOn: element.purchasedOn
            });
          }
        });
      }
    }, error => {
      //console.log(error);
    })
  }

  getServiceStation(distributorId) {
    //console.log(distributorId, " distributorId");
    let query = {
      "filters": [
        {
          "member": "distributorId",
          "value": distributorId,
          "operation": "EqualTo"
        }
      ],
      "pageNumber": 1,
      "itemsPerPage": 100,
    }
    this.spinner.show();
    this.apiService.getServicestations(query).subscribe((response) => {
      //console.log(response, " reslllllllllll");
      this.serviceCenters = response['content'].payLoad;
      // response['content'].payLoad.forEach(element => {
      //   var km = element.distance / 1000;
      //   element.distance = km.toFixed(1);
      // })
    })
    this.spinner.hide();
  }

  getServiceStationByVicinity(latitude, longitude) {
    //console.log('getServiceStationByVicinity');
    this.spinner.show();
    this.serviceCenters = [];
    this.apiService.getServiceStationByVicinity({
      "type": "Point",
      "coordinates": [longitude, latitude],
      "country": this.userDetails['userAddress'].country
    }).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      if (response['content'].payLoad.length > 0) {
        this.serviceCenters = response['content']['payLoad'];
        response['content'].payLoad.forEach(element => {
          var km = element.distance / 1000;
          element.distance = km.toFixed(1);
        })
      }
    }, error => {
      this.spinner.hide();
      ////console.log(error);
    });
  }

  getMechanics(serviceStationId) {
    //console.log(this.createRequestForm.value.date);
    //console.log(this.createRequestForm.value.date.date);
    var scheduledDateTime = this.dateService.simpleDate(this.createRequestForm.get('date').value)
    //console.log(scheduledDateTime.split(" ")[0]);
    //console.log(scheduledDateTime.split(" ")[0]+ 'T'+this.createRequestForm.get('time').value);
    scheduledDateTime = scheduledDateTime.split(" ")[0] + 'T' + this.createRequestForm.get('time').value;
    if (scheduledDateTime) {
      this.mechanics = [];
      this.spinner.show();
      const data = {
        "filters": [
          {
            "member": "serviceStationId",
            "value": serviceStationId,
            "operation": "EqualTo"
          },
          {
            "member": "scheduledDate",
            "value": scheduledDateTime,
            "operation": "EqualTo"
          }
          // {
          //   "member":"fromDate",
          //   "value":this.createRequestForm.value.date.date
          // }, 
          // {
          //   "member":"toDate",
          //   "value":this.createRequestForm.value.date.date
          // }
        ]
      }
      this.apiService.gettechnicianforassignment(data).subscribe(response => {
        this.spinner.hide();
        this.mechanics = response['content']['payLoad'];
        $('#collapseOne').addClass('show');
      }, error => {
        //console.log(error);
        this.spinner.hide();
      });

      this.createRequestForm.patchValue({
        startDateFilter: (this.createRequestForm.value.date != null) ? { date: { day: this.createRequestForm.value.date.date.day, month: this.createRequestForm.value.date.date.month, year: this.createRequestForm.value.date.date.year } } : null,
        endDateFilter: (this.createRequestForm.value.date != null) ? { date: { day: this.createRequestForm.value.date.date.day, month: this.createRequestForm.value.date.date.month, year: this.createRequestForm.value.date.date.year } } : null
      })
    }

  }

  createRequest(form: any) {
    //console.log(this.createRequestForm);
    //console.log(this.userDetails);
    this.requestId = '';
    // 7b7738e4-9baa-4c1d-abc8-7f4a62ba9204
      var scheduledDateTime = this.dateService.simpleDate(this.createRequestForm.get('date').value)
      //console.log(scheduledDateTime.split(" ")[0]);
      //console.log(scheduledDateTime.split(" ")[0]+ 'T'+this.createRequestForm.get('time').value);
      scheduledDateTime = scheduledDateTime.split(" ")[0]+ 'T'+this.createRequestForm.get('time').value;
      // //console.log(this.createRequestForm.get('date').value, this.createRequestForm.get('time').value);
      // //console.log(new Date(this.createRequestForm.get('date').value + ' ' + this.createRequestForm.get('time').value).toISOString())
      this.spinner.show()    
      var createData = {
        "serviceStationId": this.createRequestForm.get('serviceStation').value,
        "requestDescription": this.createRequestForm.get('description').value,
        "serviceType": this.createRequestForm.get('serviceType').value,
        // "userId": localStorage.getItem('userId'),
        "Mobileno": this.createRequestForm.get('mobileNo').value,
        "userId":this.userDetails['userId'],
        "userAddress": {
          "addressLine": this.userDetails['userAddress'].addressLine,
          "city":this.userDetails['userAddress']['city'],
          "state":this.userDetails['userAddress']['state'],
          "country":this.userDetails['userAddress']['country'],
          "location": {
            "type": "Point",
            "coordinates": [this.userLongitude, this.userLatitude]
          },
          "zipCode": "null"
        },
        // "scheduledDateTime":this.createRequestForm.get('date').value + 'T'+this.createRequestForm.get('time').value+'.00.000Z',
        "scheduledDateTime":scheduledDateTime,
        "tractor": {
          "tractorName": this.createRequestForm.get('tractorModel').value,
          "serialNo": this.createRequestForm.get('serialNo').value,
          "purchasedOn": this.createRequestForm.get('purchasedOn').value
        },
        "technicianId":this.selectedTechnician ? this.selectedTechnician : "",
        "hours": this.createRequestForm.get('hours').value,
        "applicationType": this.createRequestForm.get('applicationType').value,
      }
    //console.log(createData);
    
    if(this.selectedTechnician) {
      this.apiService.getmechanicstatus('userId='+this.selectedTechnician).subscribe((res:any) => {
        if(res.content.isLoggedOut && !res.content.isLoggedOut)
        {
          this.spinner.hide();
          this.errors.description = [];
          this.errors.description.push("Selected mechanic is not available.");
        } else {
          this.spinner.show();
          this.apiService.createServiceRequest(createData).subscribe(response => {
            //console.log(response);
            this.spinner.hide();
            this.requestId = response['content'].requestIdentificationNo;
            $('#successModal').modal('show');
            // this.router.navigate(['/all-services']);
          }, error => {
            console.log(error);
            this.spinner.hide();
            this.errors.description = [];
            if (error.error.errors.length > 0) {
              this.errors.description.push(error.error.errors[0]['description']);
            } else {
              this.errors.description.push('Cannot Create Request,Please Try Again Later.')
            }
          });
        }

      })
    } else {
      this.apiService.createServiceRequest(createData).subscribe(response => {
        //console.log(response);
        this.spinner.hide();
        this.requestId = response['content'].requestIdentificationNo;
        $('#successModal').modal('show');
        // this.router.navigate(['/all-services']);
      }, error => {
        //console.log(error);
        this.spinner.hide();
        this.errors.description = [];
        if (error.error.errors.length > 0) {
          this.errors.description.push(error.error.errors[0]['description']);
        } else {
          this.errors.description.push('Cannot Create Request,Please Try Again Later.')
        }
        this.spinner.hide()
      });
    }

  }

  getAddress() {
    this.autocompleteItems = [];
    let me = this;
    this.addressErr = false;
    this.createRequestForm.get('city').reset('');
    this.createRequestForm.get('state').reset('');
    // this.spinner.show()    
    var regex = /[^a-zA-Z0-9(),.-/\ ]/;
    //console.log(this.createRequestForm);
    //console.log(this.createRequestForm.get('userAddress').value);
    if (this.createRequestForm.get('userAddress').value.length >= 10 && !regex.test(this.createRequestForm.get('userAddress').value)) {
      this.spinner.hide();
      this.apiService.getPlacePredictions(this.createRequestForm.get('userAddress').value, this.userDetails['countryCode']).then(result => {
        this.spinner.hide();
        //console.log(result);
        //console.log(typeof result);
        this.autocompleteItems = <Array<any>>result;
        //console.log(this.autocompleteItems);
        $('.dropdown').css('display', 'inline');
      }, error => {
        console.error(error);
        this.spinner.hide();
      });
    } else {
      this.spinner.hide();
      this.addressErr = true;
    }
  }

  closeAlert() {
    this.errors.description = [];
    // this.success.description = [];
  }

  chooseItem(item: any) {
    //console.log(item);
    $('.dropdown').css('display', 'none');
    this.createRequestForm.patchValue({
      userAddress: item.description
    })
    this.autocompleteItems = [];
    var geocoder = new google.maps.Geocoder();
    //console.log(this.usermarker);    
    // if (this.marker && this.marker.setMap) {
    //   this.marker.setMap(null);
    // }
    geocoder.geocode({ 'placeId': item.place_id }, (results, status) => {
      //console.log(results);
      //console.log(results[0].address_components);
      results[0].address_components.forEach(element => {
        //console.log(element.types[0]);
        if (element.types[0] == 'administrative_area_level_2') {
          //console.log(element.long_name);
          this.createRequestForm.patchValue({
            city: element.long_name
          })
          this.userDetails['userAddress'].city = element.long_name;
          //get City
        }
        if (element.types[0] == 'administrative_area_level_1') {
          //console.log(element.long_name);
          this.createRequestForm.patchValue({
            state: element.long_name
          })
          this.userDetails['userAddress'].state = element.long_name;
          //get State
        }
        if (element.types[0] == 'country') {
          //console.log(element.long_name);
          this.userDetails['userAddress'].country = element.long_name;
          //get Country
        }
        if (element.types[0] == 'postal_code') {
          //console.log(element.long_name);
          this.userDetails['userAddress'].postal_code = element.long_name;
          //get Postal
        }
      });
      if (this.createRequestForm.get('city').value == undefined || this.createRequestForm.get('city').value == "") {
        this.isCityhidden = false;
      }
      if (this.createRequestForm.get('state').value == undefined || this.createRequestForm.get('state').value == "") {
        this.isStatehidden = false;
      }
      this.userDetails['userAddress'].addressLine = results[0].formatted_address;
      if (status == 'OK') {
        var lat = results[0].geometry.location.lat();
        var lng = results[0].geometry.location.lng();
        //console.log(lat, lng);
        this.userLatitude = lat;
        this.userLongitude = lng;
        // this.userDetails['userAddress']['location'].coordinates[1] = lng;
        // this.userDetails['userAddress']['location'].coordinates[0] = lat;
        var latlng = new google.maps.LatLng(lat, lng);
        if (this.loggedinUser.usertype == 'Distributor') {
          this.selectedDistributor = this.loggedinUser['userid'];
          this.selectedDistributorName = this.loggedinUser['sub'];
          this.getServiceStation(this.selectedDistributor);
        } else if (this.loggedinUser.usertype == 'FrontLineAdvisor') {
          this.selectedDistributor = this.loggedinUser['distributorId'];
          this.selectedDistributorName = this.loggedinUser['distributorName'];
          this.getServiceStation(this.selectedDistributor);
        } else if (this.loggedinUser.usertype == 'CallCenterExecutive') {
          this.selectedDistributor = this.loggedinUser['distributorId'];
          this.selectedDistributorName = this.loggedinUser['distributorName'];
          this.getServiceStation(this.selectedDistributor);
        }
        else {
          this.getServiceStationByVicinity(lat, lng);
        }

        this.usermarker.setPosition(latlng);
      }
      //console.log(status)
    });
  }

  gotoCustomerReg() {
    this.router.navigate(['/customers/add']);
    // this.router.navigate(['/registerCustomer']);
  }

  clearTechnician() {
    this.selectedTechnician = '';
    this.createRequestForm.get('serviceStation').reset('');
    this.mechanics = [];
  }
}
