import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransfereService } from '../../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { RouterModule, Routes,Router } from '@angular/router';

@Component({
  selector: 'app-edit-callcenter-executives',
  templateUrl: './edit-callcenter-executives.component.html',
  styleUrls: ['./edit-callcenter-executives.component.css']
})
export class EditCallcenterExecutivesComponent implements OnInit {
  executiveid;
  editCallcenterExecutiveForm : any;
  customerExecutiveList = [];
  executiveData = {};
  userDetails = {'usertype':'','sub':''};
  query = {};
  error = {'description':[]};
  maxLength = 10;
  minLength = 10;
  countryList = [];
  selectedCountry;
  constructor(public apiService:ApiService,public transfereservice: TransfereService,private router : Router,private fb: FormBuilder,private route: ActivatedRoute,private spinner: NgxSpinnerService,private location: Location) { 
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
      this.callList();
      this.getCountryList({});
    }

    this.editCallcenterExecutiveForm = this.fb.group({
      id: new FormControl('', [Validators.required]),
      executiveName: new FormControl('', [Validators.required,this.alphabetsValidator]),
      contact: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,this.emailValidator]),
      distributorName: new FormControl(''),
      countryHeadName: new FormControl(''),
      alternateMobileNo: new FormControl(''),
      distributorId:  new FormControl('',[Validators.required]),
    });
  }

  callList() {
    this.query = {};
    if(this.userDetails.usertype == 'Admin') {
      this.query = {}
      this.getcallcenterexecutiveList();
    } else if(this.userDetails.usertype == 'CountryHead') {
      this.query = {
        "filters" : [{
          "member": "countryHeadId",
          "value":localStorage.userId,
          "operation": "EqualTo"
        }]
      }
      this.getcallcenterexecutiveList();
    } else if(this.userDetails.usertype == 'Distributor')  {
      //when userType is Distributor.
      this.query = {
        "filters" : [{
          "member": "distributorId",
          "value":localStorage.userId,
          "operation": "EqualTo"
        }]
      }
      this.getcallcenterexecutiveList();
    }
  }

  getcallcenterexecutiveList() {
    //console.log(this.customerExecutiveList);
    this.spinner.show();
    this.apiService.getcallcenterexecutiveList(this.query).subscribe(response => {
      //console.log(response);
      this.customerExecutiveList = response['content'].payLoad;
      //console.log(this.customerExecutiveList);
      this.customerExecutiveList .forEach(element => {
        //console.log(element.id);
        //console.log(this.executiveid)
        this.spinner.hide();
        if(element.id == this.executiveid) {
          this.executiveData = element;
          this.patchValue();
        }
      });
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.executiveid = params['id'];
      this.selectedCountry = params['cname'];
      //console.log(this.executiveid);
    })

    this.editCallcenterExecutiveForm = this.fb.group({
      id: new FormControl('', [Validators.required]),
      executiveName: new FormControl('', [Validators.required,this.alphabetsValidator]),
      contact: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,this.emailValidator]),
      distributorName: new FormControl(''),
      countryHeadName: new FormControl(''),
      alternateMobileNo: new FormControl(''),
      distributorId:  new FormControl('',[Validators.required]),
    });
    this.getcallcenterexecutiveList();
  }

  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
      //console.log('valid');
      return null;
    } else if(control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');

      return {'inValid': true};
    }else {
      return null;
    }
  }

  public emailValidator(control: FormControl) {
    ////console.log(control.value);
    if (control.value && control.value.length > 0 &&  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value)) {
      //valid
      return null;
    } else if(control.value != null && control.value.length > 0 && !( /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value))) {
      //invalid
      return {'isEmail': true};

    }else {
      return null;
    }
  }
  
  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }
  patchValue() {
    //console.log('This.patch');
    //console.log(this.executiveData);
    this.editCallcenterExecutiveForm.patchValue({
      id:this.executiveData['id'],
      countryHeadName:this.executiveData['countryHeadName'],
      distributorName:this.executiveData['distributorName'],
      executiveName: this.executiveData['name'],
      contact: this.executiveData['mobileNo'],
      email:this.executiveData['email'],
      alternateMobileNo:this.executiveData['alternateMobileNo'],
      distributorId:this.executiveData['distributorId'],
    })

    if(this.selectedCountry) {
      this.countryList.forEach(countryElement => {
        if(countryElement.countryName == this.selectedCountry) {
          this.minLength = countryElement.mobileNumberLength['min'];
          this.maxLength = countryElement.mobileNumberLength['max'];
        }
      });
      this.editCallcenterExecutiveForm.get('alternateMobileNo').enable();
    } else {
      this.editCallcenterExecutiveForm.get('alternateMobileNo').disable();
      this.editCallcenterExecutiveForm.get('alternateMobileNo').reset('');
    }
  }

  getCountryList(data) {
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
    });
  }

  editCallcenterExecutive() {
    this.spinner.hide();
    let data = {
      "id":this.editCallcenterExecutiveForm.value.id,
      "name": this.editCallcenterExecutiveForm.value.executiveName,
      "mobileNo": this.editCallcenterExecutiveForm.value.contact,
      "email": this.editCallcenterExecutiveForm.value.email,
      "alternateMobileNo": this.editCallcenterExecutiveForm.value.alternateMobileNo,
      "distributorId": this.editCallcenterExecutiveForm.value.distributorId
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.editcallcenterexecutive(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/callcenterExecutive'],{queryParams:{id:2}});
      this.editCallcenterExecutiveForm.reset();
    },error => {
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }
}
