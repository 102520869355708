import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelServiceService } from '../../excel-service.service';
import { DateService } from '../../date.service';
const FILE_NAME = "Video Verbatim Report Export";
import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
import * as FileSaver from "file-saver";
declare var $: any;
@Component({
  selector: 'app-video-verbatim-report',
  templateUrl: './video-verbatim-report.component.html',
  styleUrls: ['./video-verbatim-report.component.css']
})
export class VideoVerbatimReportComponent implements OnInit {
  userDetails = { 'usertype': '', 'sub': '','countryDetails':'' };
  constructor(public apiService: ApiService, public router: Router, private spinner: NgxSpinnerService, public dateService: DateService, public excelService: ExcelServiceService) {
    $('#videoModal').on('hidden.bs.modal', function () {
      //console.log('STop VIde')
    });
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
  }
  videoverbatimReport = [];
  startDateFilter: any;
  endDateFilter: any;
  query = {};
  imageAttachment = [];
  requestVideo;
  hasMorePrevious = false;
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  selectedCountry = '';
  countryList = [];  
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public endDateOptions: IMyDpOptions = {
     // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    componentDisabled: true,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  IsfilterDisabled=false;
  ngOnInit() {
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    if (this.userDetails.usertype == 'Admin') {
      this.getCountryList();
    }else
    if (this.userDetails.usertype == 'CountryHead') 
    {
    var counrtyDetails=JSON.parse(this.userDetails.countryDetails)
    this.countryList=counrtyDetails;
    this.selectedCountry=this.countryList[0].countryName;
    
    }
     else {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
    }
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      // By default
      if (response.content.payLoad.length > 0) {
        // this.selectedCountry = this.countryList[0].countryName;
        this.filterChanged('next');
        //console.log(this.countryList, "this.getCountryList");
      }
    }, error => {
      this.spinner.hide();
    });
  }

  getVideoverbatimReport(state) {
    this.spinner.show();
    this.videoverbatimReport = [];
    this.apiService.getServiceRequest(this.query).subscribe(response => {
      this.spinner.hide();
      //console.log(response);
      this.videoverbatimReport = [];
      if (response['content'].payLoad.length > 0) {
        // this.videoverbatimReport = response['content'].payLoad;

        response['content'].payLoad.forEach(element => {
          element.imageAttachment = [];
          if (element.attachments && element.attachments.length > 0) {
            element.attachments.forEach(attachmentElement => {
              attachmentElement.serviceRequestId = element.id;
              if (attachmentElement.contentType.startsWith('image')) {
                element.hasImage = true;
                element.imageAttachment.push({ url: attachmentElement.url, fileName:attachmentElement.fileName, contentType:attachmentElement.contentType});
                // element.imageAttachment.push({url:'http://219.91.243.80/ecataloguestaging/api/images/image/ZGtTbkkxUG9jYTBhSXVKeUFEZ1Zwdz09'});
              } else if (attachmentElement.contentType.startsWith('video')) {
                //Check for video
                if (attachmentElement.nativeVerbatimText) {
                  //View Verbatim
                  element.hasVerbatim = true;
                  element.verbatimText = attachmentElement.nativeVerbatimText;
                  //console.log(element);
                  //Populate array only if video is uploaded in service request.
                  this.videoverbatimReport.push(element);
                }
              }
            });
          }
        });
      }

      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else
          {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if(this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults)
        {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else
        {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  clickOnFilter()
  {
    this.currentPage = 1;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state) {
    const filters = [];
    if(this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    filters.push({
      "member" :'queryType',
      "value":'verbatimReport',
      "operation": "EqualTo"
    })
    if(this.startDateFilter !=null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member":"fromDate",
        "value":this.dateService.simpleDate(this.startDateFilter),
        "operation":"GreaterThanOrEqualTo"
      })
    }
    if(this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member":"toDate",
        "value":this.dateService.simpleDate(this.endDateFilter),
        "operation":"LessThanOrEqualTo"
      })
    }
    this.query = {
      "filters" :filters,
      "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    this.getVideoverbatimReport(state);
  }

  resetFilter() {
    if(this.userDetails.usertype ==  'Admin') {
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.imageAttachment = [];
      this.requestVideo = [];
      this.requestVideo = "";
      this.selectedCountry = "";
      this.currentPage = 1;
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.selectedRecord = "20";
      this.videoverbatimReport = [];
      this.query = {};
    } else {
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.imageAttachment = [];
      this.requestVideo = [];
      this.requestVideo = "";
      this.currentPage = 1;
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.selectedRecord = "20";
      this.videoverbatimReport = [];
      this.query = {};
    } 
  }

  viewImage(serviceRequest) {
    this.imageAttachment = [];
    //console.log(serviceRequest);
    this.videoverbatimReport.forEach(element => {
      if(element.id == serviceRequest.id) {
        this.imageAttachment = element.imageAttachment;
        this.imageAttachment[0]['active'] = true;
      }
    });
  }

  previousClicked(index) {
    //console.log('previous');
    //console.log(index);
    //console.log(this.imageAttachment);
    this.imageAttachment.forEach((element) => {
      element.active = false;
      if(index-1 >= 0) {
        if(this.imageAttachment[index - 1]) {
          this.imageAttachment[index - 1].active = true;
        }
      } else {
        this.imageAttachment[this.imageAttachment.length - 1].active = true;
      }
    });
  }

  nextClicked(index) {
    //console.log('next');
    //console.log(index);
    //console.log(this.imageAttachment);
    this.imageAttachment.forEach((element) => {
      element.active = false;
      if(index+1 < this.imageAttachment.length) {
        if(this.imageAttachment[index + 1]) {
          this.imageAttachment[index + 1].active = true;
        } 
      } else {
        this.imageAttachment[0].active = true;
      }
    });
  }

  viewVideo(id, requestAttachment) {
    this.requestVideo = "";
    requestAttachment.forEach(element => {
      if(element.contentType.startsWith('video')) {
        element.requestIdentificationNo = id;
        this.requestVideo = element;
        $('#viewvideo_ID').remove();
        this.requestVideo = element;
        var video = $('<video />', {
          id: 'viewvideo_ID',
          src: this.requestVideo.url,
          type: 'video/mp4',
          width:"100%",
          height:"280px",
          controls: true
        });
        video.appendTo($('#reportview_ID'));
        $('#videoModal').modal('show');
      }
    });
    //console.log(this.requestVideo);
  }


  onstartDatechange(event) {
    //console.log(event);
    if(event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField:true,
        editableDateField:false,
        componentDisabled:false,
        disableUntil:{year: event.date.year, 
          month: event.date.month, 
          day: event.date.day}
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate; 
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField:true,
        editableDateField:false,
        componentDisabled:true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
    }
  }

  onendDatechange(event) {
    if(event.jsdate != null) {
      this.endDateFilter = event;
    } else {
      this.endDateFilter = '';
    }
  }
  

  private getExportableItems() {
    if (this.videoverbatimReport && this.videoverbatimReport.length > 0) {
      return this.videoverbatimReport.map((item,index) => {
        return {
          "Sr No.":index+1,
          "Request ID": item.requestIdentificationNo,
          "Request Date": item.requestedDate ?  moment(item.requestedDate).format('DD/MM/YYYY HH:mm') : '',
          "Customer Name": item.customerName,
          "Customer Contact No.": item.customerContactNumber,
          "Technician Name":item.assignedMechanic,
          "Technician Contact No.":item.mechanicContactNumber,
          "Verbatim":item.verbatimText,
        };
      });
    } else {
      return [];
    }
  }

  exporttoexcel() {
    var json = this.getExportableItems();
    this.excelService.exportDocument(json, FILE_NAME)
  }

  dumpExcelReports() {

    this.spinner.show();
    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    filters.push({
      "member": 'queryType',
      "value": 'verbatimReport',
      "operation": "EqualTo"
    })
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }

    let payload = {
      "reportType": "VideoVerbatim",
      "filters": filters
    }
    this.apiService.dumpExcelReports(payload)
      .subscribe((obj: any) => {
        console.log(obj);
        let content_disposition = obj['content-disposition'];
        let file_name = this.getFilename(content_disposition);
        const blob: any = new Blob([obj.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let link = document.createElement("a");

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        }

        else if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", file_name != '' ? file_name : 'VideoVerbatimExcelReport');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });

  }

  getFilename(content_disposition: any): string {
    let file_name = '';
    if (content_disposition != '') {
      let arr = content_disposition.split(';');
      if (arr.length > 0) {
        if (arr[1].includes('filename')) {
          file_name = arr[1].split('=')[1];
        }
      }
    }
    return file_name;
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    //console.log(obj, " state");
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }

  closeModal() {
    //console.log('closssssssseeeee')
    $( "div" ).remove( "#viewvideo_ID" );
    $('#videoModal').modal('hide');
    // $('#verbatimVideo').pause();
  }

  download(url, fileName)
  {
    // //console.log(url, fileName);
    // this.apiService.exportVideo(url).subscribe(response => {
    //   //console.log(response);
    // }, error => {
    //   //console.log(error);
    //   this.spinner.hide();
    // })
    return this.excelService.saveAsVideoFile(url, fileName)
  }

  downloadContent(url,blobName,contentType) {
    console.log(contentType)
    console.log(blobName)
    console.log(url)
    if(blobName) {
        this.spinner.show();
        this.apiService.exportContent(blobName).subscribe(response => {
          console.log(response);
          const data: Blob = new Blob([response], { type: contentType });
          this.spinner.hide();
          FileSaver.saveAs(
            data,
            blobName
          );
        }, error => {
          this.spinner.hide();
          //console.log(error);
        })
      // return this.excelService.saveAsImageFile(blobName,contentType)
    }
  }

  onChangeCountry(){
    if (this.userDetails.usertype == 'CountryHead')
     {           
      if(this.selectedCountry=="")
      {
        this.IsfilterDisabled=true;

      }else{
        this.IsfilterDisabled=false;
      }
    }
    
  }
}
