import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-distributor-list',
  templateUrl: './distributor-list.component.html',
  styleUrls: ['./distributor-list.component.css']
})
export class DistributorListComponent implements OnInit {
  distributorList = [];
  success = { 'description': [] };
  constructor(public apiService: ApiService, private router: Router, private spinner: NgxSpinnerService, private route: ActivatedRoute, private location: Location) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if (params['id'] == 1) {
        this.location.replaceState("distributors");
        this.success.description = [];
        this.success.description.push('Distributor Added Successfully.');
        var scope = this;
        setInterval(function () {
          scope.success.description = [];
        }, 5000);
      } else if (params['id'] == 2) {
        this.location.replaceState("distributors");
        this.success.description = [];
        this.success.description.push('Distributor Updated Successfully.');
        var scope = this;
        setInterval(function () {
          scope.success.description = [];
        }, 5000);
      }
    });
  }
  userDetails = { 'usertype': '', 'sub': '','countryDetails':'' };
  selectedUser = {};
  countryList = [];
  countryheadList = [];
  selectedCountry = "";
  selectedStatus = "";
  distributorName = "";
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  ngOnInit() {
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      
      
      // this.getCountryList();
      this.getFilters();
    }

  }

  clickOnFilter() {
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.callList('next');
  }

  callList(state) {
    if (this.userDetails.usertype == 'Admin')
    {
      this.getDistributorbyAdmin(state);
    } 
    else 
    {
      //when userType is Country head.
      //patch country head name , language & country by logged in user details.
      // this.getDistributorByCountryhead(state);
     // this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.getDistributorByCountryhead(this.userDetails['userid']);

      var counrtyDetails=JSON.parse(this.userDetails.countryDetails)
      this.countryList=counrtyDetails;
    }
  }

  getFilters() {
    if (this.userDetails.usertype == 'Admin') 
    {
      this.getCountryList();
    } 
    else 
    if (this.userDetails.usertype == 'CountryHead') 
    {
      //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      // this.getCountryheadList();
      this.currentPage = 1;
      this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
      // this.selectedRecord = "20";
      //console.log(this.hasMoreResults)
      this.callList('next');
    }
  }

  resetFilters() {
    if (this.userDetails.usertype == 'Admin') {
      this.selectedCountry = '';
      this.distributorList = [];
    }
    this.selectedStatus = '';
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.selectedRecord = "20";
    this.query = {};
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      this.currentPage = 1;
      this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
      // this.selectedRecord = "20";
      //console.log(this.hasMoreResults)
      this.callList('next');
    }, error => {
      this.spinner.hide();
    });
  }

  // getCountryheadList() {
  //   //console.log(this.countryheadList);
  //   let query = {}
  //   if (this.selectedCountry) {
  //     query = {"filters": [
  //       {
  //         "member": "country",
  //         "value": this.selectedCountry,
  //         "operation": "EqualTo"
  //       }
  //     ]}
  //   }
  //   this.countryheadList = [];
  //   this.spinner.show();
  //   this.apiService.getCountryhdList(query).subscribe(response => {
  //     //console.log(response);
  //     this.spinner.hide();
  //     this.countryheadList = response['content'];
  //     if (this.countryheadList.length > 0) {
  //       // this.selectedCountryhead = this.countryheadList[0].id;
  //       // this.onselectCountryhead(this.selectedCountryhead);
  //       // this.callList('next');
  //       //console.log(this.countryheadList);
  //     }
  //   }, error => {
  //     this.spinner.hide();
  //     //console.log(error);
  //   })
  // }

  saveTokens(state, response) {
    if (response['content'].queryOptions) {
      if (state == 'next') {
        //console.log(state);
        if (response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "ifffffffffff");
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
        let continuationToken = [];
        continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
        this.continuationToken = continuationToken;
        this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
      }
      else {
        this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
      }
    }
  }

  getDistributorbyAdmin(state) {
    //console.log(this.distributorList);
    this.query = {};
    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    if (this.selectedStatus != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus,
        "operation": "EqualTo"
      })
    }
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }

    this.spinner.show();
    this.apiService.getDistributorbyAdmin(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      this.saveTokens(state, response);
      //console.log(this.distributorList);
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  getDistributorByCountryhead(state) {
    //console.log(this.distributorList);
    this.query = {};
    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    if (this.selectedStatus != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus,
        "operation": "EqualTo"
      })
    }
    // this.query = {
    //   "filters":filters
    // }
    this.query = {
      "options": {
        "filters": filters,
        "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
        "itemsPerPage": this.selectedRecord,
        "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
        "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
      },
      "countryHeadId": localStorage.getItem('userId')
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(this.query).subscribe(response => {
      //console.log(response);
      this.distributorList = response['content'].payLoad;
      this.saveTokens(state, response);
      // this.distributorList.forEach(element => {
      //   element.active = true;
      // });
      this.spinner.hide();
      //console.log(this.distributorList);
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  addDistributor() {
    this.router.navigate(['/distributors/add']);
  }

  editDistributor(distributordetails) {
    //console.log(distributordetails);
    this.router.navigate(['/distributors/edit'], { queryParams: { id: distributordetails.id } });
  }

  changeStatus(distributorId) {
    //console.log(distributorId, "distributor");
    this.spinner.show();
    this.apiService.changeStatus(distributorId).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.currentPage = 1;
      this.query = {};
      this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
      this.callList('next');
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  openModal(data) {
    //console.log(data);
    $('#resetPassword').modal('show');
  }

  closeModal() {
    $('#resetPassword').modal('hide');
    this.success.description = [];
    this.success.description.push(' Password Reset Successfully.');
    var scope = this;
    setInterval(function () {
      scope.success.description = [];
    }, 5000);
    this.callList('next');
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.callList('next');
  }

  pageNavigated(obj) {
    //console.log(obj.state)
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.callList(obj.state);
  }
}
