import { Component, OnInit , Output, EventEmitter } from '@angular/core';
import { ApiService } from '../api.service'; 
import { RouterModule, Routes,Router,ActivatedRoute } from '@angular/router';
import {TransfereService} from '../transfere.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
declare var $:any;
@Component({
  selector: 'app-mechanic-list',
  templateUrl: './mechanic-list.component.html',
  styleUrls: ['./mechanic-list.component.css']
})
export class MechanicListComponent implements OnInit {

  message : any;
  flaId :any;
  selectedmechanic : any;
  serviceStationId = '';
  ServiceList: any;
  mechanicList = [];
  userDetails = {'usertype':'','sub':'','countryDetails':''};
  selectedUser = {};
  query = {};
  success = {'description':[]};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  selectedStatus = '';
  countryList = [];
  selectedCountry = '';
  countryheadList = [];
  selectedCountryhead = '';
  distributorList = [];
  selectedDistributor = '';
  selectedDistributorName = '';
  constructor(public apiService:ApiService,private router : Router,public transfereservice:TransfereService ,private spinner: NgxSpinnerService,private route: ActivatedRoute,private location: Location) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if( params['id'] == 1) {
        this.location.replaceState("technician");
        this.success.description = [];
        this.success.description.push('Technician Added Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      } else if( params['id'] == 2) {
        this.location.replaceState("technician");
        this.success.description = [];
        this.success.description.push('Technician Updated Successfully.');
        var scope = this;
        setInterval(function(){
          scope.success.description = [];
        }, 5000);
      }
    });
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
   }

  ngOnInit() {
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.callList();
    // this.getCountryList();
  }

  

callList() {
  if(this.userDetails.usertype == 'Admin') {
    // let query = {"filters":[{
    //   "member": "distributorId",
    //   "value": this.selectedDistributor,
    //   "operation": "EqualTo"
    // }]}
    // // let query = {}
    // this.getServiceStations(query);
    this.getCountryList();
    
  } else if(this.userDetails.usertype == 'CountryHead') {
    //when userType is CountryHead.
    // let data = {
    // "filters": [
    //   {
    //     "member": "country",
    //     "value": this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
    //     "operation": "EqualTo"
    //   }]
    // }
    // this.getServiceStations(data);

    this.countryList=JSON.parse(this.userDetails.countryDetails)
    this.selectedCountry =this.countryList[0].countryName;// this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];

    //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
    this.selectedCountryhead = this.userDetails['given_name'];
    this.getCountryheadList();
    this.getDistributorByCountryhead(this.userDetails['userid']);
  } else if(this.userDetails.usertype == 'Distributor'){
    //when userType is Distributor.
    // let data = {
    //   "filters": [
    //     {
    //       "member": "distributorId",
    //       "value": this.userDetails['userid'],
    //       "operation": "EqualTo"
    //     }]
    //   }
    // this.getServiceStations(data);
    this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
    this.selectedCountryhead = this.userDetails['countryHeadName'];
    this.selectedDistributor = this.userDetails['userid'];
    this.selectedDistributorName = this.userDetails['given_name'];
    this.filterChanged('next');
  } else if(this.userDetails.usertype == 'FrontLineAdvisor'){
     this.flaId = this.userDetails['userid']
     //alert(this.flaId);
    //when userType is FrontLineAdvisor.
    // let data = {
    // "filters": [
    //   {
    //     "member": "flaId",
    //     "value": this.userDetails['userid'],
    //     "operation": "EqualTo"
    //   }]
    // }
    // this.getServiceStations(data);
    this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
    this.selectedCountryhead = this.userDetails['countryHeadName'];
    this.selectedDistributor = this.userDetails['distributorId'];
    this.selectedDistributorName = this.userDetails['distributorName'];
    this.filterChanged('next');
  }
  else if(this.userDetails.usertype ==  'CallCenterExecutive') {
  this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  this.selectedCountryhead = this.userDetails['countryHeadName'];
  this.selectedDistributor = this.userDetails['distributorId'];
  this.selectedDistributorName = this.userDetails['distributorName'];
  this.filterChanged('next');
}
// this.getMechanicList('next');
}

getServiceStations() {
  this.ServiceList = [];
  this.mechanicList = [];
  // this.serviceStationId = '';
  let query = {"filters":[{
    "member": "distributorId",
    "value": this.selectedDistributor,
    "operation": "EqualTo"
  }]}
  this.apiService.getServicestations(query).subscribe((response: any) => {
    //console.log(response, "this.ServiceList");
    this.ServiceList = response.content.payLoad;
    // this.getMechanicList('next');
    // this.ServiceList.length != 0 ? this.serviceStationId = this.ServiceList[0].id : '';
    // this.getMechanicList('next');
    // response.content.payLoad.forEach(element => {
    //   if((element.address.country != "United States") && (element.address.country != "India"))
    //   {
    //     this.ServiceList.push(element);
    //   }
    // });
    //console.log(this.ServiceList, "this.ServiceList");
  },error => {
  })
}

clickOnFilter()
{
  this.currentPage = 1;
  this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
  // this.selectedRecord = "20";
  //console.log(this.hasMoreResults)
  this.filterChanged('next');
}

filterChanged(state)
{
  let query = {"filters":[{
    "member": "distributorId",
    "value": this.selectedDistributor,
    "operation": "EqualTo"
  }]}
  this.getServiceStations();
  //console.log(this.mechanicList);
  this.spinner.show();
  const filters = [];
  if(this.serviceStationId) {
    filters.push(
    {
      "member": "serviceStationId",
      "value": this.serviceStationId,
      "operation": "EqualTo"
    })
  }
  else
  {
    filters.push({
      "member": "distributorId",
      "value": this.selectedDistributor,
      "operation": "EqualTo"
    })
  }
  
  if(this.selectedStatus) {
    filters.push({
      "member":"status",
      "value":this.selectedStatus
    })
  }
  if(this.selectedStatus) {
    filters.push({
      "member":"status",
      "value":this.selectedStatus
    })
  }
  if(this.flaId) {
    filters.push({
      "member":"flaId",
      "value":this.flaId
    })
  }
  this.query = {
    "filters" :filters,
    "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
    "itemsPerPage": this.selectedRecord,
    "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
    "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
  }
  this.getMechanicList('next');
}

  getMechanicList(state) {
    
    this.apiService.getMechanic(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.mechanicList = response['content'].payLoad;
      if(response['content'].queryOptions)
      {
        if(state == 'next')
        {
          //console.log(state);
          if(response['content'].queryOptions.hasMoreResults)
          {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else
          {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if(this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults)
        {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({'pageNo':response['content']['queryOptions'].pageNumber + 1,'token':response['content']['queryOptions'].continuationToken,'hasMoreResults':response['content']['queryOptions'].hasMoreResults});
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else
        {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      //console.log(this.mechanicList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      if(this.countryList.length > 0) {
        this.selectedCountry = this.countryList[0].countryName;
        // this.getCountryheadList();
        //console.log(this.countryList, "this.getCountryList");
      }
    },error=>{
      this.spinner.hide();
    });
  }

  getCountryheadList() {
    //console.log(this.countryheadList);
    let query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ]
    }
    this.countryheadList = [];
    this.selectedCountryhead = "";
    this.spinner.show();
    this.apiService.getCountryhdList(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      if(this.userDetails.usertype==="CountryHead")
      {
        this.countryheadList = response['content'];
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.countryheadList[0].id)

      }else{
      this.countryheadList = response['content'];
      this.countryheadList.length != 0 ? this.selectedCountryhead = this.countryheadList[0].id : '';
      this.onselectCountryhead(this.selectedCountryhead);
    }
      // this.callList('next');
      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  onselectCountryhead(countryHeadId) {
    //console.log(countryHeadId);
    /*if user id admin*/
    if(this.userDetails['usertype'] == 'Admin') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId);
    }
    if (this.userDetails['usertype'] == 'CountryHead') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId);
    }
  }

  getDistributorByCountryhead(countryHeadId) {
    //console.log(this.distributorList);
    this.ServiceList = [];
    this.mechanicList = [];
    // this.serviceStationId = '';
    let data = {
      "options" :{},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      this.distributorList.length != 0 ? this.selectedDistributor = this.distributorList[0].id : '';
      
      this.getServiceStations();
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  addMechanic(){
    this.router.navigate(['/technician/add']);
  }

  editmechanicdetails(mechanic){
    this.selectedmechanic =mechanic; 
    // this.transfereservice.setData(this.selectedmechanic);
    //console.log(mechanic,"mechanic");
    this.router.navigate(['/technician/edit'],{queryParams:{id:mechanic.id}});
  }

  changeStatus(mechanicId) {
    //console.log(mechanicId,"mechanic");
    this.spinner.show();
    this.apiService.changeStatus(mechanicId).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.currentPage = 1;
      this.query = {};
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.filterChanged('next');
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  openModal(data) {
    //console.log(data);
    $('#resetPassword').modal('show');
  }

  closeModal(){
    $('#resetPassword').modal('hide');
    this.success.description = [];
    this.success.description.push(' Password Reset Successfully.');
    var scope = this;
    setInterval(function(){
      scope.success.description = [];
    }, 5000);
    this.filterChanged('next');
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    this.query = {};
    //console.log(obj.state)
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }

  closeAlert() {
    this.success.description = [];
  }

  resetFilter() {
    if(this.userDetails.usertype ==  'Admin') {
      this.selectedCountry = "";
      this.selectedCountryhead = "";
      this.selectedDistributor = "";
      this.serviceStationId = "";
      this.currentPage = 1;
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.selectedRecord = "20";
      this.mechanicList = [];
      this.countryheadList = [];
      this.distributorList = [];
      this.ServiceList = [];
      this.query = {};
    } else if(this.userDetails.usertype ==  'CountryHead') {
      this.selectedDistributor = "";
      this.serviceStationId = "";
      this.ServiceList = [];
      this.currentPage = 1;
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.selectedRecord = "20";
      this.mechanicList = [];
      this.query = {};
    } else if(this.userDetails.usertype ==  'Distributor') {
      this.serviceStationId = "";
      this.ServiceList = [];
      this.currentPage = 1;
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.selectedRecord = "20";
      this.mechanicList = [];
      this.query = {};
    } else {
      this.currentPage = 1;
      this.continuationToken = [{'pageNo':1,'token':null,'hasMoreResults':false}];
      this.selectedRecord = "20";
      this.mechanicList = [];
      this.query = {};
    } 
  }
}
