import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import {Observable, of,throwError} from 'rxjs';
import {Router} from "@angular/router";
import {catchError, tap, filter} from "rxjs/internal/operators";


@Injectable()
export class InterceptorService implements HttpInterceptor{
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

   return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      tap((evt:HttpResponse<any>)  => {
        
    }),catchError((error, caught) => {
      //intercept the respons error and displace it to the console
      //console.log(error);
      this.handleAuthError(error)
      return throwError(error);
    }) as any);
  }


  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401) {
      //navigate /delete cookies or whatever
      var userId = localStorage['userId'];
      localStorage.clear();
      localStorage.setItem('userId', userId);
      this.router.navigate(['/login']);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
    }
    throw err;
  }

  
  constructor(private router : Router) { }
}
