import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { FormControl, Validators, FormGroup, FormBuilder, NgForm, MaxLengthValidator } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from '../api.service';
import { MessagingService } from '../shared/messaging.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { AES } from "crypto-js";
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import base64url from "base64url";

declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  userType = ["FLA", "Call center", "distributor", "country head"];
  userDetails: any;
  servicestationList: any;
  error = { 'description': [] };
  icon = "fa fa-fw fa-eye field-icon";
  isImageLoading: boolean = false;
  constructor(private formBuilder: FormBuilder, private router: Router,
    public apiService: ApiService, public messagingService: MessagingService,
    private spinner: NgxSpinnerService, private translate: TranslateService) {
    if (localStorage.getItem("languageCode")) {
      this.translate.setDefaultLang(localStorage['languageCode']);
      this.translate.use(localStorage['languageCode']).subscribe((res) => {
        console.log(res);
      }, error => {
        console.log(error);
        this.translate.setDefaultLang('en');
        this.translate.use('en');
      });
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }

  }

  imageToShow: any;
  captchaId: any;

  message;
  nonce;
  path;
  privateKey; // ...
  hashDigest;
  hmacDigest;
  ngOnInit() {
    // Encrypt
    if (localStorage['access_token'] != null) {
      this.router.navigate(['/serviceStations']);
    }
    var data = 'here is my text'
    var secretKey = 'cbTNs8PUY8bpFm6fT2S5ks5bqv5GqjXv';
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
    //console.log(ciphertext);
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), secretKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //console.log('decryptedData -->' + decryptedData);
    this.isImageLoading = true;
    this.getCaptcha();
    $("form").attr('autocomplete', 'off'); // Switching form autocomplete attribute to off
    this.loginForm = this.formBuilder.group({
      mobileNo: ['', [Validators.required, Validators.minLength(7), this.numberValidator]],
      password: ['', Validators.required],
      captchaCode: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])]
    });
    // localStorage['access_token'] = 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZGl0eWEiLCJnaXZlbl9uYW1lIjoiYWRpdHlhIiwianRpIjoiYmRhOTg2ODItOTU3NC00YjI0LTg0MjMtODg4ZTVkNmVlY2EzIiwidXNlcmlkIjoiZWVmNDA3N2EtYTc1MC00MTE3LWFlODktMDNkMjYyZDMxY2YwIiwidXNlcnR5cGUiOiJGcm9udExpbmVBZHZpc29yIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbW9iaWxlcGhvbmUiOiI5MTIzMjk0ODM3IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiI5MTIzMjk0ODM3IiwiaXNzIjoiaHR0cDovL3NoZXphcnRlY2guY29tL2F1dGgvaXNzdWVyIiwiYXVkIjoiZG9vcnN0ZXBzZXJ2aWNlIn0.iQa2RWYCQMAk995WyPqOQ1-4w3sbiwizacBHzx84UtMwKpjX0EDaIiwm0CNVpBvFExsXjfkkMhowZSyOQko9tg'
  }

  getCaptcha() {
    this.spinner.show();
    this.apiService.getCaptcha().subscribe((response: any) => {
      //console.log(response);
      this.imageToShow = "data:image/png;base64," + response['body']['captchBase64Data'];
      this.captchaId = response['body']['captchaId'];
      //console.log(this.imageToShow);
      console.log(this.captchaId);
      this.isImageLoading = false;
      this.spinner.hide();
    }, error => {
      this.isImageLoading = false;
      //console.log(error);
      this.spinner.hide();
    });
  }

  signIn(user) {
    //console.log(this.loginForm);
    //console.log("SignIn");
    //console.log(this.loginForm.get('mobileNo').value);
    //console.log(this.loginForm.get('password').value);
    this.spinner.show();
    this.error.description = [];
    this.apiService.login({
      "mobileNo": this.loginForm.get('mobileNo').value,
      "password": this.loginForm.get('password').value,
      "captchaCode": this.loginForm.get('captchaCode').value,
      "captchaId": this.captchaId
    }).subscribe((response: any) => {
      //console.log(response);
      /* Encryption Decryption to be done */
      if (localStorage.getItem('userId') != null) {
        localStorage.clear();
        localStorage.setItem('isRefreshed', 'true');
      }

      localStorage.setItem('access_token', response.content.jwtToken);
      this.apiService.getuserdetails().subscribe((decryptedResponse: any) => {
        //console.log(decryptedResponse); 
        /*After token is decrypted use it*/        
        this.spinner.hide();
        localStorage.setItem('userId', decryptedResponse.content.userid);
        localStorage.setItem('mobileNo', this.loginForm.value.mobileNo);
        // get verfiy token and user details
        this.userDetails = decryptedResponse.content;
        localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
        console.log(JSON.stringify(this.userDetails));
        localStorage['languageCode'] = this.userDetails.languagecode ? this.userDetails.languagecode : 'en';
        if (localStorage.getItem("languageCode")) {
          this.translate.setDefaultLang(localStorage['languageCode']);
          this.translate.use(localStorage['languageCode']).subscribe((res) => {
            //console.log(res);
          }, error => {
            console.log(error);
            this.translate.setDefaultLang('en');
            this.translate.use('en');
          });
        } else {
          this.translate.setDefaultLang('en');
          this.translate.use(localStorage['languageCode']).subscribe((res) => {
            //console.log(res);
          }, error => {
            console.log(error);
            this.translate.setDefaultLang('en');
            this.translate.use('en');
          });
        }
        this.messagingService.updateToken(localStorage.getItem("userId"), localStorage.getItem("fcmToken"));
        if (this.userDetails.usertype == 'CountryHead' || this.userDetails.usertype == 'FrontLineAdvisor' || this.userDetails.usertype == 'CallCenterExecutive' || this.userDetails.usertype == 'Distributor') {
          this.getService_stations();
        }
        if (this.userDetails.enforcePasswordChange == true) {
          this.router.navigate(['changepassword'], { replaceUrl: true });
        } else if (this.userDetails.usertype == 'FrontLineAdvisor') {
          this.router.navigate(['/all-services'], { replaceUrl: true });
        } else if(this.userDetails.usertype == 'CallCenterExecutive'){
          this.router.navigate(['/serviceHistory'], { replaceUrl: true });
        }
         else {
          this.router.navigate(['/customer-registration'], { replaceUrl: true });
        }
        
      }, error => {
        this.spinner.hide();
        this.loginForm.reset('');
        this.getCaptcha();
        //console.log(error);
        //console.log(error.error.errors);
        this.error.description = [];
        if (error.error.errors && error.error.errors.length > 0) {
          this.error.description.push(error.error.errors[0]['description']);
        } else {
          this.error.description.push('Cannot Login, Please Try Again Later.');
        }

      });

    }, error => {
      this.spinner.hide();
      this.loginForm.reset('');
      this.getCaptcha();
      //console.log(error);
      //console.log(error.error.errors);
      this.error.description = [];
      if (error.error.errors && error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      } else {
        this.error.description.push('Cannot Login, Please Try Again Later.');
      }

    })
    // this.router.navigate(['/createRequest']);
  }

  public numberValidator(control: FormControl) {
    ////console.log(control);
    let isNotANumber = (/^\d+$/.test(control.value));
    ////console.log(isNotANumber);
    if (control.value && isNotANumber == true) {
      //valid
      return null;
    } else if (control.value && !isNotANumber) {
      //invalid 
      return { 'isNumber': true };
    } else {
      return null;
    }
  }


  viewPassword() {
    //console.log('viewPassword');
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
      input.attr("type", "text");
      this.icon = "fa-eye fa-eye-slash";
    } else {
      input.attr("type", "password");
      this.icon = "fa fa-fw fa-eye field-icon";
    }
  }

  getService_stations() {
    if (this.userDetails.usertype == "CountryHead") {
      this.getServicestationByID(this.userDetails["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country"], 'CountryHead');
    } else if (this.userDetails.usertype == "FrontLineAdvisor") {
      this.getServicestationByID(this.userDetails.distributorId, 'FrontLineAdvisor');
    }
    else if (this.userDetails.usertype == 'Distributor') {
      this.getServicestationByID(localStorage.getItem("userId"), 'Distributor');
    }
    else if (this.userDetails.usertype == 'CallCenterExecutive') {
      this.getServicestationByID(this.userDetails.distributorId, 'CallCenterExecutive');
    }
  }

  getServicestationByID(filter, role) {
    //console.log(filter);
    this.servicestationList = [];
    let query = {};
    if (role == 'CountryHead') {
      query = {
        "filters": [
          {
            "member": "country",
            "value": filter,
            "operation": "EqualTo"
          }
        ]
      }
    } else {
      query = {
        "filters": [
          {
            "member": "distributorId",
            "value": filter,
            "operation": "EqualTo"
          }
        ]
      }
    }
    this.spinner.show();
    
    this.apiService.getServicestations(query).subscribe(response => {
      //console.log(response,".......servicestationList");
      this.spinner.hide();
      this.servicestationList = response['content'].payLoad;
      //console.log(this.servicestationList);
      this.servicestationList.forEach(serviceStation => {
        console.log(serviceStation,"serviceStation.id");
        //console.log(serviceStation['id'],"serviceStation.id");
       
        
        if (serviceStation['id'] && localStorage.getItem("fcmToken") != null) {
          this.messagingService.subscribeTokenToTopic(serviceStation['id']);
        }
        // else { 
        //THis is creating issue for newly created users, as new user do not have any service staions,
        //they are not able to login they directly gets out to login page.sssss
        //   this.router.navigate(['/login', { replaceUrl: true }]);
        // }
      });
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }
}
