import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../api.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
declare var google;
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-edit-service-station',
  templateUrl: './edit-service-station.component.html',
  styleUrls: ['./edit-service-station.component.css']
})
export class EditServiceStationComponent implements OnInit {
  userDetails = {};
  servicecenterId;
  editServiceCenterForm: any;
  @ViewChild('editstationmap') editstationmapElement: ElementRef;
  editstationmap: any;
  map: any;
  marker: any;
  servicestation: any;
  geocoder = new google.maps.Geocoder;
  currentLng;
  currentLat;
  city = '';
  state = '';
  country = '';
  postal_code = '';
  autocompleteItems = [];
  servicecenterList = [];
  servicecenterData = {};
  service;
  error = { 'description': [] };
  countryCode = 'In';
  countryList = [];
  maxLength = 10;
  minLength = 10;
  isCityhidden: boolean = true;
  isStatehidden: boolean = true;
  addressErr = false;
  countryheadData = {};
  constructor(public apiService: ApiService, private spinner: NgxSpinnerService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private location: Location) {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    //console.log(this.userDetails);
    this.editServiceCenterForm = this.fb.group({
      id: new FormControl('', [Validators.required]),
      countryHeadName: [''],
      distributorName: [''],
      flaName: [''],
      Name: new FormControl('', [Validators.required, this.alphabetsValidator]),
      Contact: new FormControl('', [Validators.required]),
      Address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required, this.addValidator]),
      state: new FormControl('', [Validators.required, this.addValidator]),
    })
  }
  ngOnInit() {
    this.addressErr = false;
    this.service = new google.maps.places.AutocompleteService();
    $('.dropdown').css('display', 'none');
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.servicecenterId = params['id'];
    })
    this.editServiceCenterForm = this.fb.group({
      id: new FormControl('', [Validators.required]),
      countryHeadName: [''],
      distributorName: [''],
      flaName: [''],
      Name: new FormControl('', [Validators.required, this.alphabetsValidator]),
      Contact: new FormControl('', [Validators.required]),
      Address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required, this.addValidator]),
      state: new FormControl('', [Validators.required, this.addValidator]),
    })
    this.getServiceStationList(this.servicecenterId);
    // this.getCountryList({});
  }

  public addValidator(control: FormControl) {
    let isValid = false;
    // var regex = /[^a-zA-Z ]/;
    // if(control.value && control.value.trim().length >= 3 && control.value.trim().length <= 50 && !regex.test(control.value)) {
    if (control.value && control.value.trim().length >= 2 && control.value.trim().length <= 50) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    // if(control.value && control.value.trim().length >= 3 && control.value.trim().length <= 50 && !regex.test(control.value)) {
    if (control.value && control.value.trim().length >= 3 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
      //console.log('valid');
      return null;
    } else if (control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');
      return { 'inValid': true };
    } else {
      return null;
    }
  }


  getServiceStationList(serviceStaionId) {
    //console.log(this.servicecenterList);
    let servStaionQuery={
      "filters": [
        {
          "member": "serviceStationId",
          "value": serviceStaionId,
          "operation": "EqualTo"
        }
      ]
    }
    this.apiService.getServicestations(servStaionQuery).subscribe(response => {
      console.log(this.servicecenterId);
      this.servicecenterList = response['content'].payLoad;
      this.servicecenterList.forEach(element => {
        if (element.id == this.servicecenterId) {
          this.servicecenterData = element;
          console.log(element);
          console.log(this.servicecenterData);
          this.getCountryList({});
        }
      });
    }, error => {
      //console.log(error);
    })
  }

  patchValue() {
    //console.log('+++++++patched');
    //console.log(this.countryList);
    this.getCountryheadList(this.servicecenterData['address'].country);
    this.editServiceCenterForm.patchValue({
      id: this.servicecenterData['id'],
      Contact: this.servicecenterData['contact'],
      Address: this.servicecenterData['address'].addressLine,
      Name: this.servicecenterData['serviceStationName'],
      countryHeadName: this.servicecenterData['countryHeadName'],
      distributorName: this.servicecenterData['distributorName'],
      flaName: this.servicecenterData['flaName'],
      city: this.servicecenterData['address'].city,
      state: this.servicecenterData['address'].state,
    })
    this.country = this.servicecenterData['address'].country;
    //console.log(this.editServiceCenterForm);

    this.countryList.forEach(countryElement => {
      //console.log(countryElement);
      if (countryElement.countryName == this.servicecenterData['address'].country) {
        //console.log(countryElement);
        this.minLength = countryElement.mobileNumberLength['min'];
        this.maxLength = countryElement.mobileNumberLength['max'];
      }
    });
    if (this.servicecenterData['address'].location.coordinates.length > 0) {
      this.loadMap(this.servicecenterData['address'].location.coordinates[1], this.servicecenterData['address'].location.coordinates[0])
    }
  }


  getCountryList(data) {
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      this.patchValue();
      //console.log(this.countryList, "this.getCountryList");
    }, error => {
    });
  }

  getCountryheadList(country) {
    let query = {
      "filters": [
        // {
        //   "member": "country",
        //   "value": country,
        //   "operation": "EqualTo"
        // }
      ]
    }
    this.apiService.getCountryhdList(query).subscribe(response => {
     var countryheadList = response['content'];
      if(response['content'].length > 0) {
        countryheadList.forEach(element => {         
          this.spinner.hide();
          if(element.id == this.servicecenterData['countryHeadId']) {            
            this.countryheadData = element;            
            this.countryCode=this.countryheadData['countryCode'];
          }         
        });
      }
      //this.countryCode = response['content'][0].countryCode;
      
    }, error => {
      //console.log(error);
    })
  }

  editServiceCenter() {
    //console.log(this.editServiceCenterForm);
    const data = {
      "id": this.editServiceCenterForm.get('id').value,
      "serviceStationName": this.editServiceCenterForm.get('Name').value,
      "address": {
        "addressLine": this.editServiceCenterForm.get('Address').value,
        "location": {
          "type": "Point",
          "coordinates": [this.currentLng, this.currentLat],
        },
        "city": this.editServiceCenterForm.get('city').value,
        "state": this.editServiceCenterForm.get('state').value,
        "country": this.country,
        "pincode": this.postal_code,
      },
      "contact": this.editServiceCenterForm.get('Contact').value
    }
    if (this.country) {
      this.apiService.editServicestations(data).subscribe(response => {
        //console.log(response);
        this.editServiceCenterForm.reset();
        this.router.navigate(['/serviceStations'], { queryParams: { id: 2 } });
      }, error => {
        //console.log(error);
        this.error.description = [];
        if (error.error.errors.length > 0) {
          this.error.description.push(error.error.errors[0]['description']);
        }
      })
    } else {
      this.error.description.push('Country is required.');
    }
  }

  loadMap(latitude, longitude) {
    //console.log('Load');
    //console.log('Load');
    this.currentLat = latitude;
    this.currentLng = longitude;
    this.spinner.show();
    let latLng = new google.maps.LatLng(latitude, longitude);
    this.geocoder.geocode({ 'location': latLng }, (results) => {
      if (results[0]) {
        //console.log(results[0]);
        //console.log(results[0].geometry.viewport);
        var isSameCountry = false;
        results[0].address_components.forEach(element => {
          if (element.types[0] == 'country') {
            if (element.long_name == this.country) {
              isSameCountry = true;
            } else {
              //console.log('COUNTRY DOES NOT MATCH.....');
            }
          }
        });
        //console.log(isSameCountry)
        if (isSameCountry) {
          // this.editServiceCenterForm.get('city').reset('');
          this.editServiceCenterForm.get('state').reset('');
          results[0].address_components.forEach(element => {
            if (element.types[0] == 'administrative_area_level_2') {
              //console.log(element.long_name);
              this.editServiceCenterForm.patchValue({
                city: element.long_name
              })
              this.isCityhidden = true;
              //get City
            }
            if (element.types[0] == 'administrative_area_level_1') {
              //console.log(element.long_name);
              this.editServiceCenterForm.patchValue({
                state: element.long_name
              })
              this.isStatehidden = true;
              //get State
            }
            if (element.types[0] == 'country') {
              //console.log(element.long_name);
              this.country = element.long_name;
              //get Country
            }
            if (element.types[0] == 'postal_code') {
              this.postal_code = element.long_name;
              //get Postal
            }
          });
          //console.log(this.editServiceCenterForm);
          if (this.editServiceCenterForm.get('city').value == undefined || this.editServiceCenterForm.get('city').value == "") {
            this.isCityhidden = false;
          }
          if (this.editServiceCenterForm.get('state').value == undefined || this.editServiceCenterForm.get('state').value == "") {
            this.isStatehidden = false;
          }
          this.editServiceCenterForm.patchValue({
            Address: results[0].formatted_address
          })
        } else {
          //console.log('Country Does not match');
        }
      } else {
        //console.log('No results found');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    let mapOptions = {
      center: latLng,
      zoom: 12,
      disableDefaultUI: true, // a way to quickly hide all controls
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      overviewMapControl: false,
      fullscreenControl: false,
    }

    this.map = new google.maps.Map(this.editstationmapElement.nativeElement, mapOptions);


    this.marker = new google.maps.Marker({
      map: this.map,
    });

    this.marker.bindTo('position', this.map, 'center');

    var lastValidCenter = this.map.getCenter();
    this.map.addListener('dragend', () => {
      //console.log(this.map);
      //console.log(this.marker.getPosition());
      this.map.setCenter(this.marker.getPosition());
      var lat = this.marker.getPosition().lat();
      var lng = this.marker.getPosition().lng();
      //console.log(lat);
      //console.log(lng);
      var bounds = this.map.getBounds();
      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();
      //console.log('herteeeeeeeeeeeeee are bounds');
      //console.log(ne);
      //console.log(sw);
      this.loadMap(lat, lng)
    });
  }


  getAddress() {
    this.autocompleteItems = [];
    let me = this;
    this.addressErr = false;
    this.editServiceCenterForm.get('city').reset('');
    this.editServiceCenterForm.get('state').reset('');
    // this.spinner.show()    
    var regex = /[^a-zA-Z0-9(),./\-_ ]/;
    if (this.editServiceCenterForm.get('Address').value.length >= 10 
    //&& !regex.test(this.editServiceCenterForm.get('Address').value)
    ) 
    {
      this.apiService.getPlacePredictions(this.editServiceCenterForm.get('Address').value, this.countryCode).then(result => {
        this.spinner.hide();
        //console.log(result);
        //console.log(typeof result);
        this.autocompleteItems = <Array<any>>result;
        //console.log(this.autocompleteItems);
        $('.dropdown').css('display', 'inline');
      }, error => {
        console.error(error);
        this.spinner.hide();
      });
    } else {
      this.addressErr = true;
      this.spinner.hide();
    }
  }

  chooseItem(item: any) {
    //console.log(item);
    $('.dropdown').css('display', 'none');
    this.editServiceCenterForm.patchValue({
      Address: item.description
    })
    this.autocompleteItems = [];
    var geocoder = new google.maps.Geocoder();
    //console.log(this.marker);    
    // if (this.marker && this.marker.setMap) {
    //   this.marker.setMap(null);
    // }
    geocoder.geocode({ 'placeId': item.place_id }, (results, status) => {
      //console.log(results);
      //console.log(results[0].address_components);
      results[0].address_components.forEach(element => {
       //console.log("element type 0"+element.types[0]);
        if (element.types[0] == 'administrative_area_level_2') {
          this.editServiceCenterForm.patchValue({
            city: element.long_name
          })
          // this.city = element.long_name;
          //get City
        }
        if (element.types[0] == 'administrative_area_level_1') {
          //console.log(element.long_name);
          this.editServiceCenterForm.patchValue({
            state: element.long_name
          })
          // this.state = element.long_name;
          //get State
        }
        if (element.types[0] == 'country') {
          //console.log(element.long_name);
          this.country = element.long_name;
          //get Country
        }
        if (element.types[0] == 'postal_code') {
          //console.log(element.long_name);
          this.postal_code = element.long_name;
          //get Postal
        }
      });
      if (this.editServiceCenterForm.get('city').value == undefined || this.editServiceCenterForm.get('city').value == "") {
        this.isCityhidden = false;
      }
      if (this.editServiceCenterForm.get('state').value == undefined || this.editServiceCenterForm.get('state').value == "") {
        this.isStatehidden = false;
      }
      if (status == 'OK') {
        var lat = results[0].geometry.location.lat();
        var lng = results[0].geometry.location.lng();
        //console.log(lat, lng);
        this.currentLat = lat;
        this.currentLng = lng;
        var latlng = new google.maps.LatLng(lat, lng);
        this.marker.setPosition(latlng);
        // this.loadMap(this.currentLat,this.currentLng);
      }

      //console.log(status)
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }
}
