import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RouterModule, Routes,Router } from '@angular/router';
import { MessagingService } from "../shared/messaging.service";

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  changePwdForm:FormGroup;
  error = {'description':[]};
  characters = "@%+\/'!#$^?:.(){}[]~";
  ntspecialCharacters = '`&*-_;",<>';
  constructor(private formBuilder: FormBuilder,private messagingService: MessagingService,public apiService:ApiService,private spinner: NgxSpinnerService,private router : Router) { }

  ngOnInit() {
    this.changePwdForm = this.formBuilder.group({
      oldpassword: new FormControl('', [Validators.required]),
      newpassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
      confirmpassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
    });
  }

  signIn(form:any) {

  }

  // public alphanumericValidator(control: FormControl) {
  //   // let isWhitespace = (control.value || '').trim().length === 0;
  //   let isValid = false;
  //   var regex = /[a-zA-Z0-9~!@#$%^()+?:'.\{\}\[\]\\\/]/gi;  
  //   if(control.value && control.value.trim().length >= 6) {
  //     var validLength = control.value.match(regex).length;
  //     if(control.value.trim().length == validLength) {
  //       isValid = true;
  //       //console.log('1');
  //       return isValid ? null : { 'alphanumeric': true }
  //     } else {
  //       return isValid ? null : { 'alphanumeric': false }
  //     }
  //   } else if(!control.value) {
  //     //console.log('2');
  //     return null;
  //   } else {
  //     //console.log('3');
  //     return isValid ? null : { 'alphanumeric': false }
  //   }
  // }


  
  public alphanumericValidator(control: FormControl) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = false;
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+!*^()@%&':.?~])(?!.*[|<>`"\s-_=,]).{8,20}$/;
    // //console.log(control.value.trim().match(regex));
    //console.log(regex.test(control.value));
    if(control.value && regex.test(control.value.trim())) {
      //console.log('1');
      isValid = true;
      return isValid ? null : { 'alphanumeric': true }
    } else if(!control.value) {
      //console.log('2');
      return null;
    } else {
      //console.log('3');
      return isValid ? null : { 'alphanumeric': false }
    }
  }

  changePassword() {
    //console.log(this.changePwdForm);
    this.error.description = [];
    if(this.changePwdForm.value.newpassword == this.changePwdForm.value.confirmpassword) {
      let data = {
        "userId": localStorage.userId,
        "oldPassword": this.changePwdForm.value.oldpassword,
        "newPassword": this.changePwdForm.value.newpassword
      }
      this.spinner.show();
      this.apiService.changePassword(data).subscribe(response => {
        //console.log(response);
        var userId = localStorage['userId'];
        localStorage.clear();
        localStorage.setItem('userId', userId);
        this.messagingService.requestPermission();
        this.router.navigate(['login'],{replaceUrl:true});
        this.spinner.hide();
      }, error => {
        //console.log(error);
        this.error = {'description' :[]};
        this.spinner.hide();
        if(error.error.errors.length > 0) {
          this.error.description.push(error.error.errors[0].description);
        } else {
          this.error.description.push('Current Password Entered is Invalid.')
        }
      })
    }
  }

  cancelChanges() {
    this.router.navigate(['/serviceStations']);
  }

  closeAlert() {
    this.error.description = [];
  }
}
