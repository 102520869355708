import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl, NgForm } from '@angular/forms';
import { RouterModule, Routes, Router } from '@angular/router';
import { ApiService } from '../api.service';
declare var google;
import * as moment from 'moment';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { DateService } from '../date.service';
declare var $: any; import { NgxSpinnerService } from 'ngx-spinner';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from 'constants';

@Component({
  selector: 'app-all-services',
  templateUrl: './all-services.component.html',
  styleUrls: ['./all-services.component.css']
})
export class AllServicesComponent implements OnInit {
  errorMsg = "";
  errorMsg1 = "";
  countryList = [];
  countryheadList = [];
  distributorList = [];
  mechanics = [];
  serviceRequestsAccepted = [];
  serviceRequests = [];
  serviccenterList = [];
  map: any;
  marker: any;
  cancelReason = '';
  time = '';
  audio = '';
  date: any;
  serviceId = '';
  currentTab = "Accepted";
  query = {};
  error = { 'description': [] };
  success = { 'description': [] };
  serviceCharg: any = '';
  spareCharg: any = '';
  oilCharg: any = '';
  labourCharg: any = '';
  totalAmt: any;
  receivedAmt: any = '';
  balanceAmt: any = '';
  remartksTxt: any = '';
  dateofInvoice: any = '';
  // Initialized to Data range of 2 months ago.
  startDateFilter: any;
  endDateFilter: any;
  techstartDateFilter: any;
  techendDateFilter: any;
  requestIdFilter: any;
  rescheduleTextErr = false;
  cancelReasonErr = false;
  createInvoiceForm: FormGroup;
  request: any;
  currentDateTime:any = new Date();
  isJobTime = false;
  futureDate: any;
  scheduleDate: any;
  public techstartDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  public model: Object = {};
  public techendDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public invoiceDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public endDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  userDetails = {'usertype':'','sub':'','userid':'','countryDetails':''};
  selectedCountry = '';
  selectedCountryhead = '';
  selectedCountryheadName = '';
  selectedDistributor = '';
  selectedDistributorName = '';
  selectedserviceStations = '';
  servicestationList = [];
  selectedTechnician = '';
  rescheduleText = '';
  public reschedulestartDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false,
    disableUntil: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
  };
  @ViewChild('trackmap') mapElement: ElementRef;
  updatestatus: string;
  selectedFiles: any = [];
  selectedVideoFiles: any = [];
  uploadErr = false;
  uploadErrVideo = false;
  constructor(private router: Router, public apiService: ApiService, private spinner: NgxSpinnerService, public dateService: DateService) {
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
    let d: Date = new Date();
    this.model = {
      date: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      }
    };
  }

  ngOnInit() {

    this.error = { 'description': [] };
    this.success = { 'description': [] };
    this.uploadErr = false;
    this.uploadErrVideo = false;
    this.createInvoiceForm = new FormGroup({
      serviceCharges: new FormControl('', [Validators.required,]),
      spareCharges: new FormControl('', [Validators.required,]),
      oilCharges: new FormControl('', [Validators.required,]),
      labourCharges: new FormControl('', [Validators.required,]),
      totlaCharges: new FormControl(true),
      recievedAmount: new FormControl('', [Validators.required,]),
      balance: new FormControl('', [Validators.required,]),
      AttachmentImages: new FormControl(),
      AttachmentVideos: new FormControl(),
      remarks: new FormControl(),
      dateofInvoice: new FormControl(new Date(), [Validators.required,]),
      time:new FormControl('', [Validators.required]),
    })
    // var d = new Date();
    // //console.log(d.toLocaleDateString());
    // d.setMonth(d.getMonth() - 2);
    // //console.log(d.toLocaleDateString());
    // this.startDateFilter = {'formatted':d.toLocaleDateString()};
    // this.endDateOptions = {
    //   componentDisabled:false
    // }
    // this.getServiceRequest('Accepted');
    this.callList();
    // this.getServiceRequest('Requested');
  }
  onDateChanged(event: IMyDateModel) {
    // date selected
    this.dateofInvoice = event.formatted
    console.log('Formatted date: ', event.formatted);
  }

  totalAmount() {

    this.totalAmt = Number(this.serviceCharg) + Number(this.spareCharg) + Number(this.oilCharg) + Number(this.labourCharg);
    this.balanceAmt = Number(this.receivedAmt) - this.totalAmt;
  }

  iskeyUpNumber(evt) {
    ////console.log(evt, " iskeyUpNumber");
    this.serviceCharg = isNaN(this.serviceCharg) ? this.serviceCharg.slice(0, -1) : this.serviceCharg;
    this.spareCharg = isNaN(this.spareCharg) ? this.spareCharg.slice(0, -1) : this.spareCharg;
    this.oilCharg = isNaN(this.oilCharg) ? this.oilCharg.slice(0, -1) : this.oilCharg;
    this.labourCharg = isNaN(this.labourCharg) ? this.labourCharg.slice(0, -1) : this.labourCharg;
    this.receivedAmt = isNaN(this.receivedAmt) ? this.receivedAmt.slice(0, -1) : this.receivedAmt;
    this.totalAmount();
  }


  callList() {
    if (this.userDetails.usertype == 'Admin') {
      this.getCountryList();
    } else if (this.userDetails.usertype == 'CountryHead') 
    {
      //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      var counrtyDetails=JSON.parse(this.userDetails.countryDetails)
      this.countryList=counrtyDetails;
      this.selectedCountry=this.countryList[0].countryName;      
      this.selectedCountryhead = this.userDetails['userid'];
      this.selectedCountryheadName = this.userDetails['given_name'];
      this.getCountryheadList();
      this.getDistributorByCountryhead(this.userDetails['userid']);
    } else if (this.userDetails.usertype == 'Distributor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadId'];
      this.selectedCountryheadName = this.userDetails['countryHeadName']
      this.selectedDistributor = this.userDetails['userid'];
      this.selectedDistributorName = this.userDetails['given_name'];
      this.getServicestationByDistributor(this.selectedDistributor);
      this.getServiceStation(this.selectedDistributor);
    } else if (this.userDetails.usertype == 'FrontLineAdvisor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadId'];
      this.selectedCountryheadName = this.userDetails['countryHeadName']
      this.selectedDistributor = this.userDetails['distributorId'];
      this.selectedDistributorName = this.userDetails['distributorName'];
      this.getServicestationByDistributor(this.selectedDistributor);
      this.getServiceStation(this.selectedDistributor);
    } else if (this.userDetails.usertype == 'CallCenterExecutive') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadId'];
      this.selectedCountryheadName = this.userDetails['countryHeadName']
      this.selectedDistributor = this.userDetails['distributorId'];
      this.selectedDistributorName = this.userDetails['distributorName'];
      this.getServicestationByDistributor(this.selectedDistributor);
      this.getServiceStation(this.selectedDistributor);
    }
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      // by default
      if (this.countryList.length > 0) {
        this.selectedCountry = this.countryList[0].countryName;
        this.getCountryheadList();
        //console.log(this.countryList, "this.getCountryList");
      }
    }, error => {
      //console.log(this.error);
      this.spinner.hide();
      this.error.description = [];
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
        // setTimeout(function(){
        //   $(".myAlert-top").hide();
        // }, 2000);
      }
    });
  }

  getCountryheadList() {
    let query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ]
    }
    this.spinner.show();
    this.selectedCountryhead = "";
    this.distributorList = [];
    this.selectedDistributor = "";
    this.servicestationList = [];
    this.selectedserviceStations = "";
    //console.log(this.countryheadList);
    this.apiService.getCountryhdList(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.countryheadList = response['content'];
      // By default
      if(this.userDetails.usertype==="CountryHead")
      {
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.countryheadList[0].id)

      }else
      if (this.countryheadList.length > 0) {
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.selectedCountryhead);
        //console.log(this.countryheadList);
      }
    }, error => {
      this.spinner.hide();
      //console.log(error);
      this.error.description = [];
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
        // setTimeout(function(){
        //   $(".myAlert-top").hide();
        // }, 2000);
      }
    })
  }

  onselectCountryhead(countryHeadId) {
    //console.log(countryHeadId);
    /*if user id admin*/
    if (this.userDetails['usertype'] == 'Admin') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.servicestationList = [];
      this.selectedserviceStations = "";
      this.getDistributorByCountryhead(countryHeadId);
    }
    if (this.userDetails['usertype'] == 'CountryHead') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId,);
    }
  }

  onselectDistributor(distributorId) {
    //console.log(distributorId);
    /*if user id admin*/
    if (this.userDetails['usertype'] == 'Admin') {
      //console.log(distributorId);
      this.servicestationList = [];
      this.selectedserviceStations = "";
      this.getServicestationByDistributor(distributorId);
      this.getServiceStation(distributorId);
    }
    
  }

  getServiceStation(distributorId) {
    //console.log(this.serviccenterList);
    this.query = {
      "filters": [
        {
          "member": "distributorId",
          "value": distributorId,
          "operation": "EqualTo"
        }
      ],
      "pageNumber": 1,
      "itemsPerPage": 100,
    }
    this.spinner.show();
    this.apiService.getServicestations(this.query).subscribe(response => {
      //console.log(response, " reslllllllllll");
      this.serviccenterList = response['content'].payLoad;
    })
  }

  getServiceStationByVicinity(requestDetails) {
    // this.serviccenterList = [];
    this.apiService.getServiceStationByVicinity({
      "type": "Point",
      "coordinates": [requestDetails['customerAddress'].location.coordinates[0], requestDetails['customerAddress'].location.coordinates[1]]
    }).subscribe((response: any) => {
      //console.log(response);
      // this.serviceRequests.forEach(element => {
      //   if(element.id == requestDetails.id) {
      //     element['servicecenterList'] = response.content.payLoad;
      //     element['fromDate'] = ( element.scheduledDate != null)? {date: { day:  new Date( element.scheduledDate).getDate(),month:  new Date( element.scheduledDate).getMonth() + 1,year: new Date( element.scheduledDate).getFullYear()}} : null,
      //     element['toDate'] = ( element.scheduledDate != null)? {date: { day:  new Date( element.scheduledDate).getDate(),month:  new Date( element.scheduledDate).getMonth() + 1,year: new Date( element.scheduledDate).getFullYear()}} : null
      //   }
      // });
      this.serviccenterList = response['content'].payLoad;
      this.gettechnicianforassignment(requestDetails);
    }, error => {
      //console.log(error);
      this.error.description = [];
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
        // setTimeout(function(){
        //   $(".myAlert-top").hide();
        // }, 2000);
      }
    })
  }



  getDistributorByCountryhead(countryHeadId) {
    //console.log(this.distributorList);
    let data = {
      "options": {},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      if (this.distributorList.length > 0) {
        this.selectedDistributor = this.distributorList[0].id;
      }
      if (this.currentTab == "Requested") {
        this.filterChanged(this.currentTab);
      }
      else {
        this.filterChanged(this.currentTab);
      }

      // this.filterChanged("Requested");
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
      this.error.description = [];
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
        // setTimeout(function(){
        //   $(".myAlert-top").hide();
        // }, 2000);
      }
    })
  }

  getServicestationByDistributor(distributorId) {
    //console.log(this.servicestationList);
    this.selectedserviceStations = "";
    this.servicestationList = [];
    let query = {
      "filters": [
        {
          "member": "distributorId",
          "value": distributorId,
          "operation": "EqualTo"
        }
      ]
    }

    this.spinner.show();
    this.apiService.getServicestations(query).subscribe(response => {
      //console.log(response, " getting all station...");
      this.spinner.hide();
      this.servicestationList = response['content'].payLoad;
      // By default      
      if (this.currentTab == "Requested") {
        this.filterChanged(this.currentTab);
      }
      else {
        this.filterChanged(this.currentTab);
      }
      //console.log(this.servicestationList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
      this.error.description = [];
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
        // setTimeout(function(){
        //   $(".myAlert-top").hide();
        // }, 2000);
      }
    })
  }


  createRequest() {
    this.router.navigate(['/createRequest']);
  }

  gettechnicianforassignment(requestDetails) {
    //console.log('++++++++gettechnicianforassignment++++++++');
    this.mechanics = [];
    const data = {
      "filters": [
        {
          "member": "serviceStationId",
          "value": requestDetails.serviceStationId,
          "operation": "EqualTo"
        },
        {
          "member": "scheduledDate",
          "value": requestDetails.scheduledDate,
          "operation": "EqualTo"
        }
        // {
        //   "member":"fromDate",
        //   "value":requestDetails.fromDate
        // }, 
        // {
        //   "member":"toDate",
        //   "value":requestDetails.toDate
        // }
      ]
    }
    this.apiService.gettechnicianforassignment(data).subscribe(response => {
      //console.log(response);
      this.serviceRequests.forEach(element => {
        //console.log(element);
        if (element.id == requestDetails.id) {
          element['mechanics'] = response['content'].payLoad;
        }
      });
    }, error => {
      ////console.log(error);
      this.error.description = [];
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
        // setTimeout(function(){
        //   $(".myAlert-top").hide();
        // }, 2000);
      }
    });
  }

  selectTechnician(requestDetails, technicianDetails) {
    //console.log(technicianDetails);
    //console.log(requestDetails);
    this.selectedTechnician = '';
    this.serviceRequests.forEach(mecElement => {
      //console.log(mecElement);
      if (requestDetails.id == mecElement.id) {
        mecElement.mechanics.forEach(element => {
          //console.log(element.mechanicId);
          //console.log(technicianDetails.mechanicId);

          if (element.mechanicId == technicianDetails.mechanicId) {

            element.selected = true;
            mecElement.selectedTechnician = element.mechanicId;
            //console.log(mecElement.selectedTechnician);
            //console.log(element.mechanicId);
            //console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");

          } else {
            element.selected = false;
          }
        });
      }
    });


  }

  getServiceRequest(status) {
    if (status == 'Accepted') {
      this.serviceRequestsAccepted = [];
    } else if (status == 'Assigned') {
      this.serviceRequests = [];
      // if(this.userDetails.usertype == 'Distributor'){
      //   this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      //   this.selectedCountryhead = this.userDetails['countryHeadName'];
      //   this.selectedDistributor = this.userDetails['userid'];
      //   this.selectedDistributorName = this.userDetails['given_name'];
      this.query['filters'].forEach(element => {
        //console.log(element);
        if(element.value == "Accepted") 
        {
          element.value = "Assigned"
        }
      });
    } else if (status == 'Requested') {
      this.serviceRequests = [];
    }
    // const data = {
    //   "filters":[
    //     {
    //       "member" :'status',
    //       "value":status,
    //       "operation": "EqualTo"
    //     }
    //   ]
    // }
    console.log(this.query);
    console.log(this.userDetails);

    if(this.userDetails.usertype==="FrontLineAdvisor")
    {
        this.query['filters'].push({
          "member": "flaid",
          "value": this.userDetails.userid
        })
    }
    this.spinner.show();
    this.apiService.getServiceRequest(this.query).subscribe(response => {
      this.spinner.hide();
      if (status == 'Accepted') {
        this.serviceRequestsAccepted = response['content'].payLoad;
        //For Audio file display
        response['content'].payLoad.forEach(element => {
          element.imageAttachment = [];
          element.requestVideo = [];
          element.requestAudio = [];
          if (element.voiceAttachments && element.voiceAttachments.length > 0) {
            element.voiceAttachments.forEach(attachmentElement => {
              attachmentElement.serviceRequestId = element.id;
               if (attachmentElement.contentType.startsWith('audio')) {
                //Check for Audio
                //Populate array only if video is uploaded in service request.
                element.requestAudio.push(attachmentElement);
              }
            });
          }
        });
        this.getServiceRequest('Assigned');
      } else if (status == 'Requested') {
        //console.log(response);
        this.serviceRequests = response['content'].payLoad;
      }
      else if (status == 'Assigned') {
        //when status is Assigned {}
        //console.log(response);
        this.serviceRequestsAccepted = this.serviceRequestsAccepted.concat(response['content'].payLoad);
        //For Audio file display
        response['content'].payLoad.forEach(element => {
          element.imageAttachment = [];
          element.requestVideo = [];
          element.requestAudio = [];
          if (element.voiceAttachments && element.voiceAttachments.length > 0) {
            element.voiceAttachments.forEach(attachmentElement => {
              attachmentElement.serviceRequestId = element.id;
               if (attachmentElement.contentType.startsWith('audio')) {
                //Check for Audio
                //Populate array only if video is uploaded in service request.
                element.requestAudio.push(attachmentElement);
              }
            });
          }
        });
      }
      //console.log(this.serviceRequestsAccepted);
    }, error => {
      //console.log(error);
      this.spinner.hide();
      this.error.description = [];
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
        // setTimeout(function(){
        //   $(".myAlert-top").hide();
        // }, 2000);
      }
    })
  }

  viewAudio(requestAudio) {
    if (requestAudio.length > 0) {
      $('#verbatimvideo_ID').remove();
      var audio = $('<video />', {
        id: 'verbatimvideo_ID',
        src: requestAudio[0].url,
        type: 'audio/mp3',
        width: "100%",
        height: "100px",
        controls: true
      });
      audio.appendTo($('#verbatim_ID'));
      $('#audioModal').modal('show');
    }
  }

  filterChanged(currentTab) {
    //console.log(this.selectedCountry);
    //console.log(this.selectedserviceStations);
    //console.log(this.selectedCountryhead);
    //console.log(this.startDateFilter);
    //console.log(this.endDateFilter);
    this.query = {};
    const filters = [];
    if (this.selectedCountryhead) {
      filters.push({
        "member": "countryhead",
        "value": this.selectedCountryhead
      })
    }
    if (this.selectedDistributor) {
      filters.push({
        "member": "distributorId",
        "value": this.selectedDistributor
      })
    }
    if (currentTab == "Accepted") {
      filters.push({
        "member": "status",
        "value": "Accepted"
      })
    }
    if (currentTab == "Requested") {
      filters.push({
        "member": "status",
        "value": "Requested"
      })
    }
    if (this.selectedserviceStations) {
      filters.push({
        "member": "serviceStationName",
        "value": this.selectedserviceStations
      })
    }
    if (this.requestIdFilter) {
      filters.push({
        "member": "requestIdentificationNo",
        "value": this.requestIdFilter
      })
    }
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }
    //console.log(filters);
    this.query = {
      "filters": filters
    }
    if (this.currentTab == 'Accepted' && this.selectedCountryhead && this.selectedDistributor) {
      this.getServiceRequest('Accepted');
    } else if (this.currentTab == 'Requested' && this.selectedCountryhead && this.selectedDistributor) {
      this.getServiceRequest('Requested');
    }

  }

  assignRequesttoMech(serviceRequestId, mechanicId) {
    //console.log(mechanicId);
    //console.log(serviceRequestId);

    const data = {
      "serviceRequestId": serviceRequestId,
      "mechanicId": mechanicId
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.getmechanicstatus('userId=' + mechanicId).subscribe((res: any) => {
      //console.log(res);
      this.spinner.hide();
      this.error.description = [];
      // if (res.content.isLoggedOut) {
      //   this.error.description = ["Selected mechanic is not available."];
      //   this.filterChanged("Requested");
      //   // this.error.description.push(error.error.errors[0]['description']);
      //   // setTimeout(function(){
      //   //   $(".myAlert-top").hide();
      //   // }, 2000);
      // }
      // else {
        this.spinner.show();
        this.apiService.assignRequesttoMech(data).subscribe(res => {
          //console.log(res);
          this.spinner.hide();
          this.selectedTechnician = "";
          this.filterChanged(this.currentTab);
          // this.getServiceRequest('Requested');
        }, error => {
          this.spinner.hide();
          this.error.description = [];
          if (error.error.errors.length > 0) {
            this.error.description.push(error.error.errors[0]['description']);
            // setTimeout(function(){
            //   $(".myAlert-top").hide();
            // }, 2000);
          }
        })
      //} //else end

    })

  }

  rescheduleRequest(serviceId) {
    //console.log(serviceId);
    this.time = '';
    this.date = '';
    this.serviceId = serviceId;
    $('#rescheduleModal').modal('show');
  }

  rescheduleModalClose() {
    this.time = '';
    this.date = '';
    this.serviceId = '';
    this.rescheduleText = '';
    this.rescheduleTextErr = false;
    $('#rescheduleModal').modal('hide');
  }

  resetFilter() {
    // if(this.userDetails.usertype ==  'CallCenterExecutive') {
    //   this.selectedserviceStations = "";
    //   this.startDateFilter = "";
    //   this.endDateFilter = "";
    //   this.requestIdFilter = "";
    //   this.query = {};
    //   this.serviceRequestsAccepted = [];
    //   this.serviceRequests = [];
    // } else {
    //   this.selectedCountry = "";
    //   this.selectedCountryhead = "";
    //   this.selectedDistributor = "";
    //   this.selectedserviceStations = "";
    //   this.startDateFilter = "";
    //   this.endDateFilter = "";
    //   this.requestIdFilter = "";
    //   this.query = {};
    //   this.serviceRequestsAccepted = [];
    //   this.serviceRequests = [];
    // }
    if (this.userDetails.usertype == 'Admin') {
      this.selectedCountry = "";
      this.selectedCountryhead = "";
      this.selectedDistributor = "";
      this.selectedserviceStations = "";
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.requestIdFilter = "";
      this.countryheadList = [];
      this.distributorList = [];
      this.servicestationList = [];
      this.query = {};
      this.serviceRequestsAccepted = [];
      this.serviceRequests = [];
    } else if (this.userDetails.usertype == 'CountryHead') {
      this.selectedDistributor = "";
      this.selectedserviceStations = "";
      this.servicestationList = [];
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.requestIdFilter = "";
      this.query = {};
      this.serviceRequestsAccepted = [];
      this.serviceRequests = [];
    } else if (this.userDetails.usertype == 'Distributor') {
      this.selectedserviceStations = "";
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.requestIdFilter = "";
      this.query = {};
      this.serviceRequestsAccepted = [];
      this.serviceRequests = [];
    } else if (this.userDetails.usertype == 'FrontLineAdvisor') {
      this.selectedserviceStations = "";
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.requestIdFilter = "";
      this.query = {};
      this.serviceRequestsAccepted = [];
      this.serviceRequests = [];
    } else if (this.userDetails.usertype == 'CallCenterExecutive') {
      this.selectedserviceStations = "";
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.requestIdFilter = "";
      this.query = {};
      this.serviceRequestsAccepted = [];
      this.serviceRequests = [];
    }
  }

  rescheduleModalSubmit() {
    //console.log(this.time);
    //console.log(this.date);
    //console.log(this.rescheduleText);
    //console.log(this.serviceId);   
    var serviceRequestDate = this.dateService.simpleDate(this.date)
    //console.log(serviceRequestDate.split(" ")[0]);
    //console.log(serviceRequestDate.split(" ")[0]+ 'T'+this.time); 
    serviceRequestDate = serviceRequestDate.split(" ")[0] + 'T' + this.time;
    // var serviceRequestDate = this.dateService.simpleDate(this.date)
    // //console.log(serviceRequestDate.split(" ")[0]);
    // //console.log(serviceRequestDate.split(" ")[0]+ 'T'+this.time+'Z');
    const data = {
      serviceRequestId: this.serviceId,
      rescheduledDateTime: serviceRequestDate,
      rescheduleReason: this.rescheduleText
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.reschedule(data).subscribe(res => {
      //console.log(res);
      this.serviceId = '';
      this.rescheduleText = '';
      this.spinner.hide();
      this.rescheduleModalClose();
      this.filterChanged(this.currentTab);
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.error.description = [];
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
        // setTimeout(function(){
        //   $(".myAlert-top").hide();
        // }, 2000);
      }
    })
  }

  handleStatusUpdate(ml: any) {
    this.checkFutureDate(ml);    
    if (ml.status == 'Assigned') {
      this.confirmationModal(ml.id, 'Accepted', ml);
    }
    else if (ml.status == 'Accepted') {
      this.confirmationModal(ml.id, 'OnTheWay', ml)
    }
    else if (ml.status == 'OnTheWay') {
      this.confirmationModal(ml.id, 'Reached', ml)
    }
    else if (ml.status == 'Reached') {
      
      this.confirmationModalJobDone(ml.id, 'JobDone', ml)
    }
    else {
      console.log(ml);
    }
  }

  confirmationModal(serviceId: string, updateStatus: string, serviceReuest: any) {
    this.updatestatus = updateStatus;
    this.serviceId = serviceId;
    console.log(updateStatus, serviceId, serviceReuest);
    $('#conformationModal').modal('show');
  }

  confirmationModalClose() {
    this.updatestatus = '';
    this.serviceId = '';
    $('#conformationModal').modal('hide');
  }

  confirmationModalSubmit() {
    //console.log(this.updatestatus, this.serviceId);
    this.success['description'] = [];
    this.error['description'] = [];
    if (this.updatestatus == 'Accepted') {
      const data = {
        serviceRequestId: this.serviceId,
      }
      this.spinner.show();
      this.apiService.acceptServiceRequest(data).subscribe(res => {
        this.serviceId = '';
        this.updatestatus = '';
        this.spinner.hide();
        this.success = { 'description': ['Request Updated Successfully!'] };
        this.confirmationModalClose();
        this.filterChanged(this.currentTab);
      }, error => {
        console.log(error);
        if (error.error && error.error.errors.length > 0) {
          this.error.description.push(error.error.errors[0]['description']);
          let el = document.getElementById('addCustomerTop');
          el.scrollIntoView();
        }
        this.spinner.hide();
      })
    }
    else {
      const data = {
        serviceRequestId: this.serviceId,
        status: this.updatestatus
      }
      this.spinner.show();
      this.apiService.updateServiceRequest(data).subscribe(res => {
        this.serviceId = '';
        this.updatestatus = '';
        this.spinner.hide();
        this.success = { 'description': ['Request Updated Successfully!'] };
        this.confirmationModalClose();
        this.filterChanged(this.currentTab);
      }, error => {
        console.log(error);
        if (error.error && error.error.errors.length > 0) {
          this.error.description.push(error.error.errors[0]['description']);
          let el = document.getElementById('addCustomerTop');
          el.scrollIntoView();
        }
        this.spinner.hide();
      })
    }


  }


  handleImageInput($files) {

    let filecount = this.selectedFiles.length + $files.target.files.length;

    if (filecount > 3) {
      this.uploadErr = true;
      this.createInvoiceForm.get('AttachmentImages').reset('');
      return;
    }

    for (let i = 0; i < $files.target.files.length; i++) {
      let file = $files.target.files[i];
      if (file.type && file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var self = this;
        reader.onload = function (e) {

          //Initiate the JavaScript Image object.
          var image: any = new Image();

          //Set the Base64 string return from FileReader as source.
          image.src = e.target['result'];

          //Validate the File Height and Width.
          image.onload = function () {
            if (image.height >= 150 && image.width >= 150 && Math.round(file.size / 1024 / 1024) <= 5) {
              self.uploadErr = false;

              if (file.name.length > 35) {
                file.fileName = file.name.substring(0, 32).concat('...');
              } else {
                file.fileName = file.name;
              }
              self.selectedFiles.push(file);
            } else {
              self.uploadErr = true;
              self.createInvoiceForm.get('AttachmentImages').reset('');
              self.selectedFiles = [];
            }
          };
        };
      }
      else {
        this.uploadErr = true;
        this.createInvoiceForm.get('AttachmentImages').reset('');
        this.selectedFiles = [];
      }
    }
  }

  handleVideoFileInput($files) {

    this.selectedVideoFiles = [];
    let file: File = null;
    file = $files.target.files[0];

    //file has to be image with max 2 mb size & dimension 150*150
    if (file.type && file.type == 'video/mp4' || file.type == 'video/3gpp' || file.type == 'video/mpeg') {

      if (Math.round(file.size / 1024 / 1024) <= 50) {
        this.uploadErrVideo = false;
        this.selectedVideoFiles.push(file);
      } else {
        this.uploadErrVideo = true;
        this.createInvoiceForm.get('AttachmentVideos').reset('');
        this.selectedVideoFiles = [];
      }
    } else {
      this.uploadErrVideo = true;
      this.createInvoiceForm.get('AttachmentVideos').reset('');
      this.selectedVideoFiles = [];
    }
  }

  confirmationModalJobDone(serviceId: string, updateStatus: string, serviceReuest: any) {
    this.updatestatus = updateStatus;
    this.serviceId = serviceId;
    this.request = serviceReuest;
    console.log(updateStatus, serviceId, serviceReuest);
    $('#uploadModal').modal('show');
  }

  

  confirmationModalJobDoneClose() {
    this.updatestatus = '';
    this.serviceId = '';
    this.selectedFiles = [];
    this.selectedVideoFiles = [];
    this.createInvoiceForm.get('serviceCharges').reset('');
    this.createInvoiceForm.get('spareCharges').reset('');
    this.createInvoiceForm.get('oilCharges').reset('');
    this.createInvoiceForm.get('labourCharges').reset('');
    this.createInvoiceForm.get('totlaCharges').reset('');
    this.createInvoiceForm.get('recievedAmount').reset('');
    this.createInvoiceForm.get('balance').reset('');
    this.createInvoiceForm.get('AttachmentImages').reset('');
    this.createInvoiceForm.get('AttachmentVideos').reset('');
    this.createInvoiceForm.get('remarks').reset('');
    this.createInvoiceForm.get('dateofInvoice').reset('');

    this.serviceCharg = '';
    this.spareCharg = '';
    this.oilCharg = '';
    this.labourCharg = '';
    this.totalAmt = '';
    this.receivedAmt = '';
    this.balanceAmt = '';
    this.remartksTxt = '';
    this.dateofInvoice = '';


    this.uploadErr = false;
    this.uploadErrVideo = false;
    $('#uploadModal').modal('hide');
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  confirmationModalJobDoneSubmit(createInvoiceForm: any) {

    //var Jobdonedate = this.dateService.simpleDate_withoutTime(this.createInvoiceForm.get('dateofInvoice').value) //for validation
    //var Jobdonedate = invoiceDate.split(' ')[0];
    //var dateofinvoice = this.formatDate(Jobdonedate)
    // var scheduledDate = this.request.scheduledDate.split('T')[0];
    
    //let tempdate = createInvoiceForm.value.dateofInvoice.jsdate;
    //console.log(createInvoiceForm);
    var scheduledDate = this.request.scheduledDate
    var invoiceDate = this.dateService.simpleDate(this.createInvoiceForm.get('dateofInvoice').value);// for store datetime
    var tempInvoiceDate = this.dateService.simpleDate_withoutTime(this.createInvoiceForm.get('dateofInvoice').value) + ' ' +this.createInvoiceForm.get('time').value + ':00';
    invoiceDate = invoiceDate.split(" ")[0]+ 'T'+this.createInvoiceForm.get('time').value + ':00';
    //invoiceDate = tempdate.split(" ")[0]+ 'T'+this.createInvoiceForm.get('time').value;
    // console.log('scheduledDate',scheduledDate)

    var tempScheduleDate = scheduledDate.split("T")[0] + ' ' + scheduledDate.split("T")[1];

    
    // console.log('ScheduleDate:-',new Date(tempScheduleDate));    
    // console.log('new date', new Date(tempInvoiceDate) );
    

    // if (invoiceDate > scheduledDate ) {
    //   alert('test');
    //   this.errorMsg = 'Jobdone date must be after schedule date';
    //   return false
    // }
    

    this.futureDate = new Date(tempInvoiceDate);
    this.scheduleDate = new Date(tempScheduleDate );  

    // console.log(this.scheduleDate);
    if( this.futureDate.getDate() < this.scheduleDate.getDate())
    {
      this.errorMsg = 'Jobdone date must be after schedule date';
      return false;
    }
    else if( this.futureDate.getDate() >= this.scheduleDate.getDate())
    {
      // console.log('ScheduleTime:-'+ this.scheduleDate.getTime());
      // console.log('FutureTime:-'+this.futureDate.getTime());
      if( this.futureDate.getTime() <= this.scheduleDate.getTime() ){        
        // alert('false date time');
        this.errorMsg1 = 'Jobdone time must be after schedule time';
        return false  
      }      
    }
    
    //alert('after date compare');
  
    let payload = new FormData();
    payload.append('ServiceRequestId', this.serviceId);
    payload.append('ServiceCharges', this.serviceCharg);
    payload.append('SpareCharges', this.spareCharg);
    payload.append('OilCharges', this.oilCharg);
    payload.append('LabourCharges', this.labourCharg);
    payload.append('ReceivedAmount', this.receivedAmt);
    payload.append('Remarks', this.remartksTxt);
    payload.append('InvoiceDate', invoiceDate);
    // if( this.selectedFiles != ''){
    //   payload.append('Files', this.selectedFiles, this.selectedFiles.name);
    // }
    for (var i = 0; i < this.selectedFiles.length; i++) {
      payload.append("Files", this.selectedFiles[i], this.selectedFiles[i].name);
    }

    for (var i = 0; i < this.selectedVideoFiles.length; i++) {
      payload.append("Files", this.selectedVideoFiles[i], this.selectedVideoFiles[i].name);
    }
    
    this.spinner.show();
    this.apiService.createInvoice(payload).subscribe(res => {

      this.spinner.hide();
      this.success = { 'description': ['Request Completed Successfully!'] };
      this.confirmationModalJobDoneClose();
      this.filterChanged(this.currentTab);
    }, error => {
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
        let el = document.getElementById('addCustomerTop');
        el.scrollIntoView();
      }
      this.spinner.hide();
    })
  }

  cancelRequest(serviceId) {
    //console.log(serviceId);
    this.cancelReason = '';
    this.serviceId = serviceId;
    $('#exampleModal').modal('show');
  }

  cancelRequestClose() {
    this.cancelReason = '';
    this.serviceId = '';
    this.cancelReasonErr = false;
    $('#exampleModal').modal('hide');
  }

  cancelRequestSubmit() {
    //console.log(this.cancelReason);
    //console.log(this.serviceId);
    if (!this.cancelReasonErr) {
      this.success['description'] = [];
      this.error['description'] = [];
      const data = {
        serviceRequestId: this.serviceId,
        cancellationReason: this.cancelReason
      }
      this.spinner.show();
      this.apiService.serviceRequestCancel(data).subscribe(res => {
        //console.log(res);
        this.serviceId = '';
        this.spinner.hide();
        this.success = { 'description': ['Request Cancelled Successfully!'] };
        this.cancelRequestClose();
        this.filterChanged(this.currentTab);
      }, error => {
        if (error.error.errors.length > 0) {
          this.error.description.push(error.error.errors[0]['description']);
          let el = document.getElementById('addCustomerTop');
          el.scrollIntoView();
        }
        this.spinner.hide();
      })
    }
  }

  viewMap(latitude, longitude) {
    //console.log(latitude);
    //console.log(longitude);
    this.spinner.show();
    let latLng = new google.maps.LatLng(latitude, longitude);
    let mapOptions = {
      center: latLng,
      zoom: 12,
      disableDefaultUI: true, // a way to quickly hide all controls
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      overviewMapControl: false,
      fullscreenControl: false,
      scrollWheel: false,
    }
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    this.marker = new google.maps.Marker({
      map: this.map,
    });
    this.spinner.hide();
    this.marker.bindTo('position', this.map, 'center');
  }

  closeAlert() {
    this.error.description = [];
  }

  closeModal() {
    //console.log('closssssssseeeee')
    $("div").remove("#verbatimvideo_ID");
    $('#audioModal').hide('hide');
  }

  getCurrentab(currentTab) {
    this.currentTab = currentTab;
    if (currentTab == "Requested") {
      this.filterChanged(currentTab);
    }
    else {
      this.filterChanged(currentTab);
    }
  }

  onstartDatechange(event) {
    //console.log(event);
    if (event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate;
      // this.onChangeFilter();
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onendDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
      // this.onChangeFilter();
    } else {
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onstartFilterDatechange(event, requestDetails) {

    //console.log(requestDetails);
    if (event.jsdate != null) {
      this.techstartDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.techendDateFilter = '';
      this.serviceRequests.forEach(element => {
        //console.log(element);
        if (element.id == requestDetails.id) {
          element.toDate = "";
        }
      });
      this.techendDateOptions = resetDate;
      // this.onChangeFilter();
    } else {
      this.techstartDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.techendDateOptions = resetDate;
      this.serviceRequests.forEach(element => {
        //console.log(element);
        if (element.id == requestDetails.id) {
          element.toDate = "";
        }
      });
      this.techendDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onendFilterDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
      // this.onChangeFilter();
    } else {
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  checkMechanics(data) {
    //console.log(data);
    if (data && data.length > 0) {
      data.forEach(element => {
        let data = "userId=" + element.mechanicId
        this.apiService.getmechanicstatus(data).subscribe(res => {
          //console.log(res)
        })
      });
      return true;
    }


  }


  checkValidation() {
    //console.log(this.rescheduleText);
    var regex = /[^a-zA-Z0-9(),?. ]/;
    //console.log(regex.test(this.rescheduleText));
    if (this.rescheduleText && this.rescheduleText.trim().length > 10 && !regex.test(this.rescheduleText.trim()) && this.rescheduleText.trim().length <= 100) {
      //console.log('1');
      this.rescheduleTextErr = false;
    } else {
      //console.log('2');
      this.rescheduleTextErr = true;
    }
  }

  checkCancelValidation() {
    //console.log(this.cancelReason);
    var regex = /[^a-zA-Z0-9(),?. ]/;
    //console.log(regex.test(this.cancelReason));
    if (this.cancelReason && this.cancelReason.trim().length >= 10 && !regex.test(this.cancelReason.trim()) && this.cancelReason.trim().length <= 100) {
      //console.log('1');
      this.cancelReasonErr = false;
    } else {
      //console.log('2');
      this.cancelReasonErr = true;
    }
  }

  checkFutureDate(ml: any){    
    this.futureDate = new Date();
    this.scheduleDate = new Date(ml.scheduledDate);

console.log(this.futureDate.getDate());
console.log(this.scheduleDate.getDate());

  // if( this.futureDate.getDate() < this.scheduleDate.getDate())
  //   {
  //     this.isJobTime = true;
  //   }
    if( this.futureDate.getDate() >= this.scheduleDate.getDate())
    {      
      if( this.futureDate.getTime() <= this.scheduleDate.getTime() ){                       
        this.isJobTime = true;
      }  
      else
      {
        this.isJobTime = false;
      }    
    }
    else if(this.futureDate.getDate() < this.scheduleDate.getDate())
    {
      if( this.futureDate.getTime() <= this.scheduleDate.getTime() ){                       
        this.isJobTime = true;
      }  
      else
      {
        this.isJobTime = false;
      }    
    }
    else{
      this.isJobTime = true;
    }

    // this.currentDateTime = new Date();//compare with requested date and time
    // if( this.currentDateTime.getDate() > this.futureDate.getDate())
    // {
    //   if(this.currentDateTime.getTime() > this.futureDate.getTime() ){        
    //     this.isJobTime = false;      
    //   }
    //   else{
    //     this.isJobTime = true;
    //   }
    // }
    // else{
    //   this.isJobTime = true;
    // }

  }

  onChangeFilter() {

  }

}
