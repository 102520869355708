import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelServiceService } from '../../excel-service.service';
import { DateService } from '../../date.service';
const FILE_NAME = "Customer Service Request Export";
import * as FileSaver from "file-saver";
import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
declare var $: any;
@Component({
  selector: 'app-customer-service-request',
  templateUrl: './customer-service-request.component.html',
  styleUrls: ['./customer-service-request.component.css']
})
export class CustomerServiceRequestComponent implements OnInit {
  userDetails = { 'usertype': '', 'sub': '','userid':'','countryDetails':'' };
  countryList = [];
  countryheadList = [];
  distributorList = [];
  selectedCountry = '';
  selectedCountryhead = '';
  selectedDistributor = '';
  selectedStatus = '';
  selectedDistributorName = '';
  tractorSrlNo : any;
  startDateFilter: any;
  endDateFilter: any;
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public endDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    componentDisabled: true,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  IsfilterDisabled=false;
  constructor(public apiService: ApiService, public router: Router, private spinner: NgxSpinnerService, public dateService: DateService, public excelService: ExcelServiceService) {
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
  }
  customerserviceReport = [];
  
  ngOnInit() {
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.callList();

    $(document).ready(function () {
      $('.table-responsive').doubleScroll();
    });
  }

  getCustomerserviceReport(state) {
    this.spinner.show();
    this.customerserviceReport = [];
    //console.log(this.query);
    //console.log(this.hasMoreResults);
    if(this.userDetails.usertype==="FrontLineAdvisor")
    {
        this.query['filters'].push({
          "member": "flaid",
          "value": this.userDetails.userid
        })
    }
    this.apiService.getCustomerserviceReport(this.query).subscribe(response => {
      //console.log(response);
      this.customerserviceReport = response['content'].payLoad;
      //console.log(response['content'].queryOptions.hasMoreResults);
    //  console.log('Test',this.customerserviceReport);
      response['content'].payLoad.forEach(element => {
        element.imageAttachment = [];
        element.requestVideo = [];
        element.requestAudio = [];
        if (element.attachments && element.attachments.length > 0) {
          element.attachments.forEach(attachmentElement => {
            attachmentElement.serviceRequestId = element.id;
            if (attachmentElement.contentType.startsWith('image')) {
              element.hasImage = true;
              element.imageAttachment.push({ url: attachmentElement.url, fileName: attachmentElement.fileName, contentType: attachmentElement.contentType });
              // element.imageAttachment.push({url:'http://219.91.243.80/ecataloguestaging/api/images/image/ZGtTbkkxUG9jYTBhSXVKeUFEZ1Zwdz09'});
            } else if (attachmentElement.contentType.startsWith('video')) {
              //Check for video
              //Populate array only if video is uploaded in service request.
              element.requestVideo.push(attachmentElement);
              console.log(element);
            }
            else if (attachmentElement.contentType.startsWith('audio')) {
              //Check for Audio
              //Populate array only if video is uploaded in service request.
              element.requestAudio.push(attachmentElement);
              console.log(element);
            }
          });
        }
      });

      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      this.spinner.hide();
      //console.log(this.hasMoreResults);
      //console.log(this.customerserviceReport);
      //console.log(this.continuationToken);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  clickOnFilter() {
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state) {
    const filters = [];
    //console.log(this.startDateFilter);
    //console.log(this.endDateFilter);


    if (this.selectedDistributor) {
      filters.push({
        "member": "distributorId",
        "value": this.selectedDistributor
      })
    } else if (this.selectedCountryhead) {
      filters.push({
        "member": "countryheadId",
        "value": this.selectedCountryhead
      })
    } else if (this.selectedCountry || this.selectedCountry == '') {
      filters.push({
        "member": "country",
        "value": this.selectedCountry ? this.selectedCountry : 'All'
      })
    }

    if (this.startDateFilter && this.startDateFilter != null) {
      filters.push({
        "member": "fromDate",
        "value": this.simpleDate(this.startDateFilter)
      })
    }
    if (this.endDateFilter && this.endDateFilter != null) {
      filters.push({
        "member": "toDate",
        "value": this.simpleDate(this.endDateFilter)
      })
    }
    if (this.selectedStatus != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus
      })
    }
    
    if (this.tractorSrlNo != "") {
      filters.push({
        "member": "tractorSrlNo",
        "value": this.tractorSrlNo
      })
    }
    //console.log(filters)
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    console.log(this.query);
    if (filters.length > 0) {
      this.getCustomerserviceReport(state);
    }
  }

  simpleDate(dateInfo) {
    return (dateInfo.date.year + '-' + dateInfo.date.month + '-' + dateInfo.date.day + ' ').concat(moment().format("HH:mm:ss A"));
  }

  onstartDatechange(event) {
    //console.log(event);
    if (event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate;
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
    }
  }

  onendDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
    } else {
      this.endDateFilter = '';
    }
  }

  private getExportableItems() {
    if (this.customerserviceReport && this.customerserviceReport.length > 0) {
      return this.customerserviceReport.map((item, index) => {
        return {
          "Sr No.": index + 1,
          "Country": item.countryName,
          "Customer Name": item.customerName,
          "Mobile No.": item.mobileNo,
          "Tractor Model": item.tractorModel,
          "Service Id": item.requestIdentificationNumber,
          "Request Date": item.requestDate != null ? moment(item.requestDate).format('DD/MM/YYYY HH:mm') : '',
          "Service Date": item.serviceDate != null ? moment(item.serviceDate).format('DD/MM/YYYY HH:mm') : '',
          "Assigned Date": item.assignedDate != null ? moment(item.assignedDate).format('DD/MM/YYYY HH:mm') : '',
          "Accepted Date": item.acceptedDate != null ? moment(item.acceptedDate).format('DD/MM/YYYY HH:mm') : '',
          "Reschedule Date": item.reScheduledDate != null ? moment(item.reScheduledDate).format('DD/MM/YYYY HH:mm') : '',
          "Job done Date": item.jobDoneDate != null ? moment(item.jobDoneDate).format('DD/MM/YYYY HH:mm') : '',
          "Time taken to book": item.timeTakenToAssign != null ? item.timeTakenToAssign : 0,
          "Turn around time": item.timeTakenToComplete != null ? item.timeTakenToAssign : 0,
          "Service Station": item.serviceStationName,
          "Service Address": item.serviceAddress,
          "Service Description": item.serviceDescription,
          "Service Charges": item.serviceCharges,
          "Spare Charges": item.spareCharges,
          "Oil Charges": item.oilCharges,
          "Labour Charges": item.labourCharges,
          "Technician Assigned": item.mechanic,
          "Request Mode": item.requestMode,
          "Status": item.status
        };
      });
    } else {
      return [];
    }
  }

  exporttoexcel() {
    var json = this.getExportableItems();
    this.excelService.exportDocument(json, FILE_NAME)
  }

  dumpExcelReports() {

    this.spinner.show();

    const filters = [];
    
    if (this.selectedDistributor) {
      filters.push({
        "member": "distributorId",
        "value": this.selectedDistributor
      })
    } else if (this.selectedCountryhead) {
      filters.push({
        "member": "countryheadId",
        "value": this.selectedCountryhead
      })
    } else if (this.selectedCountry || this.selectedCountry == '') {
      filters.push({
        "member": "country",
        "value": this.selectedCountry ? this.selectedCountry : 'All'
      })
    }

    if (this.startDateFilter && this.startDateFilter != null) {
      filters.push({
        "member": "fromDate",
        "value": this.simpleDate(this.startDateFilter)
      })
    }
    if (this.endDateFilter && this.endDateFilter != null) {
      filters.push({
        "member": "toDate",
        "value": this.simpleDate(this.endDateFilter)
      })
    }
    if (this.selectedStatus != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus
      })
    }
    if (this.tractorSrlNo != "") {
      filters.push({
        "member": "tractorSrlNo",
        "value": this.tractorSrlNo
      })
    }

    let payload = {
      "reportType": "CustomerServiceRequest",
      "filters": filters
    }
    this.apiService.dumpExcelReports(payload)
      .subscribe((obj: any) => {
        console.log(obj);
        let content_disposition = obj['content-disposition'];
        let file_name = this.getFilename(content_disposition);
        const blob: any = new Blob([obj.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let link = document.createElement("a");

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        }

        else if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", file_name != '' ? file_name : 'CustomerServiceRequestExcelReport');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });

  }

  getFilename(content_disposition: any): string {
    let file_name = '';
    if (content_disposition != '') {
      let arr = content_disposition.split(';');
      if (arr.length > 0) {
        if (arr[1].includes('filename')) {
          file_name = arr[1].split('=')[1];
        }
      }
    }
    return file_name;
  }

  resetFilter() {
    if (this.userDetails.usertype == 'Admin') {
      this.selectedCountry = '';
      this.selectedCountryhead = '';
      this.selectedDistributor = '';
      this.selectedDistributorName = '';
      this.countryheadList = [];
      this.distributorList = [];
      this.tractorSrlNo = '';
    } else if (this.userDetails.usertype == 'CountryHead') {
      this.selectedDistributor = '';
      this.selectedDistributorName = '';
    }
    this.customerserviceReport = [];
    this.tractorSrlNo = '';
    this.selectedStatus = '';
    this.startDateFilter = '';
    this.hasMoreResults = false;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.currentPage = 1;
    this.selectedRecord = "20";
    this.endDateFilter = '';
    this.query = {};
  }

  callList() {
    if (this.userDetails.usertype == 'Admin') {
      this.getCountryList();
    } else if (this.userDetails.usertype == 'CountryHead')
     {
      // this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      // this.selectedCountryhead = this.userDetails['given_name'];
    this.countryList=JSON.parse(this.userDetails.countryDetails)
    this.selectedCountry =this.countryList[0].countryName;// this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
    //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
    this.selectedCountryhead = this.userDetails['given_name'];
    this.getCountryheadList();
      this.getDistributorByCountryhead(this.userDetails['userid']);
    } else if (this.userDetails.usertype == 'Distributor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['userid'];
      this.selectedDistributorName = this.userDetails['given_name'];
    } else if (this.userDetails.usertype == 'FrontLineAdvisor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['distributorId'];
      this.selectedDistributorName = this.userDetails['distributorName'];
    }
  }

  getCountryList() {
    this.spinner.show();
    this.countryList = [];
    this.selectedCountry = "";
    this.countryheadList = [];
    this.selectedCountryhead = "";
    this.distributorList = [];
    this.selectedDistributor = "";
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      this.filterChanged('next');
      // By default
      // if(this.countryList.length > 0) {
      //   this.selectedCountry = this.countryList[0].countryName;
      //   this.getCountryheadList();
      // }
    }, error => {
      this.spinner.hide();
    });
  }

  getCountryheadList() {

    if (this.userDetails.usertype == 'CountryHead' && this.selectedCountry=="")
    {  
      this.IsfilterDisabled=true;
      this.selectedCountryhead='';
      this.countryheadList=[];         
   }
   else
   {
    this.IsfilterDisabled=false;  
     
   
    //console.log(this.countryheadList);
    let query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ]
    }
    this.countryheadList = [];
    this.selectedCountryhead = "";
    this.distributorList = [];
    this.selectedDistributor = '';
    this.spinner.show();
    this.apiService.getCountryhdList(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      if(this.userDetails.usertype==="CountryHead")
      {
        this.countryheadList = response['content'];
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.countryheadList[0].id)

      }else
      {
      this.countryheadList = response['content'];
      }
      
      // if(this.countryheadList.length > 0) {
      //   this.selectedCountryhead = this.countryheadList[0].id;
      //   this.onselectCountryhead(this.selectedCountryhead);
      // }
      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }
  }

  onselectCountryhead(countryHeadId) {
    //console.log(countryHeadId);
    /*if user id admin*/
    if (this.userDetails['usertype'] == 'Admin') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId);
    }
    if (this.userDetails['usertype'] == 'CountryHead') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId);
    }
  }

  getDistributorByCountryhead(countryHeadId) {
    //console.log(this.distributorList);
    let data = {
      "options": {},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      // if(this.distributorList.length > 0) {
      //   this.selectedDistributor = this.distributorList[0].id;
      //   this.filterChanged('next');
      // }
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    //console.log(obj, " state");
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }
  imageAttachment = [];

  previousClicked(index) {
    //console.log('previous');
    //console.log(index);
    //console.log(this.imageAttachment);
    this.imageAttachment.forEach((element) => {
      element.active = false;
      if (index - 1 >= 0) {
        if (this.imageAttachment[index - 1]) {
          this.imageAttachment[index - 1].active = true;
        }
      } else {
        this.imageAttachment[this.imageAttachment.length - 1].active = true;
      }
    });
  }

  nextClicked(index) {
    //console.log('next');
    //console.log(index);
    //console.log(this.imageAttachment);
    this.imageAttachment.forEach((element) => {
      element.active = false;
      if (index + 1 < this.imageAttachment.length) {
        if (this.imageAttachment[index + 1]) {
          this.imageAttachment[index + 1].active = true;
        }
      } else {
        this.imageAttachment[0].active = true;
      }
    });
  }
  viewImage(serviceRequest, imageAttachment) {
    this.imageAttachment = [];
    console.log(imageAttachment);
    if (imageAttachment.length > 0) {
      $('#imageModal').modal('show');
      imageAttachment[0]['active'] = true;
      this.imageAttachment = imageAttachment;
    }
  }


  viewVideo(requestVideo) {
    console.log(requestVideo);
    if (requestVideo.length > 0) {
      $('#verbatimvideo_ID').remove();
      var video = $('<video />', {
        id: 'verbatimvideo_ID',
        src: requestVideo[0].url,
        type: 'video/mp4',
        width: "100%",
        height: "280px",
        controls: true
      });
      video.appendTo($('#verbatim_ID'));
      $('#videoModal').modal('show');
    }
  }

  viewAudio(requestAudio) {
    console.log(requestAudio);
    if (requestAudio.length > 0) {
      $('#verbatimvideo_ID').remove();
      var audio = $('<video />', {
        id: 'verbatimvideo_ID',
        src: requestAudio[0].url,
        type: 'audio/mp3',
        width: "100%",
        height: "100px",
        controls: true
      });
      audio.appendTo($('#verbatim_ID'));
      $('#videoModal').modal('show');
    }
  }


  downloadContent(url, blobName, contentType) {
    // console.log(contentType)
    // console.log(blobName)
    // console.log(url)
    if (blobName) {
      this.spinner.show();
      this.apiService.exportContent(blobName).subscribe(response => {
        console.log(response);
        const data: Blob = new Blob([response], { type: contentType });
        this.spinner.hide();
        FileSaver.saveAs(
          data,
          blobName
        );
      }, error => {
        this.spinner.hide();
        //console.log(error);
      })
      // return this.excelService.saveAsImageFile(blobName, contentType)
    }
  }
  closeModal() {
    //console.log('closssssssseeeee')
    $("div").remove("#verbatimvideo_ID");
    $('#videoModal').hide('show');
    $('#audioModal').hide('show');
    $('#imageModal').hide('hide');
  }
}
