import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as firebase from 'Firebase';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../api.service';
import { MessagingService } from "../shared/messaging.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  // @Output() getUpdatedCount: EventEmitter<any> = new EventEmitter();
  ref = firebase.database().ref('WebNotifications/');
  notifications = [];
  servicestationList = [];
  serviceStationIds = [];
  userDetails = { 'usertype': '', 'sub': '' };

  constructor(private spinner: NgxSpinnerService, public apiService: ApiService, private messagingService: MessagingService) {
    localStorage.setItem('notificationCount', '0');
    // this.messagingService.currentMessage.subscribe(value => {
    //   //console.log(value, "msg from notification...");
    //   this.messagingService.messageReceived = [];
    //   //console.log(this.messagingService.messageReceived);
    // })
    this.messagingService.messageReceived = [];
    //console.log(this.messagingService.messageReceived, "msg from notification...");
    // this.getUpdatedCount.emit(0);
    // this.getNotifications();
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
      this.getService_stations();
      // this.getFilters();
    }
  }

  ngOnInit() {
  }

  getService_stations() {
    if (this.userDetails.usertype == "CountryHead") {
      this.getServicestationByID(this.userDetails["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country"], 'CountryHead');
    } else if (this.userDetails.usertype == "FrontLineAdvisor") {
      this.getServicestationByID(this.userDetails['distributorId'], 'FrontLineAdvisor');
    }
    else if (this.userDetails.usertype == 'Distributor') {
      this.getServicestationByID(localStorage.getItem("userId"), 'Distributor');
    }
    else if (this.userDetails.usertype == 'CallCenterExecutive') {
      this.getServicestationByID(this.userDetails['distributorId'], 'CallCenterExecutive');
    }
  }


  getServicestationByID(filter, role) {
    //console.log(filter);
    this.servicestationList = [];
    this.spinner.show();
    let query = {};
    if (role == 'CountryHead') {
      query = {
        "filters": [
          {
            "member": "country",
            "value": filter,
            "operation": "EqualTo"
          }
        ]
      }
    } else {
      query = {
        "filters": [
          {
            "member": "distributorId",
            "value": filter,
            "operation": "EqualTo"
          }
        ]
      }
    }
    this.apiService.getServicestations(query).subscribe(response => {
      //console.log(response,".......servicestationList");
      this.spinner.hide();
      this.servicestationList = response['content'].payLoad;
      if (response['content'].payLoad.length > 0) {
        this.servicestationList.forEach(element => {
          this.serviceStationIds.push(element.id);
        });
      }
      this.getNotifications();
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getNotifications() {
    //console.log('gettttttttttttttttNotificationsssssss')
    this.spinner.show();
    this.ref.limitToLast(30).once('value', snapshot => {
      // void repeat notification
      this.notifications = [];
      snapshot.forEach(function (child) {
        //console.log(child.val());
        if (this.serviceStationIds.indexOf(child.val().Recipient) > -1 || this.serviceStationIds.indexOf(child.val().ServiceStationId) > -1) {
          if (this.notifications.length > 0 && child.val().NotificationId) {
            //console.log('if');
            var idIndex = this.notifications.findIndex(element => element.value.NotificationId === child.val().NotificationId);

            //console.log(idIndex);
            if ((idIndex <= -1)) {
              //console.log('push');
              this.notifications.push({ key: child.key, value: child.val() });
            } else {
              //console.log('Do not push elemenet');
            }
          } else {
            //console.log('else');
            this.notifications.push({ key: child.key, value: child.val() });
          }

          //console.log(this.notifications);
        }
      }.bind(this));
      //console.log(this.notifications);
      this.spinner.hide();
      // this.notification.reverse();
    }, (error: any) => {
      this.spinner.hide();
      //console.log(error);
    });
  }

  deleteNotification(item) {
    //console.log(item.key);
    // this.spinner.show();
    this.ref.child(item.key).remove()
      .then(function () {
        //console.log("Remove succeeded.");
        // this.spinner.hide();
      }, (error: any) => {
        // this.spinner.hide();
        //console.log(error);
      });
    this.notifications.forEach((element, index) => {
      //console.log(element.key)
      if (element.key == item.key) {
        //console.log('matched++++++++++++++++++++++++++');
        this.notifications.splice(index, 1);
      }
    });

    // this.ref.child(key).remove();
  }
}
