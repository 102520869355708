import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransfereService } from '../../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { RouterModule, Routes,Router } from '@angular/router';

@Component({
  selector: 'app-edit-mechanic',
  templateUrl: './edit-mechanic.component.html',
  styleUrls: ['./edit-mechanic.component.css']
})
export class EditMechanicComponent implements OnInit {
  constructor(public apiService:ApiService,public transfereservice: TransfereService,private router : Router,private fb: FormBuilder,private route: ActivatedRoute,private spinner: NgxSpinnerService,private location: Location) { 
    this.editMechanicForm = this.fb.group({
      id:new FormControl('', [Validators.required]),
      Contact: new FormControl('', [Validators.required]),
      serviceStationId: new FormControl ('',[Validators.required]),
      Name: new FormControl('', [Validators.required,this.alphabetsValidator]),
    });
  }
  editMechanicForm: FormGroup;
  mechanicList = [];
  mechanicid;
  mechanicData = {};
  ServiceList = [];
  error = {'description':[]};
  userDetails = {'usertype':'','sub':''};
  countryList = [];
  userCountryDetails ={};
  mobilenumberLength={'min':9,'max':11}
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.mechanicid = params['id'];
      //console.log(this.mechanicid);
    })
    
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
      this.callList();
    }

    this.editMechanicForm = this.fb.group({
      id:new FormControl('', [Validators.required]),
      Contact: new FormControl('', [Validators.required]),
      serviceStationId: new FormControl ('',[Validators.required]),
      Name: new FormControl('', [Validators.required,this.alphabetsValidator]),
    });
    this.getMechanicList();
    // let query = {"filter":[],itemsPerPage: 500}
    // // let query = {}
    // this.apiService.getServicestations(query).subscribe((response: any) => {
    //   //console.log(response, "this.ServiceList");
    //   this.ServiceList = response.content.payLoad;
    //   //console.log(this.ServiceList, "this.ServiceList");
    // });
    
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }
  
  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
    //console.log('valid');
      return null;
    } else if(control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');
      return {'inValid': true};
    }else {
      return null;
    }
  }

  callList() {
    //console.log(this.userDetails.usertype)
    if(this.userDetails.usertype == 'Admin') 
    {
      let query = {"filter":[],itemsPerPage: 500}
      // let query = {}
      this.getServiceStations(query);
    } else if(this.userDetails.usertype == 'CountryHead') {
      //when userType is CountryHead.
      let data = {
      "filters": [
        {
          "member": "country",
          "value": this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
          "operation": "EqualTo"
        }, {
          "member": "Status",
          "value": true,
          "operation": "EqualTo"
        }]
      }
      this.getServiceStations(data);
    } else if(this.userDetails.usertype == 'Distributor'){
      //when userType is Distributor.
      let data = {
        "filters": [
          {
            "member": "distributorId",
            "value": this.userDetails['userid'],
            "operation": "EqualTo"
          }, {
            "member": "Status",
            "value": true,
            "operation": "EqualTo"
          }]
        }
      this.getServiceStations(data);
    } else if(this.userDetails.usertype == 'FrontLineAdvisor'){
      //when userType is FrontLineAdvisor.
      let data = {
      "filters": [
        {
          "member": "flaId",
          "value": this.userDetails['userid'],
          "operation": "EqualTo"
        }, {
          "member": "Status",
          "value": true,
          "operation": "EqualTo"
        }]
      }
      this.getServiceStations(data);
    }
  }

  getMechanicList() {
    this.spinner.show();

    let mechfilterdata = {
      "filters": [
        {
          "member": "mechanicId",
          "value": this.mechanicid,
          "operation": "EqualTo"
        }, {
          "member": "Status",
          "value": true,
          "operation": "EqualTo"
        }]
      }
    this.apiService.getMechanic(mechfilterdata).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.mechanicList = response['content'].payLoad;
      //console.log(this.mechanicList);
      if(response['content'].payLoad.length > 0) {
        this.mechanicList .forEach(element => 
          {
          if(element.id == this.mechanicid) 
          {
            this.mechanicData = element;
            this.getCountryDetails(element.countryName)   
            //console.log(this.mechanicData)
            this.patchValue();
          }
        });
      }
    }, error => {
      this.spinner.hide();      
      //console.log(error);
    })
  }

  getServiceStations(data) {
    this.apiService.getServicestations(data).subscribe((response: any) => {
      //console.log(response, "this.ServiceList");
      this.ServiceList = [];
      this.ServiceList = response.content.payLoad;
      // response.content.payLoad.forEach(element => {
      //   if((element.address.country != "United States") && (element.address.country != "India"))
      //   {
      //     this.ServiceList.push(element);
      //   }
      // });
      //console.log(this.ServiceList, "this.ServiceList");
    },error => {
    })
  }

  patchValue() {
    
    this.editMechanicForm.patchValue({
      id:this.mechanicData['id'],
      Contact: this.mechanicData['mobileNo'],
      serviceStationId: this.mechanicData['serviceStationId'],
      Name: this.mechanicData['name'],
    })
  }

  editMechanic() {
    //console.log(this.editMechanicForm.value);
    let data = {
      "id":this.editMechanicForm.value.id,
      "name": this.editMechanicForm.value.Name,
      "serviceStationId": this.editMechanicForm.value.serviceStationId,
      "mobileNo":this.editMechanicForm.value.Contact
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.editMechanic(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/technician'],{queryParams:{id:2}});
      this.editMechanicForm.reset();
    },error => {
      //console.log(error);
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getCountryDetails(countryname) {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {      
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      if(this.countryList.length > 0) {
        this.userCountryDetails = this.countryList.find(x=>x.countryName==countryname);        
        this.mobilenumberLength=this.userCountryDetails['mobileNumberLength']        
      }
    },error=>{
      this.spinner.hide();
    });
  }

  closeAlert() {
    this.error.description = [];
  }
}
