import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { TransfereService } from '../../transfere.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-editfla',
  templateUrl: './editfla.component.html',
  styleUrls: ['./editfla.component.css']
})
export class EditflaComponent implements OnInit {
  countryList = [];
  editflaForm:any;
  flaId;
  flaList = [];
  flaData = {};
  error = {'description':[]};
  userDetails = {'usertype':'','sub':''};
  maxLength = 10;
  minLength = 9;
  constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService,private router : Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private location: Location) {
    this.editflaForm = this.fb.group({
      id:new FormControl('', Validators.required),
      country: new FormControl(''),
      language: new FormControl(''),
      countryHeadName:new FormControl(''),
      distributorName : [''],
      distributorId: new FormControl(''),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      email:new FormControl('', [Validators.required,this.emailValidator]),
      Contact:new FormControl('', [Validators.required])
    });
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.flaId = params['id'];
      //console.log(this.flaId);
    })

    this.editflaForm = this.fb.group({
      id:new FormControl('', Validators.required),
      country: new FormControl(''),
      language: new FormControl(''),
      countryHeadName:new FormControl(''),
      distributorName : [''],
      distributorId: new FormControl(''),
      name: new FormControl('', [Validators.required,this.alphabetsValidator]),
      email:new FormControl('', [Validators.required,this.emailValidator]),
      Contact:new FormControl('', [Validators.required])
    });
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
      this.callList();
    }
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  callList() {
    if(this.userDetails.usertype == 'Admin') {
      this.getFlabyAdmin();
    } else if(this.userDetails.usertype == 'CountryHead') 
    {
      this.getFlaByCountryhead();
    } else if(this.userDetails.usertype == 'Distributor') {
      //when userType is Distributor.
      //patch country head name , language & country by logged in user details.
      this.getFlaByDistributor();
    }
  }

  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
    //console.log('valid');
      return null;
    } else if(control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');
      return {'inValid': true};
    }else {
      return null;
    }
  }

  public emailValidator(control: FormControl) {
    ////console.log(control.value);
    if (control.value && control.value.length > 0 &&  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value)) {
      //valid
      return null;
    } else if(control.value != null && control.value.length > 0 && !( /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value))) {
      //invalid
      return {'isEmail': true};

    }else {
      return null;
    }
  }

  getFlabyAdmin() {
    //console.log(this.flaList);
    this.spinner.show();
    const filters = [];
    filters.push({
      "member": "flaId",
      "value":this.flaId,
      "operation": "EqualTo"
    })
    let data={
      "Filters":filters
    }
    this.apiService.getFlabyAdmin(data).subscribe(response => {
      console.log(response);
      this.spinner.hide();
      if(response['content'].payLoad.length > 0) {
        this.flaList = response['content'].payLoad;
        this.flaList.forEach(element => {
          if(element.id == this.flaId) {
            this.flaData = element;
            this.patchValue();
          }
        });
        //console.log(this.flaData);
      }
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  getFlaByCountryhead() {
    //console.log(this.flaList);
    this.spinner.show();
    let data ={"countryHeadId": localStorage.userId,
    "options":{}}
    this.apiService.getFlaByCountryhead(data).subscribe(response => {
      //console.log(response);
      if(response['content'].payLoad.length > 0) {
        this.flaList = response['content'].payLoad;
        this.flaList.forEach(element => {
          if(element.id == this.flaId) {
            this.flaData = element;
            this.patchValue();
          }
        });
        //console.log(this.flaData);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getFlaByDistributor() {
    //console.log(this.flaList);
    this.spinner.show();
    let data ={"distributorId": localStorage.userId,
    "options":{}}
    this.apiService.getFlaByDistributor(data).subscribe(response => {
      //console.log(response);
      if(response['content'].payLoad.length > 0) {
        this.flaList = response['content'].payLoad;
        this.flaList.forEach(element => {
          if(element.id == this.flaId) {
            this.flaData = element;
            this.patchValue();
          }
        });
        //console.log(this.flaList);
      }
      this.spinner.hide();
      //console.log(this.flaList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  patchValue() {

    this.getMinAndMaxLenghBuCountry(this.flaData['address'].country)

    if(this.userDetails.usertype == 'Admin') {
      this.editflaForm.patchValue({
        id:this.flaData['id'],
        country:this.flaData['address'].country,
        language: this.flaData['language'],
        distributorName:this.flaData['distributorName'],
        countryHeadName:this.flaData['countryHeadName'],
        name:this.flaData['name'] ,
        email:this.flaData['email'],
        Contact:this.flaData['mobileNo']
      })
    } else if(this.userDetails.usertype == 'CountryHead') {
      this.editflaForm.patchValue({
        id:this.flaData['id'],
        country:this.flaData['address'].country,
        language: this.userDetails['language'],
        distributorName:this.flaData['distributorName'],
        countryHeadName:this.flaData['countryHeadName'],
        name:this.flaData['name'],
        email:this.flaData['email'],
        Contact:this.flaData['mobileNo']
      })
    }  else if(this.userDetails.usertype == 'Distributor') {
      this.editflaForm.patchValue({
        id:this.flaData['id'],
        country:this.flaData['address'].country,
        language: this.userDetails['language'],
        distributorName:this.flaData['distributorName'],
        countryHeadName:this.flaData['countryHeadName'],
        name:this.flaData['name'],
        email:this.flaData['email'],
        Contact:this.flaData['mobileNo']
      })
    }

  }

  editFla() {
    //console.log(this.editflaForm);
    //console.log(this.editflaForm.value);
    let data = {
      "id":this.editflaForm.value.id,
      "name": this.editflaForm.value.name,
      "mobileNo": this.editflaForm.value.Contact,
      "email": this.editflaForm.value.email
    }
    //console.log(data);
    this.apiService.editFla(data).subscribe((response: any) => {
      //console.log(response);
      this.router.navigate(['/fla'],{queryParams:{id:2}});
      this.editflaForm.reset();
    },error => {
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }

  getMinAndMaxLenghBuCountry(countryName)
  {
    let countrydetails={};
    let data={
      "filters":[{
        "member":"country",
        "value":countryName
      }]
    }
    this.spinner.show();
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      countrydetails = response.content.payLoad[0];     
      this.minLength = countrydetails['mobileNumberLength'].min;
      this.maxLength = countrydetails['mobileNumberLength'].max;
      this.spinner.hide();
      
    },error=>{
      this.spinner.hide();
    });

  }
}
