import { Injectable,Output,EventEmitter } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'



@Injectable()
export class MessagingService {
  @Output() getUpdatedCount: EventEmitter<any> = new EventEmitter();
  currentMessage = new BehaviorSubject(null);
  messageReceived = [];
  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {

    console.log(userId, token);

    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log('TOKEN:-' +  token);
        localStorage.setItem('fcmToken',token);
        // this.subscribeTokenToTopic('dss');
        // this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  subscribeTokenToTopic(topic) {
    //console.log(topic);
    fetch('https://iid.googleapis.com/iid/v1/' + localStorage.getItem("fcmToken") + '/rel/topics/' + topic, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'key=AAAA5bE6Iro:APA91bF_qqkjfXMxDB42MVcUrn8oJ2WXASKAuIYodbzxoHZMZGVAulsZtihU_dIBquSEU9grTf3cNN_LKbXWHD8GVFCDMLc_5a61fLMQp7afYdnQrazNjpnbhZ3BnZp9o5TFGJ9ryPyK'
      })
    }).then(response => {
      //console.log(response);
      if (response.status < 200 || response.status >= 400) {
        throw 'Error subscribing to topic: ' + response.status + ' - ' + response.statusText;
      }
      //console.log('Subscribed to "' + topic + '"');
    }).catch(error => {
      console.error(error);
      // throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
      
    })
  }


  delete_subscription (topic){
    //console.log(topic);
    fetch('', {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'key=AAAA5bE6Iro:APA91bF_qqkjfXMxDB42MVcUrn8oJ2WXASKAuIYodbzxoHZMZGVAulsZtihU_dIBquSEU9grTf3cNN_LKbXWHD8GVFCDMLc_5a61fLMQp7afYdnQrazNjpnbhZ3BnZp9o5TFGJ9ryPyK'
      })
    }).then(response => {
      if (response.status < 200 || response.status >= 400) {
        throw 'Error delete_subscription to topic: ' + response.status + ' - ' + response.text();
      }
      //console.log('Subscribed to "' + topic + '"');
    }).catch(error => {
      console.error(error);
    })
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.messageReceived = [];
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        //console.log("new message received. ", payload);
        this.messageReceived.push(payload);
        // localStorage.setItem('notificationCount',this.messageReceived.length.toString());
        this.getUpdatedCount.emit(this.messageReceived.length);
        // this.currentMessage.next(this.messageReceived);
      })
  }
}
