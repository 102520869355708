import { Component, OnInit, Output, EventEmitter,ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes,Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $ :any;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private fb: FormBuilder,public apiService:ApiService,private router : Router,private spinner: NgxSpinnerService) {
    this.resetPasswordForm = this.fb.group({
      id: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      mobileNo: new FormControl ('',[Validators.required]),
      newPassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
      confirmPassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
    });
   }
  resetPasswordForm : any;
  userDetails = {'usertype':'','sub':''};
  characters = "@%+\/'!#$^?:.(){}[]~";
  @Output() resetEvent = new EventEmitter<any>();
  isValid :boolean = false;
  specialCharacters = "@%+\/'!#$^?:.(){}[]~";
  ntspecialCharacters = '`&*-_;",<>';
  ngOnInit() {
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    }
    this.resetPasswordForm = this.fb.group({
      id: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      mobileNo: new FormControl ('',[Validators.required]),
      newPassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
      confirmPassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
    });
  }

  resetDataShare(data) {
    // //console.log(data);
    this.resetPasswordForm .patchValue({
      id: data.id,
      username: data.name,
      mobileNo: data.mobileNo
    })
  }


  public alphanumericValidator(control: FormControl) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = false;
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+!*^()@%&':.?~])(?!.*[|<>`"\s-_=,]).{8,20}$/;
    // //console.log(control.value.trim().match(regex));
    // //console.log(regex.test(control.value));
    if(control.value && regex.test(control.value.trim())) {
      // //console.log('1');
      isValid = true;
      return isValid ? null : { 'alphanumeric': true }
    } else if(!control.value) {
      // //console.log('2');
      return null;
    } else {
      // //console.log('3');
      return isValid ? null : { 'alphanumeric': false }
    }
  }

  // public alphanumericValidator(control: FormControl) {
  //   // let isWhitespace = (control.value || '').trim().length === 0;
  //   let isValid = false;
  //   var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@%#+'$%?^\/\&[\:\].\\\\(){}*~])/; 
  //   if(control.value && control.value.trim().length >= 3 && control.value.trim().length <= 50 && (!isNaN(control.value) || !regex.test(control.value))) {
  //     isValid = true;
  //     return isValid ? null : { 'alphanumeric': true }
  //   }else if(!control.value) {
  //     return null;
  //   } else {
  //     return isValid ? null : { 'alphanumeric': false }
  //   }
  // }

  validate(newPassword) {
    // //console.log("@%+\/'!#$^?:.(){}[]~");
    // //console.log("validate");
    // //console.log(newPassword);
    // /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]+$/i
    var regex = /[a-zA-Z0-9~!@#$%^()+?:'.\{\}\[\]\\\/]+$/i;
    // //console.log(regex.test(newPassword));
    // //console.log(newPassword.match(regex));
  }

  resetPassword() {
    let data = {
      "userId": this.resetPasswordForm.value['id'],
      "password": this.resetPasswordForm.value['newPassword'],
      "confirmPassword": this.resetPasswordForm.value['confirmPassword']
    }
    // //console.log(this.resetPasswordForm);
    // //console.log(data);

    this.spinner.show();
    this.apiService.resetPassword(data).subscribe(response => {
      // //console.log(response);
      this.spinner.hide();
      this.resetPasswordForm.reset();
      this.isValid = false;
      this.resetEvent.emit('Password Reset Successfully.');
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }


  closeReset() {
    this.resetPasswordForm.reset();
  }

  checkConfirmpassword() {
    this.isValid = false;
    //console.log(this.isValid);
    if(this.resetPasswordForm.value['newPassword'] && this.resetPasswordForm.value['confirmPassword'] && this.resetPasswordForm.value['newPassword'] == this.resetPasswordForm.value['confirmPassword'] ) {
      this.isValid = true;
      //console.log(this.isValid);
    } else {
      this.isValid = false;
      //console.log(this.isValid);
    }
  }

}
