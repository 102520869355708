import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ActivatedRoute,RouterModule, Routes,Router } from '@angular/router';
@Component({
  selector: 'app-edit-tractor-list',
  templateUrl: './edit-tractor-list.component.html',
  styleUrls: ['./edit-tractor-list.component.css']
})
export class EditTractorListComponent implements OnInit {
  editTractorModelForm:any;
  countryList = [];
  selectedItems = [];
  dropdownSettings = {};
  tratorId;
  tractorModelList = [];
  tractorModelData = {};
  error = {'description':[]};
  constructor(private fb: FormBuilder,private location: Location, public apiService: ApiService,private router : Router,private route: ActivatedRoute,private spinner: NgxSpinnerService) {
    this.editTractorModelForm = this.fb.group({
      id: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      modelname: new FormControl ('',[Validators.required,this.alphabetsValidator]),
      active: new FormControl(true, [Validators.required]),
    });
  }
  language;
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.tratorId = params['id'];
      //console.log(this.tratorId);
    })
    this.countryList = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'countryName',
      textField: 'countryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      enableCheckAll:false,
      // allowSearchFilter: true
    };

    this.editTractorModelForm = this.fb.group({
      id: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      modelname: new FormControl ('',[Validators.required,this.alphabetsValidator]),
      active: new FormControl(true, [Validators.required]),
    });
    this.getTractorList();
    this.getCountryList({});
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z0-9 ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public alphanumericValidator(control: FormControl) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = false;
    var regex = /[^a-zA-Z0-9&\-+, ]/;
    if(control.value && control.value.trim().length >= 3 && control.value.trim().length <= 50 && (!isNaN(control.value) || !regex.test(control.value))) {
      isValid = true;
      return isValid ? null : { 'alphanumeric': true }
    }else if(!control.value) {
      return null;
    } else {
      return isValid ? null : { 'alphanumeric': false }
    }
  }

  onItemSelect(item: any) {
    //console.log(item);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }
  
  getCountryList(data) {
    this.apiService.getCountryList(data).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      //console.log(this.countryList, "this.getCountryList");
    },error=>{
      
    });
  }

  onSelectcountry(countryName) {
    //console.log(countryName)
    this.language = '';
    this.countryList.forEach(element => {
      if(element.countryName == countryName) {
        this.language = element.languages[0].languageName
      }
    });
    //console.log(this.language)

  }

  getTractorList() {
    //console.log(this.tractorModelList);
    this.apiService.getTractorModels({}).subscribe(response => {
      //console.log(response);
      this.tractorModelList = response['content'].payLoad;
      this.tractorModelList.forEach(element => {
        //console.log(element.id);
        //console.log(this.tratorId);
        this.spinner.hide();
        if(element.id == this.tratorId) {
          this.tractorModelData = element;
          this.patchValue();
        }
        //console.log(this.tractorModelData);
        
      });
      //console.log(this.tractorModelList);
    }, error => {
      //console.log(error);
    })
  }

  patchValue() {
    //console.log(this.tractorModelData);
    //console.log(this.tractorModelData['id']);
    //console.log(this.tractorModelData['tractorName']);

    // var localizationDetails = [];
    // if(this.tractorModelData['localizationDetails'].length > 0) {
    //   this.tractorModelData['localizationDetails'].forEach(element => {
    //     localizationDetails.push(element.country);
    //   });
    // }
    // //console.log(localizationDetails);
    this.editTractorModelForm.patchValue({
      id:this.tractorModelData['id'],
      modelname:this.tractorModelData['tractorName'],
      country:this.tractorModelData['country'],
      active:this.tractorModelData['active'],
    })
    this.language = this.tractorModelData['language']
    //console.log(this.editTractorModelForm);

  }

  editTractorModel() {
    //console.log(this.editTractorModelForm.value);
    // var localizationDetails = [];
    // this.countryList.forEach(countryelement => {
    //   this.editTractorModelForm.value.country.forEach(selectedCountry => {
    //     //console.log(selectedCountry);
    //     //console.log(countryelement);
    //     if(selectedCountry == countryelement.countryName) {
    //       localizationDetails.push({
    //         "country": countryelement.countryName,
    //         "language": countryelement.languages[0].languageName,
    //         "localizedName": "",
    //         "active": true
    //       })
    //     }
    //   });
    // });
    // //console.log(localizationDetails);
    // let data = {
    //   "id":this.editTractorModelForm.value.id,
    //   "tractorName": this.editTractorModelForm.value.modelname,
    //   "localizationDetails": localizationDetails,
    //   "active": true,
    // }

    let data = {
      "id":this.editTractorModelForm.value.id,
      "tractorName": this.editTractorModelForm.value.modelname,
      "country": this.editTractorModelForm.value.country,
      "language": this.language,
      "active": this.editTractorModelForm.value.active
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.editTractorModels(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/tractors'],{queryParams:{id:2}});
      this.editTractorModelForm.reset();
    },error => {
      //console.log(error);
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }
}
