import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl, NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelServiceService } from '../excel-service.service';
import { DateService } from '../date.service';
const FILE_NAME = "Customer Registration Export";
import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
import { Location } from '@angular/common';


declare var $: any;

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {

  userDetails = { 'usertype': '', 'sub': '','userid': '' };
  query = {};
  success = { 'description': [] };
  error = { 'description': [] };
  
  mobileFilter = "";
  distributorId = "";
  userType="";
  isDistributor=false;
  constructor(public excelService: ExcelServiceService, public apiService: ApiService, private route: ActivatedRoute, private location: Location, public router: Router, private spinner: NgxSpinnerService, public dateService: DateService) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      if (params['id'] == 1) {
        this.location.replaceState("customers");
        this.success.description = [];
        this.success.description.push('Customers Updated Successfully.');
        var scope = this;
        setInterval(function () {
          scope.success.description = [];
        }, 5000);
      } else if (params['id'] == 2) {
        this.location.replaceState("customers");
        this.success.description = [];
        this.success.description.push('Customers Added Successfully.');
        var scope = this;
        setInterval(function () {
          scope.success.description = [];
        }, 5000);
      }
    });
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.userType = this.userDetails.usertype;
      if(this.userDetails.usertype == "Distributor"){
        this.isDistributor=true;
        this.distributorId = this.userDetails.userid
       
      }
    }
  }
  customerregReport = [];
  countryList = [];
  currentDate = new Date();
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  currentDay = new Date().getDate();
  fixedYear = '2019';
  fy = [];
  selectedCountry = '';
  selectedFY = '';
  startDateFilter: any;
  endDateFilter: any;
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  hasMorePrevious = false;
  continuationToken = [];
  selectedCustomer;
  selectedStatus = '';
  selectedFiles: any = [];
  uploadErr = false;
  createInvoiceForm: FormGroup;
  exceltemplate:String="";

  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public endDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    componentDisabled: true,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  myData = "hello";
  //Current is 1st April 2019 to 31st March 2020 also change from & to date
  ngOnInit() {
    //console.log(this.currentYear)
    //console.log(this.currentMonth)
    //console.log(this.currentDay);
    this.getExcelTemplate();

    if (this.userDetails.usertype == 'Admin')
    {      
      this.getCountryList();
    } else 
    {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.clickOnFilter();
    }
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];

    this.createInvoiceForm = new FormGroup({      
      AttachmentExcel: new FormControl()
      
    })
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      // By default
      if (this.countryList.length > 0) {
        this.selectedCountry = '';
        this.clickOnFilter();
        //console.log(this.countryList, "this.getCountryList");
      }
    }, error => {
      this.spinner.hide();
    });
  }

  clickOnFilter() {
    this.currentPage = 1;
    //console.log(this.mobileFilter);
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state) {
    const filters = [];
    //console.log(this.mobileFilter)
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    if (this.mobileFilter) {
      filters.push({
        "member": "mobileNo",
        "value": this.mobileFilter.trim(),
        "operation": "Contains"
      })
    }
    if (this.startDateFilter && this.startDateFilter != null) {
      filters.push({
        "member": "fromDate",
        "value": this.simpleDate(this.startDateFilter)
      })
    }
    if (this.endDateFilter && this.endDateFilter != null) {
      filters.push({
        "member": "toDate",
        "value": this.simpleDate(this.endDateFilter)
      })
    }
    if (this.selectedStatus != "") {
      filters.push({
        "member": "status",
        "value": this.selectedStatus,
        "operation": "EqualTo"
      })
    }

    // if (this.distributorId) {
    //   filters.push({
    //     "member": "distributorId",
    //     "value": this.distributorId,
    //   })
    // }
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    //console.log(this.query);
    this.getCustomerregReport(state);
  }

  simpleDate(dateInfo) {
    return (dateInfo.date.year + '-' + dateInfo.date.month + '-' + dateInfo.date.day + ' ').concat(moment().format("HH:mm:ss A"));
  }

  getCustomerregReport(state) {
    this.spinner.show();
    this.customerregReport = [];
    this.apiService.getCustomers(this.query).subscribe(response => {
      //console.log(response);
      this.customerregReport = response['content'].payLoad;
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      this.spinner.hide();
      //console.log(this.customerregReport);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  private getExportableItems() {
    if (this.customerregReport && this.customerregReport.length > 0) {
      return this.customerregReport.map((item, index) => {
        //console.log(item , index);
        if (item.tractors && item.tractors.length > 1) {
          var tractorString = '';
          item.tractors.forEach(element => {
            tractorString = tractorString.concat(element.tractorName);
            tractorString = tractorString.concat(',');
          });
          item.tractorString = tractorString.slice(0, tractorString.length - 1);
        }
        else if (item.tractors && item.tractors.length == 1) {
          var tractorString = '';
          tractorString = item.tractors[0].tractorName;
          item.tractorString = tractorString;
        }
        return {
          "Sr No.": index + 1,
          "Country": item.country,
          "Customer Name": item.customerName,
          "Mobile No.": item.mobileNo,
          "Tractor Model Name": item.tractorString,
          "Registration Date": item.registrationDate != null ? moment(item.registrationDate).format('DD/MM/YYYY HH:mm') : ''
        };
      });
    } else {
      return [];
    }
  }

  onstartDatechange(event) {
    //console.log(event);
    if (event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate;
      // this.onChangeFilter();
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onendDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
      // this.onChangeFilter();
    } else {
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onChangeFilter() {
    const filters = [];
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
      })
    }
    //console.log(filters);
  }

  exporttoexcel() {
    var json = this.getExportableItems();
    this.excelService.exportDocument(json, FILE_NAME)
  }

  resetFilter() {
    if (this.userDetails.usertype == 'Admin') {
      this.selectedCountry = '';
      this.mobileFilter = "";
    }
    this.selectedRecord = "20";
    this.selectedFY = '';
    this.startDateFilter = '';
    this.mobileFilter = "";
    this.endDateFilter = '';
    this.query = {};
    this.hasMorePrevious = false;
    this.hasMoreResults = false;
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.customerregReport = [];
    this.selectedStatus = '';
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    //console.log(obj.state)
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }

  deleteModal(selectedCustomer) {
    this.selectedCustomer = selectedCustomer;
    $('#deleteModal').modal('show');
  }

  cancelDelete() {
    this.selectedCustomer = "";
    $('#deleteModal').modal('hide');
  }

  changeStatus(customerId) {
    //console.log(customerId,"distributor");
    this.spinner.show();
    this.apiService.changeCustomerStatus(customerId).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.currentPage = 1;
      this.query = {};
      this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
      this.cancelDelete();
      this.clickOnFilter();
    }, error => {
      //console.log(error);
      this.spinner.hide();
    })
  }

  editCustomer(customerDetails) {
    //console.log(countryheaddetails);
    this.router.navigate(['/customers/edit'], { queryParams: { id: customerDetails.id } });
  }

  closeAlert() {
    this.success.description = [];
  }

  addCustomer() {
    this.router.navigate(['/customers/add']);
  }

  showBulkUpload() {    
    $('#uploadModal').modal('show');
  }

  closeBulkUpload() {    
    $('#uploadModal').modal('hide');
  }

  handleExcelInput($files) {

    let filecount = this.selectedFiles.length + $files.target.files.length;

    if (filecount > 3) {
      this.uploadErr = true;
      this.createInvoiceForm.get('AttachmentExcel').reset('');
      return;
    }
    
      let file = $files.target.files[0];      
      //console.log(file);
      if (file.type && file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var self = this;
        reader.onload = function (e) {
          
          //Validate the File Size.          
            if ( Math.round(file.size / 1024 / 1024) <= 5) {
              self.uploadErr = false;

              if (file.name.length > 35) {
                file.fileName = file.name.substring(0, 32).concat('...');
              } else {
                file.fileName = file.name;
              }
              self.selectedFiles.push(file);
            } else {
              self.uploadErr = true;
              self.createInvoiceForm.get('AttachmentExcel').reset('');
              self.selectedFiles = [];
            }          
        };
      }
      else {
        this.uploadErr = true;
        this.createInvoiceForm.get('AttachmentExcel').reset('');
        this.selectedFiles = [];
      }    
  }

  confirmationModalFileUploadSubmit(createInvoiceForm: any) {          
  
    let payload = new FormData();
    
      payload.append("Files", this.selectedFiles[0], this.selectedFiles[0].name);    
      this.spinner.show();
      this.apiService.uploadCustomer(payload).subscribe(res => {
      if (res.succeeded) {
        this.spinner.hide();      
      this.success.description = ["Bulk Data Completed Successfully!"];
      this.closeBulkUpload();
      setTimeout(function () {
        $(".myAlert-top").hide();
      }, 5000);
      }           
    }, error => {
      if (error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);        
      }
      this.spinner.hide();
    })
  }

  downloadMyFile(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'src/assets/image/DSS_Customer_Template.xlsx');
    link.setAttribute('download', `DSS_Customer_Template1.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

getExcelTemplate(){  
  this.apiService.downloadTemplate().subscribe(response => {
    let downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(response.body);
                downloadLink.setAttribute('download', 'DSS_Customer_Template1.xlsx');
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();

  }

    );


  


//   this.apiService.downloadTemplate().subscribe(res => {
//     //let data = event as HttpResponse < Blob > ;
//     let data1 = Event as Response.HttpResponse <Blob>;
//     const downloadedFile = new Blob([data.body as BlobPart], {
//         type: data.body?.type
//     });
//     console.log("ddd", downloadedFile)
//     if (downloadedFile.type != "") {
//         const a = document.createElement('a');
//         a.setAttribute('style', 'display:none;');
//         document.body.appendChild(a);
//         a.download = this.fileUrl;
//         a.href = URL.createObjectURL(downloadedFile);
//         a.target = '_blank';
//         a.click();
//         document.body.removeChild(a);
//     }
// });
// this.apiService.downloadTemplate().subscribe((response:any) => {
//   const filename = response.headers.get('Content-Disposition').split(/[;'=]/).pop();
//   //saveAs(response.body, decodeURIComponent(filename));
// });
}

downLoadFile(data: any, type: string) {
  let blob = new Blob([data], { type: type});
  let url = window.URL.createObjectURL(blob);
  let pwa = window.open(url);
  if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
  }
}
}
