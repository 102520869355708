import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMyDpOptions } from 'mydatepicker';
import { DateService } from '../../date.service';
import { FormControl, Validators, FormGroup, FormBuilder, NgForm } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-tractorsrlno-wise-sevice-history',
  templateUrl: './tractorsrlno-wise-sevice-history.component.html',
  styleUrls: ['./tractorsrlno-wise-sevice-history.component.css']
})
export class TractorsrlnoWiseSeviceHistoryComponent implements OnInit {
  errorMsg ="";
  serviceHistory = [];
  tractorModelList = [];
  countryList = [];
  historyDetails = {};
  selectedCountry = "";
  selectedTractor = "";
  tractorSrlNo : any;
  selectedStatus = "";
  selectedStatusDate = ''
  startDateFilter: any;
  endDateFilter: any;
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  feedbackList = [];
  questionsList = [];
  createFeedback: FormGroup;
  selectedValue: any;
  ServiceRequestId: '';
  request :any;
  public DateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false,
    //disableUntil: {year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDate()}
  };
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}
  };

  public endDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    componentDisabled: true,
    height: '28px',
    showClearDateBtn: false
  };
  createData = [];
  public model: Object = {};
  constructor(public apiService: ApiService, private spinner: NgxSpinnerService, public dateService: DateService,
    private formBuilder: FormBuilder) {
    this.createFeedback = this.formBuilder.group({
      id: new FormControl(''),
      rating: new FormControl('',[Validators.required]),
      feedbackdate: new FormControl('',[Validators.required]),
      time:new FormControl('', [Validators.required]),
      comment:new FormControl('', [Validators.required]),
    });
    let d: Date = new Date();
    this.model = {date: {year: d.getFullYear(), 
                         month: d.getMonth() + 1, 
                         day: d.getDate()}};
  }
  userDetails = { 'usertype': '', 'sub': '','countryDetails':'' };
  IsfilterDisabled=false;

  ngOnInit() {

    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
      // this.callList();
      if (this.userDetails.usertype == 'Admin') {
        this.getCountryList();
      } else 
      if (this.userDetails.usertype == 'CountryHead') {

        var counrtyDetails=JSON.parse(this.userDetails.countryDetails)
      this.countryList=counrtyDetails;
      this.selectedCountry=this.countryList[0].countryName;
      
      }
      else {
        this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
        this.onChangeCountry();
        this.filterChanged('next');
      }
      //this.getTractorList();
    }
  }

  clickOnFilter() {
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state) {
    this.query = {};
    const filters = [];
    this.selectedStatusDate = '';
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry
      })
    }
    if (this.selectedTractor) {
      filters.push({
        "member": "tractorModel",
        "value": this.selectedTractor
      })
    }
    if (this.selectedStatus) {
      filters.push({
        "member": "status",
        "value": this.selectedStatus
      })
      this.selectedStatusDate = this.selectedStatus;
    }
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }
    if (this.tractorSrlNo) {
      filters.push({
        "member": "tractorSrno",
        "value": this.tractorSrlNo
      })
    }
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    //console.log(this.query);
    this.callList(state);
  }

  onChangeCountry() {
    if (this.userDetails.usertype == 'CountryHead' && this.selectedCountry=="")
    {  
      this.IsfilterDisabled=true;
      this.selectedTractor="";
      this.tractorModelList=[];        
   }
   else
   {
    this.IsfilterDisabled=false;  
    this.selectedTractor = '';
    this.tractorModelList = [];
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.hasMoreResults = false;

    let query = {};
    query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ],
      "itemsPerPage": -1
    }
    this.getTractorList(query);
  }
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      // By default
      // this.selectedCountry = this.countryList[0].countryName;
      this.filterChanged('next')
      //console.log(this.countryList, "this.getCountryList");
    }, error => {
      this.spinner.hide();
    });
  }

  getTractorList(query) {
    this.spinner.show();
    //console.log(this.tractorModelList);
    this.apiService.getTractorModels(query).subscribe(response => {
      //console.log(response);
      this.tractorModelList = response['content'].payLoad;
      this.spinner.hide();
      //console.log(this.tractorModelList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }


  callList(state) {
    if (this.userDetails.usertype == 'Admin') {
      this.getHistoryByAdmin(state);
    } 
    else if (this.userDetails.usertype == 'CountryHead') {
      //when userType is Country head.
      this.getHistoryByCountryhead(state);
     }
    else if (this.userDetails.usertype == 'Distributor') {
      //Distributor
      this.getHistoryByDistributor(state);
    }
     else if (this.userDetails.usertype == 'FrontLineAdvisor') {
      //FrontLineAdvisor
      this.getHistoryByFla(state);
    }
  }

  getHistoryByAdmin(state) {
    this.spinner.show();
    this.apiService.getserviceHistoryByAdmin(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.serviceHistory = response['content'].payLoad;
      if (response['content'].payLoad.length > 0) {
        this.serviceHistory.forEach((element, index) => {
          element.avgfeedback = 0;
          if (element.feedback && element.feedback.length > 0) {
            element.feedback.forEach((feedbackelement, elementIndex) => {
              //console.log(feedbackelement)
              element.avgfeedback = element.avgfeedback + feedbackelement.rating;
              //console.log(elementIndex);
              //console.log(element.feedback.length);
              if (elementIndex == element.feedback.length - 1) {
                element.avgfeedback = (element.avgfeedback / element.feedback.length).toFixed(2);
              }
            });
          }
        });
      }

      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
        else {
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }

      //console.log(this.serviceHistory);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getHistoryByCountryhead(state) {
    this.spinner.show();
    this.apiService.getServiceHistoryByCountryhead(localStorage.userId, this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.serviceHistory = response['content'].payLoad;
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
          } else if (!response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "else ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        } else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff 1111111111");
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
        } else if (!response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff no resultssssssssss");
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      //console.log(this.serviceHistory);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getHistoryByDistributor(state) {
    this.spinner.show();
    this.apiService.getServiceHistoryByDistributor(localStorage.userId, this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.serviceHistory = response['content'].payLoad;
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
          } else if (!response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "else ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        } else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff 1111111111");
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
        } else if (!response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff no resultssssssssss");
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      //console.log(this.serviceHistory);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getHistoryByFla(state) {
    this.spinner.show();
    this.apiService.getServiceHistoryByFla(localStorage.userId, this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.serviceHistory = response['content'].payLoad;
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
          } else if (!response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "else ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        } else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff 1111111111");
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
        } else if (!response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff no resultssssssssss");
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      //console.log(this.serviceHistory);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getHistoryByExecutive(state) {
    this.spinner.show();
    this.apiService.getHistoryByExecutive(localStorage.userId, this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.serviceHistory = response['content'].payLoad;
      if (response['content'].queryOptions) {
        if (state == 'next') {
          //console.log(state);
          if (response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
          } else if (!response['content'].queryOptions.hasMoreResults) {
            //console.log(state + '-->'+ "else ifffffffffff");
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        } else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff 1111111111");
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          let continuationToken = [];
          continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
          this.continuationToken = continuationToken;
        } else if (!response['content'].queryOptions.hasMoreResults) {
          //console.log(state + '-->'+ "else ifffffffffff no resultssssssssss");
          this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
        }
      }
      //console.log(this.serviceHistory);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  selectedHistorydetails(details) {
    //console.log(details);
    this.historyDetails = {};
    this.historyDetails = details;
  }

  onDatechange(event) {

  }

  onstartDatechange(event) {
    //console.log(event);

    if (event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate;
      // this.onChangeFilter();
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  onendDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
      // this.onChangeFilter();
    } else {
      this.endDateFilter = '';
      // this.onChangeFilter();
    }
  }

  resetFilter() {
    if (this.userDetails.usertype == 'Admin') {
      this.selectedCountry = "";
      this.selectedTractor = "";
      this.selectedStatus = "";
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.serviceHistory = [];
      this.tractorModelList = [];
      this.tractorSrlNo = "";
    } else {
      this.selectedTractor = "";
      this.selectedStatus = "";
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.serviceHistory = [];
      this.tractorModelList = [];
      this.tractorSrlNo = "";
    }
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.query = {};
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.filterChanged('next')
  }


  pageNavigated(obj) {
    //console.log(obj.state)
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }

  // closeAlert() {
  //   this.success.description = [];
  // }

  feedback(ml: any) {
    // alert(JSON.stringify(ml.id));
    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    this.ServiceRequestId = ml.id;
    this.request = ml;
    this.questionsList = [];
    this.query = {
      "filters": filters,
    //   "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
    //   "itemsPerPage": this.selectedRecord,
    //   "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
    //   "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
     }
    // console.log(this.selectedCountry)
    $('#uploadModal').modal('show');
    this.spinner.show();
    this.apiService.getallFeedbackQuestion(this.query).subscribe(response => {
      this.feedbackList = response['content'].payLoad;
      this.spinner.hide();
      if (this.feedbackList.length > 0) {
        this.createData = this.feedbackList.map(question => ({
          country: question.questionDetails[0].country,
          language: question.questionDetails[0].language,
          question: question.questionDetails[0].question,
          questionDetailId: question.questionDetails[0].questionDetailId,
          rating: 0,
          questionId: question.id,
          DefaultQuestion: question.questionDetails[0],
          NativeQuestion: question.questionDetails[1]
        }))
      }
      //console.log(this.createData)
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  confirmationModalClose() {
    $('#uploadModal').modal('hide');
  }


  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  dumpExcelReports() {

    this.spinner.show();

    const filters = [];
    
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry
      })
    }
    if (this.selectedTractor) {
      filters.push({
        "member": "tractorModel",
        "value": this.selectedTractor
      })
    }
    if (this.selectedStatus) {
      filters.push({
        "member": "status",
        "value": this.selectedStatus
      })
      this.selectedStatusDate = this.selectedStatus;
    }
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }
    
    if (this.tractorSrlNo) {
      filters.push({
        "member": "tractorSrno",
        "value": this.tractorSrlNo
      })
    }

    let payload = {
      "reportType": "ServiceHistoryReport",
      "filters": filters
    }
    this.apiService.dumpExcelReports(payload)
      .subscribe((obj: any) => {
        console.log(obj);
        let content_disposition = obj['content-disposition'];
        let file_name = this.getFilename(content_disposition);
        const blob: any = new Blob([obj.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let link = document.createElement("a");

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        }

        else if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", file_name != '' ? file_name : 'ServiceHistoryExcelReport');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });

  }


  getFilename(content_disposition: any): string {
    let file_name = '';
    if (content_disposition != '') {
      let arr = content_disposition.split(';');
      if (arr.length > 0) {
        if (arr[1].includes('filename')) {
          file_name = arr[1].split('=')[1];
        }
      }
    }
    return file_name;
  }
}
