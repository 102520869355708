import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelServiceService } from '../../excel-service.service';
import { DateService } from '../../date.service';
const FILE_NAME = "Feedback Report Export";
import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
// import { all } from 'q';
declare var $: any;
@Component({
  selector: 'app-feedback-report',
  templateUrl: './feedback-report.component.html',
  styleUrls: ['./feedback-report.component.css']
})
export class FeedbackReportComponent implements OnInit {
  countryList = [];
  countryheadList = [];
  distributorList = [];
  servicestationList = [];
  userDetails = { 'usertype': '', 'sub': '','userid':'','countryDetails':'' };
  selectedCountry = '';
  selectedCountryhead = '';
  selectedDistributor = '';
  selectedDistributorName = '';
  selectedserviceStations = '';
  selectedRating = '';
  fy = [];
  selectedFy = '';
  fixedYear = '2019';
  currentDate = new Date();
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  currentDay = new Date().getDate();
  constructor(public apiService: ApiService, public router: Router, private spinner: NgxSpinnerService, public dateService: DateService, public excelService: ExcelServiceService) {
    if (localStorage.getItem('userDetails') != null) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
  }
  feedbackReport = [];
  startDateFilter: any;
  endDateFilter: any;
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  hasMorePrevious = false;
  continuationToken = [];
  questionsList = [];
  public startDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    height: '28px',
    showClearDateBtn: false
  };
  public endDateOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    showInputField: true,
    editableDateField: false,
    componentDisabled: true,
    height: '28px',
    showClearDateBtn: false
    // disableUntil: {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()}
  };
  query = {};
  ngOnInit() {
    this.callList();
    $(document).ready(function () {
      $('.table-responsive').doubleScroll();
    });
    //Current is 1st April 2019 to 31st March 2020 also change from & to date
    if (this.currentYear > 2019 && this.currentMonth >= 4 && this.currentDay >= 1) {
      //If today is 1st April or greater , then new financial year has started.
      for (let index = 2019; index < this.currentYear; index++) {
        if (index < 2019) {
          this.fy.push({ "currrentYear": index, "comingYear": index + 1 });
        }
      }
    }
    this.fy.push({ "currrentYear": this.currentYear, "comingYear": this.currentYear + 1 });
  }

  callList() {
    if (this.userDetails.usertype == 'Admin') {
      this.getCountryList();
    } else if (this.userDetails.usertype == 'CountryHead')
     {
      //this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.countryList=JSON.parse(this.userDetails.countryDetails)
      this.selectedCountry =this.countryList[0].countryName;// this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];

      this.selectedCountryhead = this.userDetails['given_name'];
      this.getCountryheadList();
      this.getDistributorByCountryhead(this.userDetails['userid']);
    } else if (this.userDetails.usertype == 'Distributor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['userid'];
      this.selectedDistributorName = this.userDetails['given_name'];
      this.getServicestationByDistributor(this.selectedDistributor);
    } else if (this.userDetails.usertype == 'FrontLineAdvisor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.selectedCountryhead = this.userDetails['countryHeadName'];
      this.selectedDistributor = this.userDetails['distributorId'];
      this.selectedDistributorName = this.userDetails['distributorName'];
      this.getServicestationByDistributor(this.selectedDistributor);
    }
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response.content.payLoad;
      // By default
      if (this.countryList.length > 0) {
        this.selectedCountry = this.countryList[0].countryName;
        this.filterChanged('next');
        this.getCountryheadList();
        //console.log(this.countryList, "this.getCountryList");
      }
    }, error => {
      this.spinner.hide();
    });
  }

  getCountryheadList() {
    let query = {
      "filters": [
        {
          "member": "country",
          "value": this.selectedCountry,
          "operation": "EqualTo"
        }
      ]
    }
    this.spinner.show();
    this.selectedCountryhead = "";
    this.distributorList = [];
    this.selectedDistributor = "";
    this.selectedDistributorName = "";
    this.servicestationList = [];
    this.selectedserviceStations = "";
    //console.log(this.countryheadList);
    this.apiService.getCountryhdList(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      if(this.userDetails.usertype==="CountryHead")
      {
        this.countryheadList = response['content'];
        this.selectedCountryhead = this.countryheadList[0].id;
        this.onselectCountryhead(this.countryheadList[0].id)

      }else{
      
      this.countryheadList = response['content'];
      }
      //console.log(this.countryheadList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  onselectCountryhead(countryHeadId) {
    //console.log(countryHeadId);
    /*if user id admin*/
    if (this.userDetails['usertype'] == 'Admin') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.getDistributorByCountryhead(countryHeadId);
    }
    if (this.userDetails['usertype'] == 'CountryHead') {
      //console.log(countryHeadId);
      this.distributorList = [];
      this.selectedDistributor = "";
      this.getDistributorByCountryhead(countryHeadId);
    }
  }

  onselectDistributor(distributorId) {
    //console.log(distributorId);
    /*if user id admin*/
    if (this.userDetails['usertype'] == 'Admin') {
      //console.log(distributorId);
      this.servicestationList = [];
      this.getServicestationByDistributor(distributorId);
    }
  }

  getDistributorByCountryhead(countryHeadId) {
    //console.log(this.distributorList);
    this.selectedDistributor = "";
    this.selectedDistributorName = '';
    this.distributorList = [];
    let data = {
      "options": {},
      "countryHeadId": countryHeadId
    }
    this.spinner.show();
    this.apiService.getDistributorByCountryhead(data).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.distributorList = response['content'].payLoad;
      //console.log(this.distributorList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  getServicestationByDistributor(distributorId) {
    //console.log(this.servicestationList);
    this.servicestationList = [];
    this.selectedserviceStations = "";
    let query = {
      "filters": [
        {
          "member": "distributorId",
          "value": distributorId,
          "operation": "EqualTo"
        }
      ]
    }

    this.spinner.show();
    this.apiService.getServicestations(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.servicestationList = response['content'].payLoad;
      //console.log(this.servicestationList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }


  getFeedbackReport(state) {
    this.spinner.show();
    this.feedbackReport = [];
    if(this.userDetails.usertype==="FrontLineAdvisor")
    {
        this.query['filters'].push({
          "member": "flaid",
          "value": this.userDetails.userid
        })
    }
    this.apiService.getFeedbackReport(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      if (response['content'].payLoad.length > 0) {
        // this.questionsList = response['content'].payLoad[0].questions;
        this.feedbackReport = response['content'].payLoad;
        //console.log(this.feedbackReport);
        this.feedbackReport.forEach(element => {
          element.finalQuestions = [];
          if (element.questions && element.questions.length > 0) {


            this.questionsList.forEach(questionElement => {

              //console.log(element.questions.findIndex((item) => item.defaultQuestion == questionElement));
              var questionIndex = element.questions.findIndex((item) => item.defaultQuestion == questionElement);
              if (questionIndex > -1) {
                element.finalQuestions.push({ defaultQuestion: element.questions[questionIndex].defaultQuestion, rating: element.questions[questionIndex].rating });
              } else if (questionIndex == -1) {
                element.finalQuestions.push({ defaultQuestion: questionElement, rating: 0 });
              }
            });
          }
        });

        if (response['content'].queryOptions) {
          if (state == 'next') {
            //console.log(state);
            if (response['content'].queryOptions.hasMoreResults) {
              //console.log(state + '-->'+ "ifffffffffff");
              this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
              //console.log(this.hasMoreResults + '-->'+ "ifffffffffff");
              let continuationToken = [];
              continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
              this.continuationToken = continuationToken;
              this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            }
            else {
              this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
            }
          }
          else if (this.continuationToken.length == 1 && response['content'].queryOptions.hasMoreResults) {
            let continuationToken = [];
            continuationToken = this.continuationToken.concat({ 'pageNo': response['content']['queryOptions'].pageNumber + 1, 'token': response['content']['queryOptions'].continuationToken, 'hasMoreResults': response['content']['queryOptions'].hasMoreResults });
            this.continuationToken = continuationToken;
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
          else {
            this.hasMoreResults = response['content']['queryOptions'].hasMoreResults;
          }
        }
        this.spinner.hide();
        //console.log(this.feedbackReport);
      } else {
        this.questionsList = [];
      }
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  clickOnFilter() {
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    // this.selectedRecord = "20";
    //console.log(this.hasMoreResults)
    this.filterChanged('next');
  }

  filterChanged(state) {
    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry
      })
    }
    if (this.selectedDistributor) {
      filters.push({
        "member": "distributorId",
        "value": this.selectedDistributor
      })
    }
    if (this.selectedserviceStations) {
      filters.push({
        "member": "serviceStationId",
        "value": this.selectedserviceStations
      })
    }
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        // "value":this.startDateFilter.date,
        "value": this.dateService.simpleDate_withoutTime(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate_withoutTime(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }
    this.query = {
      "filters": filters,
      "pageNumber": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      "itemsPerPage": this.selectedRecord,
      "hasMoreResults": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      "continuationToken": this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    }
    this.getallQuestions(state);
    this.getFeedbackReport(state);
  }

  onstartDatechange(event) {
    //console.log(event);
    if (event.jsdate != null) {
      this.startDateFilter = event;
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: false,
        disableUntil: {
          year: event.date.year,
          month: event.date.month,
          day: event.date.day
        }
      }
      this.endDateFilter = '';
      this.endDateOptions = resetDate;
      this.onChangeFilter();
    } else {
      this.startDateFilter = '';
      let resetDate: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        showInputField: true,
        editableDateField: false,
        componentDisabled: true
      }
      this.endDateOptions = resetDate;
      this.endDateFilter = '';
      this.onChangeFilter();
    }
  }

  onendDatechange(event) {
    if (event.jsdate != null) {
      this.endDateFilter = event;
      this.onChangeFilter();
    } else {
      this.endDateFilter = '';
      this.onChangeFilter();
    }
  }


  getallQuestions(state) {
    this.spinner.show();
    this.questionsList = [];

    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry
      })
    }
    let query = {
      "filters": filters,
      "itemsPerPage": -1
    }
    this.apiService.getallFeedback(query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      if (response['content'].payLoad.length > 0) {
        response['content'].payLoad.forEach(element => {
          element.questionDetails.forEach(questionELement => {
            if (questionELement.country == "Default") {
              this.questionsList.push(questionELement.question);
            }
          });
        });
      }
      //console.log(this.questionsList);
      this.getFeedbackReport(state);
    }, error => {
      this.spinner.hide();
    });
  }

  onChangeFilter() {
    const filters = [];
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        "value": this.dateService.simpleDate(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }
    //console.log(filters);
  }

  resetFilter() {
    if (this.userDetails.usertype == 'Admin') {
      this.selectedCountry = "";
      this.selectedCountryhead = "";
      this.selectedDistributor = "";
      this.selectedDistributorName = "";
      this.servicestationList = [];
      this.countryheadList = [];
      this.distributorList = [];
      this.servicestationList = [];
      this.selectedserviceStations = "";
      this.selectedFy = "";
      this.selectedRating = "";
      this.selectedRecord = "20";
      this.startDateFilter = '';
      this.endDateFilter = "";
      this.questionsList = [];
      this.feedbackReport = [];
    } else if (this.userDetails.usertype == 'CountryHead') {
      this.selectedDistributor = "";
      this.selectedDistributorName = "";
      this.servicestationList = [];
      this.selectedserviceStations = "";
      this.selectedRating = "";
      this.selectedRecord = "20";
      this.startDateFilter = '';
      this.endDateFilter = "";
      this.questionsList = [];
      this.feedbackReport = [];
    } else if (this.userDetails.usertype == 'Distributor') {
      this.selectedserviceStations = "";
      this.selectedRating = "";
      this.selectedRecord = "20";
      this.startDateFilter = '';
      this.endDateFilter = "";
      this.questionsList = [];
      this.feedbackReport = [];
    } else {
      this.selectedRating = "";
      this.selectedRecord = "20";
      this.startDateFilter = '';
      this.endDateFilter = "";
      this.questionsList = [];
      this.feedbackReport = [];
    }
    this.query = {};
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
  }

  private getExportableItems() {
    if (this.feedbackReport && this.feedbackReport.length > 0) {
      return this.feedbackReport.map((item, index) => {
        return {
          "Sr No.": index + 1,
          "Distributor Code": item.distributorCode,
          "Request Id": item.requestIdentificationNo,
          "Customer": item.customerName,
          "Service Station Name": item.serviceStationName,
          "Tractor Model Name": item.tractorModel,
          "Service Date": item.serviceDate != null ? moment(item.serviceDate).format('DD/MM/YYYY') : '',
          "Technician": item.mechanic,
          "Questions": item.finalQuestions
        };
      });
    } else {
      return [];
    }
  }

  exporttoexcel() {
    //console.log(this.getExportableItems());
    var excelExport = [];
    var json = this.getExportableItems();
    if (json.length > 0) {
      json.forEach((element, index) => {
        var questions = element['Questions'];
        //console.log(index);
        //console.log(questions);
        for (var i in questions) {
          //console.log(i); // alerts key
          //console.log(questions[i].defaultQuestion); //alerts key's value
          // //console.log(questions[i].rating);
          element[questions[i].defaultQuestion] = questions[i].rating;
        }
        //console.log(json);
        delete element['Questions'];
      });
    }
    //console.log(json);
    this.excelService.exportDocument(json, FILE_NAME)
  }

  dumpExcelReports() {

    this.spinner.show();

    const filters = [];
    if (this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry
      })
    }
    if (this.selectedDistributor) {
      filters.push({
        "member": "distributorId",
        "value": this.selectedDistributor
      })
    }
    if (this.selectedserviceStations) {
      filters.push({
        "member": "serviceStationId",
        "value": this.selectedserviceStations
      })
    }
    if (this.startDateFilter != null && this.startDateFilter.jsdate != null) {
      filters.push({
        "member": "fromDate",
        // "value":this.startDateFilter.date,
        "value": this.dateService.simpleDate_withoutTime(this.startDateFilter),
        "operation": "GreaterThanOrEqualTo"
      })
    }
    if (this.endDateFilter != null && this.endDateFilter.jsdate != null) {
      filters.push({
        "member": "toDate",
        "value": this.dateService.simpleDate_withoutTime(this.endDateFilter),
        "operation": "LessThanOrEqualTo"
      })
    }

    let payload = {
      "reportType": "Feedback",
      "filters": filters
    }
    this.apiService.dumpExcelReports(payload)
      .subscribe((obj: any) => {
        console.log(obj);
        let content_disposition = obj['content-disposition'];
        let file_name = this.getFilename(content_disposition);
        const blob: any = new Blob([obj.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let link = document.createElement("a");

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        }

        else if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", file_name != '' ? file_name : 'FeedbackExcelReport');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
      });

  }

  getFilename(content_disposition: any): string {
    let file_name = '';
    if (content_disposition != '') {
      let arr = content_disposition.split(';');
      if (arr.length > 0) {
        if (arr[1].includes('filename')) {
          file_name = arr[1].split('=')[1];
        }
      }
    }
    return file_name;
  }

  changeRecords(selectedRecord) {
    //console.log(selectedRecord);
    //Reset Data & call list functionality
    this.query = {};
    this.selectedRecord = selectedRecord;
    this.currentPage = 1;
    this.continuationToken = [{ 'pageNo': 1, 'token': null, 'hasMoreResults': false }];
    this.filterChanged('next');
  }

  pageNavigated(obj) {
    //console.log(obj, " state");
    this.currentPage = obj.currentPage;
    this.continuationToken = obj.continuationToken;
    this.hasMoreResults = obj.hasMoreResults;
    this.selectedRecord = obj.selectedRecord;
    this.filterChanged(obj.state);
  }
}
