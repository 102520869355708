import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { RouterModule, Routes,Router,ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
@Component({
  selector: 'app-editservice-type',
  templateUrl: './editservice-type.component.html',
  styleUrls: ['./editservice-type.component.css']
})
export class EditserviceTypeComponent implements OnInit {
  servicetypeList = [];
  countryList = [];
  servicetypeId;
  selectedCountry;
  servicetypeData = {};
  editServicetypeForm: any;
  typeId;
  defaultStatus;
  defaulttype = "";
  defaulttypelId;
  query = {};
  isValid : boolean = true;
  nativeQuestion : boolean = true;
  error = {'description':[]};
  constructor(public apiService:ApiService,private spinner: NgxSpinnerService,private fb: FormBuilder,private router:Router,private route: ActivatedRoute,private location: Location) { 
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.servicetypeId = params['id'];
      this.selectedCountry = params['country'];      
      //console.log(this.servicetypeId);
      //console.log(this.selectedCountry);
    })

    this.editServicetypeForm = this.fb.group({
      id:['',Validators.required],
      country:['',Validators.required],
      language:['',Validators.required],
      serviceType:['',Validators.required]
    })
  }

  ngOnInit() {
    this.editServicetypeForm = this.fb.group({
      id:['',Validators.required],
      country:['',Validators.required],
      language:['',Validators.required],
      serviceType:['',Validators.required]
    })
    this.getCountryList();
  }

  getCountryList() {
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.countryList = response.content.payLoad;
      if(this.countryList.length > 0) {
        this.countryList.forEach(element => {
          element.serviceType = "";
        });
      }
      //console.log(this.countryList, "this.getCountryList");
      this.getServicetypeList();
    },error=>{
      
    });
  }

  

  getServicetypeList() {
    //console.log(this.servicetypeList);
    const filters = [];
    if(this.selectedCountry) {
      filters.push({
        "member": "country",
        "value": this.selectedCountry,
        "operation": "EqualTo"
      })
    }
    this.query = {"filters":filters};
    this.spinner.show();
    this.apiService.getallServicetypes(this.query).subscribe(response => {
      //console.log(response);
      this.spinner.hide();
      this.servicetypeList = response['content'].payLoad;
      if(this.servicetypeList.length > 0) {
        this.servicetypeList.forEach(element => {
          if(element.id == this.servicetypeId) {
            this.servicetypeData = element;
            this.patchValue();
          }
        });
      }
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  patchValue() {
    //console.log(this.servicetypeData);
    this.typeId = this.servicetypeData['id'];
    this.servicetypeData['serviceTypeDetails'].forEach(typeElement => {
      this.countryList.forEach(countryElement => {
        //console.log(typeElement);
        //console.log(countryElement);

        //console.log(typeElement.country +"-->"+ countryElement.countryName +"--->");
        if(typeElement.country == countryElement.countryName) {
          countryElement.serviceType = typeElement.serviceTypeName;
          countryElement.active = typeElement.active;
          countryElement.serviceTypeDetailId = typeElement.serviceTypeDetailId;
        } else if(typeElement.country == 'Default') {
          this.defaulttype = typeElement.serviceTypeName;
          this.defaultStatus = typeElement.active;
          this.defaulttypelId = typeElement.serviceTypeDetailId;
        }
      });
    });

    //console.log(this.countryList);
  }

  editserviceType() {
    const serviceTypes = [];
    //console.log(this.countryList);
    serviceTypes.push({
      "serviceTypeDetailId": this.defaulttypelId,
      "serviceTypeName": this.defaulttype ? this.defaulttype.trim() : '',
      "country": "Default",
      "language": "English",
      "active": true
    })
    
    this.countryList.forEach(element => {
      if(element.serviceType.length > 0) {
        serviceTypes.push({
          "serviceTypeDetailId": element.serviceTypeDetailId,
          "serviceTypeName": element.serviceType ? element.serviceType.trim() : '',
          "country": element.countryName,
          "language": element.languages[0].languageName,
          "active": element.active
        })
      }
    });

    //console.log(serviceTypes);
    
    let data = {"id":this.typeId,
    "serviceTypeDetails":serviceTypes};
    //console.log(data);
    this.apiService.editServicetype(data).subscribe(response => {
      //console.log(response);
      this.editServicetypeForm.reset();
      this.router.navigate(['/serviceTypes'],{queryParams:{id:2}});
    }, error => {
      //console.log(error);
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }
  
  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  changeStatus(id) {
    this.countryList.forEach(element => {
      if(element.id == id) {
        element.active = !element.active;
      }
    });
  }
  
  public alphanumericValidator(control) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    // this.isValid = false;
    // var regex = /[^a-zA-Z0-9&]/;
    // //console.log(regex.test(control));
    //console.log(control);
    if(control && control.trim().match(/^[0-9a-zA-Z ]+$/) && control.trim().length >= 4 ) {
      this.isValid = true;  
      this.defaultStatus = true;
      return true;
    } else if(control && control.trim() == "") {
      this.isValid = false;
      this.defaultStatus = false;     
      return false;
    } else {
      this.isValid = false;   
      this.defaultStatus = false;  
      return false;
    } 
  }

  changedefaultStatus() {
    if(this.defaulttype && this.defaulttype.length > 0) {
      this.alphanumericValidator(this.defaulttype);
    } else {
      this.defaultStatus = false;
    }
  }
  
  addNativeQuestion(selectedId) {
    //console.log(this.countryList);
    //console.log(selectedId);
    this.nativeQuestion = false;
    this.countryList.forEach(element => {
      element.serviceTypeError = false;
      if(element.id == selectedId) {
        if(element.serviceType && element.serviceType.trim().length >= 4) {
          //console.log('valid');
          element.serviceTypeError = false;
          element.active = true;
          this.nativeQuestion = true;
        } else if(element.serviceType && element.serviceType.trim().length > 0) {
          //console.log('in valid');
          element.serviceTypeError = true;
          this.nativeQuestion = false;
          element.active = false;
        } else if(element.serviceType && element.serviceType.trim() == "") {
          //console.log('in valid');
          element.serviceTypeError = true;
          this.nativeQuestion = false;
          element.active = false;
        }
      }
    });
  }

  closeAlert() {
    this.error.description = [];
  }
}
