import { Component, OnInit} from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router,ActivatedRoute } from '@angular/router'; 

@Component({
  selector: 'app-forgot-reset-password',
  templateUrl: './forgot-reset-password.component.html',
  styleUrls: ['./forgot-reset-password.component.css']
})
export class ForgotResetPasswordComponent implements OnInit {
  
  //forgotresetpasswordForm: any = FormGroup;
  forgotresetpasswordForm : any;   
  isValid :boolean = false;
  errors = {'description':[]};
  success = {'description':[]};
  public resetObj : any = {  
    uId: "", token : ""
  };
  constructor(private fb: FormBuilder,public apiService: ApiService,public spinner :NgxSpinnerService,private router:Router,private route:ActivatedRoute) { 
    this.forgotresetpasswordForm = this.fb.group({            
      newPassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
      confirmPassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
    });
    
  }

  ngOnInit() {
    this.resetObj.uId=this.route.snapshot.paramMap.get('ui');
    this.resetObj.token=this.route.snapshot.paramMap.get('token');
    this.forgotresetpasswordForm = this.fb.group({           
      newPassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
      confirmPassword: new FormControl('', [Validators.required,this.alphanumericValidator]),
    });
  }

  
  public alphanumericValidator(control: FormControl) {
    // let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = false;
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+!*^()@%&':.?~])(?!.*[|<>`"\s-_=,]).{8,20}$/;
    // //console.log(control.value.trim().match(regex));
    // //console.log(regex.test(control.value));
    if(control.value && regex.test(control.value.trim())) {
      // //console.log('1');
      isValid = true;
      return isValid ? null : { 'alphanumeric': true }
    } else if(!control.value) {
      // //console.log('2');
      return null;
    } else {
      // //console.log('3');
      return isValid ? null : { 'alphanumeric': false }
    }
  }

  forgetResetPassword() {
    let data = {
      "UserId": this.resetObj.uId,
      "Token":this.resetObj.token,
      "Password": this.forgotresetpasswordForm.value['newPassword'],
      "ConfirmPassword": this.forgotresetpasswordForm.value['confirmPassword']
    }
    // //console.log(this.resetPasswordForm);
    //console.log(data);

    this.spinner.show();
    this.apiService.forgetResetPassword(data).subscribe(response => {
      // //console.log(response);
      this.spinner.hide();
      this.forgotresetpasswordForm.reset();
      this.isValid = false;
      this.success.description.push('Password Reset successfully.');
    }, error => {
      //console.log(error);
      this.spinner.hide();
      this.errors.description = [];
        if (error.error.errors && error.error.errors.length > 0) {
          this.errors.description.push(error.error.errors[0]['description']);
        } else {
          this.errors.description.push('Network Issue, Please Try Again Later.');
        }
    })
  }

}
