import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { RouterModule, Routes,Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-queries-feedback',
  templateUrl: './queries-feedback.component.html',
  styleUrls: ['./queries-feedback.component.css']
})
export class QueriesFeedbackComponent implements OnInit {
  selectedTractor = '';
  selectedStatus = '';
  CustomerQueryList = [];
  countryList = [];
  selectedCountry = "";
  success = {'description':[]};
  userDetails = {'usertype':'','sub':'','countryDetails':''};
  query = {};
  continuationToken = [];
  selectedRecord = "20";
  currentPage = 1;
  hasMoreResults = false;
  constructor(public apiService:ApiService,public router : Router,private spinner: NgxSpinnerService,private route: ActivatedRoute,private location: Location) {  
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }
  }

  ngOnInit() {
    //this.getCustomerQueriesList();
    this.calllist();
  }

  calllist()
  {
    if(this.userDetails.usertype == 'Admin') 
    {
     
     this.getCountryList();
      
    }
    else if(this.userDetails.usertype == 'CountryHead')
    {
      this.countryList=JSON.parse(this.userDetails.countryDetails);
      this.selectedCountry=this.countryList[0].countryName;
      this.getCustomerQueriesList();

    } else if(this.userDetails.usertype == 'Distributor' || this.userDetails.usertype == 'FrontLineAdvisor') {
      this.selectedCountry = this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
      this.getCustomerQueriesList();
    }
  }

  getCountryList() {
    this.spinner.show();
    this.apiService.getCountryList({}).subscribe((response: any) => {
      //console.log(response, "this.getCountryList");
      this.spinner.hide();
      this.countryList = response['content'].payLoad;
      // by default
      this.selectedCountry = this.countryList[0].countryName;
      //this.getCustomerQueriesList();
      // console.log(this.countryList, "this.getCountryList");
    },error=>{
      this.spinner.hide();
    });
  }


  getCustomerQueriesList() {
   // alert();
   //console.log(this.selectedCountry)
    this.CustomerQueryList =[];
    this.spinner.show();
    const filters = [];
    filters.push({
      "member": "country",
      "value": this.selectedCountry,
      "operation": "EqualTo"
    })
    if(this.selectedStatus  != "")
    {
      filters.push({
        "member": "isActive",
        "value": this.selectedStatus,
        "operation": "EqualTo"
      })
    }
      this.query = {"filters":filters,
      // "pageNumber":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['pageNo'] : 1,
      // "itemsPerPage": this.selectedRecord,
      // "hasMoreResults":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['hasMoreResults'] : false,
      // "continuationToken":this.continuationToken.length > 0 ? this.continuationToken[this.continuationToken.length - 1]['token'] : null
    };   
    this.apiService.getCustomerQueries(this.query).subscribe(response => {
      //console.log(response);
      this.CustomerQueryList = []
      this.CustomerQueryList = response['content'].payLoad;
      
      this.spinner.hide();
      // console.log(this.CustomerQueryList);
    }, error => {
      this.spinner.hide();
      //console.log(error);
    })
  }

  resetFilters() {
    this.selectedCountry = "";
    this.CustomerQueryList = [];
  }
}
