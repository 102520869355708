import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { TransfereService } from '../../transfere.service';
import { RouterModule, Routes,Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare var google;
import { Location } from '@angular/common';

@Component({
  selector: 'app-addmechanic',
  templateUrl: './addmechanic.component.html',
  styleUrls: ['./addmechanic.component.css']
})
export class AddmechanicComponent implements OnInit {

  addMechanicForm: FormGroup;
  ServiceList: any;
  city = '';
  state = '';
  country ='';
  @ViewChild('mechanicmap')mechanicmapElement: ElementRef;
  mechanicmap: any;
  map: any;
  marker:any;
  geocoder = new google.maps.Geocoder;
  currentLng;
  currentLat;
  error = {'description':[]};
  userDetails = {'usertype':'','sub':'','userid':'','countryDetails':''};
  countryList = [];
  maxLength = 10;
  minLength = 10;
constructor(private fb: FormBuilder, public apiService: ApiService, public transfereservice: TransfereService,private router : Router,private spinner: NgxSpinnerService,private location: Location) {
  this.addMechanicForm = this.fb.group({
    Contact: new FormControl('', [Validators.required]),
    serviceStationId: new FormControl ('',[Validators.required]),
    Name: new FormControl('', [Validators.required,this.alphabetsValidator]),
    Country:new FormControl('',[Validators.required])
  });
}

ngOnInit() {
  this.addMechanicForm = this.fb.group({
    Contact: new FormControl('', [Validators.required]),
    serviceStationId: new FormControl ('',[Validators.required]),
    Name: new FormControl('', [Validators.required,this.alphabetsValidator]),
    Country:new FormControl('')
  });
  this.addMechanicForm.get('Contact').disable();
  if(localStorage.getItem('userDetails') != null ) {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    //console.log(this.userDetails);
    this.callList();
  }
}

public alphabetsValidator(control: FormControl) {
  let isValid = false;
  var regex = /[^a-zA-Z ]/;
  if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
    isValid = true;
    return isValid ? null : { 'alphabets': true }
  } else {
    return isValid ? null : { 'alphabets': false }
  }
}

callList() {
  if(this.userDetails.usertype == 'Admin') {
    let query = {"filter":[{
      "member": "Status",
      "value": true,
      "operation": "EqualTo"
    }],itemsPerPage: 500}
    // let query = {}
    this.getServiceStations(query);
    this.getCountryList({});
  } else if(this.userDetails.usertype == 'CountryHead') 
  {
    //when userType is CountryHead.
    let data = {
    "filters": [
      {
        "member": "countryHeadId",
        "value": this.userDetails.userid,
        "operation": "EqualTo"
      }, {
        "member": "Status",
        "value": true,
        "operation": "EqualTo"
      }]
    }
    if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
      this.countryList.forEach(countryElement => {
        if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
          this.minLength = countryElement.mobileNumberLength['min'];
          this.maxLength = countryElement.mobileNumberLength['max'];
        }
      });
      this.addMechanicForm.get('Contact').enable();
    } else {
      this.addMechanicForm.get('Contact').disable();
      this.addMechanicForm.get('Contact').reset('');
    }
    this.getServiceStations(data);
  } else if(this.userDetails.usertype == 'Distributor'){
    //when userType is Distributor.
    let data = {
      "filters": [
        {
          "member": "distributorId",
          "value": this.userDetails['userid'],
          "operation": "EqualTo"
        }, {
          "member": "Status",
          "value": true,
          "operation": "EqualTo"
        }]
      }
      if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
        this.countryList.forEach(countryElement => {
          if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
            this.minLength = countryElement.mobileNumberLength['min'];
            this.maxLength = countryElement.mobileNumberLength['max'];
          }
        });
        this.addMechanicForm.get('Contact').enable();
      } else {
        this.addMechanicForm.get('Contact').disable();
        this.addMechanicForm.get('Contact').reset('');
      }
    this.getServiceStations(data);
  } else if(this.userDetails.usertype == 'FrontLineAdvisor'){
    //when userType is FrontLineAdvisor.
    let data = {
    "filters": [
      {
        "member": "flaId",
        "value": this.userDetails['userid'],
        "operation": "EqualTo"
      }, {
        "member": "Status",
        "value": true,
        "operation": "EqualTo"
      }]
    }
    if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
      this.countryList.forEach(countryElement => {
        if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
          this.minLength = countryElement.mobileNumberLength['min'];
          this.maxLength = countryElement.mobileNumberLength['max'];
        }
      });
      this.addMechanicForm.get('Contact').enable();
    } else {
      this.addMechanicForm.get('Contact').disable();
      this.addMechanicForm.get('Contact').reset('');
    }
    this.getServiceStations(data);
  }
}


getCountryList(data) {
  this.apiService.getCountryList(data).subscribe((response: any) => {
    //console.log(response, "this.getCountryList");
    this.countryList = response.content.payLoad;
    //console.log(this.countryList, "this.getCountryList");
  },error=>{
  });
}

getServiceStations(data) {
  this.apiService.getServicestations(data).subscribe((response: any) => {
    //console.log(response, "this.ServiceList");
    this.ServiceList = [];
    this.ServiceList = response.content.payLoad;
    // response.content.payLoad.forEach(element => {
    //   if((element.address.country != "United States") && (element.address.country != "India"))
    //   {
    //     this.ServiceList.push(element);
    //   }
    // });
    //console.log(this.ServiceList, "this.ServiceList");
  },error => {
  })
}

public numberValidator(control: FormControl) {
  if (control.value && control.value.length >= 10) {
    //valid
  //console.log('valid');
    return null;
  } else if(control.value != null && control.value.length < 10) {
    //invalid
    //console.log('invalid');
    return {'inValid': true};
  }else {
    return null;
  }
}

public alphanumericValidator(control: FormControl) {
  // let isWhitespace = (control.value || '').trim().length === 0;
  let isValid = false;
  var regex = /[^a-zA-Z0-9&\-+, ]/;
  if(control.value && control.value.trim().length >= 3 && control.value.trim().length <= 50 && (!isNaN(control.value) || !regex.test(control.value))) {
    isValid = true;
    return isValid ? null : { 'alphanumeric': true }
  }else if(!control.value) {
    return null;
  } else {
    return isValid ? null : { 'alphanumeric': false }
  }
}


addmechanic() {
  //console.log(this.addMechanicForm.value);
  let data = {
    "name": this.addMechanicForm.value.Name,
    "mobileNo": this.addMechanicForm.value.Contact,
    "serviceStationId": this.addMechanicForm.value.serviceStationId,
    "Country": this.addMechanicForm.value.Country
  }
  //console.log(data);
  this.spinner.show();
  this.apiService.addMechanic(data).subscribe((response: any) => {
    //console.log(response);
    this.spinner.hide();
    this.router.navigate(['/technician'],{queryParams:{id:1}});
    this.addMechanicForm.reset();
  },error => {
    this.spinner.hide();
    this.error.description = [];
    if(error.error.errors.length > 0) {
      this.error.description.push(error.error.errors[0]['description']);
    }
  })
}


loadMap(latitude,longitude) {
  //console.log('Load');
  //console.log('Load');
  let latLng = new google.maps.LatLng(latitude,longitude);
  this.geocoder.geocode({'location': latLng}, (results) =>{
    if (results[0]) {
      //console.log(results[0]);
      var isSameCountry = false;
        results[0].address_components.forEach(element => {
          if(element.types[0] == 'country') {
            if(element.long_name == this.country) {
              isSameCountry = true;
            } else {
              //console.log('COUNTRY DOES NOT MATCH.....');
            }
          }
        });
        if(isSameCountry) {
          results[0].address_components.forEach(element => {
            //console.log(element.types[0]);
            if(element.types[0] == 'administrative_area_level_2') {
            //console.log(element.long_name);
            this.city = element.long_name;
            //get City
            }
            if(element.types[0] == 'administrative_area_level_1') {
            //console.log(element.long_name);
            this.state = element.long_name;
            //get State
            }
            if(element.types[0] == 'country') {
              //console.log(element.long_name);
              this.country = element.long_name;
              //get State
              }
          });
          this.addMechanicForm.patchValue({
            Address:results[0].formatted_address

          })
        }else {
          //console.log('Country Does not match');
        }
    } else {
      //console.log('No results found');
    }
  });
  let mapOptions = {
    center: latLng,
    zoom: 12,
    disableDefaultUI: true, // a way to quickly hide all controls
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    streetViewControl: false,
    overviewMapControl: false,
    fullscreenControl: false,
  }
 
  this.map = new google.maps.Map(this.mechanicmapElement.nativeElement, mapOptions);
    

  this.marker = new google.maps.Marker({
    map: this.map,
  });

  this.marker.bindTo('position', this.map, 'center');
}


getMechAddress(stationId) {
  //console.log(this.ServiceList);
  //console.log(stationId);
  if(stationId) {
  this.ServiceList.forEach(element => {
    if(element.id == stationId) {
      this.countryList.forEach(countryElement => {
        //console.log(countryElement);
        if(countryElement.countryName == element.address.country) {
          this.addMechanicForm.get('Contact').enable();
          this.minLength = countryElement.mobileNumberLength['min'];
          this.maxLength = countryElement.mobileNumberLength['max'];
        }
      });
      this.addMechanicForm.patchValue({
        Country:element.address.country
      })
      }
    }); 
  } else {
    this.addMechanicForm.get('Contact').disable();
    this.addMechanicForm.get('Contact').reset('');
  }
}

registerMechanic() {
  // this.apiService.addMechanic({}).subscribe(response => {
  //   //console.log(response);
  //   // this.mechanicList = response['content'];
  // }, error => {
  //   //console.log(error);
  // })
}
cancel() {
  this.location.back(); // <-- go back to previous location on cancel
}

closeAlert() {
  this.error.description = [];
}
}
