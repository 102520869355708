import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
declare var google;
import { ApiService } from '../../api.service';
import { RouterModule, Routes,Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-callcenter-executives',
  templateUrl: './callcenter-executives.component.html',
  styleUrls: ['./callcenter-executives.component.css']
})
export class CallcenterExecutivesComponent implements OnInit {
  @ViewChild('callcenterexecutivemap')callcenterexecutivemapElement: ElementRef;
  callcenterexecutivemap: any;
  map: any;
  marker:any;
  addCallcenterExecutiveForm: FormGroup;
  error = {'description':[]};
  maxLength = 10;
  minLength = 10;
  constructor(private fb: FormBuilder,public apiService: ApiService,private router : Router,private spinner: NgxSpinnerService,private location: Location) { 
    if(localStorage.getItem('userDetails') != null ) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      //console.log(this.userDetails);
    }

    this.addCallcenterExecutiveForm = this.fb.group({
      countryHeadId:new FormControl('', [Validators.required]),
      distributorId:new FormControl('', [Validators.required]),
      countryHeadName:new FormControl(''),
      distributorName:new FormControl(''),
      executiveName: new FormControl('', [Validators.required,this.alphabetsValidator]),
      contact: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,this.emailValidator]),
      alternateMobileNo: ['']
    });
    this.addCallcenterExecutiveForm.get('contact').disable();
    this.addCallcenterExecutiveForm.get('alternateMobileNo').disable();
  }
  geocoder = new google.maps.Geocoder;
  currentLng;
  currentLat;
  countryheadList = [];
  distributorList = [];
  countryList = [];
  userDetails = {'usertype':'','sub':''};

  ngOnInit() {
    this.addCallcenterExecutiveForm = this.fb.group({
      countryHeadId:new FormControl('', [Validators.required]),
      distributorId:new FormControl('', [Validators.required]),
      countryHeadName:new FormControl(''),
      distributorName:new FormControl(''),
      executiveName: new FormControl('', [Validators.required,this.alphabetsValidator]),
      contact: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,this.emailValidator]),
      alternateMobileNo: ['']
    });
    this.addCallcenterExecutiveForm.get('contact').disable();
    this.addCallcenterExecutiveForm.get('alternateMobileNo').disable();
    this.callList();
    // if(navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position)=>{
    //     this.currentLat = position.coords.latitude;
    //     this.currentLng = position.coords.longitude;
    //     //console.log(this.currentLat);
    //     //console.log(this.currentLng);
    //     this.loadMap(this.currentLat,this.currentLng);
    //   }, (error) => {
    //     alert("Geolocation is not supported by this browser.");
    //   });
    // } else {
    //   alert("Geolocation is not supported by this browser.");
    // }
  }

  public alphabetsValidator(control: FormControl) {
    let isValid = false;
    var regex = /[^a-zA-Z ]/;
    if(control.value && control.value.trim().length >= 1 && control.value.trim().length <= 30 && !regex.test(control.value)) {
      isValid = true;
      return isValid ? null : { 'alphabets': true }
    } else {
      return isValid ? null : { 'alphabets': false }
    }
  }

  public numberValidator(control: FormControl) {
    if (control.value && control.value.length >= 10) {
      //valid
    //console.log('valid');

      return null;
    } else if(control.value != null && control.value.length < 10) {
      //invalid
      //console.log('invalid');

      return {'inValid': true};
    }else {
      return null;
    }
  }

  public emailValidator(control: FormControl) {
    ////console.log(control.value);
    if (control.value && control.value.length > 0 &&  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value)) {
      //valid
      return null;
    } else if(control.value != null && control.value.length > 0 && !( /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(control.value))) {
      //invalid
      return {'isEmail': true};

    }else {
      return null;
    }
  }

callList() {
  if(this.userDetails.usertype == 'Admin') {
    this.getCountryhdList();
    this.getCountryList({});
  } else if(this.userDetails.usertype == 'CountryHead') {
    //if CountryHead is logged in patch his/her own details to form.
    this.addCallcenterExecutiveForm.patchValue({
      countryHeadId:this.userDetails['userid'],
      countryHeadName:this.userDetails['given_name'],
      country:this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'],
      language:this.userDetails['language']
    })
    if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
      this.countryList.forEach(countryElement => {
        if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
          this.minLength = countryElement.mobileNumberLength['min'];
          this.maxLength = countryElement.mobileNumberLength['max'];
        }
      });
      this.addCallcenterExecutiveForm.get('contact').enable();
      this.addCallcenterExecutiveForm.get('alternateMobileNo').enable();
    } else {
      this.addCallcenterExecutiveForm.get('contact').disable();
      this.addCallcenterExecutiveForm.get('alternateMobileNo').disable();
      this.addCallcenterExecutiveForm.get('contact').reset('');
      this.addCallcenterExecutiveForm.get('alternateMobileNo').reset('');
    }
    this.getDistributorByCountryhead(this.userDetails['userid']);
  } else if(this.userDetails.usertype == 'Distributor') {
    //if Distributor is logged in patch his/her own details to form,
    //country,langug,countryhead,distributor details
    this.addCallcenterExecutiveForm.patchValue({
      countryHeadId:this.userDetails['countryHeadId'],
      countryHeadName:this.userDetails['countryHeadName'],
      distributorId:this.userDetails['userid'],
      distributorName:this.userDetails['given_name'],
      language:this.userDetails['language']
    })
    if(this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
      this.countryList.forEach(countryElement => {
        if(countryElement.countryName== this.userDetails['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']) {
          this.minLength = countryElement.mobileNumberLength['min'];
          this.maxLength = countryElement.mobileNumberLength['max'];
        }
      });
      this.addCallcenterExecutiveForm.get('contact').enable();
      this.addCallcenterExecutiveForm.get('alternateMobileNo').enable();
    } else {
      this.addCallcenterExecutiveForm.get('contact').disable();
      this.addCallcenterExecutiveForm.get('alternateMobileNo').disable();
      this.addCallcenterExecutiveForm.get('contact').reset('');
      this.addCallcenterExecutiveForm.get('alternateMobileNo').reset('');
    }
  }
}

getCountryList(data) {
  this.apiService.getCountryList(data).subscribe((response: any) => {
    //console.log(response, "this.getCountryList");
    this.countryList = response.content.payLoad;
    //console.log(this.countryList, "this.getCountryList");
  },error=>{
  });
}

onselectCountryhead(countryHeadId) {
  //console.log(countryHeadId);
  //console.log(this.userDetails['usertype']);
  this.countryheadList.forEach(element => {
  //console.log(element);
    if(element.id == countryHeadId) {
      this.addCallcenterExecutiveForm.get('contact').enable();
      this.addCallcenterExecutiveForm.get('alternateMobileNo').enable();
      this.countryList.forEach(subelement => {
        //console.log(subelement);

        if(subelement.countryName == element.address.country) {
          //console.log(subelement);
          this.minLength = subelement.mobileNumberLength['min'];
          this.maxLength = subelement.mobileNumberLength['max'];
        }
      });
      this.addCallcenterExecutiveForm.patchValue({
        language : element.language,
        country: element.address.country
      })
    }
  });

  /*if user id admin*/
  if(this.userDetails['usertype'] == 'Admin') {
    //console.log(countryHeadId);
    this.distributorList = [];
    this.getDistributorByCountryhead(countryHeadId);
  }
}

getCountryhdList() {
  //console.log(this.countryheadList);
  this.apiService.getCountryhdList({}).subscribe(response => {
    //console.log(response);
    this.countryheadList = response['content'];
    //console.log(this.countryheadList);
  }, error => {
    //console.log(error);
  })
};

getDistributorByCountryhead(countryHeadId) {
  //console.log(this.distributorList);
  this.spinner.show();
  let data = {
    "options" :{},
    "countryHeadId": countryHeadId
  }
  this.apiService.getDistributorByCountryhead(data).subscribe(response => {
    //console.log(response);
    this.spinner.hide();
    this.distributorList = response['content'].payLoad;
    //console.log(this.distributorList);
  }, error => {
    this.spinner.hide();
    //console.log(error);
  })
}

  addCallcenterExecutive() {
    //console.log(this.addCallcenterExecutiveForm.value);
    let data = {
      "name": this.addCallcenterExecutiveForm.value.executiveName,
      "mobileNo": this.addCallcenterExecutiveForm.value.contact,
      "email": this.addCallcenterExecutiveForm.value.email,
      "alternateMobileNo": this.addCallcenterExecutiveForm.value.alternateMobileNo,
      "distributorId": this.addCallcenterExecutiveForm.value.distributorId
    }
    //console.log(data);
    this.spinner.show();
    this.apiService.addcallcenterexecutive(data).subscribe((response: any) => {
      //console.log(response);
      this.spinner.hide();
      this.router.navigate(['/callcenterExecutive'],{queryParams:{id:2}});
      this.addCallcenterExecutiveForm.reset();
    },error => {
      this.spinner.hide();
      this.error.description = [];
      if(error.error.errors.length > 0) {
        this.error.description.push(error.error.errors[0]['description']);
      }
    })
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  closeAlert() {
    this.error.description = [];
  }
}

